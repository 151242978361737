import { FilterMatchMode } from "primereact/api";

export class Colaborador {

    model = {
        id: {
            value: null,
            export: true,
            header: 'Código',
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'id',
            sort: true,
            matchMode: FilterMatchMode.EQUALS,
            showFilterMatchModes: false,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        fecha: {
            value: null,
            export: true,
            header: 'Fecha (día/mes/año):',
            table: true,
        },
        nombre_completo: {
            value: '',
            export: true,
            header: 'Razón Social / Nombre:',
            table: true,
            filter: true,
            sort: true,
            filterPlaceholder: 'Buscar por razón social',
            filterField: 'razon_social',
            matchMode: FilterMatchMode.CONTAINS,
            showFilterMatchModes: false,
            headerStyle: { width: '30%', minWidth: '20rem' },
        },
        identificacion: {
            value: '',
            export: true,
            header: 'No. Identificación:',
            filterField: 'identificacion',
            filterPlaceholder: 'Buscar por número de identificación',
            table: true,
            filter: true,
            sort: true,
            matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '20%', minWidth: '15rem' }
        },
        estado: {
            value: null,
            export: true,
            header: 'Estado:',
            table: true,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
    }
    optionLabel = 'nombre';
    mTitle = 'Instrucciones';
    mensaje = <><b>Instrucciones para completar el proceso de envío de información:</b><ol style={{ textAlign: 'justify' }}>
                <li>En cuanto se registre la información de las secciones de la 1 a la 8, favor guardar la información.</li>
                <li>Cuando la información se guarde, dar click en reporte y el sistema generará el formulario de su información
                    en versión PDF, el cual se deberá firmar electrónicamente, o imprimir y firmar físicamente. Si el formulario
                    es firmado físicamente, este documento deberá ser entregado al vendedor.</li>
                <li>En el botón anexos se deberá cargar el formulario firmado y los anexos requeridos.</li>
                <li>Cuando el formulario y sus anexos sean cargados al sistema favor dar click en enviar.</li></ol></>;
    titleBVG = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px' }}>Con el fin de obtener un conocimiento adecuado de las personas que desean iniciar o
        mantienen relaciones de negocios con BOLSA DE VALORES DE GUAYAQUIL S.A. BVG y que se encuentran en la categoría de Clientes y Proveedores o Accionistas, (Personas Jurídicas)
        de acuerdo a la Ley vigente, Reglamento a la Ley, Resoluciones, Políticas y procedimientos internos, necesitamos que Usted nos proporcione los siguientes datos.
        Es importante que los campos no queden en blanco, es posible que alguna información no aplique, en cuyo caso deberá expresarse escribiendo N/A.</span>;
    mensajeBVG = <><b>Instrucciones para completar el proceso de envío de información:</b><ol style={{ textAlign: 'justify' }}>
    <li>En cuanto se registre la información de las secciones de la 1 a la 7, favor guardar la información.</li>
    <li>Cuando la información se guarde, dar click en reporte y el sistema generará el formulario de su información en versión PDF, el cual se deberá firmar electrónicamente,
        o imprimir y firmar físicamente.</li>
    <li>En el botón anexos se deberá cargar el (los) formulario(s) firmado y los anexos requeridos.</li>
    <li>Cuando el formulario y sus anexos sean cargados al sistema favor dar click en enviar.</li>
    <li>Click en el botón <i>Siguiente</i> de cada sección para guardar la información.</li></ol></>;
}
