import { FilterMatchMode } from "primereact/api"

export class Gestion {

    model = {
        id: { value: null, export: false },
        nombre: {
            value: '', export: true, header: 'Nombre', required: false, maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'nombre', table: true, filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS
        },
        descripcion: {
            value: '', export: true, header: 'Descripción', required: false, maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'descripcion', table: false, filter: false, sort: false
        },
        respaldo: { header: 'Es respaldo', value: { nombre: 'NO', cat_id: false }, required: 'Campo requerido', optionLabel: 'respaldo', export: false, table: false },
        padre: { value: null, export: false },
        ruta: {
            value: '', export: true, header: 'Ruta', required: false, maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'ruta', table: true, filter: false, sort: false
        },
        sigla: {
            value: '', export: true, header: 'Sigla', required: false, maxLength: 'Máximo 150 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 150, min: 4, filterField: 'sigla', table: true, filter: false, sort: false
        },
        estado: { header: 'Estado', value: { nombre: 'Activo', cat_id: true }, required: 'Estado es requerido', optionLabel: 'nombre', export: false, table: false },
        estado_gestion: { header: 'Estado', value: '', required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true },
        fecha: {header: 'Fecha', value: null, table: true, export: true}
    }

    optionLabel = 'nombre'
}
