import { useForm } from "react-hook-form";
import { InputComponent } from "../../components/InputComponent";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { GenericComponent } from "../../components/GenericComponent";
import { PepFichaService } from "../../service/ficha/PepFichaService";
import { useState } from "react";
import { PepFuncion } from "../../models/ficha/PepFuncion";
import { Generico } from "../../components/Generico";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";

export const PepCargo = ({ tipo, id, toast, permss, opcion, catalogos }) => {

    const generic = new GenericComponent();
    const { accionesTbl } = Generico();
    const omodel = new PepFuncion();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const cargaSrv = new PepFichaService();
    const [registros, setRegistros] = useState(null);
    const [dialogCargo, setDialogCargo] = useState(false);
    const [cargo, setCargo] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inText, inDrop, inNumber } = InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    const editCargo = () => {
        cargaSrv.consultarPep(cargo?.id, opcion, tipo, id, 0).then(res => {
            let _cat = catalogos;
            const lista = { tipo_cargo: _cat.cargo };
            let _car = res.data;

            Object.keys(lista).forEach(function (key) {
                if (_car[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _car[key]) {
                            _car[key] = element;
                        }
                    });
                }
            });

            reset(_car);
            setDialogCargo(true);
        });
    }

    const loadLazyCargo = (idp) => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        let _lazyParams = lazyParams;
        _lazyParams.idp = idp ?? id;
        _lazyParams.tipo = tipo;
        loadLazyTimeout = setTimeout(() => {
            cargaSrv.getPep({ lazyEvent: JSON.stringify(_lazyParams) }, opcion).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
            });
        });
    }

    const onSubmit = (data) => {
        data.idp = id;
        data.tipo = tipo;
        if (getValues('id') == null) {
            cargaSrv.newPep(data, opcion).then(res => {
                setDialogCargo(false);
                loadLazyCargo();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            cargaSrv.editarPep(data, opcion).then(res => {
                setDialogCargo(false);
                loadLazyCargo();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const openNew = () => {
        setCargo(null);
        setDialogCargo(true);
        reset(defaultValues);
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteCargo(cargo?.id)
        });
    };

    const deleteCargo = (id) => {
        cargaSrv.eliminarPep(id, opcion, tipo).then(res => {
            loadLazyCargo();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const dialogoCargo = () => {
        return (
            <Dialog visible={dialogCargo} header={getValues('id') == null ? "Nueva Información" : "Editar Información"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogCargo(false)} style={{ width: '400px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="formgrid grid">
                        {inDrop('tipo_cargo', catalogos?.cargo, { lab: omodel.optionLabel }, 12)}
                        {inText('funcion', {}, 12)}
                        {inNumber('rmu', 2, true, {}, 12)}
                        {inText('observacion', {}, 12)}
                    </div>

                    {generic.buttonsForms(setDialogCargo)}
                </form>
            </Dialog>
        )
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!cargo?.nemonico || cargo.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editCargo() } });
        if (permss?.eliminar && (!cargo?.nemonico || cargo?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    const headerTemplate = (options) => {
        return (
            <div className={options.className}>
                <div className="flex align-items-center gap-2">
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" type="button" onClick={openNew} title="Registrar información"
                        style={{ display: permss?.crear ? 'block' : 'none' }} />
                    <span className="font-bold">Indique el Cargo o Función que desempeña o desempeñaba, en el último año, dentro o fuera del país Usted, su cónyuge o conviviente, o su familiar cercano</span>
                </div>
            </div>
        );
    }

    const tableCargo = () => {
        return (
            <>
                <Panel headerTemplate={headerTemplate} className="panel-ficha col-12">
                    {table({ action01: accionesTbl, reg: setCargo, items: itemsMenu() })}
                </Panel>
            </>
        )
    }

    return {
        tableCargo,
        dialogoCargo,
        loadLazyCargo
    }
}
