import { FilterMatchMode } from "primereact/api";

export class NombrePep1 {

    model = {
        id: { value: null, export: false, header: 'Código' },
        nombres: {
            value: '', export: true, header: 'Nombres', required: 'Campo requerido', maxLength: 'Máximo 500 caracteres', table: true, filter: true, max: 500, min: 4,
            filterPlaceholder: 'Buscar por Nombres', minLength: 'Mínimo 4 caracteres', filterField: 'nombres', matchMode: FilterMatchMode.CONTAINS, sort: true,
            headerStyle: { width: '30%', minWidth: '15rem' }
        },
        puesto: {
            value: '', export: true, table: true, filter: true, header: 'Puesto institucional', required: 'Campo requerido', maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, headerStyle: { width: '30%', minWidth: '15rem' }, fielterField: 'puesto', sort: true,
            matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Buscar por puesto institucional'
        },
        identificacion: {
            value: '', export: true, header: 'Identificación', required: false, maxLength: 'Máximo 50 caracteres', table: true, filter: false, sort: false,
            minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'identificacion'
        },
        mensual: { value: 0, table: true, header: 'Remuneración mensual', required: false, minLength: 'Valor mínimo 0', min: 0 },
        anual: { value: 0, table: true, header: 'Remuneración anual', required: false, minLength: 'Valor mínimo 0', min: 0 },
    }
}
