import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { Plantilla } from "../../models/matriz/Plantilla";
import { useForm } from "react-hook-form";
import { PlantillaService } from "../../service/matriz/PlantillaService";
import { InputComponent } from "../../components/InputComponent";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Seccion } from "./Seccion";
import { PlantillaCliente } from "./PlantillaCliente";
import { PlantillaAccionista } from "./PlantillaAccionista";
import { Generico } from "../../components/Generico";

export const Plantillas = () => {

    const generic = new GenericComponent();
    const omodel = new Plantilla();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues, shouldTrim: true });
    let loadLazyTimeout = null;
    const [registers, setRegisters] = useState(null);
    const plantillaService = new PlantillaService();
    const toast = useRef(null);
    const [dialogPlantilla, setDialogPlantilla] = useState(false);
    const [dialogDetalle, setDialogDetalle] = useState(false);
    const [dialogSeccion, setDialogSeccion] = useState(false);
    const [plan, setPlan] = useState(null);
    const { accionesTbl } = Generico();
    const tipos = [
        { nombre: 'Accionista', cat_id: 1 },
        { nombre: 'Cliente', cat_id: 2 },
        { nombre: 'Empleado', cat_id: 3 }
    ];
    const [dialogAccionista, setDialogAccionista] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText } = InputComponent({ registers, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            plantillaService.lista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegisters(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        reset(defaultValues);
        setDialogPlantilla(true);
    }

    const edit = () => {
        plantillaService.consultar(plan?.id).then(res => {
            const lista = { estado: generic.estados, mostrar_logo: generic.sino, mostrar_razon: generic.sino, tipo: tipos };
            let _plantilla = res.data;

            Object.keys(lista).forEach(function (key) {
                if (_plantilla[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _plantilla[key]) {
                            _plantilla[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_plantilla, omodel.model));
            setDialogPlantilla(true);
        });
    }

    const deleteItem = () => {
        confirmDialog({
            message: '¿Esta seguro de eliminar la plantilla?',
            header: 'Confirmar',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
                plantillaService.eliminar(plan?.id).then(res => {
                    generic.funcSuccess(res, toast);
                    loadLazyData();
                }).catch(error => {
                    generic.funcDeleteError(error, toast);
                });
            }
        });
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            plantillaService.nuevo(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogPlantilla(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            plantillaService.editar(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogPlantilla(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const construir = () => {
        setDialogDetalle(plan?.tipo !== 'Accionista' && plan?.tipo !== 'Empleado');
        setDialogAccionista(plan?.tipo === 'Accionista' || plan?.tipo === 'Empleado');
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar)
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { edit() } });
        if (permss?.actualizar)
            items.push({ label: 'Parámetros', icon: 'pi pi-qrcode', command: () => { construir() } });
        if (permss?.eliminar)
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { deleteItem() } });

        return [{ label: 'Opciones', items: items }];
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogDetalle && !dialogAccionista && <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nueva plantilla', permss)}
                    end={generic.rightToolbarTemplate(registers, omodel, 'Listado_plantilla', 'LISTADO PLANTILLAS')}></Toolbar>
                {table({ action01: accionesTbl, reg: setPlan, items: itemsMenu() })}
            </Card>}

            <Dialog visible={dialogPlantilla} header={getValues('id') == null ? "Nueva plantilla" : "Editar plantilla"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogPlantilla(false)} style={{ maxWidth: '400px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre', {}, 12)}
                        {inText('nemonico', {}, 12)}
                        {inDrop('tipo', tipos, { lab: omodel.optionLabel }, 6)}
                        {inDrop('mostrar_logo', generic.sino, { lab: omodel.optionLabel }, 6)}
                        {inDrop('mostrar_razon', generic.sino, { lab: omodel.optionLabel }, 6)}
                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 6)}
                    </div>

                    {generic.buttonsForms(setDialogPlantilla)}
                </form>
            </Dialog>

            <Dialog visible={dialogSeccion} header="Secciones" modal className="p-fluid modal-ssize"
                onHide={e => setDialogSeccion(false)} style={{ maxWidth: '1000px' }}>
                <Seccion generic={generic} permss={permss} toast={toast} />
            </Dialog>

            {dialogDetalle && <PlantillaCliente plan={plan} generic={generic} permss={permss} toast={toast} setRegresar={setDialogDetalle} />}
            {dialogAccionista && <PlantillaAccionista plan={plan} generic={generic} permss={permss} toast={toast} setRegresar={setDialogAccionista} />}
        </>
    )
}
