import { Panel } from "primereact/panel"
import { TabPanel, TabView } from "primereact/tabview"
import { useEffect, useState } from "react";
import { CatalogoCom } from "../../components/CatalogoCom";
import { AdCatalogoService } from "../../service/admin/AdCatalogoService";

export const FiltroPep = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [permss, setPermss] = useState(null);
    const catalogoSrv = new AdCatalogoService();

    useEffect(() => {
        catalogoSrv.getPermiso(0).then(res => {
            setPermss(res.data);
        });
    }, []);

    return (
        <>
            <Panel header="FILTROS PUESTO INSTITUCIONAL" style={{ maxWidth: '100%', margin: 'auto' }}>
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Deben incluir las siguientes palabras:">
                        <CatalogoCom permss={permss} opcion="pep" />
                    </TabPanel>
                    <TabPanel header="No deben incluir parte de la siguientes frases:">
                        <CatalogoCom permss={permss} opcion="no-pep" />
                    </TabPanel>
                </TabView>
            </Panel>
        </>
    )
}
