import { useEffect, useState } from "react";
import { GrupoDetalleEmpresa } from "../../models/administracion/GrupoDetalleEmpresa";
import { useForm } from "react-hook-form";
import { GrupoEmpresaDetalleService } from "../../service/admin/GrupoEmpresaDetalleService";
import { confirmDialog } from "primereact/confirmdialog";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputComponent } from "../../components/InputComponent";

export const GrupoEmpresaDetalle = ({ grupoId, generic, toast }) => {
    const omodel = new GrupoDetalleEmpresa();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [detalles, setDetalles] = useState(null);
    const detalleService = new GrupoEmpresaDetalleService();
    const [dialogDetalle, setDialogDetalle] = useState(false);
    const [selectedEmpresa, setSelectedEmpresa] = useState(null);
    const [lista, setLista] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inAutocomplete } = InputComponent({ registers: detalles, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            _lazyParams.grupoId = grupoId;
            detalleService.getDetalle({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setDetalles(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const onSubmit = (data) => {
        data.grupo = grupoId;
        if (getValues('id') == null) {
            detalleService.newDetalle(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogDetalle(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            detalleService.editarDetalle(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogDetalle(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editDetalle = (detalle) => {
        detalleService.consultarDetalle(detalle.id, grupoId).then(res => {
            let _detalle = res.data.detalle;
            const lista = { estado: generic.estados };

            Object.keys(lista).forEach(function (key) {
                if (_detalle[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _detalle[key]) {
                            _detalle[key] = element;
                        }
                    });
                }
            });
            if (_detalle['empresa'] != null) {
                _detalle['empresa'] = res.data.empresa;
                setSelectedEmpresa(res.data.empresa);
            }
            reset(generic.renewValues(_detalle, omodel.model));
            setDialogDetalle(true);
        });
    };

    const openNew = () => {
        setDialogDetalle(true);
        reset(defaultValues);
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarDetalle(data)
        });
    }

    const eliminarDetalle = (data) => {
        detalleService.eliminarDetalle(data.id, grupoId).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const searchEmpresa = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setLista([...lista])
            }
            else {
                detalleService.consultarEmpresa(event.query.toUpperCase(), grupoId).then(data => {
                    setLista(data.data);
                });
            }
        });
    }

    return (
        <>
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Registrar empresa', permss)}
                    end={generic.rightToolbarTemplate(detalles, omodel, 'Listado_detalle', 'LISTADO DE EMPRESAS')}></Toolbar>
                {table({ action: generic.actionEditTemplate, methEdit: editDetalle, perm: permss, methDelete: confirmDelete })}

                <Dialog visible={dialogDetalle} header={getValues('id') == null ? "Registrar empresa" : "Registrar empresa"} modal className="p-fluid modal-ssize"
                    onHide={e => setDialogDetalle(false)} style={{ maxWidth: '400px' }}>
                    <br />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="formgrid grid">
                            {inAutocomplete('empresa', selectedEmpresa, setSelectedEmpresa, lista, searchEmpresa, { lab: omodel.optionLabel, col: 12, noEsActividad: true })}
                            {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 12)}

                        </div>

                        {generic.buttonsForms(setDialogDetalle)}
                    </form>
                </Dialog>
            </Card>
        </>
    )
}
