import { FilterMatchMode } from "primereact/api"

export class PlanCliente {

    model = {
        id: {
            value: null, header: 'Código', export: true, table: true, sort: true, filterField: 'id', filter: true, matchMode: FilterMatchMode.EQUALS,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        orden: {
            value: 1, export: true, header: 'Orden', required: 'Orden es requerido', table: true, max: 1000, min: 1, minLength: 'Mínimo 1', maxLength: 'Máximo 1000',
            filterField: 'orden', filter: true, sort: true, matchMode: FilterMatchMode.EQUALS, headerStyle: { width: '10%', minWidth: '10rem' }
        },
        nombre: {
            value: '', export: true, header: 'Etiqueta principal', required: 'Campo requerido', maxLength: 'Máximo 600 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 600, min: 4, filterField: 'nombre', filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '30%', minWidth: '20rem' }
        },
        seccion: {
            value: null, export: false, required: false, header: 'Sección', table: true,
            filterField: 'seccion', headerStyle: { width: '10%', minWidth: '10rem' }
        },
        factor: {
            value: null, export: false, required: false, header: 'Factor', table: true, headerStyle: { width: '10%', minWidth: '10rem' }
        },
        ponderacion: {
            value: 0, export: false, header: 'Ponderación', required: false, maxLength: 'Máximo 100', table: true,
            minLength: 'Mínimo 0', max: 100, min: 0, headerStyle: { width: '20%', minWidth: '15' }
        },
        campo_valor: {
            value: null, export: false, required: false, header: 'Campo valor', table: true, filter: false, sort: true, headerStyle: { width: '10%', minWidth: '10rem' }
        },
        formula1: {
            value: '', export: true, header: 'Fórmula 1', required: false, maxLength: 'Máximo 1000 caracteres', table: false,
            minLength: 'Mínimo 3 caracteres', max: 1000, min: 3, headerStyle: { width: '20%', minWidth: '15' }
        },
        formula: {
            value: '', export: true, header: 'Fórmula 2', required: false, maxLength: 'Máximo 1000 caracteres', table: false,
            minLength: 'Mínimo 3 caracteres', max: 1000, min: 3, headerStyle: { width: '20%', minWidth: '15' }
        },
        form1: {
            value: '', export: true, header: 'Etiqueta secundaria', required: false, maxLength: 'Máximo 500 caracteres', table: false,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, headerStyle: { width: '30%', minWidth: '20rem' }
        },
        es_senial: { header: '¿Es señal de alerta?', value: { nombre: 'SI', cat_id: true }, required: 'Debe seleccionar', optionLabel: 'nombre', export: false, table: false },
        estado: { header: 'Estado', value: { nombre: 'Activo', cat_id: true }, required: 'Estado es requerido', optionLabel: 'nombre', export: false, table: false },
    }
    optionLabel = 'nombre';
    valores = [
        { nombre: 'Valor 1', cat_id: 'valor1' },
        { nombre: 'Valor 2', cat_id: 'valor2' },
        { nombre: 'Valor 3', cat_id: 'valor3' },
        { nombre: 'Valor 4', cat_id: 'valor4' },
        { nombre: 'Valor 5', cat_id: 'valor5' },
    ];
}
