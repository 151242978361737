import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { GenericComponent } from '../../../components/GenericComponent';
import { ClientePJService } from '../../../service/ClientePJService';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { ReferenciaBancaria } from '../ReferenciaBancaria';
import { ReferenciaComercial } from '../ReferenciaComercial';
import { AmpliadaCpj } from '../../../models/cpj/AmpliadaCpj';

export const InfoApliada = ({ toast, nuevo, back, next, id, permss }) => {

    const generic = new GenericComponent();
    const omodel = new AmpliadaCpj();
    const defaultValues = generic.default(omodel.model);
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({ defaultValues });
    const [clientepj] = useState([]);
    const clientepjSrv = new ClientePJService();
    const { inNumber } = InputComponent({ registers: clientepj, omodel, generic, errors, control, permss, id });
    const [idPJ, setIdPJ] = useState(null);
    const { tableBancaria, dialogoBancaria, loadLazyBancaria } = ReferenciaBancaria({ tipo: 'pj', id, generic, toast, permss });
    const { tableComercial, dialogoComercial, loadLazyComercial } = ReferenciaComercial({ tipo: 'pj', id, generic, toast, permss });

    useEffect(() => {
        if (!nuevo) {
            setIdPJ(id);
            clientepjSrv.consultarClientepj(id, 'p7').then(res => {
                let _clientepj = res.clientepj;
                reset(_clientepj);
                loadLazyBancaria(id);
                loadLazyComercial(id);
            });
        }
    }, [false])

    const onSubmit = (data) => {
        data.paso = 'p7';
        data.id = id;
        clientepjSrv.editarClientepj(data).then(res => {
            generic.funcSuccess(res, toast);
            next(idPJ);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    return (
        <>
            < form onSubmit={handleSubmit(onSubmit)}>
                <Panel header="PARTE C" className="panel-ficha">
                    <Panel header="C.1 INFORMACIÓN AMPLIADA CLIENTE" className="panel-ficha">
                        <div className="formgrid grid">
                            <div className={"field col-6"}>
                                <b>Información Económica del Representante Legal:</b>
                            </div>

                            <div className={"field col-6"}>
                                <b>Información Económica del Apoderado:</b>
                            </div>

                            {inNumber('INGR_MENSUAL_RL', 2, true)}
                            {inNumber('EGRE_MENSUAL_RL', 2, true)}
                            {inNumber('INGR_MENSUAL_AP', 2, true)}
                            {inNumber('EGRE_MENSUAL_AP', 2, true)}
                        </div>
                    </Panel>
                    <br />

                    <Panel header="C.2 REFERENCIAS DE LA COMPAÑÍA" className="panel-ficha">
                        <Panel header="REFERENCIAS BANCARIAS" className="panel-ficha">
                            {tableBancaria()}
                        </Panel>
                        <br />

                        <Panel header="REFERENCIAS COMERCIALES O PERSONALES (ÚNICAMENTE EN EL CASO DE SER REQUERIDO POR EL VENDEDOR)" className="panel-ficha">
                            {tableComercial()}
                        </Panel>
                    </Panel>
                </Panel >
                <br />
                <div className="formgrid grid">
                    <div className="field col">
                        <Button label="Anterior" icon="pi pi-chevron-left" onClick={back} />
                    </div>
                    <div className="field col">
                        <Button label="Siguiente" icon="pi pi-chevron-right" type="submit" />
                    </div>
                </div>
            </form >
            {dialogoBancaria()}
            {dialogoComercial()}
        </>
    )
}
