import { useForm } from "react-hook-form";
import { ConsumidoresFrm } from "../../models/ConsumidoresFrm"
import { ConsumidorFrmService } from "../../service/ConsumidorFrmService";
import { InputComponent } from "../../components/InputComponent";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { useToken } from "../../components/useToken";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { Envio } from "./Envio";
import { Generico } from "../../components/Generico";

export const Formulario = ({ id, generic, toast, permss, empresa, tipo }) => {

    const omodel = new ConsumidoresFrm();
    const { accionesTbl } = Generico();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [forms, setForms] = useState(null);
    const formService = new ConsumidorFrmService();
    const [catalogo, setCatalogo] = useState(null);
    const [dialogNotificacion, setDialogNotificacion] = useState(false);
    const [dialogEnvio, setDialogEnvio] = useState(false);
    const [notificacion, setNotificacion] = useState(null);
    const { getRol } = useToken();
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inCalendar } =
        InputComponent({ registers: forms, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.ids = id;
            _lazyParams.tipo = tipo;
            delete _lazyParams.catalogo;
            delete _lazyParams.empresa;
            if (empresa) {
                _lazyParams.empresa = empresa;
            }
            if (getRol()) {
                _lazyParams.rol = getRol();
            }
            if (catalogo == null) {
                _lazyParams.catalogo = 'lista';
            }
            formService.getForm({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setForms(res.data.registros);
                setLoading(false);
                if (catalogo == null) {
                    setCatalogo(res.data.catalogo);
                }
            });
        });
    }

    const onSubmit = (data) => {
        data.ids = id;
        data.fecha = generic.dateToString(data.fecha);
        if (getRol()?.id && getRol()?.idr) {
            data.idr = getRol()?.idr;
        }
        if (getValues('id') == null) {
            formService.newForm(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogNotificacion(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            formService.editarForm(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogNotificacion(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editForm = () => {
        formService.consultarForm(notificacion?.id, 0).then(res => {
            let cats = catalogo;
            let _form = res.data.formulario;
            const lista = { formulario: cats.forms, estado_cf: generic.estados };
            if (catalogo?.empresas?.length > 0 && _form.empresa) {
                lista.empresa = cats.empresas;
            }

            Object.keys(lista).forEach(function (key) {
                if (_form[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _form[key]) {
                            _form[key] = element;
                        }
                    });
                }
            });
            _form.fecha = generic.stringToDate(_form.fecha);
            reset(generic.renewValues(_form, omodel.model));
            setDialogNotificacion(true);
        });
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación eliminación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarForm()
        });
    }

    const eliminarForm = () => {
        formService.eliminarForm(notificacion?.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const enviar = () => {
        confirmDialog({
            message: '¿Está seguro de enviar la notificación?',
            header: 'Confirmación envío',
            icon: 'pi pi-exclamation-triangle',
            accept: () => notificar()
        });
    }

    const notificar = () => {
        setLoading(true);
        const _data = notificacion;
        _data.ids = id;
        formService.notificar(_data).then(res => {
            setLoading(false);
            generic.funcSuccess(res, toast);
        }).catch(error => {
            setLoading(false);
            generic.funcDeleteError(error, toast);
        })
    }

    const openNew = () => {
        setNotificacion(null);
        reset(defaultValues);
        setDialogNotificacion(true);
    }

    const showNotificacion = () => {
        setDialogEnvio(true);
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (notificacion?.estadof !== 'Finalizado' || notificacion?.estado_cf === 'Activo'))
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editForm() } });
        if (permss?.actualizar && (!notificacion?.estadof || notificacion?.estadof === 'Borrador'))
            items.push({ label: 'Enviar notificación al correo.', icon: 'pi pi-send', command: () => { enviar() } });
        if (permss?.consultar)
            items.push({ label: 'Notificaciones enviadas', icon: 'pi pi-book', command: () => { showNotificacion() } });
        if (permss?.eliminar && !notificacion?.estadof)
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    return (
        <>
            <Card title="Favor leer las siguientes instrucciones de uso:">
                {omodel.aviso}
            </Card>
            <br />

            <Card title="Lista de formularios">
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}></Toolbar>
                {table({ action01: accionesTbl, reg: setNotificacion, items: itemsMenu() })}
            </Card>

            <Dialog visible={dialogNotificacion} header={getValues('id') == null ? "Nuevo envío de formulario" : "Editar envío de formulario"} modal
                className="p-fluid modal-ssize" onHide={e => setDialogNotificacion(false)} style={{ maxWidth: '800px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inDrop('formulario', catalogo?.forms, { lab: omodel.optionLabel }, 6)}
                        {inDrop('estado_cf', generic.estados, { lab: omodel.optionLabel }, 6)}
                        {inCalendar('fecha', { min: 'min', minNull: 'check' }, 6)}
                        {inText('observacion', {}, 6)}
                        {catalogo?.empresas?.length > 0 && inDrop('empresa', catalogo?.empresas, { lab: omodel.optionLabel }, 6)}
                    </div>

                    {generic.buttonsForms(setDialogNotificacion)}
                </form>
            </Dialog>

            <Dialog visible={dialogEnvio} header="Notificaciones enviadas" modal
                className="p-fluid modal-ssize" onHide={e => setDialogEnvio(false)} style={{ maxWidth: '800px' }}>
                <Envio id={notificacion?.id} service={formService} permss={permss} />
            </Dialog>
        </>
    )
}
