import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { GenericComponent } from '../../../components/GenericComponent';
import { ClientePJService } from '../../../service/ClientePJService';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { CatalogoService } from '../../../service/CatalogoService';
import { Pago } from '../Pago';
import { Tercero } from '../Tercero';
import { OrigenCpj } from '../../../models/cpj/OrigenCpj';

export const OrigenLic = ({ toast, catalogos, back, next, id, permss }) => {

  const generic = new GenericComponent();
  const omodel = new OrigenCpj();
  const defaultValues = generic.default(omodel.model, null);
  const { control, formState: { errors }, handleSubmit, reset, setError, setValue } = useForm({ defaultValues });
  const [clientepj] = useState([]);
  const clientepjSrv = new ClientePJService();
  const { inNumber, inText, inArea, inDrop, inCalendar } =
    InputComponent({ registers: clientepj, omodel, generic, errors, control, permss, id });
  const [esBeneficiario, setEsBeneficiario] = useState(true);
  const [ecuadorOL, setEcuadorOL] = useState(false);
  const [ciudadesOL, setCiudadesOL] = useState([]);
  const catalogoSrv = new CatalogoService();
  const { loadLazyDataPago, tablePago, dialogoPago } = Pago({ toast: toast, tipo: 'pj', id: id, permss });
  const { loadLazyTercero, tableTercero, dialogoTercero } = Tercero({ toast: toast, tipo: 'pj', id: id, permss });

  useEffect(() => {
    clientepjSrv.consultarClientepj(id, 'p6').then(res => {
      let cats = catalogos;
      let _clientepj = res.clientepj;
      const lista = {
        moneda: cats.moneda,
        pais_ol: cats.pais,
        tipo_valor_entregado: cats.valorEntregado,
      };
      if (res.ciudades_ol != undefined) {
        lista['ciudad_ol'] = res.ciudades_ol;
        lista['provincia_ol'] = cats.provincias;
        setCiudadesOL(res.ciudades_ol);
      }
      Object.keys(lista).forEach(function (key) {
        if (_clientepj[key] != null) {
          lista[key].forEach(element => {
            if (element.cat_id === _clientepj[key]) {
              _clientepj[key] = element;
            }
          });
        }
      });
      setEcuadorOL(JSON.stringify(_clientepj.pais_ol) === JSON.stringify(cats.ecuador));
      setEsBeneficiario(JSON.stringify(_clientepj.tipo_valor_entregado) === JSON.stringify(cats.ben_final));
      if (_clientepj.fecha_transaccion) {
        _clientepj.fecha_transaccion = generic.stringToDate(_clientepj.fecha_transaccion);
        reset(generic.renewValues(_clientepj, omodel.model));
      } else {
        reset(defaultValues);
      }
      loadLazyDataPago(id);
      loadLazyTercero(id);
    });
  }, [false])

  const onSubmit = (data) => {
    data.paso = 'p6';
    data.ec = catalogos.ecuador.cat_id;
    data.fecha_transaccion = data.fecha_transaccion.toISOString().split('T')[0];
    data.id = id;
    clientepjSrv.editarClientepj(data).then(res => {
      generic.funcSuccess(res, toast);
      next(id);
    }).catch(error => {
      generic.funcError(error, toast, setError);
    });
  }

  const onChangePaisOL = (e) => {
    if (JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador) && !ecuadorOL) {
      setValue('canton_ol', '');
    }
    setEcuadorOL(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
  }

  const onChangeProvinciaOL = (e) => {
    catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
      setCiudadesOL(res.data);
    });
  }

  const onChangeTipoValor = (e) => {
    setEsBeneficiario(JSON.stringify(e.value) === JSON.stringify(catalogos.ben_final));
  }

  return (
    <>
      < form onSubmit={handleSubmit(onSubmit)}>
        <Panel header="PARTE B" className="panel-ficha">
          <Panel header=" ORIGEN LÍCITO DE FONDOS" className="panel-ficha">
            <div className="formgrid grid">
              {inText('transaccion_realiza')}
              {inNumber('valor_total_bien_servicio', 2, true)}
              {inDrop('moneda', catalogos?.moneda, { lab: omodel.optionLabel })}
              {inCalendar('fecha_transaccion')}

              {inDrop('pais_ol', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePaisOL })}
              {ecuadorOL && inDrop('provincia_ol', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvinciaOL })}
              {ecuadorOL && inDrop('ciudad_ol', ciudadesOL, { lab: omodel.optionLabel })}
              {!ecuadorOL && inText('canton_ol')}
            </div>

            <Panel header="FORMA DE PAGO" className="panel-ficha">
              {tablePago()}
            </Panel>
            <br />

            <div className="formgrid grid">
              {inArea('detalle_act_economica', 5, 100, 6)}
              {inDrop('tipo_valor_entregado', catalogos?.valorEntregado, { lab: omodel.optionLabel, chn: onChangeTipoValor }, 6)}
            </div>

            {esBeneficiario &&
              <Panel header="En caso de señalar Beneficiario Final o Tercero/s complete lo siguiente:" className="panel-ficha">
                {tableTercero()}
              </Panel>}
          </Panel>
        </Panel>
        <br />
        <div className="formgrid grid">
          <div className="field col">
            <Button label="Anterior" icon="pi pi-chevron-left" onClick={back} />
          </div>
          <div className="field col">
            <Button label="Siguiente" icon="pi pi-chevron-right" type="submit" />
          </div>
        </div>
      </form >
      {dialogoPago()}
      {dialogoTercero()}
    </>
  )
}