import { FilterMatchMode } from "primereact/api"

export class Plantilla {

    model = {
        id: {
            value: null, header: 'Código', export: true, table: true, sort: true, filterField: 'id', filter: true, matchMode: FilterMatchMode.EQUALS,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        nombre: {
            value: '', export: true, header: 'Nombre', required: 'Nombre es requerido', maxLength: 'Máximo 500 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, filterField: 'nombre', filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '30%', minWidth: '20rem' }
        },
        nemonico: {
            value: '', export: true, header: 'Nemónico', required: 'Nemónico es requerido', maxLength: 'Máximo 100 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 100, min: 4, filterField: 'nombre', filter: false, sort: false,
        },
        tipo: { header: 'Tipo', value: { nombre: 'Accionista', cat_id: 1 }, required: 'Tipo es requerido', optionLabel: 'nombre', export: true, table: true },
        mostrar_logo: { header: 'Mostrar logo empresa', value: { nombre: 'SI', cat_id: true }, required: 'campo requerido', export: true, table: true },
        mostrar_razon: { header: 'Mostrar nombre empresa', value: { nombre: 'SI', cat_id: true }, required: 'campo requerido', export: true, table: true },
        estado: { header: 'Estado', value: { nombre: 'Activo', cat_id: true }, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true },
        fecha: { header: 'Fecha', value: '', required: 'campo requerido', export: true, table: true },
    }
    optionLabel = 'nombre'
}
