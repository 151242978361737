export class OtroMueble {

    model = {
        id: { value: null, export: false },
        tipo_bien: {
            value: null,
            table: true,
            header: 'Otros Tipos de Bienes Muebles:',
            required: 'Seleccione una opción',
            footer: 'SUBTOTAL:',
        },
        valor: {
            value: 0,
            table: true,
            header: 'Valor del Bien:',
            required: 'Valor requerido',
            footer: 0,
        },
    }

    optionLabel = 'nombre';
}
