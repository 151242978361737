import { FilterMatchMode } from "primereact/api"

export class ConfiguracionArchivo {

    model = {
        id: {
            value: null, header: 'Código', export: true, table: true, sort: true, filterField: 'id', filter: true, matchMode: FilterMatchMode.EQUALS,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        orden: { value: 1, export: true, header: 'Orden', required: 'Orden es requerido', table: true, max: 1000, min: 1, minLength: 'Mínimo 1', maxLength: 'Máximo 1000',
            filterField: 'orden', filter: true, sort: true, matchMode: FilterMatchMode.EQUALS, headerStyle: { width: '10%', minWidth: '10rem' } },
        nombre: {
            value: '', export: true, header: 'Nombre / Tipo adjunto', required: false, maxLength: 'Máximo 500 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, filterField: 'nombre', filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '30%', minWidth: '20rem' }
        },
        tipo_formulario: {
            value: null, export: false, required: 'Seleccione', header: 'Tipo de formulario', table: true, filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS,
            filterField: 'tipo_formulario', headerStyle: { width: '15%', minWidth: '15rem' }
        },
        tipo_adjunto: {
            value: null, export: false, required: false, header: 'Tipo de adjunto', table: false, filter: false, sort: true, matchMode: FilterMatchMode.CONTAINS,
            filterField: 'tipo_adjunto', headerStyle: { width: '10%', minWidth: '10rem' }
        },
        obligatorio: { header: '¿Archivo obligatorio?', value: null, required: 'Campo requerido', optionLabel: 'nombre', export: true, table: true },
        es_revision: { header: '¿Es para revisión?', value: null, required: 'Campo requerido', optionLabel: 'nombre', export: true, table: true },
        nemonicos_obligatorio: {
            value: '', export: true, header: 'Nemónicos', required: false, maxLength: 'Máximo 500 caracteres', table: false,
            minLength: 'Mínimo 3 caracteres', max: 500, min: 3, filterField: 'formula1', filter: false,
        },
        extension_valida: {
            value: '', export: true, header: 'Extensiones válidas', required: false, maxLength: 'Máximo 300 caracteres', table: false,
            minLength: 'Mínimo 3 caracteres', max: 500, min: 3, filterField: 'extension_valida', filter: false,
            headerStyle: { width: '30%', minWidth: '20rem' }
        },
        estado: { header: 'Estado', value: { nombre: 'Activo', cat_id: true }, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: false },
    }
    optionLabel = 'nombre'
}
