import { FilterMatchMode } from "primereact/api"

export class PepForm {

    model = {
        idp: { value: null, export: false },
        id: {
            value: null,
            export: false,
            header: 'Código',
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'id',
            sort: true,
            matchMode: FilterMatchMode.EQUALS,
            showFilterMatchModes: false,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        tipo_formulario: {
            value: null,
            export: false,
            header: 'Tipo de formulario:',
            required: 'Seleccione una opción',
        },
        fecha: {
            value: new Date(),
            export: false,
            header: 'Fecha (día/mes/año):',
            required: 'Fecha es requerido',
            table: true,
            sort: false,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        pais: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton: {
            value: '',
            export: false,
            header: 'Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Ciudad es requerida'
        },
        apellidos: {
            value: '',
            export: false,
            header: 'Apellidos Completos del declarante:',
            required: 'Información requerida',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'apellidos',
            sort: true,
            matchMode: FilterMatchMode.CONTAINS,
        },
        nombres: {
            value: '',
            export: false,
            header: 'Nombres Completos del declarante:',
            required: 'Información requerida',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'nombres',
            sort: true,
            matchMode: FilterMatchMode.CONTAINS,
        },
        tipo_identificacion: {
            value: null,
            export: false,
            header: 'Tipo de documento:',
            required: 'Seleccione una opción'
        },
        identificacion: {
            value: '',
            export: false,
            header: 'No. doc. Identificación:',
            required: 'No. RUC / Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'identificacion',
            sort: true,
            matchMode: FilterMatchMode.CONTAINS,
        },
        razon_social_cumple: {
            value: '',
            export: false,
            header: 'Razón Social o Denominación de la Entidad, Empresa o Institución Pública donde cumple o cumplió funciones la Persona Expuesta Políticamente:',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3
        },
        direccion_cumple: {
            value: '',
            export: false,
            header: 'Dirección de la Entidad, Empresa o Institución Pública:',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3
        },
        pais_cumple: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia_cumple: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad_cumple: {
            value: null,
            export: false,
            header: 'Cantón/Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton_cumple: {
            value: '',
            export: false,
            header: 'Cantón/Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Ciudad es requerida'
        },
        en_cargo: {
            value: null,
            export: true,
            header: 'Actualmente se mantiene en el cargo:',
            required: 'Campo requerido',
            table: false
        },
        observacion_cargo: {
            value: '',
            export: false,
            header: 'Observación cargo:',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Campo requerido'
        },
        fecha_inicio: {
            value: new Date(),
            export: false,
            header: 'Fecha Inicio de funciones:',
            required: 'Fecha es requerido',
            table: false,
            sort: true,
        },
        fecha_fin: {
            value: new Date(),
            export: false,
            header: 'Fecha Término de funciones:',
            required: 'Fecha es requerido',
            table: false,
            sort: true,
        },
        tipo_relacion: {
            value: '',
            export: false,
            header: 'Tipo de Relación:',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Campo requerido'
        },
        cargo_funcion: {
            value: '',
            export: false,
            header: 'Cargo o Función:',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Campo requerido'
        },
        nombre_razon_social: {
            value: '',
            export: false,
            header: 'Nombre o Razón Social:',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Campo requerido'
        },
        estado: {
            header: 'Estado',
            value: null,
            required: 'Estado es requerido',
            export: true,
            table: true
        },
        formulario:{
            header: 'Formulario origen',
            value: null,
            required: false,
            export: true,
            table: true
        },
        lugar_aprobacion: {
            value: '',
            export: false,
            header: 'Lugar:',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Campo requerido'
        },
        fecha_aprobacion: {
            value: new Date(),
            export: false,
            header: 'Fecha Aprobación:',
            required: 'Fecha es requerido',
            table: false,
            sort: true,
        },
    }
    optionLabel = 'nombre';
    msgPep = <>Estimado usuario(a), al guardar el formulario PEP no podrá eliminar la información, ni editar la opción de SI/NO es PEP del formulario principal.</>
    lchk = <><span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '11px', verticalAlign: 'bottom' }}><ol><li>Revisión SRI</li><li>Revisión Registro Civil/Cédula/NUI</li>
        <li>Revisión SCVS, SB, SEPS, otros</li><li>Revisión Listas PEP,WC1,ONU,Sentenciados, Otros:</li></ol></span></>
    anexos = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '11px', verticalAlign: 'bottom' }}><ol><li>Cédula de Identidad NUI, Pasaporte, Visa o Documento de identidad del Cliente</li>
        <li>Cédula de Identidad NUI, Pasaporte, Visa o Documento de identidad del PEP Vinculado</li><li>Recibo de cualquiera de los servicios básicos, de la dirección declarada del Cliente</li>
        <li>Cualquier otro documento solicitado de respaldo</li></ol></span>
    cab1 = <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Con el fin de obtener un conocimiento adecuado de las personas que desean iniciar o mantienen relaciones de negocios con BOLSA DE VALORES DE GUAYAQUIL S.A. BVG
        y que se encuentran en la categoría de Persona Expuesta Políticamente (PEP's), de acuerdo a la Ley vigente, Reglamento a la Ley, Resoluciones, Políticas y procedimientos
        internos, necesitamos que Usted nos proporcione los siguientes datos.</span>
    cab2 = <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Adicionalmente declaro que mantengo (favor detallar que tipo de relación de las siguientes: comerciales, contractuales,
        laborales o me encuentro asociado de cualquier otra forma) con (favor detallar el nombre de la persona natural o jurídica)</span>
    cap3 = <span style={{ fontSize: '10px', alignContent: 'justify' }}><p>Declaro expresamente que la información proporcionada en este documento es verdadera y correcta. Autorizo a la Bolsa de Valores de Guayaquil S.A BVG, a realizar la comprobación de
        esta declaración, e informar a las autoridades competentes en caso de investigación y/o determinación de transacciones u operaciones que se consideren inusuales e injustificadas en relación a
        mis actividades y movimiento comercial. Eximo a la Bolsa de Valores de Guayaquil S.A BVG de toda responsabilidad, inclusive respecto a terceros, si esta declaración fuese falsa o errónea.</p>
        <p>Además, asumo el compromiso de informar cualquier cambio relacionado con la información indicada en el presente documento en un máximo de treinta (30) días, manteniendo actualizada a Bolsa
            de Valores de Guayaquil .S.A. BVG, proporcionándole la documentación e información que sea necesaria.</p></span>
    cap4 = <span style={{ fontSize: '10px', alignContent: 'justify' }}>En cumplimiento a los procedimientos de la Debida Diligencia, a la Política "Conozca a su Cliente" y/o "Conozca a su Empleado"
        una vez analizada la información proporcionada , apruebo la vinculación o la continuidad de la relación comercial con Bolsa de Valores de Guayaquil. S.A. BVG.</span>
    cap5 = <span style={{ fontSize: '10px', alignContent: 'justify', fontWeight: 'bold' }}>LOS DATOS PROPORCIONADOS SERÁN MANEJADOS CON ESTRICTA RESERVA Y CONFIDENCIALIDAD, EN CUMPLIMIENTO DE LO ESTIPULADO EN LA QUINTA
        DISPOSICIÓN GENERAL, DEL TITULO XXIV DE LA LEY DEL MERCADO DE VALORES-LIBRO SEGUNDO DEL CÓDIGO ORGÁNICO MONETARIO Y FINANCIERO REFERENTE AL SIGILO BURSÁTIL, EN CONCORDANCIA CON LA CUARTA
        DISPOSICION GENERAL Y CUARTO PÁRRAFO DEL ARTÍCULO 15, CAPÍTULO III, TÍTULO II DE LA LEY ORGÁNICA DE PREVENCIÓN, DETECCIÓN Y ERRADICACIÓN DEL DELITO DE LAVADO DE ACTIVOS Y DEL FINANCIAMIENTO
        DE DELITOS, Y TERCER PÁRRAFO DEL ARTÍCULO 25, CAPÍTULO III, TÍTULO I DEL REGLAMENTO GENERAL A LA LEY ORGÁNICA DE PREVENCIÓN, DETECCIÓN Y ERRADICACIÓN DEL DELITO DE LAVADO DE ACTIVOS Y DEL
        FINANCIAMIENTO DE DELITOS.</span>
    mensajeBVG = <><b>Instrucciones para completar el proceso de envío de información:</b><ol style={{ textAlign: 'justify' }}>
        <li>En cuanto se registre la información de las secciones de la 1 a la 3, favor guardar la información.</li>
        <li>Cuando la información se guarde, dar click en reporte y el sistema generará el formulario de su información en versión PDF, el cual se deberá firmar electrónicamente,
            o imprimir y firmar físicamente.</li>
        <li>En el botón anexos se deberá cargar el formulario firmado y los anexos requeridos.</li>
        <li>Cuando el formulario y sus anexos sean cargados al sistema favor dar click en enviar.</li></ol></>;
}
