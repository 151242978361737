import Axios from "axios";

export class ClientePNService {

    getClientepn(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/clientepn/lista?${queryParams}`).then(res => res.data);
    }

    newClientepn(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/clientepn/nuevo`, data).then(res => res.data);
    }

    editarClientepn(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/clientepn/editar`, data).then(res => res.data);
    }

    consultarClientepn(id, seccion) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/clientepn/consulta/${id}/${seccion}`).then(res => res.data);
    }

    eliminarClientepn(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/clientepn/eliminar/${id}`).then(res => res.data);
    }
    descargar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/clientepn/reporte/${id}`, { headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache' }, responseType: 'blob' }).then(async res => res.data);
    }
}