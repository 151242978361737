import Axios from "axios";

export class UsuarioPermisoService {

    getPermiso(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/usuario-permiso/lista?${queryParams}`).then(res => res.data);
    }

    getRolEmpresa(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/usuario-permiso/lista-empresa?${queryParams}`).then(res => res.data);
    }

    getRolGrupoEmpresa(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/usuario-permiso/lista-empresa-grupo?${queryParams}`).then(res => res.data);
    }

    newPermiso(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/administracion/usuario-permiso/nuevo`, data).then(res => res.data);
    }

    editarPermiso(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/administracion/usuario-permiso/editar`, data).then(res => res.data);
    }

    consultarPermiso(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/usuario-permiso/ver/${id}/${iniciar}`).then(res => res.data);
    }

    eliminarPermiso(id, usuario) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/administracion/usuario-permiso/eliminar/${id}/${usuario}`).then(res => res.data);
    }

    rolPermiso(opcion, id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/usuario-permiso/roles/${opcion}/${id}`).then(res => res.data);
    }

    consultarRecursoEmpresa(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/menu/lista-empresa/${id}`).then(res => res.data);
    }

    consultarRecursoempresaGrupo(id, idr) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/menu/lista-empresa-grupo/${id}/${idr}`).then(res => res.data);
    }
}