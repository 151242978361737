import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { Origen } from "../../models/fuente/Origen";
import { useForm } from "react-hook-form";
import { OrigenService } from "../../service/fuente/OrigenService";
import { InputComponent } from "../../components/InputComponent";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import XLSX from "xlsx-color";
import { Button } from "primereact/button";
import { PersonaFuente } from "./PersonaFuente";

export const Origenes = ({ personaId, detalleId }) => {
    const generic = new GenericComponent();
    const omodel = new Origen();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registros, setRegistros] = useState(null);
    const toast = useRef(null);
    const origenService = new OrigenService();
    const [dialogOrigen, setDialogOrigen] = useState(false);
    const [dialogSubir, setDialogSubir] = useState(false);
    const [dialogFuentePersona, setDialogFuentePersona] = useState(false);
    const [origen, setOrigen] = useState(null);
    const [archivo, setArchivo] = useState(null);
    const [siPersona, setSiPersona] = useState(false);
    let fileRef = useRef(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText } = InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        setSiPersona(personaId != undefined || detalleId != undefined);
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            if (personaId != undefined) {
                _lazyParams.personaId = personaId;
            }
            if (detalleId != undefined) {
                _lazyParams.detalleId = detalleId;
            }
            origenService.getOrigen({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        setDialogOrigen(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            origenService.newOrigen(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogOrigen(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            origenService.editarOrigen(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogOrigen(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editOrigen = (origen) => {
        origenService.consultarOrigen(origen.id).then(res => {
            let _origen = res.data;
            let lista = { estado: generic.estadosNum };

            Object.keys(lista).forEach(function (key) {
                if (_origen[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _origen[key]) {
                            _origen[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_origen, omodel.model));
            setDialogOrigen(true);
        });
    };

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar la fuente de origen?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarOrigen(data)
        });
    }

    const eliminarOrigen = (data) => {
        origenService.eliminarOrigen(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button label="Nuevo origen" icon="pi pi-plus" className="p-button-success mr-2" type="button" onClick={openNew} />
                {permss?.crear && <Button label="Subir archivo" icon="pi pi-upload" className="p-button-success mr-2" type="button" onClick={subir} />}
                {permss?.crear && <a href={`${process.env.PUBLIC_URL}/Plantilla-origen-fuente.xlsx`} download="Plantilla-origen-fuente" target="_blank" rel="noreferrer">
                    <Button label="Descargar plantilla" icon="pi pi-download" className="p-button-success mr-2" type="button" /></a>}
            </div>
        );
    }

    const submitFile = (event) => {
        event.preventDefault();
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

            origenService.subirArchivo({ info: data }).then(res => {
                generic.funcSuccess(res, toast);
                setDialogSubir(false);
                setArchivo(null);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        };
        reader.readAsBinaryString(archivo);
    }

    const invoiceUploadHandler = ({ files }) => {
        const [file] = files;
        setArchivo(file);
    }

    const selectFile = (e) => {
        setArchivo(e.files[0]);
        fileRef?.clear();
    }

    const subir = () => {
        setDialogSubir(true);
        setArchivo(null);
    }

    const mostrarPersonas = (data) => {
        setDialogFuentePersona(true);
        setOrigen(data);
    }

    const acciones = (rowData) => {
        return (
            <div className="actions">
                {permss?.actualizar && (!rowData?.nemonico || rowData.nemonico != 'FINALIZADO') &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editOrigen(rowData)} title="Editar" />}
                <Button icon="pi pi-users" title='Personas relacionadas' className="p-button-rounded p-button-success mr-2" onClick={() => mostrarPersonas(rowData)} />
                {permss?.eliminar && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDelete(rowData)} title="Eliminar" />}
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                {!siPersona &&
                    <Toolbar className="mb-4" start={leftToolbarTemplate}
                        end={generic.rightToolbarTemplate(registros, omodel, 'Listado_fuentes_origen', 'LISTADO FUENTE DE ORIGEN')}></Toolbar>
                }
                {table({ action5: acciones })}
            </Card>

            <Dialog visible={dialogOrigen} header={getValues('id') == null ? "Nuevo Origen" : "Editar origen"} modal className="p-fluid modal-ssize" onHide={e => setDialogOrigen(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre', {}, 6)}
                        {inText('identificacion', {}, 6)}
                        {inText('detalle', {}, 6)}
                        {inText('link', {}, 6)}
                        {inDrop('estado', generic.estadosNum, { lab: omodel.optionLabel }, 6)}

                    </div>

                    {generic.buttonsForms(setDialogOrigen)}
                </form>
            </Dialog>

            <Dialog visible={dialogSubir} header="Subir fuentes de origen" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)}>
                <span style={{ fontSize: '10px' }} >
                    Cumplir en forma general y por columnas:
                    <ul>
                        <li>El archivo plantilla descargar del botón <i>Descargar plantilla.</i></li>
                        <li>Si no hay información en los campos opcionales colocar el signo punto(.)</li>
                        <li><b>Nombre</b>: Entre 4 y 2000 caracteres.</li>
                        <li><b>Identificación</b>: Entre 5 y 50 caracteres. Debe ser único y que no haya sido previamente registrado. <br />
                            Si ya existe, se actualizará el resto de la información de ese registro.</li>
                        <li><b>Detalle</b>: Opcional, entre 10 y 2000 caracteres.</li>
                        <li><b>Link</b>: Opcional, entre 10 y 2000 caracteres.</li>
                    </ul>
                </span>
                <form onSubmit={submitFile}>
                    <div className="field col">
                        <label>Seleccione un archivo(Máximo 1MB, tipo de archivo admitido Xlsx (Excel))*</label><br />
                        <FileUpload name="file" accept=".xlsx" maxFileSize={1000000} uploadHandler={invoiceUploadHandler} mode="basic"
                            customUpload chooseLabel="Seleccione" onSelect={selectFile} ref={ref => { fileRef = ref }} />
                    </div>
                    {archivo?.name}
                    {generic.buttonsForms(setDialogSubir)}
                </form>
            </Dialog>

            <Dialog visible={dialogFuentePersona} header={"Personas vinculadas: " + origen?.nombre} modal className="p-fluid modal-ssize"
                onHide={e => { setDialogFuentePersona(false); setOrigen(null); }} style={{ maxWidth: '900px' }}>
                <PersonaFuente origenId={origen?.id} />
            </Dialog>
        </>
    )
}
