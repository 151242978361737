export class Pep {

    model = {
        id: { value: null, export: false },
        idp: { value: null, export: false },
        nombre_completo: {
            value: '',
            export: false,
            header: 'Apellidos y Nombres Completos de los PEP´s:',
            required: 'Información requerida',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 500,
            min: 3,
            table: true,
            filter: false,
            sort: true
        },
        cargo: {
            value: '',
            export: false,
            header: 'Cargo:',
            required: 'campo requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            table: true,
            max: 200,
            min: 3
        },
    }
    optionLabel = 'nombre'
}
