import { Panel } from "primereact/panel";
import { InputComponent } from "../../../components/InputComponent";
import { CatalogoService } from "../../../service/CatalogoService";
import { useForm } from "react-hook-form";
import { ClientePNService } from "../../../service/ClientePNService";
import { useEffect, useState } from "react";
import { ActvCpn } from "../../../models/cpn/ActvCpn";
import { Generico } from "../../../components/Generico";

export const ActividadPn = ({ id, catalogos, toast, seccion, nextPrev, next, permss }) => {

    const omodel = new ActvCpn();
    const model = omodel.model;    
    const { funcError, funDefault, funcSuccess, renewValues } = Generico();
    const defaultValues = funDefault(model);
    const catalogoSrv = new CatalogoService();
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({ defaultValues });  
    const { inDrop, inText, inNumber, inAutocomplete } = InputComponent({ omodel, errors, control, permss, id });  
    const clientepnSrv = new ClientePNService();
    const [ecuadorDD, setEcuadorDD] = useState(false);
    const [ciudadesDD, setCiudadesDD] = useState(null);
    const [ecuadorAE, setEcuadorAE] = useState(false);
    const [ciudadesAE, setCiudadesAE] = useState(null);
    const [selectedCiiu, setSelectedCiiu] = useState(null);
    const [ciius, setCiius] = useState(null);
    const [otraSituacion, setOtraSituacion] = useState(false);
    useEffect(() => {
        editFicha();
    }, [id]);

    const onChangePaisDD = (e) => {
        setEcuadorDD(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvinciaDD = (e) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudadesDD(res.data);
        });
    }

    const onChangePaisAE = (e) => {
        setEcuadorAE(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeSituacion = (e) => {
        setOtraSituacion(JSON.stringify(e.value) === JSON.stringify(catalogos.otra));
    }

    const onChangeProvinciaAE = (e) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudadesAE(res.data);
        });
    }

    const searchCiiu = (event) => {
        setTimeout(() => {
          if (!event.query.trim().length) {
            setCiius([...ciius]);
          }
          else {
            catalogoSrv.getActividades(event.query.toLowerCase()).then(data => {
              setCiius(data.data);
            });
          }
        });
      }

    const editFicha = () => {
        clientepnSrv.consultarClientepn(id, seccion).then(res => {
            const cats = catalogos;
            let _clientepn = res.clientepn;
            const lista = {
                pais_dd: cats.pais,
                pais_ae: cats.pais,
                situacion_laboral: cats.situacionLaboral
            }
            if (res.ciudad_dd != undefined) {
                lista['ciudad_dd'] = res.ciudad_dd;
                lista['provincia_dd'] = cats.provincias;
                setCiudadesDD(res.ciudad_dd);
            }
            if (res.ciudades_ae != undefined) {
                lista['ciudad_ae'] = res.ciudades_ae;
                lista['provincia_ae'] = cats.provincias;
                setCiudadesAE(res.ciudades_ae);
            }
            Object.keys(lista).forEach(function (key) {
                if (_clientepn[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _clientepn[key]) {
                            _clientepn[key] = element;
                        }
                    });
                }
            });
            if (_clientepn['actividad_economica'] != null) {
                _clientepn['actividad_economica'] = res.actividad_economica;
                setSelectedCiiu(res.actividad_economica);
            }
            setEcuadorDD(JSON.stringify(_clientepn.pais_dd) === JSON.stringify(cats.ecuador));
            setEcuadorAE(JSON.stringify(_clientepn.pais_ae) === JSON.stringify(cats.ecuador));
            setOtraSituacion(JSON.stringify(_clientepn.situacion_laboral) === JSON.stringify(cats.otra));
            if(_clientepn.direccion) {
                reset(renewValues(_clientepn, model));
            }
            else {
                defaultValues.id = id;
                reset(defaultValues);
            }
        });
    }

    const onSubmit = (data) => {
        data.seccion = seccion;
        data.ec = catalogos.ecuador.cat_id;
        clientepnSrv.editarClientepn(data).then(res => {
            funcSuccess(res, toast);
            next();
        }).catch(error => {
            funcError(error, toast, setError);
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Panel header="A.2 DATOS DEL DOMICILIO" className="panel-ficha">
                <div className="formgrid grid">
                    {inDrop('pais_dd', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePaisDD, val: false })}
                    {ecuadorDD && inDrop('provincia_dd', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvinciaDD, val: false })}
                    {ecuadorDD && inDrop('ciudad_dd', ciudadesDD, { lab: omodel.optionLabel })}
                    {!ecuadorDD && inText('canton_dd')}
                    {inText('direccion')}
                </div>

                <div className="formgrid grid">
                    {!ecuadorDD && inText('codigo_postal')}
                    {inText('telefono_domicilio')}
                    {inText('telefono_celular')}
                    {inText('email')}
                </div>
            </Panel>
            <br />

            <Panel header="A.3 ACTIVIDAD ECONÓMICA" className="panel-ficha">
                <div className="formgrid grid">
                    {inDrop('situacion_laboral', catalogos?.situacionLaboral, { lab: omodel.optionLabel, chn: onChangeSituacion })}
                    {otraSituacion && inText('detalle_sl')}
                    {inText('lugar_trabajo')}
                    {inText('cargo')}

                    {inText('ruc', {obl: 'no'})}
                    {inText('tiempo_trabaja')}
                    {inAutocomplete('actividad_economica', selectedCiiu, setSelectedCiiu, ciius, searchCiiu, { lab: 'nombre' })}
                    {inText('direccion_trabajo')}
                </div>

                <div className="formgrid grid">
                    {inDrop('pais_ae', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePaisAE, val: false })}
                    {ecuadorAE && inDrop('provincia_ae', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvinciaAE, val: false })}
                    {ecuadorAE && inDrop('ciudad_ae', ciudadesAE, { lab: omodel.optionLabel })}
                    {!ecuadorAE && inText('canton_ae')}
                </div>

                <div className="formgrid grid">
                    {inNumber('ingreso_mensual')}
                    {inNumber('egreso_mensual')}
                    {inNumber('otro_ingreso_mes')}
                    {inText('detalle_otro_ingreso', {obl: 'no'})}

                    {inText('telefono_trabajo')}
                    {inText('email_trabajo')}
                </div>
            </Panel>
            <br />

            {nextPrev()}
        </form>
    )
}
