import { Panel } from 'primereact/panel';
import { PepDecCpn } from '../../../models/cpn/PepDecCpn';
import { ClientePNService } from '../../../service/ClientePNService';
import { useForm } from 'react-hook-form';
import { Generico } from '../../../components/Generico';
import { InputComponent } from '../../../components/InputComponent';
import { useEffect, useState } from 'react';
import { Divider } from 'primereact/divider';

export const PepPn = ({ id, toast, seccion, nextPrev, next, permss }) => {

    const omodel = new PepDecCpn();
    const model = omodel.model;
    const { funcError, funDefault, funcSuccess, stringToDate, renewValues } = Generico();
    const defaultValues = funDefault(model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, setValue } = useForm({ defaultValues });
    const { inText, inNumber, inCheck, inCalendar } = InputComponent({ omodel, errors, control, permss, id });
    const clientepnSrv = new ClientePNService();
    const [esPep, setEsPep] = useState(false);
    const [relacionPep, setRelacionPep] = useState(false);

    useEffect(() => {
        editFicha();
    }, [id]);

    const onSubmit = (data) => {
        data.seccion = seccion;
        data.id = id;
        if (data.pep || data.esta_relacionado_pep) {
            data.fecha_nombramiento_pep = data.fecha_nombramiento_pep.toISOString().split('T')[0];
            if (data.fecha_dejo_pep) {
                data.fecha_dejo_pep = data.fecha_dejo_pep.toISOString().split('T')[0];
            }
        }
        else {
            delete data.fecha_nombramiento_pep;
            delete data.fecha_dejo_pep;
            delete data.relacion;
            delete data.nombre_pep;
            delete data.cargo_pep;
            delete data.institucion_trabaja_pep;
        }

        clientepnSrv.editarClientepn(data).then(res => {
            funcSuccess(res, toast);
            next();
        }).catch(error => {
            funcError(error, toast, setError);
        });
    }

    const editFicha = () => {
        clientepnSrv.consultarClientepn(id, seccion).then(res => {
            let _clientepn = res.clientepn;
            if (_clientepn.pep || _clientepn.esta_relacionado_pep) {
                _clientepn.fecha_nombramiento_pep = stringToDate(_clientepn.fecha_nombramiento_pep);
                if (_clientepn.fecha_dejo_pep) {
                    _clientepn.fecha_dejo_pep = stringToDate(_clientepn.fecha_dejo_pep);
                }
                setEsPep(_clientepn.pep);
                setRelacionPep(_clientepn.esta_relacionado_pep);
            }
            reset(renewValues(_clientepn, model));
        });
    }

    const actualizarPatrimonio = (e, info) => {
        const actvs = ['ACTV_INMUEBLES', 'ACTV_MUEBLES', 'ACTV_EFECTIVO', 'ACTV_INVERSIONES', 'ACTV_CREDITOS', 'ACTV_OTROS'];
        const pasvs = ['PASV_BANCARIOS', 'PASV_HIPOTECARIOS', 'PASV_QUIROGRAFARIO', 'PASV_CREDITO', 'PASV_OTRAS_CTAS', 'PASV_OTROS_PASV'];
        let totalPasivos = 0;
        let totalActivos = 0;
        actvs.forEach(itm => {
            if (getValues(itm) && !isNaN(getValues(itm))) {
                totalActivos += parseFloat(getValues(itm));
            }
        });
        pasvs.forEach(itm => {
            if (getValues(itm) && !isNaN(getValues(itm))) {
                totalPasivos += parseFloat(getValues(itm));
            }
        });
        setValue('total_activos', totalActivos);
        setValue('total_pasivos', totalPasivos);
        setValue('total_patrimonio', totalActivos - totalPasivos);
    }

    const onChangePep = (e) => {
        setEsPep(e.checked);
    }

    const onChangeRelacionPep = (e) => {
        setRelacionPep(e.checked);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Panel header="A.5 PERSONA EXPUESTA POLÍTICAMENTE (PEP)" className="panel-ficha">
                <div className="field col" style={{ textAlign: 'justify', fontStyle: 'italic' }}>
                    {omodel.strPep}
                </div>
                <Divider />

                <div className="formgrid grid">
                    <div className="field col-12" style={{ fontWeight: 'bold' }}>¿Es usted un PEP o Está relacionado con un PEP? En el caso de ser afirmativo colocar un check en el cuadro; caso contrario dejar en blanco.</div>
                    {inCheck('pep', null, { chn: onChangePep }, 6)}
                    {inCheck('esta_relacionado_pep', null, { chn: onChangeRelacionPep })}
                    {relacionPep && inText('relacion')}
                    <Divider />

                    {(esPep || relacionPep) && inText('nombre_pep')}
                    {(esPep || relacionPep) && inText('cargo_pep')}
                    {(esPep || relacionPep) && inText('institucion_trabaja_pep')}
                    <Divider />

                    {(esPep || relacionPep) && inCalendar('fecha_nombramiento_pep')}
                    {(esPep || relacionPep) && inCalendar('fecha_dejo_pep', { obl: 'no' })}
                </div>
            </Panel>
            <br />

            <Panel header="A.6 DECLARACIÓN PATRIMONIAL SIMPLE" className="panel-ficha">
                <div className="formgrid grid">
                    {inNumber('ACTV_INMUEBLES', 2, true, { func: actualizarPatrimonio, vars: null })}
                    {inNumber('PASV_BANCARIOS', 2, true, { func: actualizarPatrimonio, vars: null })}
                </div><div className="formgrid grid">
                    {inNumber('ACTV_MUEBLES', 2, true, { func: actualizarPatrimonio, vars: null })}
                    {inNumber('PASV_HIPOTECARIOS', 2, true, { func: actualizarPatrimonio, vars: null })}
                </div><div className="formgrid grid">
                    {inNumber('ACTV_EFECTIVO', 2, true, { func: actualizarPatrimonio, vars: null })}
                    {inNumber('PASV_QUIROGRAFARIO', 2, true, { func: actualizarPatrimonio, vars: null })}
                </div><div className="formgrid grid">
                    {inNumber('ACTV_INVERSIONES', 2, true, { func: actualizarPatrimonio, vars: null })}
                    {inNumber('PASV_CREDITO', 2, true, { func: actualizarPatrimonio, vars: null })}
                </div><div className="formgrid grid">
                    {inNumber('ACTV_CREDITOS', 2, true, { func: actualizarPatrimonio, vars: null })}
                    {inNumber('PASV_OTRAS_CTAS', 2, true, { func: actualizarPatrimonio, vars: null })}
                </div><div className="formgrid grid">
                    {inNumber('ACTV_OTROS', 2, true, { func: actualizarPatrimonio, vars: null })}
                    {inNumber('PASV_OTROS_PASV', 2, true, { func: actualizarPatrimonio, vars: null })}
                </div>
                <Divider />

                <div className="formgrid grid">
                    {inNumber('total_activos', 2, true, { disabled: true })}
                    {inNumber('total_pasivos', 2, true, { disabled: true })}
                </div>
                <Divider />

                <div className="formgrid grid">
                    {inNumber('total_patrimonio', 2, true, { disabled: true, min: -10000000000000 })}
                </div>
            </Panel>
            <br />

            {nextPrev()}
        </form>
    )
}
