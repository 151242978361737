export class Beneficiario {

    model = {
        id: {value: null, export: false},
        idp: {value: null, export: false},
        tipo_identificacion: {value: null, export: false, header: 'Tipo de identificación', filterPlaceholder: 'Buscar por tipo de identificación', table: true, filter: false, sort: true},
        identificacion: {value: '', export: false, header: 'No. de Identificación / RUC:', required: 'No. de Identificación / RUC es requerido', maxLength: 'Máximo 50 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'identificacion', filterPlaceholder: 'Buscar por número de identificación', table: true, filter: false, sort: true},
        nombres: {value: '', export: true, header: 'Nombres', required: 'Nombres es requerido', maxLength: 'Máximo 300 caracteres', 
            filterPlaceholder:'nombres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'nombres'},       
        apellidos: {value: '', export: true, header: 'Apellidos', required: 'Apellidos es requerido', maxLength: 'Máximo 300 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'apellidos'},
        razon_social: {value: '', export: true, header: 'Razón social', required: 'Razón Social es requerido', maxLength: 'Máximo 300 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'razon_social'},
        nombres_razon: {header: 'Nombre / Razón social', table: true, filter: false, sort: true}
    }    
    optionLabel = 'nombre';
}