import { Panel } from 'primereact/panel';
import { LicitoCpn } from '../../../models/cpn/LicitoCpn';
import { Generico } from '../../../components/Generico';
import { useForm } from 'react-hook-form';
import { InputComponent } from '../../../components/InputComponent';
import { ClientePNService } from '../../../service/ClientePNService';
import { useEffect, useState } from 'react';
import { CatalogoService } from '../../../service/CatalogoService';
import { Pago } from '../Pago';
import { Tercero } from '../Tercero';
import { Divider } from 'primereact/divider';

export const PartebPn = ({ id, catalogos, toast, seccion, nextPrev, next, permss }) => {

    const omodel = new LicitoCpn();
    const model = omodel.model;
    const { funcError, funDefault, funcSuccess, stringToDate } = Generico();
    const defaultValues = funDefault(model);
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({ defaultValues });
    const { inText, inNumber, inCalendar, inDrop } = InputComponent({ omodel, errors, control, permss, id });
    const { loadLazyDataPago, tablePago, dialogoPago } = Pago({ toast: toast, tipo: 'clientePn', id: id, permss });
    const { loadLazyTercero, tableTercero, dialogoTercero } = Tercero({ toast: toast, tipo: 'clientePn', id: id, permss });
    const [ecuadorOL, setEcuadorOL] = useState(false);
    const [ciudadesOL, setCiudadesOL] = useState(null);
    const clientepnSrv = new ClientePNService();
    const catalogoSrv = new CatalogoService();

    useEffect(() => {
        editFicha();
    }, [id]);

    const onChangePaisOL = (e, pago) => {
        setEcuadorOL(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvinciaOL = (e, pago) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudadesOL(res.data);
        });
    }

    const editFicha = () => {
        clientepnSrv.consultarClientepn(id, seccion).then(res => {
            const cats = catalogos;
            let _clientepn = res.clientepn;
            let lista = {
                moneda: cats.moneda,
                pais_ol: cats.pais,
                tipo_valor_entregado: cats.valorEntregado
            }
            if (res.ciudades_ol != undefined) {
                lista['ciudad_ol'] = res.ciudades_ol;
                lista['provincia_ol'] = cats.provincias;
                setCiudadesOL(res.ciudades_ol);
            }
            Object.keys(lista).forEach(function (key) {
                if (_clientepn[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _clientepn[key]) {
                            _clientepn[key] = element;
                        }
                    });
                }
            });
            setEcuadorOL(JSON.stringify(_clientepn.pais_ol) === JSON.stringify(cats.ecuador));
            if (_clientepn.transaccion_realiza) {
                _clientepn.fecha_transaccion = stringToDate(_clientepn.fecha_transaccion);
                reset(_clientepn);
            }
            else {
                reset(defaultValues);
            }
            loadLazyDataPago(_clientepn.id);
            loadLazyTercero(_clientepn.id);
        });
    }

    const onSubmit = (data) => {
        data.seccion = seccion;
        data.id = id;
        data.ec = catalogos.ecuador.cat_id;
        data.fecha_transaccion = data.fecha_transaccion.toISOString().split('T')[0];
        clientepnSrv.editarClientepn(data).then(res => {
            funcSuccess(res, toast);
            next();
        }).catch(error => {
            funcError(error, toast, setError);
        });
    }

    return (
        <Panel header="PARTE B - B.1 DECLARACIÓN DE ORIGEN LÍCITO DE RECURSOS" className="panel-ficha">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formgrid grid">
                    {inText('transaccion_realiza')}
                    {inNumber('valor_total_bien_servicio', 2, true)}
                    {inDrop('moneda', catalogos?.moneda, { lab: omodel.optionLabel })}
                    {inDrop('pais_ol', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePaisOL, val: false })}

                    {ecuadorOL && inDrop('provincia_ol', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvinciaOL, val: false })}
                    {ecuadorOL && inDrop('ciudad_ol', ciudadesOL, { lab: omodel.optionLabel })}
                    {!ecuadorOL && inText('canton_ol')}
                    {inCalendar('fecha_transaccion')}

                    {inText('detalle_act_economica')}
                    {inDrop('tipo_valor_entregado', catalogos?.valorEntregado, { lab: omodel.optionLabel })}
                </div>
                <br />

                {nextPrev()}
            </form>

            <Panel header="FORMA DE PAGO" className="panel-ficha">
                {tablePago()}
            </Panel>
            <br />

            <Panel header="En caso de señalar Beneficiario Final o Tercero/s complete lo siguiente:" className="panel-ficha">
                {tableTercero()}
            </Panel>
            <Divider />
            <div className="field col" style={{ textAlign: 'justify', fontStyle: 'italic', fontWeight: 'bold' }}>
                {omodel.strBeneDeclara}
            </div>
            {dialogoPago()}
            {dialogoTercero()}
        </Panel>
    )
}
