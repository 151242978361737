import Axios from "axios";

export class PlanColorService {

    lista(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/matriz/config-color/lista?${queryParams}`).then(res => res.data);
    }

    nuevo(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/matriz/config-color/nuevo`, data).then(res => res.data);
    }

    editar(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/matriz/config-color/editar`, data).then(res => res.data);
    }

    consultar(id, idp) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/matriz/config-color/consulta/${id}/${idp}`).then(res => res.data);
    }

    eliminar(id, idp) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/matriz/config-color/eliminar/${id}/${idp}`).then(res => res.data);
    }
}
