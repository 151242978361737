import { Panel } from "primereact/panel"
import { ConyCpn } from "../../../models/cpn/ConyCpn";
import { CatalogoService } from "../../../service/CatalogoService";
import { useForm } from "react-hook-form";
import { InputComponent } from "../../../components/InputComponent";
import { Generico } from "../../../components/Generico";
import { ClientePNService } from "../../../service/ClientePNService";
import { useEffect, useState } from "react";
import { Divider } from 'primereact/divider';

export const ConyuguePn = ({ id, catalogos, toast, seccion, nextPrev, next, permss }) => {

    const omodel = new ConyCpn();
    const model = omodel.model;
    const { funcError, funDefault, funcSuccess, stringToDate, renewValues } = Generico();
    const defaultValues = funDefault(model);
    const catalogoSrv = new CatalogoService();
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({ defaultValues });
    const { inDrop, inText, inNumber, inAutocomplete, inCalendar } = InputComponent({ omodel, errors, control, permss, id });
    const clientepnSrv = new ClientePNService();
    const [ecuadorTR, setEcuadorTR] = useState(false);
    const [ciudadesTR, setCiudadesTR] = useState(null);
    const [selectedCiiu, setSelectedCiiu] = useState(null);
    const [ciius, setCiius] = useState(null);
    const [otraSituacion, setOtraSituacion] = useState(false);

    useEffect(() => {
        editFicha();
    }, [id]);

    const onChangePaisTR = (e) => {
        setEcuadorTR(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeSituacion = (e) => {
        setOtraSituacion(JSON.stringify(e.value) === JSON.stringify(catalogos.otra));
    }

    const onChangeProvinciaTR = (e) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudadesTR(res.data);
        });
    }

    const searchCiiu = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setCiius([...ciius]);
            }
            else {
                catalogoSrv.getActividades(event.query.toLowerCase()).then(data => {
                    setCiius(data.data);
                });
            }
        });
    }

    const onSubmit = (data) => {
        data.seccion = seccion;
        data.ec = catalogos.ecuador.cat_id;
        data.id = id;
        data.fecha_nacimiento_cy = data.fecha_nacimiento_cy.toISOString().split('T')[0];
        clientepnSrv.editarClientepn(data).then(res => {
            funcSuccess(res, toast);
            next();
        }).catch(error => {
            funcError(error, toast, setError);
        });
    }

    const editFicha = () => {
        clientepnSrv.consultarClientepn(id, seccion).then(res => {
            const cats = catalogos;
            let _clientepn = res.clientepn;
            const lista = {
                tipo_identificacion_cy: cats.tipo,
                nacionalidad_cy: cats.nacionalidad,
                genero_cy: cats.genero,
                situacion_laboral_cy: cats.situacionLaboral,
                pais_tr: cats.pais,

            };            
            if (res.ciudad_tr_cy != undefined) {
                lista['ciudad_tr_cy'] = res.ciudad_tr_cy;
                lista['provincia_tr_cy'] = cats.provincias;
                setCiudadesTR(res.ciudad_tr_cy);
            }
            Object.keys(lista).forEach(function (key) {
                if (_clientepn[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _clientepn[key]) {
                            _clientepn[key] = element;
                        }
                    });
                }
            });
            setEcuadorTR(JSON.stringify(_clientepn.pais_tr) === JSON.stringify(cats.ecuador));
            if (_clientepn['actividad_economica_cy'] != null) {
                _clientepn['actividad_economica_cy'] = res.actividad_economica_cy;
                setSelectedCiiu(res.actividad_economica_cy);
            }
            if (_clientepn.nombres_ec) {
                _clientepn.fecha_nacimiento_cy = stringToDate(_clientepn.fecha_nacimiento_cy);
                reset(renewValues(_clientepn, model));
            }
            else {
                defaultValues.id = id;
                reset(defaultValues);
            }
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Panel header="A.4 DATOS DEL CÓNYUGE/CONVIVIENTE" className="panel-ficha">
                <div className="formgrid grid">
                    {inText('apellidos_cy')}
                    {inText('nombres_ec')}
                    {inDrop('tipo_identificacion_cy', catalogos?.tipo, { lab: omodel.optionLabel })}
                    {inText('identificacion_cy')}
                    <Divider />

                    {inDrop('nacionalidad_cy', catalogos?.nacionalidad, { lab: omodel.optionLabel })}
                    {inCalendar('fecha_nacimiento_cy')}
                    {inDrop('genero_cy', catalogos?.genero, { lab: omodel.optionLabel })}
                    {inDrop('situacion_laboral_cy', catalogos?.situacionLaboral, { lab: omodel.optionLabel, chn: onChangeSituacion })}
                    <Divider />

                    {otraSituacion && inText('detalle_sl_cy')}
                    {inText('lugar_trabajo_cy')}
                    {inText('cargo_cy')}
                    {inText('ruc_cy', {obl: 'no'})}
                    <Divider />

                    {inText('tiempo_trabajo_cy')}
                    {inAutocomplete('actividad_economica_cy', selectedCiiu, setSelectedCiiu, ciius, searchCiiu, { lab: 'nombre' })}
                    {inText('direccion_trabajo_cy')}
                    <Divider />

                    {inDrop('pais_tr', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePaisTR, val: false })}
                    {ecuadorTR && inDrop('provincia_tr_cy', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvinciaTR, val: false })}
                    {ecuadorTR && inDrop('ciudad_tr_cy', ciudadesTR, { lab: omodel.optionLabel })}
                    {!ecuadorTR && inText('canton_tr_cy')}
                    <Divider />

                    {inNumber('ingreso_mensual_cy')}
                    {inNumber('egreso_mensual_cy')}
                    {inNumber('otro_ingreso_mes_cy')}
                    {inText('detalle_otro_ingreso_cy', {obl: 'no'})}
                    <Divider />

                    {inText('telefono_trabajo_cy')}
                    {inText('email_trabajo_cy')}
                </div>
            </Panel>
            <br />

            {nextPrev()}
        </form>
    )
}
