import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { NombrePep1 } from "../../models/administracion/NombrePep1";
import { PepEtlService } from "../../service/admin/PepEtlService";
import { InputComponent } from "../../components/InputComponent";
import { Card } from "primereact/card";
import { useForm } from "react-hook-form";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";

export const ListaTmpPep = ({ colaId, permss, setTotalPep }) => {
    const generic = new GenericComponent();
    const toast = useRef(null);
    const omodel = new NombrePep1();
    const defaultValues = generic.default(omodel.model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registros, setRegistros] = useState(null);
    const etlService = new PepEtlService();
    const [dialogPep, setDialogPep] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inNumber, inText } =
        InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.idc = colaId;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            etlService.getListaPep({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setTotalPep(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
            });
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarPep(data)
        });
    }

    const eliminarPep = (data) => {
        etlService.eliminarPep(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const onSubmit = (data) => {
        data.idc = colaId;
        console.log(data)
        etlService.editarPep(data).then(res => {
            generic.funcSuccess(res, toast);
            setDialogPep(false);
            loadLazyData();
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const editPep = (data) => {
        etlService.consultarPep(data.id).then(res => {
            reset(generic.renewValues(res.data, omodel.model));
            setDialogPep(true);
        });
    };

    return (
        <>
            <Toast ref={toast} />
            <Card title="Lista de PEP cargados (por procesar)" style={{ maxWidth: '100%', margin: 'auto' }}>
                {table({ action: generic.actionEditTemplate, methEdit: editPep, perm: permss, methDelete: confirmDelete })}
            </Card>
            <Dialog visible={dialogPep} header="Editar registro" modal className="p-fluid modal-ssize"
                onHide={e => setDialogPep(false)} style={{ maxWidth: '800px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombres', {}, 6)}
                        {inText('identificacion', {}, 6)}
                        {inText('puesto', {}, 6)}
                        {inNumber('mensual', 2, true, {}, 6)}
                        {inNumber('anual', 2, true, {}, 6)}
                    </div>
                    {generic.buttonsForms(setDialogPep)}
                </form>
            </Dialog>
        </>
    )
}
