import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { Detalles } from "../../models/fuente/Detalles";
import { DetallesService } from "../../service/fuente/DetallesService";
import { useForm } from "react-hook-form";
import { InputComponent } from "../../components/InputComponent";
import { Origenes } from "./Origenes";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { PersonaFuente } from "./PersonaFuente";

export const DetalleFuente = ({ personaId, origenId }) => {
  const generic = new GenericComponent();
  const omodel = new Detalles();
  const [permss, setPermss] = useState(null);
  const defaultValues = generic.default(omodel.model, null);
  const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
  let loadLazyTimeout = null;
  const [registros, setRegistros] = useState(null);
  const toast = useRef(null);
  const detalleService = new DetallesService();
  const [dialogDetalle, setDialogDetalle] = useState(false);
  const [dialogOrigen, setDialogOrigen] = useState(false);
  const [dialogPersona, setDialogPersona] = useState(false);
  const [siPopup, setSiPopup] = useState(false);
  const [detalle, setDetalle] = useState(null);
  const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText } = InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

  useEffect(() => {
    setSiPopup(personaId != undefined || origenId != undefined);
    loadLazyData();
  }, [lazyParams]);

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      let _lazyParams = lazyParams;
      delete _lazyParams.rtipo;
      if (permss == null) {
        _lazyParams.rtipo = true;
      }
      if (personaId != undefined) {
        _lazyParams.personaId = personaId;
      }
      if (origenId != undefined) {
        _lazyParams.origenId = origenId;
      }
      detalleService.getDetalle({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
        setTotalRecords(res.data.total);
        setRegistros(res.data.registros);
        setLoading(false);
        if (res.data.perss !== undefined) {
          setPermss(res.data.perss);
        }
      });
    });
  }

  const openNew = () => {
    setDialogDetalle(true);
    reset(defaultValues);
  }

  const onSubmit = (data) => {
    if (getValues('id') == null) {
      detalleService.newDetalle(data).then(res => {
        generic.funcSuccess(res, toast);
        setDialogDetalle(false);
        loadLazyData();
      }).catch(error => {
        generic.funcError(error, toast, setError);
      });
    }
    else {
      detalleService.editarDetalle(data).then(res => {
        generic.funcSuccess(res, toast);
        setDialogDetalle(false);
        loadLazyData();
      }).catch(error => {
        generic.funcError(error, toast, setError);
      });
    }
  }

  const editDetalle = (detalle) => {
    detalleService.consultarDetalle(detalle.id).then(res => {
      let _detalle = res.data;
      let lista = { estado: generic.estadosNum };

      Object.keys(lista).forEach(function (key) {
        if (_detalle[key] != null) {
          lista[key].forEach(element => {
            if (element.cat_id === _detalle[key]) {
              _detalle[key] = element;
            }
          });
        }
      });
      reset(generic.renewValues(_detalle, omodel.model));
      setDialogDetalle(true);
    });
  };

  const confirmDelete = (data) => {
    confirmDialog({
      message: '¿Está seguro de eliminar la información?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => eliminarDetalle(data)
    });
  }

  const eliminarDetalle = (data) => {
    detalleService.eliminarDetalle(data.id).then(res => {
      loadLazyData();
      generic.funcSuccess(res, toast);
    }).catch(error => {
      generic.funcDeleteError(error, toast);
    });
  }

  const mostrarOrigen = (data) => {
    setDialogOrigen(true);
    setDetalle(data);
  }

  const mostrarPersonas = (data) => {
    setDialogPersona(true);
    setDetalle(data);
  }

  const acciones = (rowData) => {
    return (
      <div className="actions">
        {permss?.actualizar && (!rowData?.nemonico || rowData.nemonico !== 'FINALIZADO') &&
          <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editDetalle(rowData)} title="Editar" />}
        <Button icon="pi pi-images" title='Fuentes relacionadas' className="p-button-rounded p-button-success mr-2" onClick={() => mostrarOrigen(rowData)} />
        <Button icon="pi pi-users" title='Personas relacionadas' className="p-button-rounded p-button-success mr-2" onClick={() => mostrarPersonas(rowData)} />
        {permss?.eliminar && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDelete(rowData)} title="Eliminar" />}
      </div>
    );
  }

  return (
    <>
      <Toast ref={toast} />
      <Card style={{ maxWidth: '100%', margin: 'auto' }}>
        {!siPopup &&
          <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo detalle', permss)}
            end={generic.rightToolbarTemplate(registros, omodel, 'Listado_fuentes_persona', 'LISTADO FUENTE DE PERSONAS')}></Toolbar>
        }
        {table({ action5: acciones })}
      </Card>

      <Dialog visible={dialogDetalle} header={getValues('id') == null ? "Registrar persona" : "Editar persona"} modal className="p-fluid modal-ssize"
        onHide={e => setDialogDetalle(false)} style={{ maxWidth: '600px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formgrid grid">
            {inText('detalle', {}, 6)}
            {inText('link', {}, 6)}
            {inDrop('estado', generic.estadosNum, { lab: omodel.optionLabel }, 6)}
            {getValues('id') == null && inText('identificacion_origen', {}, 6)}
            {getValues('id') == null && inText('identificacion_persona', {}, 6)}

          </div>

          {generic.buttonsForms(setDialogDetalle)}
        </form>
      </Dialog>

      <Dialog visible={dialogOrigen} header="Origen de fuentes relacionadas al detalle seleccionado" modal className="p-fluid modal-ssize"
        onHide={e => { setDialogOrigen(false); setDetalle(null); }}>
        <Origenes detalleId={detalle?.id} />
      </Dialog>

      <Dialog visible={dialogPersona} header="Personas vinculadas al detalle" modal className="p-fluid modal-ssize" style={{ maxWidth: '900px' }}
        onHide={e => { setDialogPersona(false); setDetalle(null); }} >
        <PersonaFuente detalleId={detalle?.id} />
      </Dialog>

    </>
  )
}
