import Axios from "axios";

export class OrigenService {

    getOrigen(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/fuente/origen/lista?${queryParams}`).then(res => res.data);
    }

    newOrigen(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/fuente/origen/nuevo`, data).then(res => res.data);
    }

    editarOrigen(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/fuente/origen/editar`, data).then(res => res.data);
    }

    consultarOrigen(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/fuente/origen-ver/${id}`).then(res => res.data);
    }

    eliminarOrigen(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/fuente/origen/eliminar/${id}`).then(res => res.data);
    }

    subirArchivo(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/fuente/origen/subir-origen`, data).then(res => res.data);
    }
}