export class Mueble {

    model = {
        id: { value: null, export: false },
        vehiculo: {
            value: '',
            table: true,
            header: 'Vehículo',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        numero_placa: {
            value: '',
            table: true,
            header: 'Número de Placa',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        matricula: {
            value: '',
            table: true,
            header: 'Matrícula',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        modelo_anio: {
            value: '',
            table: true,
            header: 'Modelo (Año de Fabricación)',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        marca: {
            value: '',
            table: true,
            header: 'Marca',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        color: {
            value: '',
            table: true,
            header: 'Color',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            footer: 'SUBTOTAL:',
        },
        valor: {
            value: 0,
            table: true,
            header: 'Valor del Bien:',
            required: 'Valor requerido',
            footer: 0,
        },
    }

    optionLabel = 'nombre';
}
