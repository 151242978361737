import { Toast } from "primereact/toast";
import { GenericComponent } from "../../components/GenericComponent";
import { Menus } from "../../models/Menus";
import { MenuServicio } from "../../service/MenuServicio";
import { InputComponent } from "../../components/InputComponent";
import { Card } from "primereact/card";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useEffect, useRef, useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { useForm } from "react-hook-form";
import { MenuPermiso } from "./MenuPermiso";

export const Menu = () => {

    const generic = new GenericComponent();
    const omodel = new Menus();
    const model = omodel.model;
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const toast = useRef(null);
    const menuServicio = new MenuServicio();
    const [dialogMenu, setDialogMenu] = useState(false);
    const [dialogPermiso, setDialogPermiso] = useState(false);
    const [menus, setMenus] = useState(null);
    const [menu, setMenu] = useState(null);
    const [catalogo, setCatalogo] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inText, inDrop, inInteger } =
        InputComponent({ registers: menus, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyMenu();
    }, [lazyParams]);

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el menú?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarMenu(data)
        });
    }

    const eliminarMenu = (data) => {
        menuServicio.eliminar(data.id).then(res => {
            loadLazyMenu();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const openNew = () => {
        menuServicio.newMenu({ iniciar: 1 }).then(res => {
            setCatalogo(res.data.lista);
            setDialogMenu(true);
            reset(defaultValues);
        });
    }

    const loadLazyMenu = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        let _lazyParams = lazyParams;
        delete _lazyParams.rtipo;
        if (permss == null) {
            _lazyParams.rtipo = true;
        }
        loadLazyTimeout = setTimeout(() => {
            menuServicio.getLista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setMenus(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            menuServicio.newMenu(data).then(res => {
                setDialogMenu(false);
                loadLazyMenu();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            menuServicio.editarMenu(data).then(res => {
                setDialogMenu(false);
                loadLazyMenu();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editMenu = (data) => {
        menuServicio.consultarMenu(data.id).then(res => {
            const _cat = res.data.lista;
            setCatalogo(res.data.lista);
            const lista = { estado: generic.estados, ultimo: generic.sino, menu_padre: _cat };
            const _menu = res.data.menu;
            Object.keys(lista).forEach(function (key) {
                if (_menu[key] != null) {
                    lista[key].forEach(element => {
                        let value = element.cat_id;
                        if (key == 'menu_padre') value = element.id;
                        if (value === _menu[key]) {
                            _menu[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_menu, model));
            setDialogMenu(true);
        });
    }

    const asignarPermiso = (rowData) => {
        setMenu(rowData);
        setDialogPermiso(true);
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, '', permss)}></Toolbar>
                {table({ action3: generic.actionEditDetailTemplate, methEdit: editMenu, perm: permss, methDelete: confirmDelete, details: asignarPermiso, icono: 'pi pi-key' })}
            </Card>
            <Dialog visible={dialogMenu} header={getValues('id') == null ? "Nuevo Menú" : "Editar Menú"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogMenu(false)} style={{ maxWidth: '1000px' }} >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre')}
                        {inText('descripcion')}
                        {inText('url', { obl: 'no' })}
                        {inText('icono')}

                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel })}
                        {inDrop('menu_padre', catalogo, { lab: omodel.optionLabel })}
                        {inInteger('nivel')}
                        {inInteger('orden')}

                        {inDrop('ultimo', generic.sino, { lab: omodel.optionLabel })}
                        {inText('nemonico')}
                    </div>
                    {generic.buttonsForms(setDialogMenu)}
                </form>
            </Dialog>

            <Dialog visible={dialogPermiso} header={"Asignar permiso al menú: " + menu?.nombre} style={{ maxWidth: '1000px' }} modal className="p-fluid modal-ssize"
                onHide={e => setDialogPermiso(false)}>
                <MenuPermiso id={menu?.id} toast={toast} generic={generic} permss={permss} />
            </Dialog>
        </>
    )
}
