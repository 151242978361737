import { Button } from "primereact/button";
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Adjunto } from "../models/Adjunto";
import AdjuntoService from "../service/AdjuntoService";
import { InputComponent } from "./InputComponent";
import { ScrollPanel } from 'primereact/scrollpanel';
import '../assets/scrolls.css';
import { useToken } from "./useToken";
import { Message } from "primereact/message";

export const FileComponent = ({ generic, tipo, toast, unuevo, udelete, permss, id }) => {

    let fileRef = useRef(null);
    const [dialogSubir, setDialogSubir] = useState(false);
    const [archivo, setArchivo] = useState(null);
    const omodel = new Adjunto();
    const model = omodel.model;
    const defaultValues = generic.default(model);
    const { handleSubmit, control, formState: { errors }, reset, setError } = useForm(defaultValues);
    const { inArea, inDrop } = InputComponent({ omodel, generic, errors, control, permss, id });
    const adjuntoService = new AdjuntoService();
    const [idp, setIdp] = useState(null);
    const [header, setHeader] = useState(null);
    const [adjuntos, setAdjuntos] = useState(null);
    const [urlDownload, setUrlDownload] = useState(null);
    const [catalogos, setCatalogos] = useState(null);
    const [dialogDelete, setDialogDelete] = useState(false);
    const [adjunto, setAdjunto] = useState(null);
    const [dialogAnexo, setDialogAnexo] = useState(false);
    const [anexoPendientes, setAnexoPendientes] = useState(null);
    const [dialogLista, setDialogLista] = useState(false);
    const [estado, setEstado] = useState(null);
    const { closeSession, getTipo, getRol } = useToken();
    const [dialogIns, setdialogIns] = useState(false);
    const [load, setLoad] = useState(false);

    const invoiceUploadHandler = ({ files }) => {
        const [file] = files;
        const size = parseInt(process.env.REACT_APP_FILE_SIZE);
        if (file.size > size) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1024000) + " Mb. El archivo actual tiene: " + (file.size / 1024000).toFixed(3) + " Mb.", life: 3000 });
        }
        else {
            setArchivo(file);
        }
        fileRef?.clear();
    }

    const onSubmitFile = (data) => {
        if (archivo == null) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el archivo.', life: 3000 });
            return;
        }
        const formData = new FormData();
        formData.append('file', archivo);
        formData.append('tipo', tipo);
        formData.append('descripcion', data.descripcion);
        formData.append('id', idp);
        formData.append('lista', data.lista.id);
        adjuntoService.newAdjunto(formData, unuevo).then(res => {
            setAdjuntos(res.data);
            reset(defaultValues);
            setArchivo(null);
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const nuevoUpload = (id, title, urls, urld, toast = null) => {
        adjuntoService.listar(id, tipo, urls, catalogos == null ? 1 : 0).then(res => {
            setArchivo(null);
            reset(defaultValues);
            setIdp(id);
            setHeader(title);
            setDialogSubir(true);
            setAdjuntos(res.data.lista);
            setUrlDownload(urld);
            if (catalogos == null) {
                setCatalogos(res.data.cat);
            }
        }).catch(error => {
            if(toast){
                toast.current.show({ severity: 'error', summary: 'Error', detail: error?.response?.data?.message, life: 3000 });
            }
        });
    }

    const descargarAdjunto = (id, nombre) => {
        adjuntoService.descargar(id, urlDownload, idp).then(res => {
            generic.downloadPdf(res, nombre);
        });
    }

    const itemTemplate = () => {
        const listItems = adjuntos?.map((option) =>
            <li key={option.id}>
                <a style={{ cursor: 'pointer' }} onClick={() => descargarAdjunto(option.id, option.adjunto)}>{option.tipo_adjunto}</a>
                <Button icon="pi pi-times" title="Eliminar este anexo" className="p-button-rounded p-button-danger p-button-text" onClick={e => eliminarAdjunto(option)} aria-label="Cancel" />
            </li>
        );

        return listItems
    }

    const selectFile = (e) => {
        const size = parseInt(process.env.REACT_APP_FILE_SIZE);
        if (e.files[0].size > size) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1024000) + " Mb. El archivo actual tiene: " + (e.files[0].size / 1024000).toFixed(3) + " Mb.", life: 3000 });
        }
        else {
            setArchivo(e.files[0]);
        }
        fileRef?.clear();
    }

    const eliminarAdjunto = (data) => {
        setAdjunto(data);
        setDialogDelete(true);
    }

    const eliminar = () => {
        adjuntoService.deleteAdjunto(adjunto.id, idp, udelete).then(res => {
            setAdjuntos(res.data);
            setDialogDelete(false);
            setAdjunto(null);
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const eliminarConfirmacionAnexo = () => {

        return (
            <Dialog visible={dialogDelete} style={{ width: '450px' }} header="Confirmación" modal footer={generic.deleteDialogFooter(setDialogDelete, eliminar)} onHide={e => { setDialogDelete(false) }}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {adjunto?.id && (
                        <span>
                            ¿Está seguro de eliminar el anexo?
                        </span>
                    )}
                </div>
            </Dialog>
        )
    }

    const dialogInstruccion = (title, instruccion) => {
        return (
            <Dialog visible={dialogIns} style={{ width: '450px' }} header={title} modal onHide={e => { setdialogIns(false) }}>
                {instruccion}
            </Dialog>
        )
    }

    const listar = (id, urls, urld, estado) => {
        adjuntoService.listar(id, tipo, urls, 0).then(res => {
            setIdp(id);
            setEstado(estado);
            setDialogLista(true);
            setAdjuntos(res.data.lista);
            setUrlDownload(urld);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const listaFormulario = (urlc, loadLazy) => {
        return (
            <Dialog visible={dialogLista} header="Lista de archivos" modal className="p-fluid modal-ssize" onHide={e => setDialogLista(false)} style={{ width: '600px' }}>
                <div className="scrollpanel-demo">
                    <div className="card">
                        <ScrollPanel className="custombar1" style={{ width: '100%', height: '150px' }}>
                            <h6 style={{ width: '100%', textAlign: 'center' }}>Anexos incluído en el formulario</h6>
                            <ul>{
                                adjuntos?.map((option) =>
                                    <li key={option.id}>
                                        <a style={{ cursor: 'pointer' }} onClick={() => descargarAdjunto(option.id, option.adjunto)}>{option.tipo_adjunto}</a>
                                    </li>
                                )
                            }</ul>
                        </ScrollPanel>
                    </div>
                </div>
                <br />

                <div className="formgrid grid">
                    <div className="field col-12" style={{ textAlign: 'center' }}>
                        {getTipo() === 'todos' && estado === 'FINALIZADO' && <><Button label="Cambiar a aprobar" type="button" style={{ maxWidth: '170px' }} icon="pi pi-history"
                            className="p-button-warning" onClick={() => cambiarFormulario(urlc, loadLazy, 'ca')} />&nbsp;</>}
                        {(getTipo() === 'empresa' || getTipo() === 'todos') && estado === 'ENVIADO' && <><Button label="Aprobar" type="button" style={{ maxWidth: '150px' }}
                            icon="pi pi-check-square" className="p-button-warning" onClick={() => cambiarFormulario(urlc, loadLazy, 'a')} loading={load} />&nbsp;</>}
                        {getTipo() === 'empresa' && estado === 'ENVIADO' && <><Button label="Volver a borrador" type="button" icon={"pi pi-undo"} className="p-button-warning"
                            style={{ maxWidth: '170px' }} onClick={() => cambiarFormulario(urlc, loadLazy, 'b')} />&nbsp;</>}
                        <Button label="Cerrar" icon="pi pi-times" style={{ maxWidth: '100px' }} disabled={load} type="button" className="p-button-warning" onClick={() => setDialogLista(false)} />
                    </div>
                </div>
            </Dialog>
        )
    }

    const cambiarFormulario = (urlc, loadLazy, valor) => {
        confirmDialog({
            message: '¿Está seguro?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => aprobar(urlc, loadLazy, valor)
        });
    }

    const aprobar = (urlc, loadLazy, valor) => {
        setLoad(true);
        let data = { id: idp, estado: estado, cambio: valor };
        if(getRol()?.tipo){
            data.tipo = getRol().tipo;
        }
        adjuntoService.aprobar(data, urlc).then(res => {
            setLoad(false);
            loadLazy();
            generic.funcSuccess(res, toast);
            setDialogLista(false);
        }).catch(error => {
            setLoad(false);
            generic.funcError(error, toast, setError);
        });
    }

    const upLoadFile = () => {
        return (
            <Dialog visible={dialogSubir} header={header} modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '600px' }}>
                <Message style={{ width: '100%' }} severity="info"
                    text={"Seleccionar el anexo y posterior ir a +Seleccione para que cargue el anexo seleccionado. Formato permitido pdf, tamaño máximo por archivo: " +
                        (parseInt(process.env.REACT_APP_FILE_SIZE)/1024000) + " Mb."} />
                <div className="scrollpanel-demo">
                    <div className="card">
                        <ScrollPanel className="custombar1" style={{ width: '100%', height: '150px' }}>
                            <h6 style={{ width: '100%', textAlign: 'center' }}>Anexos incluídos</h6>
                            <ul>{itemTemplate()}</ul>
                        </ScrollPanel>
                    </div>
                </div>
                <br />
                <div className="card">
                    <form onSubmit={handleSubmit(onSubmitFile)}>

                        <div className="formgrid grid">
                            {inDrop('lista', catalogos, { lab: omodel.optionLabel }, 12)}

                            {inArea('descripcion', 3, 30, 6)}
                            <div className="field col-6">
                                <label>Seleccione un anexo*</label><br />
                                <FileUpload name="file" accept=".pdf" maxFileSize={205000} uploadHandler={invoiceUploadHandler} mode="basic"
                                    customUpload chooseLabel="Seleccione" onSelect={selectFile} ref={ref => { fileRef = ref }} />
                            </div>
                        </div>
                        {archivo?.name}
                        <br /><br />

                        <div className="formgrid grid">
                            <div className="field col" style={{ textAlign: 'center' }}>
                                <Button label="Subir" style={{ maxWidth: '120px', margin: 'auto' }} type="submit" icon="pi pi-check" className="p-button-warning" />
                            </div>
                            <div className="field col">
                                <Button label="Cerrar" style={{ maxWidth: '120px' }} icon="pi pi-times" type="button" className="p-button-warning" onClick={() => setDialogSubir(false)} />
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        )
    }

    const enviarFormulario = (id, urle, tipo) => {
        confirmDialog({
            message: '¿Está seguro? Si envía no podrá modificar la información',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => enviarFormulario1(id, urle, tipo)
        });
    }

    const enviarFormulario1 = (id, urle, tipo) => {
        adjuntoService.enviar({ id: id, tipo: tipo }, urle).then(res => {
            if (res.data.includes('success')) {
                generic.funcMessage("success", "Cambio correcto", res.message, toast);
                if (res.data === 'success1') {
                    closeSession();
                    window.location.href = `${process.env.REACT_APP_WEBROOT}/`;
                }
            }
            else if (res.data === 'declarante' || res.data === 'sin') {
                generic.funcMessage("error", "Error al enviar formulario", res.message, toast);
            }
            else {
                setAnexoPendientes(res.data);
                setDialogAnexo(true);
            }
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const anexoPendiente = (header, mensaje) => {

        return (
            <Dialog visible={dialogAnexo} header={header} modal className="p-fluid modal-ssize" onHide={e => setDialogAnexo(false)} style={{ width: '600px' }}>
                <h3>{mensaje}</h3>
                <ul>
                    {anexoPendientes?.map((option) =>
                        <li key={option.id}>
                            <a>{option.tipo_adjunto}</a>
                        </li>
                    )}
                </ul>
                <div className="field col" style={{ width: '100%', textAlign: 'center' }} >
                    <Button label="Cancelar" style={{ maxWidth: '120px' }} icon="pi pi-times" type="button" className="p-button-warning" onClick={() => setDialogAnexo(false)} />
                </div>
            </Dialog>
        )
    }

    const enviarFormularioPep = (id, urle, tipo, reiniciar) => {
        confirmDialog({
            message: '¿Está seguro? Si envía no podrá modificar la información',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => enviarFormulario1Pep(id, urle, tipo, reiniciar)
        });
    }

    const enviarFormulario1Pep = (id, urle, tipo, reiniciar) => {
        adjuntoService.enviar({ id: id, tipo: tipo }, urle).then(res => {
            if (res.data.includes('success')) {
                generic.funcMessage("success", "Cambio correcto", res.message, toast);
                if (res.data === 'success1') {
                    closeSession();
                    window.location.href = `${process.env.REACT_APP_WEBROOT}/`;
                } else if(reiniciar) {
                    reiniciar('ENVIADO');
                }
            }
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error al enviar el formulario', detail: error.response.data.message, life: 3000 });
        });
    }

    return {
        upLoadFile,
        nuevoUpload,
        eliminarConfirmacionAnexo,
        anexoPendiente,
        enviarFormulario,
        listaFormulario,
        listar,
        dialogInstruccion,
        setdialogIns,
        enviarFormularioPep
    }
}
