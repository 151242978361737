import { FilterMatchMode } from "primereact/api"

export class Origen {

    model = {
        id: {value: null, export: false},
        nombre: {value: '', export: false, header: 'Nombre origen', required: 'Nombre de es requerido', maxLength: 'Máximo 2000 caracteres', minLength: 'Mínimo 4 caracteres', 
            max: 2000, min: 4, table: true, filter: true, sort: true, filterPlaceholder:'Buscar por nombre de origen', matchMode: FilterMatchMode.CONTAINS},
        identificacion: {value: '', export: false, header: 'Identificación', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres', minLength: 'Mínimo 5 caracteres',
            max: 50, min: 5, table: true, filter: true, sort: true, filterPlaceholder:'Buscar por idntificación', matchMode: FilterMatchMode.CONTAINS},
        detalle: {value: '', export: true, header: 'Detalle', required: false, maxLength: 'Máximo 200 caracteres', minLength: 'Mínimo 10 caracteres',
            max: 2000, min: 10, filterField: 'detalle', table: true, filter: false, sort: true, filterPlaceholder: 'Buscar por detalle.'},
        link: {value: '', export: true, header: 'Link fuente origen', required: false, maxLength: 'Máximo 2000 caracteres', 
            minLength: 'Mínimo 10 caracteres', max: 2000, min: 10, filterField: 'link', filter: false, sort: false},
        estado: {value: null, header: 'Estado', value: {nombre: 'Activo', cat_id: 1}, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true},
    }

    optionLabel = 'nombre'
}