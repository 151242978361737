export class Efectivo {

    model = {
        id: { value: null, export: false },
        institucion: {
            value: '',
            table: true,
            header: 'Institución Financiera:',
            required: 'Campo requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3,
        },
        tipo_cuenta: {
            value: null,
            table: true,
            header: 'Tipo de Cuenta:',
            required: 'Seleccione una opción',
        },
        numero_cuenta: {
            value: '',
            table: true,
            header: 'No. de Cuenta:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            footer: 'SUBTOTAL:',
        },
        saldo: {
            value: 0,
            table: true,
            header: 'Saldo a la fecha de la declaración:',
            required: 'Saldo requerido',
            footer: 0,
        },
    }

    optionLabel = 'nombre';
}
