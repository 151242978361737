import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { UsuarioRuc } from "../../models/UsuarioRuc";
import { useForm } from "react-hook-form";
import { UsuarioRucService } from "../../service/UsuarioRucService";
import { InputComponent } from "../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";

export const Masivo = () => {
    const generic = new GenericComponent();
    const omodel = new UsuarioRuc();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [usuarios, setUsuarios] = useState(null);
    const usuarioRucService = new UsuarioRucService();
    const toast = useRef(null);
    const [dialogUsuario, setDialogUsuario] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText } = InputComponent({ registers: usuarios, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            usuarioRucService.getCarga({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setUsuarios(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        setDialogUsuario(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            usuarioRucService.newCarga(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogUsuario(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            usuarioRucService.editarCarga(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogUsuario(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editCarga = (empresa) => {
        usuarioRucService.consultarCarga(empresa.id).then(res => {
            let _carga = res.data.carga;
            const lista = { estado: generic.estados };

            Object.keys(lista).forEach(function (key) {
                if (_carga[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _carga[key]) {
                            _carga[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_carga, omodel.model));
            setDialogUsuario(true);
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarCarga(data)
        });
    }

    const eliminarCarga = (data) => {
        usuarioRucService.eliminarCarga(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    return (
        <>
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nueva identificación', permss)}
                    end={generic.rightToolbarTemplate(usuarios, omodel, 'Listado_usuarios', 'LISTADO IDENTIFICACIONES')}></Toolbar>
                {table({ action: generic.actionEditTemplate, methEdit: editCarga, perm: permss, methDelete: confirmDelete })}
            </Card>

            <Dialog visible={dialogUsuario} header={getValues('id') == null ? "Nueva identificación" : "Editar identificación"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogUsuario(false)} style={{width: '400px'}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('ruc', {}, 12)}
                        {inText('nombres', {}, 12)}
                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 12)}
                    </div>
                    {generic.buttonsForms(setDialogUsuario)}
                </form>
            </Dialog>
        </>
    )
}
