import { useForm } from "react-hook-form";
import { GenericComponent } from "../../components/GenericComponent";
import { Roles } from "../../models/Roles";
import { InputComponent } from "../../components/InputComponent";
import { RolService } from "../../service/RolService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { MenuRol } from "./MenuRol";

export const Rol = () => {

    const generic = new GenericComponent();
    const omodel = new Roles();
    const model = omodel.model;
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const toast = useRef(null);
    const [roles, setRoles] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inText, inDrop } = InputComponent({ registers: roles, omodel, generic, errors, control, permss, id: getValues('id') });
    const rolService = new RolService();
    const [dialogRol, setDialogRol] = useState(false);
    const [rol, setRol] = useState(null);
    const [dialogMenu, setDialogMenu] = useState(false);

    useEffect(() => {
        loadLazyRol();
    }, [lazyParams]);

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el rol?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarRol(data)
        });
    }

    const eliminarRol = (data) => {
        rolService.eliminar(data.id).then(res => {
            loadLazyRol();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const openNew = () => {
        reset(defaultValues);
        setDialogRol(true);
    }

    const loadLazyRol = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        let _lazyParams = lazyParams;
        delete _lazyParams.rtipo;
        if (permss == null) {
            _lazyParams.rtipo = true;
        }
        loadLazyTimeout = setTimeout(() => {
            rolService.getRoles({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRoles(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            rolService.newRol(data).then(res => {
                setDialogRol(false);
                loadLazyRol();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            rolService.editRol(data).then(res => {
                setDialogRol(false);
                loadLazyRol();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editRol = (data) => {
        rolService.consultarRol(data.id).then(res => {
            const lista = { estado: generic.estados, registros: generic.sino, aprobar: generic.sino, mostrar_empresas: generic.sino };
            let _rol = res.data;
            Object.keys(lista).forEach(function (key) {
                if (_rol[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _rol[key]) {
                            _rol[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_rol, model));
            setDialogRol(true);
        });
    }

    const asignarMenus = (rowData) => {
        setRol(rowData);
        setDialogMenu(true);
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, '', permss)}></Toolbar>
                {table({ action3: generic.actionEditDetailTemplate, methEdit: editRol, perm: permss, methDelete: confirmDelete, details: asignarMenus, icono: 'pi pi-key' })}
            </Card>

            <Dialog visible={dialogRol} header={getValues('id') == null ? "Nuevo rol" : "Editar rol"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogRol(false)} style={{ maxWidth: '600px' }} >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre', {}, 6)}
                        {inText('nemonico', { obl: 'no' }, 6)}
                        {inText('descripcion', { obl: 'no' }, 6)}
                        {inDrop('estado', generic.estados, { lab: 'nombre' }, 6)}
                        {inDrop('registros', generic.sino, { lab: 'nombre' }, 6)}
                        {inDrop('aprobar', generic.sino, { lab: 'nombre' }, 6)}
                        {inDrop('mostrar_empresas', generic.sino, { lab: 'nombre' }, 6)}
                    </div>
                    {generic.buttonsForms(setDialogRol)}
                </form>
            </Dialog>

            <Dialog visible={dialogMenu} header={"Asignar menús al rol: " + rol?.nombre} modal className="p-fluid modal-ssize" onHide={e => setDialogMenu(false)} style={{ maxWidth: '1000px' }}>
                <MenuRol id={rol?.id} toast={toast} permss={permss} />
            </Dialog>
        </>
    )
}
