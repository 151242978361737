import React, { useEffect, useRef, useState } from 'react';
import { GenericComponent } from '../../components/GenericComponent';
import { Origen } from '../../models/Origen';
import { useForm } from 'react-hook-form'
import { addLocale } from 'primereact/api';
import { Card } from 'primereact/card';
import { Toolbar } from 'primereact/toolbar';
import { OrigenService } from '../../service/OrigenService';
import { Panel } from 'primereact/panel';
import { Divider } from 'primereact/divider';
import { CatalogoService } from '../../service/CatalogoService';
import { Pago } from './Pago';
import { Tercero } from './Tercero';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';
import { InputComponent } from '../../components/InputComponent';
import { FileComponent } from '../../components/FileComponent';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useToken } from '../../components/useToken';
import { FiltroMultiempresa } from '../../components/FiltroMultiempresa';
import { Generico } from '../../components/Generico';

const OrigenLicito = () => {

    const generic = new GenericComponent();
    const omodel = new Origen();
    const model = omodel.model;
    const defaultValues = generic.default(model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, trigger, setValue } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const toast = useRef(null);
    const origenService = new OrigenService();
    const catalogoService = new CatalogoService();
    const [esRuc, setEsRuc] = useState(false);
    const [esRucRep, setEsRucRep] = useState(false);
    const [esCedula, setEsCedula] = useState(false);
    const [esCedulaRep, setEsCedulaRep] = useState(false);
    const [origenes, setOrigenes] = useState([]);
    const [dialogOrigen, setDialogOrigen] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [ciudades, setCiudades] = useState([]);
    const [ciudadesPago, setCiudadesPago] = useState([]);
    const [ecuador, setEcuador] = useState(false);
    const [ecuadorPago, setEcuadorPago] = useState(false);
    const [loadReport, setLoadReport] = useState(false);
    const [empresa, setEmpresa] = useState(null);
    const [permss, setPermss] = useState(null);
    const [origen, setOrigen] = useState(null);
    const { loadLazyDataPago, tablePago, dialogoPago } = Pago({ toast: toast, tipo: 'o', id: getValues('id'), permss });
    const { loadLazyTercero, tableTercero, dialogoTercero } = Tercero({ toast: toast, tipo: 'o', id: getValues('id'), permss });
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inArea, inCalendar, inCheck, inNumber, downloadPdf } =
        InputComponent({ registers: origenes, omodel, generic, errors, control, permss, id: getValues('id') });
    const { upLoadFile, nuevoUpload, eliminarConfirmacionAnexo, enviarFormulario, anexoPendiente, listaFormulario, listar } =
        FileComponent({ generic, tipo: 'origen', toast, unuevo: 'ficha/origen/store', udelete: 'ficha/origen/eliminar-anexo', permss, id: getValues('id') });
    const { getPerss, getRol } = useToken();
    const [siCliente, setSiCliente] = useState(false);
    const { accionesTbl } = Generico();
    addLocale(generic.idioma, generic.esFormat);

    useEffect(() => {
        setSiCliente(getPerss() !== undefined);
        if (getPerss() === undefined) {
            loadLazyData();
        }
        else {
            setPermss(getPerss());
            editFicha({ id: 0 });
        }
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            delete _lazyParams.empresa;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            if (empresa) {
                _lazyParams.empresa = empresa;
            }
            if (getRol()) {
                _lazyParams.rol = getRol();
            }
            origenService.getOrigen({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setOrigenes(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        if (catalogos == null) {
            origenService.newOrigen({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setDialogOrigen(true);
                reset(defaultValues);
            });
        }
        else {
            setDialogOrigen(true);
            reset(defaultValues);
        }
    }

    const onSubmit = (data) => {
        data.fecha = data.fecha.toISOString().split('T')[0];
        data.fecha_transaccion = data.fecha_transaccion.toISOString().split('T')[0];
        data.ec = catalogos.ecuador.cat_id;
        data.ruc = catalogos.ruc;
        if (getValues('id') == null) {
            origenService.newOrigen(data).then(res => {
                setValue('id', res.data);
                trigger('id');
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            origenService.editarOrigen(data).then(res => {
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const onChangePais = (e, pago) => {
        if (pago) {
            setEcuadorPago(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
        } else {
            setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
        }
    }

    const onChangeProvincia = (e, pago) => {
        catalogoService.getCatalogosId(e.value.cat_id).then(res => {
            if (pago) {
                setCiudadesPago(res.data);
            } else {
                setCiudades(res.data);
            }
        });
    }

    const editFicha = () => {
        const iniciar = catalogos == null ? 1 : null;
        origenService.consultarOrigen(origen?.id, iniciar).then(res => {
            let cats = catalogos;
            if (iniciar === 1) {
                cats = res.catalogos;
                setCatalogos(res.catalogos);
            }
            if (!res.origen) {
                reset(defaultValues);
                setDialogOrigen(true);
                return;
            }
            let _origen = res.origen;
            const lista = {
                pais: cats.pais, tipo_persona: cats.tipo, tipo_declarante: cats.declarante, moneda: cats.moneda, tipo_entrega: cats.entregado, pais_pago: cats.pais,
                tipo_identificacion: cats.tipo_identificacion, tipo_identificacion_rep: cats.tipo_identificacion
            };
            if (res.ciudades !== undefined) {
                lista['ciudad'] = res.ciudades;
                lista['provincia'] = cats.provincias;
                setCiudades(res.ciudades);
            }
            if (res.ciudades_pago !== undefined) {
                lista['ciudad_pago'] = res.ciudades_pago;
                lista['provincia_pago'] = cats.provincias;
                setCiudadesPago(res.ciudades_pago);
            }

            Object.keys(lista).forEach(function (key) {
                if (_origen[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _origen[key]) {
                            _origen[key] = element;
                        }
                    });
                }
            });
            setEcuador(JSON.stringify(_origen.pais) === JSON.stringify(cats.ecuador));
            setEcuadorPago(JSON.stringify(_origen.pais_pago) === JSON.stringify(cats.ecuador));
            setEsRuc(JSON.stringify(_origen.tipo_identificacion) === JSON.stringify(cats.ruc));
            setEsCedula(JSON.stringify(_origen.tipo_identificacion) === JSON.stringify(cats.cedula));
            setEsRucRep(JSON.stringify(_origen.tipo_identificacion_rep) === JSON.stringify(cats.ruc));
            setEsCedulaRep(JSON.stringify(_origen.tipo_identificacion_rep) === JSON.stringify(cats.cedula));

            //Para actualizaciones
            _origen.fecha = generic.stringToDate(_origen.fecha);
            _origen.fecha_transaccion = generic.stringToDate(_origen.fecha_transaccion);

            reset(generic.renewValues(_origen, model));
            setDialogOrigen(true);
            loadLazyDataPago(_origen.id);
            loadLazyTercero(_origen.id);
        });
    };

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar el formulario?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteOrigen()
        });
    }

    const deleteOrigen = () => {
        origenService.eliminarOrigen(origen?.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const generarReporte = () => {
        setLoadReport(true);
        origenService.descargar(getValues('id')).then(res => {
            downloadPdf(res, getValues('id') + '-Formulario-Origen-Licito.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            generic.funcMessageBlobError('Complete la información antes de generar.', error, toast);
        });
    }

    const subirReporte = () => {
        const urls = "ficha/origen/listar";
        const urlsd = "ficha/origen/descarga";
        nuevoUpload(getValues('id'), 'Subir formulario y adjuntos', urls, urlsd);
    }

    const onChangeTipo = (e) => {
        setEsRuc(JSON.stringify(e.value) === JSON.stringify(catalogos.ruc));
        setEsCedula(JSON.stringify(e.value) === JSON.stringify(catalogos.cedula));
    }

    const onChangeTipoRep = (e) => {
        setEsRucRep(JSON.stringify(e.value) === JSON.stringify(catalogos.ruc));
        setEsCedulaRep(JSON.stringify(e.value) === JSON.stringify(catalogos.cedula));
    }

    const enviar = () => {
        enviarFormulario(getValues('id'), 'ficha/origen/enviar', 'origen');
    }

    const archivos = () => {
        const urls = "ficha/origen/listar";
        const urlsd = "ficha/origen/descarga";
        listar(origen?.id, urls, urlsd, origen?.nemonico);
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!origen?.nemonico || origen?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editFicha() } });
        if (permss?.consultar) items.push({ label: 'Anexos formulario', icon: 'pi pi-clone', command: () => { archivos() } });
        if (permss?.eliminar && (!origen?.nemonico || origen.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogOrigen && !siCliente && <>
                {getRol() && getRol()?.ideg && <FiltroMultiempresa loadLazyData={loadLazyData} setEmpresa={setEmpresa} />}
                <Toolbar className="mb-4" start={(getRol() && getRol()?.ideg) ? null : generic.leftToolbarTemplate(openNew, 'Nuevo origen lícito', permss)}
                    end={generic.rightToolbarTemplate(origenes, omodel, 'Reporte_origen_licito', 'LISTADO ORIGEN LICITO')}></Toolbar>
                <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                    {table({ action01: accionesTbl, reg: setOrigen, items: itemsMenu() })}
                </Card>
            </>
            }
            {dialogOrigen &&
                <Panel header="NUEVO FORMULARIO DE DECLARACIÓN DE ORIGEN LÍCITO DE RECURSOS" style={{ width: '100%', margin: 'auto' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="formgrid grid">
                            {inCalendar('fecha')}
                            {inDrop('pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: false })}
                            {ecuador && inDrop('provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: false })}
                            {ecuador && inDrop('ciudad', ciudades, { lab: omodel.optionLabel })}
                            {!ecuador && inText('canton')}
                        </div>

                        <div className="formgrid grid">
                            {inDrop('tipo_persona', catalogos?.tipo, { lab: omodel.optionLabel })}
                            {inDrop('tipo_declarante', catalogos?.declarante, { lab: omodel.optionLabel })}
                            {inText('compania')}
                            {inText('proyecto')}
                        </div>

                        <Panel header="1) INFORMACIÓN DEL CLIENTE" className="panel-ficha">
                            <div className="formgrid grid">
                                {inDrop('tipo_identificacion', catalogos?.tipo_identificacion, { lab: omodel.optionLabel, chn: onChangeTipo })}
                                {!esRuc && !esCedula && inText('identificacion')}
                                {esRuc && inText('identificacion', generic.infoIdentificacion('ruc'))}
                                {esCedula && inText('identificacion', generic.infoIdentificacion('cedula'))}
                                {esRuc && inText('razon_social')}
                                {!esRuc && inText('nombres')}
                                {!esRuc && inText('apellidos')}
                            </div>
                            <Divider />

                            {esRuc &&
                                <div className="formgrid grid">
                                    {inDrop('tipo_identificacion_rep', catalogos?.tipo_identificacion, { lab: omodel.optionLabel, chn: onChangeTipoRep })}
                                    {!esRucRep && !esCedulaRep && inText('identificacion_rep')}
                                    {esRucRep && inText('identificacion_rep', generic.infoIdentificacion('ruc'))}
                                    {esCedulaRep && inText('identificacion_rep', generic.infoIdentificacion('cedula'))}
                                    {esRucRep && inText('razon_social_rep')}
                                    {!esRucRep && inText('nombres_rep')}
                                    {!esRucRep && inText('apellidos_rep')}
                                </div>
                            }
                        </Panel>
                        <br />

                        <Panel header="2) DATOS DE LA TRANSACCIÓN" className="panel-ficha">
                            <div className="formgrid grid">
                                {inArea('descripcion', 5, 60)}
                                {inNumber('valor_total', 2, true)}
                                {inDrop('moneda', catalogos?.moneda, { lab: omodel.optionLabel })}
                                {inDrop('pais_pago', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: true })}
                            </div>

                            <div className="formgrid grid">
                                {ecuadorPago && inDrop('provincia_pago', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: true })}
                                {ecuadorPago && inDrop('ciudad_pago', ciudadesPago, { lab: omodel.optionLabel })}
                                {!ecuadorPago && inText('canton_pago')}
                                {inCalendar('fecha_transaccion')}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="3) FORMA DE PAGO" className="panel-ficha">
                            <Message severity="info" text="Estimado usuario, favor llenar el resto de campos del formulario y guardar para llenar información de medio de pago y valor." style={{ width: '100%' }} />
                            {tablePago()}
                            <br />

                            <div className="formgrid grid">
                                {inArea('detalle_actividad', 5, 100, 6)}
                                {inDrop('tipo_entrega', catalogos?.entregado, { lab: omodel.optionLabel }, 6)}
                            </div>
                        </Panel>
                        <br />

                        <Message severity="info" text="Estimado usuario, favor llenar el resto de campos del formulario y guardar para llenar información de beneficiario final o tercero." style={{ width: '100%' }} />
                        <Panel header="4) En caso de señalar Beneficiario Final o Tercero/s complete lo siguiente:">
                            {tableTercero()}
                        </Panel>
                        <br />

                        <Panel header="5) DECLARACIÓN DE ORIGEN LÍCITO DE RECURSOS" className="panel-ficha">
                            {inCheck('entrega', <><b>{model.entrega.header}</b> {omodel.strEntrega}</>, {}, 12)}
                            {inCheck('recepcion', <><b>{model.recepcion.header}</b> {omodel.strRecepcion}</>, {}, 12)}
                        </Panel>
                        <br />

                        {generic.buttonsFormsReports(setDialogOrigen, generarReporte, subirReporte, enviar, loadReport, getValues('id') != null, !siCliente)}
                    </form>
                </Panel>
            }
            {dialogoPago()}
            {dialogoTercero()}
            {upLoadFile()}
            {eliminarConfirmacionAnexo()}
            {anexoPendiente('Enviar formulario - pendientes', 'Los siguientes anexos están pendientes de subir')}
            {listaFormulario('ficha/origen/aprobar', loadLazyData)}
        </>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(OrigenLicito, comparisonFn);
