import { useForm } from "react-hook-form";
import { PlanColor } from "../../models/matriz/PlanColor";
import { useEffect, useState } from "react";
import { PlanColorService } from "../../service/matriz/PlanColorService";
import { InputComponent } from "../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { ColorPicker } from 'primereact/colorpicker';

export const PlantillaColor = ({ plan, generic, permss, toast }) => {

    const omodel = new PlanColor();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registers, setRegisters] = useState(null);
    const colorSrv = new PlanColorService();
    const [dialogColor, setDialogColor] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inNumber, inColor } = InputComponent({ registers, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.idp = plan?.id;
            colorSrv.lista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegisters(res.data.registros);
                setLoading(false);
            });
        });
    }

    const openNew = () => {
        reset(defaultValues);
        setDialogColor(true);
    }

    const edit = (data) => {
        colorSrv.consultar(data.id, plan?.id).then(res => {
            const lista = { estado: generic.estados };
            let _plan = res.data;

            Object.keys(lista).forEach(function (key) {
                if (_plan[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _plan[key]) {
                            _plan[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_plan, omodel.model));
            setDialogColor(true);
        })
    }

    const onSubmit = (data) => {
        data.idp = plan?.id;
        if (getValues('id') == null) {
            colorSrv.nuevo(data).then(res => {
                generic.funcSuccess(res, toast);
                loadLazyData();
                setDialogColor(false);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        } else {
            colorSrv.editar(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogColor(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const deleteItem = (data) => {
        confirmDialog({
            message: '¿Esta seguro de eliminar la información?',
            header: 'Confirmar',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
                colorSrv.eliminar(data.id, plan?.id).then(res => {
                    generic.funcSuccess(res, toast);
                    loadLazyData();
                }).catch(error => {
                    generic.funcDeleteError(error, toast);
                });
            }
        });
    }

    return (
        <>
            <Card style={{ maxWidth: '100%', margin: 'auto' }} title={"Factores Matriz: " + plan?.nombre}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}></Toolbar>
                {table({ action: generic.actionEditTemplate, methEdit: edit, perm: permss, methDelete: deleteItem })}
            </Card>

            <Dialog visible={dialogColor} header={getValues('id') == null ? "Nuevo color" : "Editar color"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogColor(false)} style={{ maxWidth: '600px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre', {}, 6)}
                        {inNumber('orden', 0, false, { min: 0 }, 6)}
                        {inColor('color', 6)}
                        {inColor('color_texto', 6)}
                        {inNumber('minimo', 2, false, { min: 0 }, 6)}
                        {inNumber('maximo', 2, false, { min: 0 }, 6)}
                        {inText('rango', {}, 6)}
                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 4)}
                    </div>
                    {generic.buttonsForms(setDialogColor)}
                </form>
            </Dialog>
        </>
    )
}
