export class Notificacion {
    model = {
        id: {value: null, export: false},
        enlace: {value: null, export: false, header: 'Correo Enviado/[Segundo correo]/Fecha expiración notificación', required: false,
            filterField:'enlace', table: true, filter: false, sort: false, filterPlaceholder:'Buscar'},
        fecha: {value: new Date(), export: false, header: 'Fecha envío:', required: false, table: true, sort: false},
        usuario: {value: null, export: false, header: 'Usuario envía notificación', required: false,
            filterField:'usuario', table: true, filter: false, sort: false, filterPlaceholder:'Buscar'},
    }
}
