import { useForm } from "react-hook-form";
import { InputComponent } from "../../components/InputComponent";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { GenericComponent } from "../../components/GenericComponent";
import { PepFamilia } from "../../models/ficha/PepFamilia";
import { PepFichaService } from "../../service/ficha/PepFichaService";
import { useState } from "react";
import { Generico } from "../../components/Generico";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";

export const PepFamiliar = ({ tipo, id, toast, permss, opcion, catalogos }) => {

    const generic = new GenericComponent();
    const omodel = new PepFamilia();
    const { accionesTbl } = Generico();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const familiaSrv = new PepFichaService();
    const [registros, setRegistros] = useState(null);
    const [dialogFamiliar, setDialogFamiliar] = useState(false);
    const [familia, setFamilia] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inText, inDrop } = InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    const editFamiliar = () => {
        familiaSrv.consultarPep(familia?.id, opcion, tipo, id, 0).then(res => {
            let _cat = catalogos;
            const lista = { tipo_identificacion: _cat?.tipo };
            let _fam = res.data;

            Object.keys(lista).forEach(function (key) {
                if (_fam[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _fam[key]) {
                            _fam[key] = element;
                        }
                    });
                }
            });

            reset(_fam);
            setDialogFamiliar(true);
        });
    }

    const loadLazyFamiliar = (idp) => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        let _lazyParams = lazyParams;
        _lazyParams.idp = idp ?? id;
        _lazyParams.tipo = tipo;

        loadLazyTimeout = setTimeout(() => {
            familiaSrv.getPep({ lazyEvent: JSON.stringify(_lazyParams) }, opcion).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
            });
        });
    }

    const onSubmit = (data) => {
        data.idp = id;
        data.tipo = tipo;
        if (getValues('id') == null) {
            familiaSrv.newPep(data, opcion).then(res => {
                setDialogFamiliar(false);
                loadLazyFamiliar();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            familiaSrv.editarPep(data, opcion).then(res => {
                setDialogFamiliar(false);
                loadLazyFamiliar();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const openNew = () => {
        setFamilia(null);
        setDialogFamiliar(true);
        reset(defaultValues);
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteFamilia(familia?.id)
        });
    };

    const deleteFamilia = (id) => {
        familiaSrv.eliminarPep(id, opcion, tipo).then(res => {
            loadLazyFamiliar();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const dialogoFamiliar = () => {
        return (
            <Dialog visible={dialogFamiliar} header={getValues('id') == null ? "Nueva Información" : "Editar Información"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogFamiliar(false)} style={{ width: '400px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="formgrid grid">
                        {inText('nombre_completo', {}, 12)}
                        {inDrop('tipo_identificacion', catalogos?.tipo, { lab: omodel.optionLabel }, 12)}
                        {inText('identificacion', {}, 12)}
                        {inText('parentezco', {}, 12)}
                    </div>

                    {generic.buttonsForms(setDialogFamiliar)}
                </form>
            </Dialog>
        )
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!familia?.nemonico || familia.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editFamiliar() } });
        if (permss?.eliminar && (!familia?.nemonico || familia?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    const headerTemplate = (options) => {
        return (
            <div className={options.className}>
                <div className="flex align-items-center gap-2">
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" type="button" onClick={openNew} title="Registrar información"
                        style={{ display: permss?.crear ? 'block' : 'none' }} />
                    <span className="font-bold">Si su cónyuge o conviviente, o familiares cercanos es una Persona Expuesta Políticamente, detalle</span>
                </div>
            </div>
        );
    }

    const tableFamiliar = () => {
        return (
            <>
                <Panel headerTemplate={headerTemplate} className="panel-ficha col-12">
                    {table({ action01: accionesTbl, reg: setFamilia, items: itemsMenu() })}
                </Panel>
            </>
        )
    }

    return {
        tableFamiliar,
        dialogoFamiliar,
        loadLazyFamiliar
    }
}
