export class BancariaBVG {

    model = {
        id: {value: null, export: false},
        idp: {value: null, export: false},
        institucion_financiera: {value: '', export: false, header: 'Institución Financiera:', required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, table: true, filter: false, sort: true},
        numero_cuenta: {value: '', export: true, header: 'No. de Cuenta:', required: 'Campo requerido', maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 100, min: 4, table: true},
        tipo_cuenta: {value: null, export: false, header: 'Tipo de Cuenta:', required: 'Seleccione una opción', table: true},
    }
    optionLabel = 'nombre'
}
