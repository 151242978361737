import Axios from "axios";

export class MatrizService {

    getMatriz(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/factor/matriz/lista?${queryParams}`).then(res => res.data);
    }

    newMatriz(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/factor/matriz/nuevo`, data).then(res => res.data);
    }

    editarMatriz(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/factor/matriz/editar`, data).then(res => res.data);
    }

    consultarMatriz(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/factor/matriz/consultar/${id}/${iniciar}`).then(res => res.data);
    }

    eliminarMatriz(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/factor/matriz/eliminar/${id}`).then(res => res.data);
    }

    getValor(id, nemonico) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/factor/matriz/valor/${id}/${nemonico}`).then(res => res.data);
    }

    calcular(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/factor/matriz/calcular`, data).then(res => res.data);
    }

    descargar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/matriz/reporte/${id}`, { headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache' }, responseType: 'blob'}).then(async res => res.data);
    }

    cambiarMatriz(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/factor/matriz/cambiar`, data).then(res => res.data);
    }
}