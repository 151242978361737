import { useEffect, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { ColaPepIntento } from "../../models/administracion/ColaPepIntento";
import { InputComponent } from "../../components/InputComponent";
import { Card } from "primereact/card";

export const IntentoPepCola = ({ permss, etlService, ids }) => {

    const generic = new GenericComponent();
    const omodel = new ColaPepIntento();
    let loadLazyTimeout = null;
    const [registros, setRegistros] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams } = InputComponent({ registers: registros, omodel, generic, errors: null, control: null, permss, id: null });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.ids = ids;
            etlService.getIntentos({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
            });
        });
    }

    return (
        <Card style={{ maxWidth: '100%', margin: 'auto' }}>
            {table()}
        </Card>
    )
}
