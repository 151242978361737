import { FilterMatchMode } from "primereact/api"

export class Parametros {
    model = {
        id: {value: null, export: false},
        nombre: {value: '', export: true, header: 'Nombre', required: 'Nombre es requerido', maxLength: 'Máximo 300 caracteres', filter: true, filterPlaceholder: 'Buscar por nombre',
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'nombre', table: true, sort: true, matchMode: FilterMatchMode.CONTAINS, showFilterMatchModes: false},
        codigo: {value: '', export: true, header: 'Código', required: false, maxLength: 'Máximo 100 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 100, min: 4, filterField: 'codigo', table: true, filter: false, sort: true},
        valor: {value: 0, header: 'Valor', required: 'Valor es requerido', table: true},
        orden: {value: 0, header: 'Orden', required: 'Valor es requerido distinto de 0', table: false},
        ponderacion: {value: 0, header: 'Ponderación', required: false},
        principal: {value: null, header: 'Ponderación', required: false},
        tipo_factor: {value: null, export: false, header: 'Tipo factor', required: false, opcional: 'Tipo factor es requerido'},
        padre: {value: null, export: false, header: 'Factor principal', required: false, opcional: 'Factor principal es requerido', 
            filterField:'padre', table: true, filter: false, sort: true, filterPlaceholder:'Buscar por Medio de Pago'},
        estado: {header: 'Estado', value: {nombre: 'Activo', cat_id: true}, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true},
    }
    optionLabel = 'nombre'
}