export class VinculoFamilia {

    model = {
        id: { value: null, export: false },
        nombre_completo: {
            value: '',
            table: true,
            header: 'Apellidos y Nombres:',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        parentesco: {
            value: null,
            table: true,
            header: 'Parentesco **',
            required: 'Seleccione una opción',
        },
        cargo: {
            value: '',
            table: true,
            header: 'Cargo',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        empresa: {
            value: '',
            table: true,
            header: 'Empresa',
            required: 'Campo requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3,
        },
        relacion: {
            value: '',
            table: true,
            header: 'Relación ***',
            required: 'Campo requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3,
        },
        tipo_identificacion: {
            value: null,
            table: true,
            header: 'Tipo de Identificación',
            required: 'Seleccione una opción',
        },
        identificacion: {
            value: '',
            table: true,
            header: 'No. Cédula, Pasaporte o RUC del Deudor',
            required: 'Campo requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
        },
    }

    optionLabel = 'nombre';
}
