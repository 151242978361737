export class Comercial {

    model = {
        id: {value: null, export: false},
        idp: {value: null, export: false},
        nombres: {value: '', export: false, header: 'Nombre/Razón social', required: 'Nombre/Razón social es requerido',
            maxLength: 'Máximo 500 caracteres', minLength: 'Mínimo 4 caracteres', max: 500, min: 4, table: true, filter: false, sort: true},
        contacto: {value: '', export: true, header: 'Contacto', required: 'Contacto es requerido', maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, table: true,},
        telefono_contacto: {value: '', export: true, header: 'Teléfono de contacto', required: 'Teléfono de contacto es requerido',
            maxLength: 'Máximo 100 caracteres', minLength: 'Mínimo 4 caracteres', max: 100, min: 4, table: true,},
        tiempo_relacion: {value: '', export: true, header: 'Tiempo de la relación comercial / vinculación', required: 'Tiempo de la relación comercial es requerido',
            maxLength: 'Máximo 100 caracteres', minLength: 'Mínimo 4 caracteres', max: 100, min: 4, table: true,},
    }
}
