import { FilterMatchMode } from "primereact/api";

export class InstitucionPep {

    model = {
        id: {value: null, export: false, header: 'Código'},
        razon_social: {value: '', export: true, header: 'Razón Social', required: 'Campo requerido', maxLength: 'Máximo 500 caracteres', table: true, filter: true, max: 500, min: 4, 
            filterPlaceholder:'Buscar Razón Social', minLength: 'Mínimo 4 caracteres', filterField: 'razon_social', matchMode: FilterMatchMode.CONTAINS},
        ruc: {value: '', export: false, header: 'Ruc', required: 'Ruc es requerido', maxLength: 'Máximo 13 caracteres', table: true, filter: true, sort: true,
            minLength: 'Mínimo 13 caracteres', max: 50, min: 4, filterField: 'ruc', filterPlaceholder: 'Buscar por ruc', fielterField: 'ruc',
            matchMode: FilterMatchMode.CONTAINS, headerStyle: { width: '15%', minWidth: '15rem' }},
        url_transparencia: {value: '', export: true, header: 'Url paǵina contiene listado', required: 'Campo requerido', maxLength: 'Máximo 3000 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 3000, min: 4},
        url_directorio: {value: '', export: true, header: 'Url link archivo', required: 'Campo requerido', maxLength: 'Máximo 3000 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 3000, min: 4},
        lbl_descarga: {value: '', export: true, header: 'Etiqueta link de archivo', required: false, maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 200, min: 4},
        c_nombre : {value: 0, export: false, header: 'Indice columna de nombres', required: false},
        c_pit : {value: 0, export: false, header: 'Indice columna del puesto institucional', required: false},
        c_rmu_anual : {value: 0, export: false, header: 'Indice columna de la RMU anual (-1 si no existe)', required: false},
        c_rmu_mensual : {value: 0, export: false, header: 'Indice columna de la RMU mensual (-1 si no existe)', required: false},
        c_cedula : {value: -1, export: false, header: 'Indice columna de cédula (-1 si no existe)', required: false},
        pagina : {value: 1, export: false, header: 'Número de página referencia', required: false},
        si_stream : {value: 1, export: false, header: 'Si es stream o table (No modificar)', required: false},
        estado: {value: null, export: false, header: 'Estado', required: 'Estado es requerido', table: true, sort: false},
    }
    optionLabel = 'nombre';
}