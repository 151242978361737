import Axios from "axios";

export class PersonaJuridicaService {

    getCliente(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/persona-juridica-bvg/lista?${queryParams}`).then(res => res.data);
    }

    newCliente(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/persona-juridica-bvg/nuevo`, data).then(res => res.data);
    }

    editarCliente(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/persona-juridica-bvg/editar`, data).then(res => res.data);
    }

    consultarCliente(id, seccion) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/persona-juridica-bvg/consulta/${id}/${seccion}`).then(res => res.data);
    }

    eliminarCliente(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/persona-juridica-bvg/eliminar/${id}`).then(res => res.data);
    }

    descargar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/persona-juridica-bvg/reporte/${id}`, {
            headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache', 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Expires': 0 },
            params: {
                t: new Date().getTime(),
            },
            responseType: 'blob'
        }).then(async res => res.data);
    }
}
