import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { GenericComponent } from '../../../components/GenericComponent';
import { ClientePJService } from '../../../service/ClientePJService';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { EconomicaCpj } from '../../../models/cpj/EconomicaCpj';
import { Divider } from 'primereact/divider';

export const InfEconomica = ({ toast, back, next, id, permss }) => {

  const generic = new GenericComponent();
  const omodel = new EconomicaCpj();
  const defaultValues = generic.default(omodel.model);
  const { control, formState: { errors }, handleSubmit, reset, setError, getValues, setValue } = useForm({ defaultValues });
  const [clientepj] = useState([]);
  const clientepjSrv = new ClientePJService();
  const { inNumber } = InputComponent({ registers: clientepj, omodel, generic, errors, control, permss, id: id });

  useEffect(() => {
    clientepjSrv.consultarClientepj(id, 'p3').then(res => {
      let _clientepj = res.clientepj;
      reset(_clientepj);
    });
  }, [false])

  const onSubmit = (data) => {
    data.paso = 'p3';
    data.id = id;
    clientepjSrv.editarClientepj(data).then(res => {
      generic.funcSuccess(res, toast);
      next(id);
    }).catch(error => {
      generic.funcError(error, toast, setError);
    });
  }

  const actualizarPatrimonio = (e, info) => {
    const actvs = ['INGR_MENSUAL', 'ACT_CORRIENTE', 'ACT_NO_CORRIENTE'];
    const pasvs = ['EGRE_MENSUAL', 'PAS_CORRIENTE', 'PAS_NO_CORRIENTE'];
    let totalPasivos = 0;
    let totalActivos = 0;
    actvs.forEach(itm => {
      if (getValues(itm) && !isNaN(getValues(itm))) {
        totalActivos += parseFloat(getValues(itm));
      }
    });
    pasvs.forEach(itm => {
      if (getValues(itm) && !isNaN(getValues(itm))) {
        totalPasivos += parseFloat(getValues(itm));
      }
    });
    setValue('TOT_ACTIVO', totalActivos);
    setValue('TOT_PASIVOS', totalPasivos);
    setValue('PATRIMONIO', totalActivos - totalPasivos);
  }

  return (
    <>
      < form onSubmit={handleSubmit(onSubmit)}>
        <Panel header="A.4 INFORMACIÓN ECONÓMICA DEL CLIENTE" className="panel-ficha">
          <div className="formgrid grid">
            {inNumber('INGR_MENSUAL', 2, true, { func: actualizarPatrimonio, vars: null })}
            {inNumber('EGRE_MENSUAL', 2, true, { func: actualizarPatrimonio, vars: null })}
          </div>
          <div className="formgrid grid">
            {inNumber('ACT_CORRIENTE', 2, true, { func: actualizarPatrimonio, vars: null })}
            {inNumber('PAS_CORRIENTE', 2, true, { func: actualizarPatrimonio, vars: null })}
          </div>
          <div className="formgrid grid">
            {inNumber('ACT_NO_CORRIENTE', 2, true, { func: actualizarPatrimonio, vars: null })}
            {inNumber('PAS_NO_CORRIENTE', 2, true, { func: actualizarPatrimonio, vars: null })}
          </div>
          <Divider />

          <div className="formgrid grid">
            {inNumber('TOT_ACTIVO', 2, true, { disabled: true })}
            {inNumber('TOT_PASIVOS', 2, true, { disabled: true })}
          </div>
          <Divider />

          <div className="formgrid grid">
            {inNumber('PATRIMONIO', 2, true, { disabled: true, min: -10000000000000 })}
          </div>
          <Divider />

          <div className="formgrid grid">
            {inNumber('INGR_ANUAL', 2, true)}
            {inNumber('EGRE_ANUAL', 2, true)}
          </div>
        </Panel >
        <br />
        <div className="formgrid grid">
          <div className="field col">
            <Button label="Anterior" icon="pi pi-chevron-left" onClick={back} />
          </div>
          <div className="field col">
            <Button label="Siguiente" icon="pi pi-chevron-right" type="submit" />
          </div>
        </div>
      </form >
    </>
  )
}
