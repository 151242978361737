import React, { useEffect } from 'react';
import { Route, useLocation, withRouter } from 'react-router-dom';
import App from './App';
import { Login } from './pages/Login';
import { useToken } from './components/useToken';
import Axios from 'axios';
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';

const AppWrapper = () => {

    let location = useLocation();

    const { getToken, getNombre, closeSession } = useToken();
    Axios.interceptors.request.use(request => {
        request.headers.Accept = 'application/json';
        request.headers.withCredentials = true;
        const wroot = process.env.REACT_APP_WEBROOT;
        if (wroot.includes('localhost') && getToken()) {
            request.headers.Authorization = `Bearer ${getToken()}`;
        }

        return request;
    });

    Axios.interceptors.response.use(response => response, async (error) => {
        let message = error.response.data.message;
        if (!message) {
            const responseObj = await error.response.data.text();
            error.response.data = JSON.parse(responseObj);
            message = error.response.data.message;
        }
        if (error.response !== undefined && error.response.status === 401 && message === 'Unauthenticated.') {
            closeSession();
            window.location.href = `${process.env.REACT_APP_WEBROOT}/`;
        } else {
            return Promise.reject(error);
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    switch (location.pathname) {
        case '/login':
            return <Route path="/login" component={Login} />
        default:
            const isProduction = process.env.REACT_APP_NODE_ENV !== 'local';
            return (
                getNombre() ?
                    <CacheBuster
                        currentVersion={packageJson.version}
                        isEnabled={isProduction}
                        isVerboseMode={false}
                        metaFileDirectory={process.env.REACT_APP_META}>
                        <App />
                    </CacheBuster>
                    : <Login />
            );
    }
}

export default withRouter(AppWrapper);
