import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { useState } from 'react'
import { useForm } from 'react-hook-form';
import { GenericComponent } from '../../components/GenericComponent';
import { InputComponent } from '../../components/InputComponent';
import { Declarante } from '../../models/Declarante';
import { CatalogoService } from '../../service/CatalogoService';
import { DeclaranteService } from '../../service/DeclaranteService';
import { Divider } from 'primereact/divider';
import { confirmDialog } from 'primereact/confirmdialog';

export const RepresentanteApoderado = ({ toast, tipo, id, permss }) => {

    const generic = new GenericComponent();
    const omodel = new Declarante();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [declarantes, setDeclarantes] = useState([]);
    const declaranteService = new DeclaranteService();
    const catalogoSrv = new CatalogoService();
    const [dialogDeclarante, setDialogDeclarante] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [esRuc, setEsRuc] = useState(false);
    const [esCedula, setEsCedula] = useState(false);
    const [ecuador, setEcuador] = useState(false);
    const [ciudades, setCiudades] = useState([]);
    const [tieneConyugue, setTieneConyugue] = useState(false);
    const [esClientePJ, setEsClientePJ] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inArea } =
        InputComponent({ registers: declarantes, omodel, generic, errors, control, permss, id: getValues('id') });

    const loadLazyDeclarante = (idj) => {
        setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.idp = idj == null ? id : idj;
            _lazyParams.tipo = tipo;
            declaranteService.getRepresentanteApoderado({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setDeclarantes(res.data.registros);
                setLoading(false);
            });
        });
    }

    const openNew = () => {
        setEsClientePJ(tipo == 'clientepj');
        if (catalogos == null) {
            declaranteService.newRepresentanteApoderado({ iniciar: 1, tipo: tipo }).then(res => {
                setCatalogos(res.data);
                setDialogDeclarante(true);
                reset(defaultValues);
            });
        }
        else {
            setDialogDeclarante(true);
            reset(defaultValues);
        }
    }

    const hideDialog = () => { setDialogDeclarante(false) }

    const onSubmit = (data) => {
        data.idp = id;
        data.tipo = tipo;
        data.ruc = catalogos.ruc;
        data.tipo = tipo;
        data.casado = catalogos.casado;
        data.union = catalogos.union;
        data.ec = catalogos.ecuador;
        if (getValues('id') == null) {
            declaranteService.newRepresentanteApoderado(data).then(res => {
                setDialogDeclarante(false);
                loadLazyDeclarante();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            declaranteService.editarDeclarante(data).then(res => {
                setDialogDeclarante(false);
                loadLazyDeclarante();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editFicha = (data) => {
        const iniciar = catalogos == null ? 1 : 0;
        declaranteService.consultarJuridica(data.id, tipo, id, iniciar).then(res => {
            let _cat = catalogos;
            if (iniciar == 1) {
                _cat = res.data.catalogos;
                setCatalogos(res.data.catalogos);
            }
            let _dec = res.data.declarante;
            let lista = {
                pais_id: _cat.pais, estado_civil: _cat.estado_civil, tipo_identificacion: _cat.tipo, tipo_declarante: _cat.tipoRA
            };

            if (res.data.ciudades) {
                lista['provincia_id'] = _cat.provincias;
                lista['ciudad_id'] = res.data.ciudades;
                setCiudades(res.data.ciudades);
            }
            setEcuador(_dec.pais_id == _cat.ecuador.cat_id);
            setEsClientePJ(tipo == 'clientepj');
            Object.keys(lista).forEach(function (key) {
                if (_dec[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _dec[key]) {
                            _dec[key] = element;
                        }
                    });
                }
            });
            setTieneConyugue(JSON.stringify(_dec.estado_civil) === JSON.stringify(_cat.casado) || JSON.stringify(_dec.estado_civil) === JSON.stringify(_cat.union));
            reset(generic.renewValues(_dec, omodel.model));
            setDialogDeclarante(true);
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el declarante?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarDeclarante(data)
        });
    }

    const eliminarDeclarante = (data) => {
        declaranteService.eliminarDeclarante(data.id, tipo, id).then(res => {
            loadLazyDeclarante();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const tableDeclarante = () => {
        return (
            <>
                {id && <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, '', permss)}></Toolbar>}
                {table({ action: generic.actionEditTemplate, methEdit: editFicha, perm: permss, methDelete: confirmDelete })}
            </>
        )
    }

    const onChangePais = (e) => {
        setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvincia = (e) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudades(res.data);
        });
    }


    const onChangeTipo = (e) => {
        setEsRuc(JSON.stringify(e.value) === JSON.stringify(catalogos.ruc));
        setEsCedula(JSON.stringify(e.value) === JSON.stringify(catalogos.cedula));
    }

    const onChangeEstadoCivil = (e) => {
        setTieneConyugue(JSON.stringify(e.value) === JSON.stringify(catalogos.casado) || JSON.stringify(e.value) === JSON.stringify(catalogos.union));
    }

    const dialogoDeclarante = () => {
        return (
            <Dialog visible={dialogDeclarante} header={getValues('id') == null ? "Representante Legal/Apoderado" : "Editar Representante Legal/Apoderado"} modal className="p-fluid modal-ssize" onHide={e => setDialogDeclarante(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inDrop('tipo_declarante', catalogos?.tipoRA, { lab: omodel.optionLabel }, 4)}
                        {inDrop('tipo_identificacion', catalogos?.tipo, { lab: omodel.optionLabel, chn: onChangeTipo }, 4)}
                        {!esCedula && !esRuc && inText('identificacion', {}, 4)}
                        {esCedula && inText('identificacion', generic.infoIdentificacion('cedula'), 4)}
                        {esRuc && inText('identificacion', generic.infoIdentificacion('ruc'), 4)}
                    </div>
                    <Divider />

                    <div className="formgrid grid">
                        {esRuc && inText('razon_social', {}, 4)}
                        {!esRuc && inText('nombres', {}, 4)}
                        {!esRuc && inText('apellidos', {}, 4)}
                        {!esClientePJ && inDrop('estado_civil', catalogos?.estado_civil, { lab: omodel.optionLabel, chn: onChangeEstadoCivil }, 4)}
                    </div>
                    <Divider />

                    <div className="formgrid grid">
                        {inArea('direccion_domicilio', 5, 30, 4)}
                        {inDrop('pais_id', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: false }, 4)}
                        {ecuador && inDrop('provincia_id', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: false }, 4)}
                        {ecuador && inDrop('ciudad_id', ciudades, { lab: omodel.optionLabel }, 4)}
                        {!ecuador && inText('ciudad', {}, 4)}
                    </div>
                    <Divider />

                    <div className="formgrid grid">
                        {inText('telefono_domicilio', {}, 4)}
                        {inText('telefono_oficina', {}, 4)}
                        {inText('telefono_celular', {}, 4)}
                    </div>
                    <Divider />

                    {tieneConyugue &&
                        <div className="formgrid grid">
                            {inText('apellidos_conyugue', {}, 4)}
                            {inText('nombres_conyugue', {}, 4)}
                            {inText('identificacion_conyugue', {}, 4)}
                        </div>
                    }
                    {generic.buttonsForms(hideDialog)}
                </form>
            </Dialog>
        )
    }

    return {
        loadLazyDeclarante,
        tableDeclarante,
        dialogoDeclarante,
        setDeclarantes
    }
}
