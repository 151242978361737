export class InfoBvg {
    model = {
        id: {
            value: null,
            header: 'Código',
        },
        fecha: {
            value: new Date(),
            header: 'Fecha:',
            required: 'Fecha es requerido',
        },
        pais: {
            value: null,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia: {
            value: null,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad: {
            value: null,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton: {
            value: '',
            header: 'Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Ciudad es requerida'
        },
        tipo_declaracion: {
            value: null,
            header: 'Tipo de proceso:',
            required: 'Tipo de proceso es requerido'
        },
        tipos_solicitante: {
            value: [],
            export: false,
            header: 'Relación/vinculación con BVG:',
            required: 'Relación/vinculación es requerido'
        },
        razon_social: {
            value: '',
            header: 'Razón o Denominación Social:',
            required: 'Razón Social / Nombre: es requerido',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 3 caracteres',
            min: 3,
            max: 500
        },
        nacionalidad: {
            value: null,
            header: 'Nacionalidad:',
            required: ' Nacionalidad es Requerida'
        },
        identificacion: {
            value: '',
            header: 'Identificación:',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
        },
        tipo_identificacion: {
            value: null,
            header: 'Tipo de Identificación:',
            required: 'Tipo de Identificación es requerido'
        },
        tipo_empresa: {
            value: '',
            header: 'Tipo de Empresa, Compañía o sociedad',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3,
            required: 'Tipo de Empresa es requerido'
        },
        sector_economico: {
            value: '',
            header: 'Sector Económico:',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
            required: 'Campo requerido'
        },
        actividad_economica: {
            value: null,
            header: 'Actividad económica:',
            required: 'Actividad económica es Requerida'
        },
        lugar_constitucion: {
            value: '',
            header: 'Lugar de constitución:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Lugar de constitución es requerido'
        },
        fecha_constitucion: {
            value: new Date(),
            header: 'Fecha de constitución:',
            required: 'Fecha es requerido',
        },
        pais_empresa: {
            value: null,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia_empresa: {
            value: null,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad_empresa: {
            value: null,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton_empresa: {
            value: '',
            export: false,
            header: 'Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Ciudad es requerida'
        },
        direccion_empresa: {
            value: '',
            header: 'Dirección Empresa (Matriz y Sucursal) (Calle Principal , calle transversal, No de oficina, referencia):',
            required: 'Dirección es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3
        },
        telefono_empresa: {
            value: '',
            header: 'Teléfono/celular:',
            required: 'Campo requerido',
            maxLength: 'Máximo 30 caracteres',
            minLength: 'Mínimo 7 caracteres',
            max: 30,
            min: 7,
        },
        pagina_empresa: {
            value: '',
            header: 'Página web o dirección electrónica de la empresa:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Página web o dirección electrónica es requerido',
        },
    }
    optionLabel = 'nombre';
}
