import Axios from "axios";

export class ParametroService {

    getParametro(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/parametro/lista?${queryParams}`).then(res => res.data);
    }

    newParametro(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/administracion/parametro/nuevo`, data).then(res => res.data);
    }

    editarParametro(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/administracion/parametro/editar`, data).then(res => res.data);
    }

    subirParametro(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/administracion/parametro/subir-factor`, data).then(res => res.data);
    }

    consultarParametro(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/parametro/${id}/${iniciar}`).then(res => res.data);
    }

    eliminarParametro(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/administracion/parametro/eliminar/${id}`).then(res => res.data);
    }

    exportar(id, codigo) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/parametro/exportar/${id}/${codigo}`).then(res => res.data);
    }
}