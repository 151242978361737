import { FilterMatchMode } from "primereact/api"

export class UsuarioPermiso {

    model = {
        id: { value: null, export: false },
        empresa: {
            header: 'Empresa', value: null, required: 'Empresa es requerido', optionLabel: 'nombre', export: true, table: true, filterField: 'empresa',
            filter: true, sort: true, filterPlaceholder: 'Buscar por empresa'
        },        
        grupo: {
            header: 'Grupo Empresa', value: null, required: 'Grupo Empresa es requerido', optionLabel: 'nombre', export: true, table: true, filterField: 'grupo',
            filter: true, sort: true, filterPlaceholder: 'Buscar por empresa'
        },
        rol: { header: 'Rol', value: null, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true },
        observacion: {
            value: '', export: true, header: 'Observación', required: 'Observación es requerido', maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres',
            max: 300, min: 4, filterField: 'observacion', table: true, filter: false, sort: true, filterPlaceholder: 'Buscar por Observación.', matchMode: FilterMatchMode.CONTAINS
        },
        registro_grupo: {header: 'Registro Grupo', value: {nombre: 'SI', cat_id: true}, required: 'Indicar si el permiso puede ver todos los registros del grupo.', 
            optionLabel: 'nombre', export: true, table: true},
        estado: { header: 'Estado', value: { nombre: 'Activo', cat_id: true }, required: 'Estado es requerido', optionLabel: 'nombre', export: false, table: false },
    }

    optionLabel = 'nombre'
}