import Axios from "axios";

export class GrupoEmpresaService {

    getGrupoEmpresa(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/grupo-empresa/lista?${queryParams}`).then(res => res.data);
    }

    newGrupoEmpresa(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/administracion/grupo-empresa/nuevo`, data).then(res => res.data);
    }

    editarGrupoEmpresa(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/administracion/grupo-empresa/editar`, data).then(res => res.data);
    }

    consultarGrupoEmpresa(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/grupo-empresa/${id}`).then(res => res.data);
    }

    eliminarGrupoEmpresa(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/administracion/grupo-empresa/eliminar/${id}`).then(res => res.data);
    }
}