import { FilterMatchMode } from "primereact/api"

export class Grupo {

    model = {
        id: { value: null, export: false },
        nombre_grupo: {
            value: '', export: true, header: 'Nombre grupo', required: 'Nombre Grupo es requerido', maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'nombre_grupo', table: true, filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS
        },
        corporacion: { header: 'Corporación', value: { nombre: 'SI', cat_id: true }, required: 'Indicar si es corporación', optionLabel: 'nombre', export: true, table: true },        
        estado: { header: 'Estado', value: { nombre: 'Activo', cat_id: true }, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true },
        fecha: {header: 'Fecha', value: null, table: true, export: true}
    }

    optionLabel = 'nombre'
}