export class Notificado {

    model = {
        id: { value: null, export: false },
        idp: { value: null, export: false },
        ausencia_contacto: {
            value: '', export: false, header: 'En ausencia del contacto autorizado (RL o apoderado), se notificará a:', required: 'Campo requerido',
            maxLength: 'Máximo 500 caracteres', minLength: 'Mínimo 3 caracteres', max: 500, min: 3, table: true
        },
        nombre_firma: {
            value: '', header: 'Nombres firmas autorizadas adicionales:', required: 'Campo requerido', maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 3 caracteres', max: 500, min: 3, table: true
        },
        cargo: {
            value: '', header: 'Cargo:', required: 'Campo requerido', maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres', max: 300, min: 3, table: true
        },
        correo_electronico: {
            value: '', header: 'Correo electrónico:', required: 'Campo requerido', maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres', max: 300, min: 3, table: true
        },
    }
    optionLabel = 'nombre'
}
