import Axios from "axios";

export class ProveedorService {

    getLista(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/proveedor/lista?${queryParams}`).then(res => res.data);
    }

    newProveedor(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/proveedor/nuevo`, data).then(res => res.data);
    }

    editarProveedor(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/proveedor/editar`, data).then(res => res.data);
    }

    eliminarProveedor(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/proveedor/eliminar/${id}`).then(res => res.data);
    }

    consultarProveedor(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/proveedor/consulta/${id}/${iniciar}`).then(res => res.data);
    }

    descargar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/proveedor/reporte/${id}`, { headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache' }, responseType: 'blob'}).then(async res => res.data);
    }
}
