export class DeclaranteBvg {

    model = {
        id: { value: null, export: false },
        tipo_rep: {
            value: null,
            header: 'Tipo de Declarante',
            required: 'Tipo de declarante es obligatorio'
        },
        nombres_rep: {
            value: '',
            header: 'Nombres completos',
            required: 'Nombres es requerido',
            maxLength: 'Máximo 300 caracteres',
            filterPlaceholder: 'nombres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        apellidos_rep: {
            value: '',
            header: 'Apellidos completos',
            required: 'Apellidos es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        nacionalidad_rep: {
            value: null,
            header: 'Nacionalidad',
            required: 'Nacionalidad es requerido'
        },
        tipo_identificacion_rep: {
            value: null,
            required: 'Tipo de identificación es requerido',
            header: 'Tipo de identificación',
        },
        identificacion_rep: {
            value: '',
            header: 'Identificación',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
        },
        pais_residencia: {
            value: null,
            header: 'País de Residencia',
            required: 'País de Residencia es requerido'
        },
        estado_civil: {
            value: null,
            header: 'Estado Civil',
            required: 'Estado Civil es requerido'
        },
        genero: {
            value: null,
            header: 'Sexo',
            required: 'Seleccione una opción'
        },
        lugar_nacimiento: {
            value: '',
            header: 'Lugar de Nacimiento:',
            required: 'Campo es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        fecha_nacimiento: {
            value: new Date(),
            header: 'Fecha de nacimiento:',
            required: 'Fecha es requerido'
        },
        direccion_residencia: {
            value: '',
            header: 'Dirección Residencia: (Urbanización/barrio, avenida, calle, manzana, bloque, solar, villa)',
            required: 'Dirección es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        telefono_rep: {
            value: '',
            header: 'Teléfono/celular',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        profesion_rep: {
            value: '',
            header: 'Profesión u Ocupación:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        tiempo_rep: {
            value: '',
            header: 'Tiempo de Permanencia en la Empresa:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        correo_rep: {
            value: '',
            header: 'Correo electrónico:',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        nombre_completo_cy: {
            value: '',
            header: 'Apellidos y Nombres Completos (Cónyuge):',
            required: 'Campo requerido',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 500,
            min: 3,
        },
        tipo_identificacion_cy: {
            value: null,
            required: 'Tipo de identificación es requerido',
            header: 'Tipo de identificación',
        },
        identificacion_cy: {
            value: '',
            header: 'No. De Identificación:',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
        },
        nacionalidad_cy: {
            value: null,
            header: 'Nacionalidad (Cónyuge):',
            required: 'Nacionalidad es requerido'
        },
        profesion_cy: {
            value: '',
            header: 'Profesión u Ocupación (Cónyuge):',
            required: 'Campo es requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3,
        },
    }

    optionLabel = 'nombre';
}
