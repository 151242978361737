import { FilterMatchMode } from "primereact/api"

export class Factor {
    model = {
        id: { value: null, header: 'Código', export: true, table: true, sort: true, filterField: 'id', filter: true, matchMode: FilterMatchMode.EQUALS, headerStyle: { width: '10%', minWidth: '10rem' } },
        nombre: {
            value: '', export: true, header: 'Nombre', required: 'Nombre es requerido', maxLength: 'Máximo 500 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, filterField: 'nombre', filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '30%', minWidth: '20rem' }
        },
        nemonico: {
            value: '', export: true, header: 'Siglas', required: 'Siglas es requerido', maxLength: 'Máximo 500 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, filter: false, sort: true, headerStyle: { width: '30%', minWidth: '20rem' }
        },
        ponderacion: {
            header: 'Ponderación por defecto', value: 0, required: 'Ponderación es requerido', export: true, table: true, min: 0, max: 100,
            minLength: 'Mínimo 0', maxLength: 'Máximo 100', headerStyle: { width: '10%', minWidth: '10rem' }
        },
        estado: { header: 'Estado', value: { nombre: 'Activo', cat_id: true }, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true },
        fecha: { header: 'Fecha', value: '', required: 'campo requerido', export: true, table: true },
    }
    optionLabel = 'nombre'
}
