import Axios from "axios";

export class JuridicaService {

    getJuridica(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/juridica/lista?${queryParams}`).then(res => res.data);
    }

    newJuridica(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/juridica/nuevo`, data).then(res => res.data);
    }

    editarJuridica(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/juridica/editar`, data).then(res => res.data);
    }

    consultarJuridica(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/juridica/consulta/${id}/${iniciar}`).then(res => res.data);
    }

    eliminarJuridica(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/juridica/eliminar/${id}`).then(res => res.data);
    }
    
    descargar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/juridica/reporte/${id}`, { headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache' }, responseType: 'blob' }).then(async res => res.data);
    }
}