import { FilterMatchMode } from "primereact/api";

export class NombrePep {

    model = {
        id: { value: null, export: false, header: 'Código' },
        nombres: {
            value: '', export: true, header: 'Nombres', required: 'Campo requerido', maxLength: 'Máximo 500 caracteres', table: true, filter: true, max: 500, min: 4, sort: true,
            filterPlaceholder: 'Buscar Nombres', minLength: 'Mínimo 4 caracteres', filterField: 'nombres', matchMode: FilterMatchMode.CONTAINS
        },
        identificacion: {
            value: '', export: true, header: 'Identificación', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres', table: true, filter: true, sort: false,
            minLength: 'Mínimo 13 caracteres', max: 50, min: 4, filterPlaceholder: 'Buscar por identificación', filterField: 'identificacion',
            matchMode: FilterMatchMode.CONTAINS, headerStyle: { width: '15%', minWidth: '15rem' }
        },
        puesto: {
            value: '', export: true, table: true, header: 'Puesto institucional', required: 'Campo requerido', maxLength: 'Máximo 300 caracteres', filter: true, sort: true,
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'puesto', filterPlaceholder: 'Buscar por puesto institucional', matchMode: FilterMatchMode.CONTAINS
        },
        institucion: {
            value: null, export: true, header: 'Institución', required: 'Institución es requerido', table: true, sort: false, filter: true,
            filterField: 'institucion', filterPlaceholder: 'Buscar por institución', matchMode: FilterMatchMode.CONTAINS
        },
        rmu_anual: { value: 0.0, export: false, header: 'Remuneración anual', required: false, minLength: 'Valor mínimo 0', min: 0.0 },
        rmu_mensual: { value: 0.0, export: false, header: 'Remuneración mensual', required: false, minLength: 'Valor mínimo 0', min: 0.0 },
        estado: { value: null, export: true, header: 'Estado', required: 'Estado es requerido', table: true, sort: false },
        tipo: { value: null, export: false, header: 'Tipo de identificación', required: 'Campo requerido', table: false, sort: false },
    }
    optionLabel = 'nombre';
}
