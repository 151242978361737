export class Bancaria {

    model = {
        id: {value: null, export: false},
        idp: {value: null, export: false},
        institucion_financiera: {value: '', export: false, header: 'Institución Financiera', required: 'Institución Financiera es requerido', 
            maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, table: true, filter: false, sort: true},
        numero_cuenta: {value: '', export: true, header: 'No. de Cuenta', required: 'No. de Cuenta', maxLength: 'Máximo 100 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 100, min: 4, table: true},       
        tipo_cuenta: {value: null, export: false, header: 'Tipo de Cuenta', required: 'Tipo de cuenta es requerido', table: true},
    }    
    optionLabel = 'nombre'
}