import { FilterMatchMode } from "primereact/api"

export class AdCatalogo {

    model = {
        id: {value: null, export: false},
        nombre: {value: '', export: true, header: 'Nombre', required: 'Nombre es requerido', maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres',
            max: 300, min: 4, filterField: 'nombre', table: true, filter: true, sort: true, filterPlaceholder: 'Buscar por nombre.', matchMode: FilterMatchMode.CONTAINS},
        estado: {header: 'Estado', value: {nombre: 'Activo', cat_id: 1}, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true},
    }
    optionLabel = 'nombre'
}
