import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { useRef } from "react";

export const Generico = () => {
    const menu = useRef(null);

    const buttonsForms = (hideDialog) => {
        return (
            <>
                <div className="formgrid grid">
                    <div className="field col">
                        <Button label="Guardar" style={{ maxWidth: '120px', float: 'right' }} type="submit" icon="pi pi-save" className="p-button-warning" />
                    </div>
                    <div className="field col">
                        <Button label="Cancelar" style={{ maxWidth: '120px' }} icon="pi pi-times" type="button" className="p-button-warning" onClick={() => hideDialog(false)} />
                    </div>
                </div>
            </>
        );
    }

    const funcMessageBlobError = async (title, error, toast) => {
        try {
            let message = error.response.data.message;
            if (!message) {
                const responseObj = await error.response.data.text();
                error.response.data = JSON.parse(responseObj);
                message = error.response.data.message;
            }
            toast.current.show({ severity: 'error', summary: title, detail: message, life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: title, detail: 'Corrija los errores.', life: 3000 });
        }
    }

    const funcError = (error, toast, setError) => {
        try {
            const errs = error.response.data.errors;
            Object.keys(errs).map((key) => {
                let ikey = key;
                if (key.indexOf('.') >= 0) {
                    ikey = key.split('.');
                    ikey = ikey[0];
                }
                setError(ikey, { type: 'custom', message: errs[key] });
                return 1;
            });
        } catch (error) { }

        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Corrija los errores para guardar la información.', life: 3000 });
    }

    const funDefault = (model) => {
        let values = {};
        Object.keys(model).map((key) => {
            if (model[key].value !== undefined) {
                values[key] = model[key].value;
            }
            return 1;
        });

        return values;
    }

    const getFormErrorMessage = (errors, name) => {
        return errors[name] && <small className="p-error"><br />{errors[name].message}</small>
    };

    const paramLazy = () => {
        const params = {
            first: 0,
            rows: 10,
            page: 1,
            sortField: null,
            sortOrder: null
        }

        return params;
    }

    const funcSuccess = (res, toast) => {
        try {
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: res.message, life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Información registrada correctamente.', life: 3000 });
        }
    }

    const renewValues = (values, model) => {
        Object.keys(values).map((key) => {
            if (model[key] != undefined && model[key].value != undefined) {
                if (values[key] == null && model[key].value == '') {
                    values[key] = model[key].value;
                }
            }
            return 1;
        });

        return values;
    }

    const buttonsFormsReports = (hideDialog, generarReporte, subirReporte, enviar, loading, habilitar = false, cancelar = true, siSaveBtn = true) => {
        return (
            <div className="formgrid grid">
                <div className="field col-12" style={{ textAlign: 'center' }}>
                    {siSaveBtn && <><Button label="Guardar" type="submit" icon="pi pi-save" className="p-button-warning" title="Guardar" />&nbsp;</>}
                    {habilitar && <><Button label="Formulario" icon="pi pi-print" type="button" className="p-button-warning" onClick={() => generarReporte()}
                        loading={loading} disabled={loading} title="Generar Formulario en PDF" />&nbsp;</>}
                    {habilitar && <><Button label="Enviar" icon="pi pi-send" type="button" title="Enviar formulario" className="p-button-warning" onClick={() => enviar()} />&nbsp;</>}
                    {habilitar && <><Button label="Anexos" icon="pi pi-copy" title="Ver Anexos" type="button" className="p-button-warning" onClick={() => subirReporte()} />&nbsp;</>}
                    {cancelar && <><Button label="Cancelar" icon="pi pi-times" title="Cancelar" type="button" className="p-button-warning" onClick={() => hideDialog(false)} /></>}
                </div>
            </div>
        );
    }

    const buttonsBGV = (hideDialog, load, generarReporte, subirReporte, enviar, loading, habilitar = false) => {
        return (
            <div className="formgrid grid">
                <div className="field col-12" style={{ textAlign: 'center' }}>
                    {load !== null && <><Button label="Guardar" title="Guardar" type="submit" icon="pi pi-save" className="p-button-warning" loading={load} disabled={load}
                        style={{ width: '130px' }} />&nbsp;</>}
                    {habilitar && <><Button label="Formulario" title="Generar Formulario" icon="pi pi-print" type="button" className="p-button-warning" onClick={() => generarReporte()}
                        loading={loading} disabled={loading} style={{ width: '130px' }} />&nbsp;</>}
                    {habilitar && <><Button label="Enviar" title="Enviar formulario" icon="pi pi-send" type="button" className="p-button-warning" onClick={() => enviar()} style={{ width: '130px' }} />&nbsp;</>}
                    {habilitar && <><Button label="Anexos" title="Ver Anexos" icon="pi pi-copy" type="button" className="p-button-warning" onClick={() => subirReporte()} style={{ width: '130px' }} />&nbsp;</>}
                    {hideDialog && <><Button label="Cancelar" title="Cancelar" icon="pi pi-times" type="button" className="p-button-warning" onClick={() => hideDialog(false)} style={{ width: '130px' }} /></>}
                </div>
            </div>
        );
    }

    const downloadPdf = (res, fileName) => {
        const fileURL = window.URL.createObjectURL(
            new Blob([res])
        );
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);

        fileLink.click();
        document.body.removeChild(fileLink);
    }

    const currentDate = new Date();
    const dateFormat = "dd/mm/yy";
    const estadosSiNo = [
        { nombre: 'SI', cat_id: true },
        { nombre: 'NO', cat_id: false }
    ];

    const stringToDate = (fecha) => {
        const [year, month, day] = fecha.split('-');
        return new Date(+year, month - 1, +day);
    }

    const calcularEdad = (data) => {
        let today = new Date();
        let age = today.getFullYear() - data.getFullYear();
        let m = today.getMonth() - data.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < data.getDate())) {
            age--;
        }
        return age;
    }

    const dateToString = (fecha) => {
        return fecha.toISOString().split('T')[0];
    }

    const handleKeyDown = (event) => {
        const charCode = event.which || event.keyCode;

        // Allow backspace, tab, enter, escape, delete, arrows, and decimal point
        if (
            [8, 9, 13, 27, 46, 110, 190].includes(charCode) || // Backspace, Tab, Enter, Escape, Delete, Numpad Decimal, Period
            (charCode >= 35 && charCode <= 40) // Arrow keys, Home, End
        ) {
            return;
        }

        // Prevent non-numeric keys
        if ((charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
            event.preventDefault();
        }

        // Allow only one decimal point
        const value = event.target.value;
        if ((charCode === 110 || charCode === 190) && value.includes('.')) {
            event.preventDefault();
        }

        // Restrict to two decimal places
        const decimalPosition = value.indexOf('.');
        if (decimalPosition !== -1 && value.length - decimalPosition > 2) {
            event.preventDefault();
        }
    };

    const accionesTbl = (rowData, setReg, items) => {
        return (
            <>
                <Menu model={items} popup ref={menu} id="popup_menu_center" onShow={resetAriaHidden} />
                <Button icon="pi pi-caret-down" type="button" className="p-button-rounded p-button-success mr-2" title="Opciones/Acciones"
                    onClick={(event) => { menu.current.toggle(event); setReg(rowData) }} />
            </>
        );
    }

    const resetAriaHidden = () => {
        const elements = document.querySelectorAll('a[aria-hidden="true"]');
        elements.forEach(el => el.removeAttribute('aria-hidden'));
    };

    return {
        buttonsForms,
        funcError,
        funcMessageBlobError,
        funDefault,
        getFormErrorMessage,
        paramLazy,
        currentDate,
        dateFormat,
        funcSuccess,
        stringToDate,
        renewValues,
        buttonsFormsReports,
        downloadPdf,
        calcularEdad,
        dateToString,
        handleKeyDown,
        estadosSiNo,
        buttonsBGV,
        accionesTbl,
        resetAriaHidden
    }
}
