import Axios from "axios";

export class PlantillaService {

    lista(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/matriz/config/lista?${queryParams}`).then(res => res.data);
    }

    nuevo(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/matriz/config/nuevo`, data).then(res => res.data);
    }

    editar(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/matriz/config/editar`, data).then(res => res.data);
    }

    consultar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/matriz/config/consulta/${id}`).then(res => res.data);
    }

    eliminar(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/matriz/config/eliminar/${id}`).then(res => res.data);
    }

    descargar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/matriz/config/reporte/${id}`,
            { headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache' }, responseType: 'blob'}).then(async res => res.data);
    }
}
