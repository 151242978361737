import { useForm } from "react-hook-form";
import { GenericComponent } from "../../components/GenericComponent";
import { Parametros } from "../../models/Parametros";
import { ParametroService } from "../../service/ParametroService";
import { InputComponent } from "../../components/InputComponent";
import { useEffect, useRef, useState } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import XLSX from "xlsx-color";
import { Message } from "primereact/message";

export const Parametro = () => {

    const generic = new GenericComponent();
    const omodel = new Parametros();
    const defaultValues = generic.default(omodel.model, null);
    const [permss, setPermss] = useState(null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [parametros, setParametros] = useState(null);
    const [dialogParametro, setDialogParametro] = useState(false);
    const parametroService = new ParametroService();
    const [catalogos, setCatalogos] = useState(null);
    const toast = useRef(null);
    const [param, setParam] = useState(null);
    const [dialogSubir, setDialogSubir] = useState(false);
    const [archivo, setArchivo] = useState(null);
    let fileRef = useRef(null);
    const [strFac, setStrFac] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inNumber } = InputComponent({ registers: parametros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = (data = null) => {
        let idt = param == null ? data : param;
        if (data == 0) {
            idt = null;
        }
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.ids;
            if (idt != null) {
                _lazyParams.ids = idt.id;
            }
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            parametroService.getParametro({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setParametros(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
                ponderaciones(res.data.totales);
            });
        });
    }

    const ponderaciones = (info) => {
        let str = "Total ponderaciones: ";
        info.forEach(itm => {
            str += itm.nombre + ": " + itm.total + " %. "
        });
        setStrFac(str);
    }

    const openNew = () => {
        if (catalogos == null) {
            parametroService.newParametro({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setDialogParametro(true);
                reset(defaultValues);
            });
        }
        else {
            setDialogParametro(true);
            reset(defaultValues);
        }
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            parametroService.newParametro(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogParametro(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            parametroService.editarParametro(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogParametro(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editParametro = (param) => {
        const iniciar = catalogos == null ? 1 : null;
        parametroService.consultarParametro(param.id, iniciar).then(res => {
            let cats = catalogos;
            if (iniciar == 1) {
                cats = res.data.catalogo;
                setCatalogos(res.data.catalogo);
            }
            let _param = res.data.parametro;
            let lista = { estado: generic.estados };
            if (_param.padre) {
                lista.padre = cats.lista;
            }
            if (_param.tipo_factor) {
                lista.tipo_factor = cats.factor;
            }
            Object.keys(lista).forEach(function (key) {
                if (_param[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _param[key]) {
                            _param[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_param, omodel.model));
            setDialogParametro(true);
        });
    };

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el factor?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarParametro(data)
        });
    }

    const eliminarParametro = (data) => {
        parametroService.eliminarParametro(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const subParametros = (rowData) => {
        setParam(rowData);
        loadLazyData(rowData);
    }

    const regresar = () => {
        setParam(null);
        loadLazyData(0);
    }

    const descargar = () => {
        parametroService.exportar(param.id, param.codigo).then(res => {
            generic.exportFileExcel(param.nombre, res.data);
        });
    }

    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button label="Nuevo factor" icon="pi pi-plus" className="p-button-success mr-2" type="button" onClick={openNew} />
                {param && <Button label="Regresar" icon="pi pi-replay" className="p-button-success mr-2" type="button" onClick={regresar} />}
                {param && <Button label="Subir factores" icon="pi pi-upload" className="p-button-success mr-2" type="button" onClick={subir} />}
                {param && <Button label="Descargar factores" icon="pi pi-download" className="p-button-success mr-2" type="button" onClick={descargar} />}
            </div>
        );
    };

    const subir = () => {
        setDialogSubir(true);
        setArchivo(null);
    }

    const invoiceUploadHandler = ({ files }) => {
        const [file] = files;
        setArchivo(file);
    }

    const selectFile = (e) => {
        setArchivo(e.files[0]);
        fileRef?.clear();
    }

    const submitFile = (event) => {
        event.preventDefault();
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

            parametroService.subirParametro({ info: data, id: param.id, codigo: param.codigo }).then(res => {
                generic.funcSuccess(res, toast);
                setDialogSubir(false);
                setArchivo(null);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        };
        reader.readAsBinaryString(archivo);
    }

    return (
        <>
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={leftToolbarTemplate} end={generic.rightToolbarTemplate(parametros, omodel, 'Listado_factor', 'LISTADO FACTOR')}></Toolbar>
                {param && <h5>Factor: {param.nombre}</h5>}
                <Message severity="info" text={strFac} style={{ width: '100%' }} />
                {table({ action3: generic.actionEditDetailTemplate, methEdit: editParametro, perm: permss, methDelete: confirmDelete, details: subParametros, icono: 'pi pi-share-alt' })}
            </Card>

            <Dialog visible={dialogParametro} header={getValues('id') == null ? "Nuevo parámetro" : "Editar parámetro"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogParametro(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre', {}, 6)}
                        {inNumber('valor', 2, false, { min: 0 }, 6)}
                        {inNumber('ponderacion', 0, false, { min: 0 }, 6)}
                        {getValues('principal') != 'principal' && inText('codigo', {}, 6)}
                        {getValues('principal') != 'principal' && inDrop('padre', catalogos?.lista, { lab: omodel.optionLabel }, 6)}
                        {getValues('principal') == 'principal' && inDrop('tipo_factor', catalogos?.factor, { lab: omodel.optionLabel }, 6)}
                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 6)}
                        {inNumber('orden', 0, false, { min: 0 }, 6)}
                    </div>
                    {generic.buttonsForms(setDialogParametro)}
                </form>
            </Dialog>

            <Dialog visible={dialogSubir} header="Subir factores" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)}>
                <form onSubmit={submitFile}>
                    <div className="field col">
                        <label>Seleccione un archivo(Máximo 1MB, tipo de archivo admitido Xlsx (Excel))*</label><br />
                        <FileUpload name="file" accept=".xlsx" maxFileSize={1000000} uploadHandler={invoiceUploadHandler} mode="basic"
                            customUpload chooseLabel="Seleccione" onSelect={selectFile} ref={ref => { fileRef = ref }} />
                    </div>
                    {archivo?.name}
                    {generic.buttonsForms(setDialogSubir)}
                </form>
            </Dialog>
        </>
    )
}
