export class PepDecCpn {
    model = {
        pep: {
            value: false,
            export: false,
            header: 'Es usted un PEP:',
            required: 'Información requerida.'
        },
        esta_relacionado_pep: {
            value: false,
            export: false,
            header: 'Está relacionado con un PEP:',
            required: 'Información requerida.'
        },
        relacion: {
            value: '',
            export: false,
            header: 'Relación o vínculo:',
            required: 'Relación es requqerido.',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
        },
        nombre_pep: {
            value: '',
            export: false,
            header: 'Nombre del PEP',
            required: 'Nombre es requerido.',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
        },
        cargo_pep: {
            value: '',
            export: false,
            header: 'Cargo',
            required: 'Cargo es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
        },
        institucion_trabaja_pep: {
            value: '',
            export: false,
            header: 'Institución donde trabaja o trabajó',
            required: 'Institución es requerido.',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
        },
        fecha_nombramiento_pep: {
            value: null,
            export: false,
            header: 'Fecha del nombramiento de la designación (día/mes/año):',
            required: 'Fecha es requerido.'
        },
        fecha_dejo_pep: {
            value: null,
            export: false,
            header: 'Fecha en que dejó de ser PEP, de no estar en funciones (día/mes/año):',
            required: false
        },
        ACTV_INMUEBLES: {
            value: 0,
            export: false,
            header: 'Bienes inmuebles (casa, departamento, etc.) USD:',
            required: 'Bienes inmuebles es requerido'
        },
        ACTV_MUEBLES: {
            value: 0,
            export: false,
            header: 'Bienes muebles (vehículos, muebles, enceres, etc.) USD:',
            required: 'Bienes muebles es requerido'
        },
        ACTV_EFECTIVO: {
            value: 0,
            export: false,
            header: 'Dinero (en efectivo, en bancos u otros) USD:',
            required: 'Dinero es requerido'
        },
        ACTV_INVERSIONES: {
            value: 0,
            export: false,
            header: 'Inversiones USD:',
            required: 'Inversiones es requerido'
        },
        ACTV_CREDITOS: {
            value: 0,
            export: false,
            header: 'Cuentas por cobrar USD:',
            required: 'Cuentas por cobrar es requerido'
        },
        ACTV_OTROS: {
            value: 0,
            export: false,
            header: 'Otros activos USD:',
            required: 'Otros activos es requerido'
        },
        total_activos: {
            value: 0,
            export: false,
            header: 'Total Activos USD:',
            required: 'Total Activos es requerido'
        },
        PASV_BANCARIOS: {
            value: 0,
            export: false,
            header: 'Préstamos bancarios (consumo):',
            required: 'Préstamos bancarios es requerido'
        },
        PASV_HIPOTECARIOS: {
            value: 0,
            export: false,
            header: 'Préstamos hipotecarios USD:',
            required: 'Préstamos hipotecarios es requerido'
        },
        PASV_QUIROGRAFARIO: {
            value: 0,
            export: false,
            header: 'Préstamo quirografario USD:',
            required: 'Préstamo quirografario es requerido'
        },
        PASV_CREDITO: {
            value: 0,
            export: false,
            header: 'Tarjeta de crédito USD:',
            required: 'Tarjeta de crédito es requerido'
        },
        PASV_OTRAS_CTAS: {
            value: 0,
            export: false,
            header: 'Otras cuentas por pagar USD:',
            required: 'Otras cuentas por pagar'
        },
        PASV_OTROS_PASV: {
            value: 0,
            export: false,
            header: 'Otros pasivos USD:',
            required: 'Otros pasivos es requerido'
        },
        total_pasivos: {
            value: 0,
            export: false,
            header: 'Total Activos USD:',
            required: 'Total pasivo es requerido'
        },
        total_patrimonio: {
            value: 0,
            export: false,
            header: 'Patrimonio (Total Activos - Total Pasivos) USD:',
            required: 'Total patriminio es requerido'
        },
    }
    strPep = <><p><b>PEP:</b> Son todas las personas naturales, nacionales o extranjeras, <b>que desempeñan o han desempeñado funciones</b> o cargos públicos destacados en el Ecuador o en el extranjero, 
                o funciones prominentes en una organización internacional, conforme a los lineamientos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y la Superintendencia 
                de Compañías, Valores y Seguros.<br />
                <b>Familiares y Personas Relacionadas de las Personas Expuestas Políticamente:</b> Se consideran como personas expuestas políticamente (PEP) a los familiares de las Personas Expuestas 
                Políticamente (PEP), sean estos los cónyuges o personas unidas bajo el régimen de unión de hecho, familiares hasta el segundo grado de consanguinidad o primero de afinidad; y el 
                o los colaborador (es) directo (s) esto es la (s) persona (s) natural (es) con las cuales una Persona Políticamente Expuesta (PEP), se encuentre asociada o vinculada 
                societariamente, o mantenga otro tipo de relaciones empresariales, comerciales o labores estrechas.</p><p>
                Los funcionarios públicos de menor grado que no estén comprendidos dentro del nivel jerárquico superior, se excluyen de la definición de PEP; con excepción de aquellos que ejerzan 
                la representación de un PEP o cumplan funciones en su nombre, para lo cual se realizará la correspondiente evaluación de riesgo.</p></>;
}