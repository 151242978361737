import { FilterMatchMode } from "primereact/api"

export class Vinculo {

    model = {
        idp: { value: null, export: false },
        id: {
            value: null,
            export: false,
            header: 'Código',
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'id',
            sort: true,
            matchMode: FilterMatchMode.EQUALS,
            showFilterMatchModes: false,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        tipo_declaracion: {
            value: null,
            export: false,
            header: 'Tipo de formulario:',
            required: 'Seleccione una opción',
        },
        fecha: {
            value: new Date(),
            export: false,
            header: 'Fecha (día/mes/año):',
            required: 'Fecha es requerido',
            table: true,
            sort: false,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        pais: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton: {
            value: '',
            export: false,
            header: 'Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Ciudad es requerida'
        },
        nombre_completo: {
            value: '',
            export: false,
            header: 'Apellidos y Nombres del declarante:',
            required: 'Información requerida',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'apellidos',
            sort: true,
            matchMode: FilterMatchMode.CONTAINS,
        },
        tipo_identificacion: {
            value: null,
            export: false,
            header: 'Tipo de documento:',
            required: 'Seleccione una opción'
        },
        identificacion: {
            value: '',
            export: false,
            header: 'No. doc. Identificación:',
            required: 'No. RUC / Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'identificacion',
            sort: true,
            matchMode: FilterMatchMode.CONTAINS,
        },
    }
    optionLabel = 'nombre';
    msgVin = <>Estimado usuario(a), al guardar el formulario de Vinculación no podrá eliminar la información, ni editar la opción de SI/NO relacionada del formulario principal.</>
    cap = <span style={{ fontSize: '10px', alignContent: 'justify' }}><p>Declaro expresamente que la información proporcionada en este documento es verdadera y correcta. Autorizo a la Bolsa de Valores de Guayaquil S.A BVG, a realizar la comprobación de
        esta declaración, e informar a las autoridades competentes en caso de investigación y/o determinación de transacciones u operaciones que se consideren inusuales e injustificadas en relación a
        mis actividades y movimiento comercial. Eximo a la Bolsa de Valores de Guayaquil S.A BVG de toda responsabilidad, inclusive respecto a terceros, si esta declaración fuese falsa o errónea.</p>
        <p>Además, asumo el compromiso de informar cualquier cambio relacionado con la información indicada en el presente documento en un máximo de treinta (30) días, manteniendo actualizada a Bolsa
            de Valores de Guayaquil .S.A. BVG, proporcionándole la documentación e información que sea necesaria para actualización en expedientes.</p></span>
    cap1 = <span style={{ fontSize: '10px', alignContent: 'justify', fontWeight: 'bold' }}>LOS DATOS PROPORCIONADOS POR EL CLIENTE SERÁN MANEJADOS CON ESTRICTA RESERVA Y CONFIDENCIALIDAD,
        EN CUMPLIMIENTO DE LO ESTIPULADO EN LA QUINTA DISPOSICIÓN GENERAL, DEL TITULO XXIV DE LA LEY DEL MERCADO DE VALORES-LIBRO SEGUNDO DEL CÓDIGO ORGÁNICO MONETARIO Y FINANCIERO REFERENTE
        AL SIGILO BURSÁTIL.</span>
}
