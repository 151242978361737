import { FilterMatchMode } from "primereact/api"

export class Info {

    model = {
        id: {
            value: null, header: 'Código', export: true, table: true, sort: true, filterField: 'id', filter: true, matchMode: FilterMatchMode.EQUALS,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        fecha: { header: 'FECHA DE ANÁLISIS:', value: new Date(), required: 'Campo requerido', export: true, table: true },
        razon_social: {
            value: '', export: true, header: 'NOMBRE/RAZÓN SOCIAL:', required: 'Información requerida', maxLength: 'Máximo 500 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, filterField: 'nombre', filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS
        },
        identificacion: {
            value: '', export: true, header: 'Identificación', required: 'Campo requerido', maxLength: 'Máximo 50 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'identificacion', filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS,
        },
    }
}
