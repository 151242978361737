import { FilterMatchMode } from "primereact/api";

export class AccionistaPn {

    model = {
        id: {
            value: null,
            export: false,
            header: 'Código',
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'id',
            sort: true,
            matchMode: FilterMatchMode.EQUALS,
            showFilterMatchModes: false,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        tipo_juridico: {
            value: null,
            export: false,
            header: 'Tipo de Persona Juridica:',
            required: 'Tipo de Persona Juridica es requerido'
        },
        fecha: {
            value: new Date(),
            export: false,
            header: 'Fecha (día/mes/año):',
            required: 'Fecha es requerido',
            table: true,
            sort: true,
            headerStyle: { width: '15%', minWidth: '15rem' }
        },
        pais: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton: {
            value: '',
            export: false,
            header: 'Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Ciudad es requerida'
        },
        compania: {
            value: '',
            export: false,
            header: 'Compañía:',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Ciudad es requerida'
        },
        grupo_empresarial: {
            value: null,
            export: false,
            header: 'Agrupa para grupo empresarial:',
            required: 'Seleccione'
        },
        tipo_formulario: {
            value: null,
            export: false,
            header: 'Tipo de formulario:',
            required: 'Tipo de formulario es requerido'
        },
        tipo_identificacion_pn: {
            value: null,
            export: false,
            header: 'Tipo de identificación:',
            required: 'Tipo de formulario es requerido'
        },
        apellidos_dp: {
            value: '',
            export: true,
            header: 'Apellidos completos:',
            required: 'Apellidos son requeridos',
            maxLength: 'Máximo 500 caracteres'
        },
        nombres_dp: {
            value: '',
            export: true,
            header: 'Nombres completos:',
            required: 'Nombre es requerido',
            maxLength: 'Máximo 500 caracteres'
        },
        nombre_completo: {
            value: null,
            export: true,
            header: 'Nombre Compleo',
            table: true,
            sort: true,
            headerStyle: { width: '40%', minWidth: '40rem' }
        },
        identificacion: {
            value: '',
            export: false,
            header: 'No. doc. Identidad:',
            required: 'No. RUC / Identificación tributaria es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'identificacion',
            filterPlaceholder: 'Buscar por número de identificación',
            table: true,
            filter: false,
            sort: true,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        nacionalidad: {
            value: null,
            export: false,
            header: 'Nacionalidad:',
            required: ' Buscar por nacionalidad'
        },
        genero: {
            value: null,
            export: false,
            header: 'Género:',
            required: 'Género es requerido'
        },
        fecha_nacimiento: {
            value: new Date(),
            export: false,
            header: 'Fecha de nacimiento (día/mes/año):',
            required: 'Fecha inicioes de actividad'
        },
        direccion: {
            value: '',
            export: true,
            header: 'Calle Principal No. y Calle Secundaria:',
            required: 'Dirección domicilio es requerido',
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4
        },
        pais_dp: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia_dp: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad_dp: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton_dp: {
            value: '',
            export: false,
            header: 'Ciudad',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Ciudad es requerido'
        },
        tiempo_reside_extranjero: {
            value: '',
            export: false,
            header: 'Tiempo que reside en el Ecuador (Si es extranjero):',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Tiempo es requerido'
        },
        email: {
            value: '',
            export: false,
            header: 'Correo electrónico personal:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Correo electrónico es requerido'
        },
        telefono_celular: {
            value: '',
            export: true,
            header: 'No. Celular:',
            required: 'No. Celular es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'telefono_celular'
        },
        telefono_domicilio: {
            value: '',
            export: true,
            header: 'No. de teléfono del domicilio:',
            required: 'No. de teléfono del domicilio es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'telefono_celular'
        },
        estado_civil: {
            value: null,
            export: false,
            header: 'Estado civil:',
            required: 'Estado civil es requerido'
        },
        apellidos_ec: {
            value: '',
            export: true,
            header: 'Apellidos completos:',
            required: 'Apellido es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
        },
        nombres_ec: {
            value: '',
            export: true,
            header: 'Nombres completos:',
            required: 'Nombre es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
        },
        tipo_identificacion_ec: {
            value: null,
            export: false,
            header: 'Tipo de documento:',
            required: 'Tipo identificación es requerido'
        },
        identificacion_ec: {
            value: '',
            export: false,
            header: 'No. doc. Identidad:',
            required: 'No. RUC / Identificación tributaria es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
        },
        nacionalidad_ec: {
            value: null,
            export: false,
            header: 'Nacionalidad:',
            required: 'Nacionalidad es requerido'
        },
        fecha_nacimiento_ec: {
            value: new Date(),
            export: false,
            header: 'Fecha Nacimiento (día/mes/año):',
             required: 'Fecha de nacimiento es requerido'
        },
        lugar_trabajo_ec: {
            value: '',
            export: false,
            header: 'Nombre del lugar de trabajo:',
            required: 'Campo es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
        },
        cargo_ec: {
            value: '',
            export: false,
            header: 'Cargo:',
            required: 'Cargo es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
        },
        actividad_economica: {
            value: null,
            export: false,
            header: 'Actividad Económica:',
            required: 'Actividad Económica es requerido'
        },
        ingreso_mensual_rel_dep: {
            value: 0,
            export: false,
            header: 'Ingreso Mensual en Relación de dependencia USD:',
            required: 'Ingreso Mensual en Relación de dependencia es requerido'
        },
        otros_ingresos: {
            value: 0,
            export: false,
            header: 'Otros Ingresos USD:',
            required: 'Otros Ingresos es requerido'
        },
        fuente_ingresos: {
            value: '',
            export: false,
            header: 'Especifique la fuente de los otros ingresos:',
            required: false,
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4,
        },
        otra_actividad_economica: {
            value: null,
            export: false,
            header: 'Otra Actividad Económica:',
            required: false
        },
        in_mensual_rel_dep_cony: {
            value: 0,
            export: false,
            header: 'Ingreso Mensual en Relación de dependencia (Cónyuge/Conviviente) USD:',
            required: 'Ingreso Mensual en Relación de dependencia es requerido'
        },
        otros_ingresos_cony: {
            value: 0,
            export: false,
            header: 'Otros Ingresos (Cónyuge/Conviviente) USD:',
            required: 'Otros Ingresos es requerido'
        },
        fuente_ingresos_cony: {
            value: '',
            export: false,
            header: 'Especifique la fuente de los otros ingresos del Cónyuge/Conviviente:',
            required: false,
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4,
        },
        otra_actividad_economica_cony: {
            value: null,
            export: false,
            header: 'Otras actividades económicas (Cónyuge/Conviviente):',
            required: false
        },
        gasto_mensual: {
            value: 0,
            export: false,
            header: 'Gastos Individuales (en el caso de ser soltero) / Familiares Mensuales USD:',
            required: 'Gastos Individuales es requerido'
        },

        ACTV_INMUEBLES: {
            value: 0,
            export: false,
            header: 'Bienes inmuebles (casa, departamento, etc.) USD:',
            required: 'Bienes inmuebles es requerido'
        },
        ACTV_MUEBLES: {
            value: 0,
            export: false,
            header: 'Bienes muebles (vehículo, enceres, etc.) USD:',
            required: 'Bienes muebles es requerido'
        },
        ACTV_EFECTIVO: {
            value: 0,
            export: false,
            header: 'Dinero (en efectivo, en bancos u otros) USD:',
            required: 'Dinero es requerido'
        },
        ACTV_INVERSIONES: {
            value: 0,
            export: false,
            header: 'Inversiones USD:',
            required: 'Inversiones es requerido'
        },
        ACTV_CREDITOS: {
            value: 0,
            export: false,
            header: 'Créditos por cobrar USD:',
            required: 'Créditos por cobrar es requerido'
        },
        ACTV_OTROS: {
            value: 0,
            export: false,
            header: 'Otros activos USD:',
            required: 'Otros activos es requerido'
        },
        total_activos: {
            value: 0,
            export: false,
            header: 'Total Activos USD:',
            required: 'Total Activos es requerido'
        },
        PASV_BANCARIOS: {
            value: 0,
            export: false,
            header: 'Préstamos bancarios (consumo)USD:',
            required: 'Préstamos bancarios es requerido'
        },
        PASV_HIPOTECARIOS: {
            value: 0,
            export: false,
            header: 'Préstamos hipotecarios USD:',
            required: 'Préstamos hipotecarios es requerido'
        },
        PASV_QUIROGRAFARIO: {
            value: 0,
            export: false,
            header: 'Préstamo quirografario USD:',
            required: 'Préstamo quirografario es requerido'
        },
        PASV_CREDITO: {
            value: 0,
            export: false,
            header: 'Tarjeta de crédito USD:',
            required: 'Tarjeta de crédito es requerido'
        },
        PASV_OTRAS_CTAS: {
            value: 0,
            export: false,
            header: 'Otras Cuentas/Documentos por pagar USD:',
            required: 'Otras Cuentas / Documentos por pagar es requerido'
        },
        PASV_OTROS_PASV: {
            value: 0,
            export: false,
            header: 'Otros pasivos USD:',
            required: 'Otros pasivos es requerido'
        },
        total_pasivos: {
            value: 0,
            export: false,
            header: 'Pasivos USD:',
            required: 'Pasivos es requerido'
        },
        total_patrimonio: {
            value: 0,
            export: false,
            header: 'Patrimonio (Activos - Pasivos) USD:',
            required: 'Patriminio es requerido'
        },
        pep: {
            value: null,
            export: false,
            header: 'Es usted un PEP:',
            required: 'Seleccione una opción'
        },
        esta_relacionado_pep: {
            value: null,
            export: false,
            header: 'Está relacionado con un PEP:',
            required: 'Seleccione una opción'
        },
        relacion: {
            value: '',
            export: false,
            header: 'Relación o vínculo:',
            required: 'Relación o vínculo es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
        },
        nombre_pep: {
            value: '',
            export: false,
            header: 'Nombre del PEP'
        },
        cargo_pep: {
            value: '',
            export: false,
            header: 'Cargo'
        },
        institucion_trabaja_pep: {
            value: '',
            export: false,
            header: 'Institución donde trabaja o trabajó',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Institución es reqeurido'
        },
        fecha_nombramiento_pep: {
            value: new Date(),
            export: false,
            header: 'Fecha del nombramiento de la designación (día/mes/año):',
            required: 'Fecha es requerido'
        },
        fecha_dejo_pep: {
            value: null,
            export: false,
            header: 'Fecha en que dejó de ser PEP, de no estar en funciones (día/mes/año):',
            required: false
        },
        acepta_declaracion: {
            value: null,
            export: false,
            header: 'Seleccione un opción'
        },
        observacion: {
            value: '',
            export: true,
            header: 'Observacion:',
            required: 'Observacion es requerido',
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4
        },
        estado: {
            value: null,
            export: false,
            header: 'Estado:',
            //            required: 'Estado es requerido',
            table: true,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
    }
    strPep = <><p>PEP: Son todas las personas naturales, nacionales o extranjeras, que desempeñan o han desempeñado funciones o cargos públicos destacados en el Ecuador o en el extranjero, 
        o funciones prominentes en una organización internacional, conforme a los lineamientos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y la Superintendencia de 
        Compañías, Valores y Seguros.<br /> Familiares y Personas Relacionadas de las Personas Expuestas Políticamente: Se consideran como personas expuestas políticamente (PEP) a los familiares 
        de las Personas Expuestas Políticamente (PEP), sean estos los cónyuges o personas unidas bajo el régimen de unión de hecho, familiares hasta el segundo grado de consanguinidad o primero 
        de afinidad; y el o los colaborador (es) directo (s) esto es la (s) persona (s) natural (es) con las cuales una Persona Políticamente Expuesta (PEP), se encuentre asociada o vinculada 
        societariamente, o mantenga otro tipo de relaciones empresariales, comerciales o labores estrechas.</p>
        <p>Los funcionarios públicos de menor grado que no estén comprendidos dentro del nivel jerárquico superior, se excluyen de la definición de PEP; con excepción de aquellos que ejerzan 
        la representación de un PEP o cumplan funciones en su nombre, para lo cual se realizará la correspondiente evaluación de riesgo.</p></>;
    strDeclara = <>Declaro bajo juramento que:<br /><ul>
        <li>La información registrada en el presente documento y la documentación que se acompaña es fidedigna y asumo cualquier responsabilidad por los daños que pudiere sufrir la Compañía 
            a la que declaro esta información por la eventual falsedad o imprecisión de esta; y,</li>
        <li><b>La información económica y patrimonial registrada cuenta con todos los sustentos que corresponden.</b></li>
        </ul>Finalmente, autorizo expresamente a  realizar el análisis y las verificaciones que se consideren pertinentes a la información suministrada y aquella que obtenga la compañía 
        a la que declaro; y, en el caso de detectarse alguna inusualidad, informar de manera inmediata y documentada a las autoridades competentes.</>;


    strAnexo = <ul><li>Copia del Documento de identificación.</li>
        <li>Copia del Documento de identificación del/la cónyuge/conviviente (de ser el caso).</li></ul>;
    pattern = { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Correo no válido. E.g. example@email.com' };
    optionLabel = 'nombre';
    titulo = 'Instrucciones';
    mensaje = <><b>Instrucciones para completar el proceso de envío de información:</b><ol style={{ textAlign: 'justify' }}>
        <li>En cuanto se registre la información de las secciones de la 1 a la 6, favor guardar la información.</li>
        <li>Cuando la información se guarde, dar click en reporte y el sistema generará el formulario de su información en versión PDF, el cual se deberá imprimir y firmar físicamente o electrónicamente.</li>
        <li>En el botón anexos se deberá cargar el formulario firmado y los anexos requeridos.</li>
        <li>Cuando el formulario y sus anexos sean cargados al sistema favor dar click en enviar.</li></ol></>
}