import { FilterMatchMode } from "primereact/api"

export class Empresas {

    model = {
        id: {value: null, export: false},
        tipo_identificacion: {value: null, export: false, header: 'Tipo de identificación', required: 'Tipo de identificación es requerido', 
            table: true, filter: false, sort: true, filterPlaceholder:'Buscar por Medio de Pago'},
        tipo_empresa: {value: null, export: false, header: 'Tipo de empresa', required: 'Tipo de empresa es requerido', 
            table: true, filter: false, sort: true, filterPlaceholder:'Buscar por Medio de Pago'},
        razon_social: {value: '', export: true, header: 'Razón Social', required: 'Razón Social es requerido', maxLength: 'Máximo 400 caracteres', minLength: 'Mínimo 4 caracteres',
            max: 400, min: 4, filterField: 'razon_social', table: true, filter: true, sort: true, filterPlaceholder: 'Buscar por razón social.', matchMode: FilterMatchMode.CONTAINS},
        nombre_comercial: {value: '', export: true, header: 'Nombre comercial', required: false, maxLength: 'Máximo 300 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'nombre_comercial', filter: false, sort: true},
        identificacion: {value: '', export: false, header: 'Identificación', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres', minLength: 'Mínimo 4 caracteres', 
            max: 50, min: 4, filterField: 'identificacion', filterPlaceholder: 'Buscar por identificación', table: true, filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS},
        estado: {header: 'Estado', value: {nombre: 'Activo', cat_id: true}, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true},
        grupo: {header: 'Grupo empresa', value: null, required: false, optionLabel: 'nombre', export: true, table: true},
    }

    optionLabel = 'nombre'
}