import { FilterMatchMode } from "primereact/api";

export class InfoCpj {
    model = {
        id: {
            value: null,
            export: false,
            header: 'Código',
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'id',
            sort: true,
            matchMode: FilterMatchMode.EQUALS,
            showFilterMatchModes: false,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        fecha: {
            value: new Date(),
            export: false,
            header: 'Fecha (día/mes/año):',
            required: 'Fecha es requerido',
            table: true,
            sort: true,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        pais: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton: {
            value: '',
            export: false,
            header: 'Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Ciudad es requerida'
        },
        compania: {
            value: '',
            export: false,
            header: 'Nombre de la compañía donde adquirió el bien:',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Compañía es requerida'
        },
        proyecto: {
            value: '',
            export: false,
            header: 'Proyecto:',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Proyecto es requerido'
        },
        tipo_formulario: {
            value: null,
            export: false,
            header: 'Tipo de formulario:',
            required: 'Tipo de formulario es requerido'
        },
        tipo_solicitante: {
            value: null,
            export: false,
            header: 'Tipo de Solicitante:',
            required: 'Tipo de solicitante es requerido'
        },
        razon_social: {
            value: '',
            export: true,
            header: 'Razón Social / Nombre:',
            required: 'Razón Social / Nombre: es requerido',
            maxLength: 'Máximo 500 caracteres',
            table: true,
            filter: false,
            sort: true
        },
        identificacion: {
            value: '',
            export: false,
            header: 'No. RUC / Identificación tributaria:',
            required: 'No. RUC / Identificación tributaria es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'identificacion',
            filterPlaceholder: 'Buscar por número de identificación',
            table: true,
            filter: false,
            sort: true
        },
        nacionalidad: {
            value: null,
            export: false,
            header: 'Nacionalidad:',
            required: ' Nacionalidad es Requerida'
        },
        fecha_ini_actividad: {
            value: new Date(),
            export: false,
            header: 'Fecha de inicio de actividades:',
            required: 'Fecha inicioes de actividad es Requerida'
        },
        actividad_economica: {
            value: null,
            export: false,
            header: 'Actividad económica de la entidad donde labora o negocio propio:',
            required: 'Actividad económica es Requerida'
        },
        dirreccion_oficina: {
            value: '',
            export: true,
            header: 'Dirección oficina:',
            required: 'Dirección oficina es requerido',
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4
        },
        pais_dd: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia_dd: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad_dd: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton_dd: {
            value: '',
            export: false,
            header: 'Ciudad',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Ciudad es requerida'
        },
        codigo_postal: {
            value: '',
            export: false,
            header: 'Apartado postal: ( en caso de residir en el extranjero), caso contrario colocar NA.',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 2,
            required: 'Apartado postal es requerido',
        },
        telefono_empresa: {
            value: '',
            export: true,
            header: 'Teléfono empresa:',
            required: 'No. de teléfono empresa es requerido',
            maxLength: 'Máximo 30 caracteres',
            minLength: 'Mínimo 7 caracteres',
            max: 30,
            min: 7,
            filterField: 'telefono_celular'
        },
        email_pga_web: {
            value: '',
            export: false,
            header: 'Página web o dirección electrónica de la empresa:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Página web o dirección electrónica es requerido',

        },
        empresa: { value: null, export: true, header: 'Empresa:', required: 'Empresa es requerido' },
    }
    optionLabel = 'nombre';
}
