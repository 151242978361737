import Axios from "axios";

export class FuentePersonaService {

    getPersona(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/fuente/persona/lista?${queryParams}`).then(res => res.data);
    }

    newPersona(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/fuente/persona/nuevo`, data).then(res => res.data);
    }

    editarPersona(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/fuente/persona/editar`, data).then(res => res.data);
    }

    consultarPersona(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/fuente/persona-ver/${id}`).then(res => res.data);
    }

    eliminarPersona(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/fuente/persona/eliminar/${id}`).then(res => res.data);
    }

    subirArchivo(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/fuente/persona/subir-fuente`, data).then(res => res.data);
    }

    subir(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/fuente/persona/store`, data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res.data);
    }

    consultarEstado() {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/fuente/persona-estado`).then(res => res.data);
    }
}