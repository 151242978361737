import Axios from "axios";

export class ConsumidorService {

    getConsumidor(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/form/consumidor/lista?${queryParams}`).then(res => res.data);
    }

    newConsumidor(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/form/consumidor/nuevo`, data).then(res => res.data);
    }

    editarConsumidor(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/form/consumidor/editar`, data).then(res => res.data);
    }

    consultarConsumidor(id, nem) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/form/consumidor/mostrar/${id}/${nem}`).then(res => res.data);
    }

    eliminarConsumidor(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/form/consumidor/eliminar/${id}`).then(res => res.data);
    }
}
