import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { InputComponent } from "../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { PlanTipo } from "../../models/matriz/PlanTipo";
import { PlanTipoService } from "../../service/matriz/PlanTipoService";

export const Seccion = ({ generic, permss, toast }) => {

    const omodel = new PlanTipo();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registers, setRegisters] = useState(null);
    const planTipoSrv = new PlanTipoService();
    const [dialogPlan, setDialogPlan] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText } = InputComponent({ registers, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            planTipoSrv.lista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegisters(res.data.registros);
                setLoading(false);
            });
        });
    }

    const openNew = () => {
        reset(defaultValues);
        setDialogPlan(true);
    }

    const edit = (data) => {
        planTipoSrv.consultar(data.id).then(res => {
            const lista = { estado: generic.estados };
            let _plan = res.data;

            Object.keys(lista).forEach(function (key) {
                if (_plan[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _plan[key]) {
                            _plan[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_plan, omodel.model));
            setDialogPlan(true);
        });
    }

    const deleteItem = (data) => {
        confirmDialog({
            message: '¿Esta seguro de eliminar la plantilla?',
            header: 'Confirmar',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
                planTipoSrv.eliminar(data.id).then(res => {
                    generic.funcSuccess(res, toast);
                    loadLazyData();
                }).catch(error => {
                    generic.funcDeleteError(error, toast);
                });
            }
        });
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            planTipoSrv.nuevo(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogPlan(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            planTipoSrv.editar(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogPlan(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    return (
        <>
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nueva sección', permss)}></Toolbar>
                {table({ action: generic.actionEditTemplate, methEdit: edit, perm: permss, methDelete: deleteItem })}
            </Card>

            <Dialog visible={dialogPlan} header={getValues('id') == null ? "Nueva sección" : "Editar sección"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogPlan(false)} style={{ maxWidth: '300px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre', {}, 12)}
                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 12)}
                    </div>

                    {generic.buttonsForms(setDialogPlan)}
                </form>
            </Dialog>
        </>
    )
}
