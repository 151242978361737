export class Laboral {

    model = {
        id: { value: null, export: false },
        laboral: {
            value: null,
            header: 'Condición o relación Laboral',
            required: 'Nacionalidad es requerido'
        },
        cargo: {
            value: '',
            header: 'Cargo/Función actual que desempeña:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        inicio_actividad: {
            value: new Date(),
            header: 'Inicio de actividades laborales o contractuales:',
            required: 'Fecha es requerido',
        },
        area: {
            value: '',
            header: 'Área:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        departamento: {
            value: '',
            header: 'Departamento:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        ciudad_laboral: {
            value: '',
            header: 'Ciudad:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        forma_pago: {
            value: '',
            header: 'Forma de pago:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        banco: {
            value: '',
            header: 'Banco:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        banco_cuenta: {
            value: '',
            header: 'No. De Cuenta:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        relacion_pep: {
            value: null,
            header: 'Tienen relación con Personas Expuestas Política y Públicamente (PEPs)',
            required: 'Seleccione una opción',
        },
    }
    nota = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px' }}><b>Nota:</b> Si sus respuestas fueron positivas,
        sírvase llenar el formulario de cada registro, sobre la condición o relación que mantiene con la Persona Expuesta Políticamente o Públicamente (PEP´s).</span>

    optionLabel = 'nombre';
}
