import Axios from "axios";

export class MenuServicio {

    getLista(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/menu/lista?${queryParams}`).then(res => res.data);
    }

    getPermisos(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/menu/permisos?${queryParams}`).then(res => res.data);
    }

    newMenu(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/admin/menu/nuevo`, data).then(res => res.data);
    }

    editarMenu(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/menu/editar`, data).then(res => res.data);
    }

    consultarMenu(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/menu/consulta/${id}`).then(res => res.data);
    }

    eliminar(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/admin/menu/eliminar/${id}`).then(res => res.data);
    }

    putPermiso(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/menu/permiso-asignar`, data).then(res => res);
    }
}