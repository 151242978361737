import Axios from "axios";

export class GrupoEmpresaDetalleService {

    getDetalle(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/grupo-empresa-detalle/lista?${queryParams}`).then(res => res.data);
    }

    newDetalle(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/admin/grupo-empresa-detalle/nuevo`, data).then(res => res.data);
    }

    editarDetalle(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/grupo-empresa-detalle/editar`, data).then(res => res.data);
    }

    consultarDetalle(id, grupoId) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/grupo-empresa-detalle/ver/${id}/${grupoId}`).then(res => res.data);
    }

    eliminarDetalle(id, grupoId) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/admin/grupo-empresa-detalle/eliminar/${id}/${grupoId}`).then(res => res.data);
    }

    consultarEmpresa(cadena, grupoId) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/grupo-empresa-detalle/consultar/${cadena}/${grupoId}`).then(res => res.data);
    }

    listaEmpresas(grupoId) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/grupo-empresa-detalle/empresas/${grupoId}`).then(res => res.data);
    }
}