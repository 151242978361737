export class BienInmueble {

    model = {
        id: { value: null, export: false },
        tipo_bien: {
            value: '',
            table: true,
            header: 'Tipo de Bien:',
            required: 'Campo requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3,
        },
        pais: {
            value: null,
            header: 'País:',
            table: true,
            required: 'País es requerido'
        },
        provincia: {
            value: null,
            table: true,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad: {
            value: null,
            table: true,
            header: 'Ciudad:',
            required: 'Ciudad es requerido'
        },
        canton: {
            value: '',
            header: 'Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Ciudad es requerido'
        },
        direccion: {
            value: '',
            table: true,
            header: 'Dirección:',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
            required: 'Campo requerido'
        },
        numero_predio: {
            value: '',
            table: true,
            header: 'No. De Predio:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Campo requerido',
            footer: 'SUBTOTAL:',
        },
        valor: {
            value: 0,
            table: true,
            header: 'Valor del Bien:',
            required: 'Valor requerido',
            footer: 0,
        },
    }

    optionLabel = 'nombre';
}
