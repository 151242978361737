import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { useState } from 'react'
import { GenericComponent } from '../../components/GenericComponent';
import { InputComponent } from '../../components/InputComponent';
import { FormaPago } from '../../models/FormaPago';
import { FormaPagoService } from '../../service/FormaPagoService';
import { useForm } from 'react-hook-form';
import { confirmDialog } from 'primereact/confirmdialog';

export const Pago = ({ toast, tipo, id, permss }) => {

    const generic = new GenericComponent();
    const omodel = new FormaPago();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [pagos, setPagos] = useState(null);
    const formaPagoService = new FormaPagoService();
    const [dialogPago, setDialogPago] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inNumber } = InputComponent({ registers: pagos, omodel, generic, errors, control, id: getValues('id'), permss });

    const loadLazyDataPago = (idp) => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        let _lazyParams = lazyParams;
        _lazyParams.idp = id == null ? idp : id;
        _lazyParams.tipo = tipo;
        loadLazyTimeout = setTimeout(() => {
            formaPagoService.getFormaPago({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setPagos(res.data.registros);
                setLoading(false);
            });
        });
    }

    const openNew = () => {
        if (catalogos == null) {
            formaPagoService.newFormaPago({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setDialogPago(true);
                reset(defaultValues);
            });
        }
        else {
            setDialogPago(true);
            reset(defaultValues);
        }
    }

    const onSubmit = (data) => {
        data.idp = id;
        data.tipo = tipo;
        if (getValues('id') == null) {
            formaPagoService.newFormaPago(data).then(res => {
                setDialogPago(false);
                loadLazyDataPago();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            formaPagoService.editarFormaPago(data).then(res => {
                setDialogPago(false);
                loadLazyDataPago();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editPago = (data) => {
        const iniciar = catalogos == null ? 1: 0;
        formaPagoService.getPago(data.id, iniciar).then(res => {
            let _cats = catalogos;
            if(iniciar == 1) {
                setCatalogos(res.data.catalogo);
                _cats = res.data.catalogo;
            }
            const lista = { tipo_pago: _cats.tipo };
            const _pago = res.data.pago;
            Object.keys(lista).forEach(function (key) {
                if (_pago[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _pago[key]) {
                            _pago[key] = element;
                        }
                    });
                }
            });
            reset(_pago);
            setDialogPago(true);
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarPago(data)
        });
    }

    const eliminarPago = (data) => {
        formaPagoService.eliminar(data.id, id, tipo).then(res => {
            loadLazyDataPago();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const tablePago = () => {
        return (
            <>
                {id && <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, '', permss)}></Toolbar>}
                {table({ action: generic.actionEditTemplate, methEdit: editPago, perm: permss, methDelete: confirmDelete })}
            </>
        )
    }

    const dialogoPago = () => {
        return (<>
            <Dialog visible={dialogPago} header={getValues('id') == null ? "Nueva Forma de pago" : "Editar Forma de Pago"} modal
                className="p-fluid modal-ssize" onHide={e => setDialogPago(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {inDrop('tipo_pago', catalogos?.tipo, { lab: omodel.optionLabel }, 12)}
                    {inNumber('valor', 2, true, {}, 12)}

                    {generic.buttonsForms(setDialogPago)}
                </form>
            </Dialog>
        </>)
    }

    return {
        loadLazyDataPago,
        tablePago,
        dialogoPago
    }
}
