export class Relacion {

    model = {
        id: { value: null, export: false },
        tipo_relacion: {
            value: '',
            table: true,
            header: 'Tipo de Relación:',
            required: 'Campo requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3,
        },
        cargo: {
            value: '',
            table: true,
            header: 'Cargo o función',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        razon_social: {
            value: '',
            table: true,
            header: 'Nombre o Razón Social',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
    }

    optionLabel = 'nombre';
}
