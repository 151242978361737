import { Link } from 'react-router-dom';
import { PanelMenu } from 'primereact/panelmenu';

const AppMenu = (props) => {

    return (
        <div className="layout-sidebar" onClick={props.onMenuClick}>
            <Link to="/" className="logo">
                <img id="app-logo" className="logo-image" src="assets/layout/images/favicon-2.png" alt="Prelafitfinder" />
                <span className="app-name">PRELAFIT</span>
            </Link>

            <div className="layout-menu-container">
                <PanelMenu model={props.model} className="w-full" />
            </div>
        </div>
    );
}

export default AppMenu;
