export class EconomicaCpj {
    model = {
        INGR_MENSUAL: {
            value: 0,
            export: false,
            header: 'Ingreso mensual USD:',
            required: 'Ingreso Mensual es requerido'
        },
        EGRE_MENSUAL: {
            value: 0,
            export: false,
            header: 'Egreso mensual USD:',
            required: 'Egreso mensual es requerido'
        },
        ACT_CORRIENTE: {
            value: 0,
            export: false,
            header: 'Activo Corriente:',
            required: 'Activo corriente es requerido'
        },
        PAS_CORRIENTE: {
            value: 0,
            export: false,
            header: 'Pasivo Corriente:',
            required: 'Pasivo corriente es requerido'
        },
        ACT_NO_CORRIENTE: {
            value: 0,
            export: false,
            header: 'Activo NO Corriente:',
            required: 'Activo no corriente es requerido'
        },
        PAS_NO_CORRIENTE: {
            value: 0,
            export: false,
            header: 'Pasivo No Corriente:',
            required: 'Pasivo no corriente es requerido'
        },
        TOT_ACTIVO: {
            value: 0,
            export: false,
            header: 'Total Activos USD:',
            required: 'Total activos es requerido'
        },
        TOT_PASIVOS: {
            value: 0,
            export: false,
            header: 'Total Pasivos USD:',
            required: 'Total pasivos es requerido'
        },
        PATRIMONIO: {
            value: 0,
            export: false,
            header: 'Patrimonio USD:',
            required: 'Patrimonio es requerido'
        },
        INGR_ANUAL: {
            value: 0,
            export: false,
            header: 'Ingresos año anterior USD:',
            required: 'Ingresos año anterior es requerido'
        },
        EGRE_ANUAL: {
            value: 0,
            export: false,
            header: 'Egresos año anterior USD:',
            required: 'Egresos año anterior es requerido'
        },
    }
}