import { useEffect, useState } from "react";
import { UsuarioPermiso } from "../../models/administracion/UsuarioPermiso";
import { useForm } from "react-hook-form";
import { UsuarioPermisoService } from "../../service/UsuarioPermisoService";
import { InputComponent } from "../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { RolService } from "../../service/RolService";

export const AsignarPermiso = ({ usuario, toast, generic }) => {

    const omodel = new UsuarioPermiso();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [permisos, setPermisos] = useState(null);
    const permisoService = new UsuarioPermisoService();
    const rolService = new RolService();
    const [dialogPermiso, setDialogPermiso] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [esEmpresa, setEsEmpresa] = useState(null);
    const [selectedRol, setSelectedRol] = useState(null);
    const [roles, setRoles] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inAutocomplete } = InputComponent({ registers: permisos, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            _lazyParams.usuario = usuario;
            permisoService.getPermiso({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setPermisos(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        if (catalogos == null) {
            permisoService.newPermiso({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setDialogPermiso(true);
                reset(defaultValues);
            });
        }
        else {
            setDialogPermiso(true);
            reset(defaultValues);
        }
    }

    const onSubmit = (data) => {
        data.usuario = usuario;
        if (getValues('id') == null) {
            permisoService.newPermiso(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogPermiso(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            permisoService.editarPermiso(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogPermiso(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editPermiso = (permiso) => {
        const iniciar = catalogos == null ? 1 : 0;
        permisoService.consultarPermiso(permiso.id, iniciar).then(res => {
            let cats = catalogos;
            if (iniciar === 1) {
                cats = res.data.catalogos;
                setCatalogos(res.data.catalogos);
            }
            let _perm = res.data.permiso;
            const lista = { estado: generic.estados, grupo: cats.grupo, empresa: cats.lista };
            if(_perm.empresa === null || _perm.empresa === false){
                lista.registro_grupo = generic.sino;
            }

            Object.keys(lista).forEach(function (key) {
                if (_perm[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _perm[key]) {
                            _perm[key] = element;
                        }
                    });
                }
            });
            setSelectedRol(_perm.rol);
            setEsEmpresa(_perm.empresa != null);
            reset(generic.renewValues(_perm, omodel.model));
            setDialogPermiso(true);
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarPermiso(data)
        });
    }

    const eliminarPermiso = (data) => {
        permisoService.eliminarPermiso(data.id, usuario).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const onChangePermiso = (e, opcion) => {
        if(e.value === undefined){
            setEsEmpresa(null);
        } else {
            setEsEmpresa(opcion === 'empresa');
        }
    }

    const searchRol = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setRoles([...roles]);
            }
            else {
                rolService.rolNombre(event.query.toLowerCase()).then(data => {
                    setRoles(data);
                });
            }
        });
    }

    return (
        <>
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo permiso', permss)}
                    end={generic.rightToolbarTemplate(permisos, omodel, 'Listado_permisos', 'LISTADO PERMISOS USUARIO')}></Toolbar>
                {table({ action: generic.actionEditTemplate, methEdit: editPermiso, perm: permss, methDelete: confirmDelete })}
            </Card>

            <Dialog visible={dialogPermiso} header={getValues('id') == null ? "Nuevo permiso" : "Editar permiso"} modal className="p-fluid modal-ssize" onHide={e => setDialogPermiso(false)}
                style={{ maxWidth: '800px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {(esEmpresa === null || esEmpresa === true) && inDrop('empresa', catalogos?.lista, { lab: omodel.optionLabel, chn: onChangePermiso, val: 'empresa' }, 6)}
                        {(esEmpresa === null || esEmpresa === false) && inDrop('grupo', catalogos?.grupo, { lab: omodel.optionLabel, chn: onChangePermiso, val: 'grupo' }, 6)}
                        {(esEmpresa === null || esEmpresa === false) && inDrop('registro_grupo', generic.sino, { lab: omodel.optionLabel }, 6)}
                        {inText('observacion', {}, 6)}
                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 6)}
                        {esEmpresa != null && inAutocomplete('rol', selectedRol, setSelectedRol, roles, searchRol, { lab: omodel.optionLabel, noEsActividad: true, col: 12 })}
                    </div>

                    {generic.buttonsForms(setDialogPermiso)}
                </form>
            </Dialog>
        </>
    )
}
