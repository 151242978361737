import { Card } from "primereact/card"
import { Dialog } from "primereact/dialog"
import { Toast } from "primereact/toast"
import { InputComponent } from "../../components/InputComponent";
import { PermisoServicio } from "../../service/PermisoServicio";
import { GenericComponent } from "../../components/GenericComponent";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Permisos } from "../../models/Permisos";
import { Toolbar } from "primereact/toolbar";

export const Permiso = () => {

    const generic = new GenericComponent();
    const omodel = new Permisos();
    const [permss, setPermss] = useState(null);
    const model = omodel.model;
    const defaultValues = generic.default(model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const toast = useRef(null);
    const [permisos, setPermisos] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inText, inDrop } = InputComponent({ registers: permisos, omodel, generic, errors, control, permss, id: getValues('id') });
    const permisoService = new PermisoServicio();
    const [catalogo, setCatalogo] = useState(null);
    const [dialogPermiso, setDialogPermiso] = useState(false);

    useEffect(() => {
        loadLazyPermiso();
    }, [lazyParams]);

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el permiso?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarPermiso(data)
        });
    }

    const eliminarPermiso = (data) => {
        permisoService.eliminar(data.id).then(res => {
            loadLazyPermiso();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const openNew = () => {
        permisoService.newPermiso({ iniciar: 1 }).then(res => {
            setCatalogo(res.data);
            reset(defaultValues);
            setDialogPermiso(true);
        });
    }

    const loadLazyPermiso = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        let _lazyParams = lazyParams;
        delete _lazyParams.rtipo;
        if (permss == null) {
            _lazyParams.rtipo = true;
        }
        loadLazyTimeout = setTimeout(() => {
            permisoService.getLista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setPermisos(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            permisoService.newPermiso(data).then(res => {
                setDialogPermiso(false);
                loadLazyPermiso();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            permisoService.editarPermiso(data).then(res => {
                setDialogPermiso(false);
                loadLazyPermiso();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editPermiso = (data) => {
        permisoService.consultarPermiso(data.id).then(res => {
            const _cat = res.data.catalogo;
            setCatalogo(res.data.catalogo);
            const lista = { estado: generic.estados, padre: _cat.lista, metodo: _cat.metodo, crud: _cat.crud };
            const _perm = res.data.permiso;
            Object.keys(lista).forEach(function (key) {
                if (_perm[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _perm[key]) {
                            _perm[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_perm, model));
            setDialogPermiso(true);
        });
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, '', permss)}></Toolbar>
                {table({ action: generic.actionEditTemplate, methEdit: editPermiso, perm: permss, methDelete: confirmDelete })}
            </Card>
            <Dialog visible={dialogPermiso} header={getValues('id') == null ? "Nuevo Permiso" : "Editar permiso"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogPermiso(false)} style={{ maxWidth: '1000px' }} >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inDrop('padre', catalogo?.lista, { lab: omodel.optionLabel, obl: 'no' })}
                        {inDrop('metodo', catalogo?.metodo, { lab: omodel.optionLabel, obl: 'no' })}
                        {inText('controlador', { obl: 'no' })}
                        {inText('accion', { obl: 'no' })}

                        {inDrop('crud', catalogo?.crud, { lab: omodel.optionLabel, obl: 'no' })}
                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel })}
                    </div>
                    {generic.buttonsForms(setDialogPermiso)}
                </form>
            </Dialog>
        </>
    )
}
