import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { TreeTable } from 'primereact/treetable';
import { useEffect, useState } from 'react';
import { RolService } from '../../service/RolService';

export const MenuRol = ({ id, toast, permss }) => {

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [menus, setMenus] = useState([]);
    const rolService = new RolService();
    const [lazyParams, setlazyParams] = useState({ id: id, padre: 0, total: 0, first: 0, rows: 0 });
    let loadLazyTimeout = null;
    const [screar, setScrear] = useState([]);
    const [sconsultar, setSconsultar] = useState([]);
    const [seliminar, setSeliminar] = useState([]);
    const [seditar, setSeditar] = useState([]);

    useEffect(() => {
        setRows(10);
        setFirst(0);
        loadLazyData(null);
    }, [lazyParams]);

    const loadLazyData = (event) => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.id = id;
            rolService.getMenusRol(_lazyParams).then(res => {
                setTotalRecords(res.data.total);
                setMenus(loadMenus(res.data.menus, event));
                setLoading(false);
                valores(res.data.menus);
            });
        }, 1);
    }

    const valores = (items) => {
        let _consultar = [...sconsultar];
        let _crear = [...screar];
        let _editar = [...seditar];
        let _eliminar = [...seliminar];

        items.forEach(itm => {
            if(itm.consultar !== null && itm.consultar) _consultar.push(itm.id);
            if(itm.crear !== null && itm.crear) _crear.push(itm.id);
            if(itm.actualizar !== null && itm.actualizar) _editar.push(itm.id);
            if(itm.eliminar !== null && itm.eliminar) _eliminar.push(itm.id);
        });

        setSconsultar(_consultar);
        setScrear(_crear);
        setSeditar(_editar);
        setSeliminar(_eliminar);
    }

    const loadMenus = (nodos, event) => {
        let _menus = [];
        nodos.forEach(item => {
            let node = {
                key: item.id,
                data: {
                    name: item.nombre,
                    fecha: item.fecha
                },
                leaf: item.ultimo
            };
            _menus.push(node);
        });
        if (event !== null) {
            let _node = { ...event.node };
            _node.children = _menus;
            let _nodes = menus.map(nde => {
                if (nde.key === event.node.key) {
                    nde = _node;
                } else if (('children' in nde) === true) {
                    nde.children = nde.children.map(chd => {
                        if (chd.key === event.node.key) {
                            chd = _node;
                        }
                        return chd;
                    });
                }
                return nde;
            });
            return _nodes;
        }

        return _menus;
    }

    const onExpand = (event) => {
        let _lazyParams = lazyParams;
        _lazyParams.padre = event.node.key;
        _lazyParams.total = totalRecords;
        setlazyParams(_lazyParams);
        loadLazyData(event);
    }

    const asignar = (e, lista, setLista, opcion) => {        
        let _seleccionados = [...lista];
        if (e.checked)
            _seleccionados.push(e.value);
        else
            _seleccionados.splice(_seleccionados.indexOf(e.value), 1);
        setLista(_seleccionados);
        setLoading(true);
        rolService.putMenu({ id: e.value, rol: id, agregar: e.checked, opcion: opcion }).then(res => {
            setLoading(false);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: res.data.data, life: 3000 });
        }).catch(error => {
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 3000 });
        });
    }

    const actionBodyTemplateConsultar = (rowData) => {
        return (
            <div className="actions">
                <Checkbox value={rowData.key} disabled={!permss.actualizar} checked={sconsultar.indexOf(rowData.key) !== -1} onChange={ e => asignar(e, sconsultar, setSconsultar, 0) } />
            </div>
        );
    };

    const actionBodyTemplateCrear = (rowData) => {
        return (
            <div className="actions">
                <Checkbox value={rowData.key} disabled={!permss.actualizar} checked={screar.indexOf(rowData.key) !== -1} onChange={ e => asignar(e, screar, setScrear, 1) } />
            </div>
        );
    };

    const actionBodyTemplateEditar = (rowData) => {
        return (
            <div className="actions">
                <Checkbox value={rowData.key} disabled={!permss.actualizar} checked={seditar.indexOf(rowData.key) !== -1} onChange={ e => asignar(e, seditar, setSeditar, 2) } />
            </div>
        );
    };

    const actionBodyTemplateEliminar = (rowData) => {
        return (
            <div className="actions">
                <Checkbox value={rowData.key} disabled={!permss.actualizar} checked={seliminar.indexOf(rowData.key) !== -1} onChange={ e => asignar(e, seliminar, setSeliminar, 3) } />
            </div>
        );
    };

    return (
        <>
            <Message severity="warn" text="Seleccione tanto el menú principal como los submenús requeridos." />
            <TreeTable value={menus} lazy paginator totalRecords={totalRecords}
                first={first} rows={rows} onExpand={onExpand} loading={loading}>
                <Column field="name" header="Menú" expander style={{ width: '20%' }}></Column>
                <Column field="consultar" body={actionBodyTemplateConsultar} header="Consultar" style={{ width: '10%' }}></Column>
                <Column field="crear" body={actionBodyTemplateCrear} header="Crear" style={{ width: '10%' }}></Column>
                <Column field="editar" body={actionBodyTemplateEditar} header="Editar" style={{ width: '10%' }}></Column>
                <Column field="eliminar" body={actionBodyTemplateEliminar} header="Eliminar" style={{ width: '10%' }}></Column>
                <Column field="fecha" header="Fecha Asignación" style={{ width: '15%' }}></Column>
            </TreeTable>
        </>
    )
}
