import { useForm } from "react-hook-form";
import { Comercial } from "../../models/Comercial";
import { InputComponent } from "../../components/InputComponent";
import { ComercialService } from "../../service/ComercialService";
import { Toolbar } from "primereact/toolbar";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { GenericComponent } from "../../components/GenericComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { Generico } from "../../components/Generico";

export const ReferenciaComercial = ({ tipo, id, toast, permss, num = 9 }) => {

    const generic = new GenericComponent();
    const omodel = new Comercial();
    const { accionesTbl } = Generico();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const referenciaService = new ComercialService();
    const [referencias, setReferencias] = useState(null);
    const [referencia, setReferencia] = useState(null);
    const [dialogReferencia, setDialogReferencia] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inText } = InputComponent({ registers: referencias, omodel, generic, errors, control, permss, id: getValues('id') });

    const openNew = () => {
        setDialogReferencia(true);
        setReferencia(null);
        reset(defaultValues);
    }

    const loadLazyComercial = (idp) => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        let _lazyParams = lazyParams;
        _lazyParams.idp = idp == null ? id : idp;
        _lazyParams.tipo = tipo;
        loadLazyTimeout = setTimeout(() => {
            referenciaService.getLista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setReferencias(res.data.registros);
                setLoading(false);
            });
        });
    }

    const onSubmit = (data) => {
        data.idp = id;
        data.tipo = tipo;
        if (getValues('id') == null) {
            referenciaService.newComercial(data).then(res => {
                setDialogReferencia(false);
                loadLazyComercial();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            referenciaService.editarComercial(data).then(res => {
                setDialogReferencia(false);
                loadLazyComercial();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editComercial = () => {
        reset(referencia);
        setDialogReferencia(true);
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteComercial()
        });
    };

    const deleteComercial = () => {
        referenciaService.deleteComercial(referencia?.id).then(res => {
            loadLazyComercial();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const dialogoComercial = () => {
        return (
            <Dialog visible={dialogReferencia} header={getValues('id') == null ? "Nueva Referencia Comercial" : "Editar Referencia Comercial"} modal className="p-fluid modal-ssize"
                style={{ width: '400px' }} onHide={e => setDialogReferencia(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombres', {}, 12)}
                        {inText('contacto', {}, 12)}
                        {inText('telefono_contacto', {}, 12)}
                        {inText('tiempo_relacion', {}, 12)}
                    </div>

                    {generic.buttonsForms(setDialogReferencia)}
                </form>
            </Dialog>
        )
    }

    const tableComercial = () => {
        return (
            <>
                {id && <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, '', permss)}></Toolbar>}
                {table({ action01: accionesTbl, reg: setReferencia, items: itemsMenu() })}
            </>
        )
    }

    const headerTemplate = (options) => {
        return (
            <div className={options.className}>
                <div className="flex align-items-center gap-2">
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" type="button" onClick={openNew} title="Registrar información"
                        style={{ display: permss?.crear ? 'block' : 'none' }} />
                    <span className="font-bold">{num}.- Referencia Comercial (opcional):</span>
                </div>
            </div>
        );
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar)
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editComercial() } });
        if (permss?.eliminar)
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    const tableComercialBVG = () => {
        return (
            <>
                <Panel headerTemplate={headerTemplate} className="panel-ficha">
                    {table({ action01: accionesTbl, reg: setReferencia, items: itemsMenu() })}
                </Panel>
            </>
        )
    }

    return {
        tableComercial,
        dialogoComercial,
        loadLazyComercial,
        tableComercialBVG
    }
}
