import { addLocale } from "primereact/api";
import { Card } from "primereact/card";
import { Divider } from 'primereact/divider';
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FileComponent } from "../../components/FileComponent";
import { GenericComponent } from "../../components/GenericComponent";
import { InputComponent } from "../../components/InputComponent";
import { Empleado } from "../../models/Empleado";
import { CatalogoService } from "../../service/CatalogoService";
import { EmpleadoService } from "../../service/EmpleadoService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useToken } from "../../components/useToken";
import { FiltroMultiempresa } from "../../components/FiltroMultiempresa";

export const Empleados = () => {

    const generic = new GenericComponent();
    const omodel = new Empleado();
    const model = omodel.model;
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, trigger, setValue } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const toast = useRef(null);
    const empleadoService = new EmpleadoService();
    const catalogoService = new CatalogoService();
    const [empleados, setEmpleados] = useState(null);
    const [dialogEmpleado, setDialogEmpleado] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [ecuador, setEcuador] = useState(false);
    const [ecuadorPersonal, setEcuadorPersonal] = useState(false);
    const [ciudades, setCiudades] = useState(null);
    const [ciudadesPersonal, setCiudadesPersonal] = useState(null);
    const [ciius, setCiius] = useState([]);
    const [otroCiius, setOtroCiius] = useState([]);
    const [conyugueCiius, setConyugueCiius] = useState([]);
    const [loadReport, setLoadReport] = useState(false);
    const [selectedCiiu, setSelectedCiiu] = useState(null);
    const [selectedOtroCiiu, setSelectedOtroCiiu] = useState(null);
    const [selectedConyugueCiiu, setSelectedConyugueCiiu] = useState(null);
    const [esPep, setEsPep] = useState(false);
    const [esVinculoPep, setEsVinculoPep] = useState(false);
    const [tieneConyugue, setTieneConyugue] = useState(false);
    const [esCedula, setEsCedula] = useState(false);
    const [esCedulaConyugue, setEsCedulaConyugue] = useState(false);
    const [ecuadorNac, setEcuadorNac] = useState(false);
    const [siCliente, setSiCliente] = useState(false);
    const { getPerss, getRol } = useToken();
    const [empresa, setEmpresa] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inArea, inCalendar, inNumber, inAutocomplete, downloadPdf } =
        InputComponent({ registers: empleados, omodel, generic, errors, control, permss, id: getValues('id') });
    const { upLoadFile, nuevoUpload, eliminarConfirmacionAnexo, anexoPendiente, enviarFormulario, listaFormulario, listar, dialogInstruccion, setdialogIns } =
        FileComponent({ generic, tipo: 'empleado', toast, unuevo: 'ficha/empleado/store', udelete: 'ficha/empleado/eliminar-anexo', permss, id: getValues('id') });

    addLocale(generic.idioma, generic.esFormat);

    useEffect(() => {
        setSiCliente(getPerss() != undefined);
        if (getPerss() == undefined) {
            loadLazyData();
        }
        else {
            setPermss(getPerss());
            editFicha({ id: 0 });
        }
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            delete _lazyParams.empresa;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            if (empresa) {
                _lazyParams.empresa = empresa;
            }
            if (getRol()) {
                _lazyParams.rol = getRol();
            }
            empleadoService.getLista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setEmpleados(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        if (catalogos == null) {
            empleadoService.newEmpleado({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                reset(defaultValues);
                setDialogEmpleado(true);
                setdialogIns(true);
            });
        }
        else {
            reset(defaultValues);
            setDialogEmpleado(true);
            setdialogIns(true);
        }
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el formulario?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteEmpleado(data)
        });
    }

    const deleteEmpleado = (data) => {
        empleadoService.eliminarEmpleado(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const editFicha = (data) => {
        const iniciar = catalogos == null ? 1 : null;
        empleadoService.consultarEmpleado(data.id, iniciar).then(res => {
            let cats = catalogos;
            if (iniciar == 1) {
                cats = res.catalogos;
                setCatalogos(res.catalogos);
            }
            if (!res.empleado) {
                reset(defaultValues);
                setDialogEmpleado(true);
                return;
            }

            let _emp = res.empleado;
            _emp.fecha = generic.stringToDate(_emp.fecha);
            _emp.fecha_ingreso = generic.stringToDate(_emp.fecha_ingreso);
            _emp.fecha_nacimiento = generic.stringToDate(_emp.fecha_nacimiento);
            const siConyugue = _emp.estado_civil === cats.casado.cat_id || _emp.estado_civil == cats.union.cat_id;
            const lista = {
                tipo_informacion: cats.declaracion, tipo_identificacion: cats.tipo_identificacion, nacionalidad: cats.nacionalidad, genero: cats.genero, pais: cats.pais,
                estado_civil: cats.estado_civil, pais_personal: cats.pais, es_pep: generic.estadosSiNo, relacion_pep: generic.estadosSiNo
            };
            if (siConyugue) {
                lista['tipo_identificacion_conyugue'] = cats.tipo_identificacion;
                lista['nacionalidad_conyugue'] = cats.nacionalidad;
                _emp.fecha_nacimiento_conyugue = generic.stringToDate(_emp.fecha_nacimiento_conyugue);
            }
            if (res.ciudades !== undefined) {
                lista['ciudad'] = res.ciudades;
                lista['provincia'] = cats.provincias;
                setCiudades(res.ciudades);
            }
            setEcuador(res.ciudades !== undefined);
            setEcuadorPersonal(res.ciudades_personal !== undefined);
            setEcuadorNac(_emp.nacionalidad === cats.ecuador_nac.cat_id);
            setEsPep(_emp.es_pep);
            setEsVinculoPep(_emp.relacion_pep);
            if (_emp.relacion_pep || _emp.es_pep) {
                _emp.fecha_designacion_pep = generic.stringToDate(_emp.fecha_designacion_pep);
                if (_emp.fecha_dejo_pep) {
                    _emp.fecha_dejo_pep = generic.stringToDate(_emp.fecha_dejo_pep);
                }
            }
            if (res.ciudades_personal !== undefined) {
                lista['ciudad_personal'] = res.ciudades_personal;
                lista['provincia_personal'] = cats.provincias;
                setCiudadesPersonal(res.ciudades_personal);
            }
            Object.keys(lista).forEach(function (key) {
                if (_emp[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _emp[key]) {
                            _emp[key] = element;
                        }
                    });
                }
            });
            setTieneConyugue(siConyugue);
            if (_emp['actividad_economica'] != null) {
                _emp['actividad_economica'] = res.actividad_economica;
                setSelectedCiiu(res.actividad_economica);
            }
            if (_emp['otra_actividad_economica'] != null) {
                _emp['otra_actividad_economica'] = res.otra_actividad_economica;
                setSelectedOtroCiiu(res.otra_actividad_economica);
            }
            if (_emp['otra_actividad_economica_conyugue'] != null) {
                _emp['otra_actividad_economica_conyugue'] = res.otra_actividad_economica_conyugue;
                setSelectedConyugueCiiu(res.otra_actividad_economica_conyugue);
            }
            setdialogIns(true);

            reset(generic.renewValues(_emp, model));
            setDialogEmpleado(true);
        });
    }

    const onChangePais = (e, dirr) => {
        if (dirr) {
            setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
        }
        else {
            setEcuadorPersonal(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
        }
    }

    const onChangePep = (e, es) => {
        if (es) {
            setEsPep(e.value.cat_id);
        }
        else {
            setEsVinculoPep(e.value.cat_id);
        }
    }

    const onChangeProvincia = (e, dirr) => {
        catalogoService.getCatalogosId(e.value.cat_id).then(res => {
            if (dirr) {
                setCiudades(res.data);
            }
            else {
                setCiudadesPersonal(res.data);
            }
        });
    }

    const onSubmit = (data) => {
        data.ec = catalogos?.ecuador;
        data.ecn = catalogos?.ecuador_nac;
        data.casado = catalogos?.casado;
        data.union = catalogos?.union;
        data.fecha = generic.dateToString(data.fecha);
        data.fecha_nacimiento = generic.dateToString(data.fecha_nacimiento);
        data.fecha_ingreso = generic.dateToString(data.fecha_ingreso);
        if (tieneConyugue) {
            data.fecha_nacimiento_conyugue = generic.dateToString(data.fecha_nacimiento_conyugue);
        }
        else {
            delete data.fecha_nacimiento_conyugue;
        }
        if (esPep || esVinculoPep) {
            data.fecha_designacion_pep = generic.dateToString(data.fecha_designacion_pep);
            if (data.fecha_dejo_pep) {
                data.fecha_dejo_pep = generic.dateToString(data.fecha_dejo_pep);
            }
        }
        else {
            delete data.fecha_designacion_pep;
            delete data.fecha_dejo_pep;
        }

        if (getValues('id') == null) {
            empleadoService.newEmpleado(data).then(res => {
                setValue('id', res.data);
                trigger('id');
                setdialogIns(true);
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            empleadoService.editarEmpleado(data).then(res => {
                setdialogIns(true);
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const onChangeTipo = (e, pers) => {
        if (pers) {
            setEsCedula(JSON.stringify(e.value) === JSON.stringify(catalogos.cedula));
        }
        else {
            setEsCedulaConyugue(JSON.stringify(e.value) === JSON.stringify(catalogos.cedula));
        }
    }

    const onChangeNacionalidad = (e) => {
        setEcuadorNac(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador_nac));
    }

    const onChangeEstadoCivil = (e) => {
        setTieneConyugue(JSON.stringify(e.value) === JSON.stringify(catalogos.casado) || JSON.stringify(e.value) === JSON.stringify(catalogos.union));
    }

    const searchCiiu = (event, tipo) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                if (tipo === 'ciiu') {
                    setCiius([...ciius]);
                } else if (tipo === 'otro') {
                    setOtroCiius([...otroCiius]);
                } else if (tipo === 'conyugue') {
                    setConyugueCiius([...conyugueCiius]);
                }
            }
            else {
                catalogoService.getActividades(event.query.toLowerCase()).then(data => {
                    if (tipo === 'ciiu') {
                        setCiius(data.data);
                    }
                    else if (tipo === 'otro') {
                        setOtroCiius(data.data);
                    } else if (tipo === 'conyugue') {
                        setConyugueCiius(data.data);
                    }
                });
            }
        });
    }

    const generarReporte = () => {
        setLoadReport(true);
        empleadoService.descargar(getValues('id')).then(res => {
            downloadPdf(res, getValues('id') + '-Formulario-empleado.pdf');
            setLoadReport(false);
        });
    }

    const subirReporte = () => {
        const urls = "ficha/empleado/listar";
        const urlsd = "ficha/empleado/descarga";
        nuevoUpload(getValues('id'), 'Subir formulario y adjuntos', urls, urlsd);
    }

    const enviar = () => {
        enviarFormulario(getValues('id'), 'ficha/empleado/enviar', 'empleado');
    }

    const actualizarPatrimonio = (e, info) => {
        const actvs = ['ACTV_INMUEBLES', 'ACTV_MUEBLES', 'ACTV_EFECTIVO', 'ACTV_INVERSIONES', 'ACTV_CREDITOS', 'ACTV_OTROS'];
        const pasvs = ['PASV_BANCARIOS', 'PASV_HIPOTECARIOS', 'PASV_QUIROGRAFARIO', 'PASV_CREDITO', 'PASV_OTRAS_CTAS', 'PASV_OTROS_PASV'];
        let totalPasivos = 0;
        let totalActivos = 0;
        actvs.forEach(itm => {
            if (getValues(itm) && !isNaN(getValues(itm))) {
                totalActivos += parseFloat(getValues(itm));
            }
        });
        pasvs.forEach(itm => {
            if (getValues(itm) && !isNaN(getValues(itm))) {
                totalPasivos += parseFloat(getValues(itm));
            }
        });
        setValue('activos', totalActivos);
        setValue('pasivos', totalPasivos);
        setValue('patrimonio', totalActivos - totalPasivos);
    }

    const archivos = (data) => {
        const urls = "ficha/empleado/listar";
        const urlsd = "ficha/empleado/descarga";
        listar(data.id, urls, urlsd, data.nemonico);
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogEmpleado && !siCliente && <>
                {getRol() && getRol()?.ideg && <FiltroMultiempresa loadLazyData={loadLazyData} setEmpresa={setEmpresa} />}
                <Toolbar className="mb-4" start={(getRol() && getRol()?.ideg) ? null : generic.leftToolbarTemplate(openNew, 'Nuevo empleado', permss)}
                    end={generic.rightToolbarTemplate(empleados, omodel, 'Listado_empleados', 'LISTADO INFORMACIÓN EMPLEADO')}></Toolbar>
                <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                    {table({ action3: generic.actionEditDetailTemplate, methEdit: editFicha, perm: permss, methDelete: confirmDelete, details: archivos, icono: 'pi pi-clone' })}
                </Card></>
            }
            {dialogEmpleado &&
                <Panel header="NUEVO FORMULARIO DE EMPLEADO - Datos Personales" style={{ width: '100%', margin: 'auto' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="formgrid grid">
                            {inCalendar('fecha')}
                            {inDrop('pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: true })}
                            {ecuador && inDrop('provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: true })}
                            {ecuador && inDrop('ciudad', ciudades, { lab: omodel.optionLabel })}
                            {!ecuador && inText('canton')}
                        </div>
                        <Divider />

                        <div className="formgrid grid">
                            {inDrop('tipo_informacion', catalogos?.declaracion, { lab: omodel.optionLabel })}
                            {inCalendar('fecha_ingreso')}
                            {inText('cargo')}
                        </div>

                        <Panel header="1) DATOS PERSONALES Y DE UBICACIÓN:" style={{ width: '100%', margin: 'auto' }}>
                            <div className="formgrid grid">
                                {inText('nombres')}
                                {inText('apellidos')}
                                {inDrop('tipo_identificacion', catalogos?.tipo_identificacion, { lab: omodel.optionLabel, chn: onChangeTipo, val: true })}
                                {!esCedula && inText('identificacion')}
                                {esCedula && inText('identificacion', generic.infoIdentificacion('cedula'))}
                            </div>
                            <Divider />

                            <div className="formgrid grid">
                                {inDrop('nacionalidad', catalogos?.nacionalidad, { lab: omodel.optionLabel, chn: onChangeNacionalidad })}
                                {inDrop('genero', catalogos?.genero, { lab: omodel.optionLabel })}
                                {inCalendar('fecha_nacimiento')}
                                {inArea('calles', 3, 1000)}
                            </div>
                            <Divider />

                            <div className="formgrid grid">
                                {inDrop('pais_personal', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: false })}
                                {ecuadorPersonal && inDrop('provincia_personal', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: false })}
                                {ecuadorPersonal && inDrop('ciudad_personal', ciudadesPersonal, { lab: omodel.optionLabel })}
                                {!ecuadorPersonal && inText('canton_personal')}
                                {!ecuadorNac && inText('tiempo_residencia')}
                            </div>
                            <Divider />

                            <div className="formgrid grid">
                                {inText('correo_personal', { pattern: omodel.pattern })}
                                {inText('celular')}
                                {inText('telefono')}
                                {inDrop('estado_civil', catalogos?.estado_civil, { lab: omodel.optionLabel, chn: onChangeEstadoCivil })}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="2) DATOS DEL CÓNYUGE / CONVIVIENTE (Si aplica):" style={{ width: '100%', margin: 'auto' }}>
                            {tieneConyugue && <>
                                <div className="formgrid grid">
                                    {inText('nombres_conyugue')}
                                    {inText('apellidos_conyugue')}
                                    {inDrop('tipo_identificacion_conyugue', catalogos?.tipo_identificacion, { lab: omodel.optionLabel, chn: onChangeTipo, val: false })}
                                    {!esCedulaConyugue && inText('identificacion_conyugue')}
                                    {esCedulaConyugue && inText('identificacion_conyugue', generic.infoIdentificacion('cedula'))}
                                </div>
                                <Divider />

                                <div className="formgrid grid">
                                    {inDrop('nacionalidad_conyugue', catalogos?.nacionalidad, { lab: omodel.optionLabel, chn: onChangeNacionalidad })}
                                    {inCalendar('fecha_nacimiento_conyugue')}
                                    {inText('lugar_trabajo_conyugue')}
                                    {inText('cargo_conyugue')}
                                </div>
                                <Divider />

                                <div className="formgrid grid">
                                    {inAutocomplete('actividad_economica', selectedCiiu, setSelectedCiiu, ciius, searchCiiu, { lab: omodel.optionLabel, val: 'ciiu' })}
                                </div>
                            </>}
                        </Panel>
                        <br />

                        <Panel header="3) INFORMACIÓN ECONÓMICA:" style={{ width: '100%', margin: 'auto' }}>
                            <div className="formgrid grid">
                                {inNumber('ingreso_mensual_dependencia_empleado', 2, true)}
                                {inNumber('otros_ingreso_empleado', 2, true)}
                                {inText('fuentes_ingresos_empleado')}
                                {inAutocomplete('otra_actividad_economica', selectedOtroCiiu, setSelectedOtroCiiu, otroCiius, searchCiiu, { lab: omodel.optionLabel, val: 'otro' })}
                            </div>
                            <Divider />

                            {tieneConyugue && <>
                                <div className="formgrid grid">
                                    {inNumber('ingreso_mensual_conyugue', 2, true)}
                                    {inNumber('otros_ingreso_conyugue', 2, true)}
                                    {inText('fuentes_ingresos_conyugue')}
                                    {inAutocomplete('otra_actividad_economica_conyugue', selectedConyugueCiiu, setSelectedConyugueCiiu, conyugueCiius, searchCiiu, { lab: omodel.optionLabel, val: 'conyugue' })}
                                </div>
                                <Divider />
                            </>}

                            <div className="formgrid grid">
                                {inNumber('gasto_individual', 2, true)}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="4) DECLARACIÓN PATRIMONIAL:" style={{ width: '100%', margin: 'auto' }}>
                            <div className="formgrid grid">
                                {inNumber('ACTV_INMUEBLES', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('PASV_BANCARIOS', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>

                            <div className="formgrid grid">
                                {inNumber('ACTV_MUEBLES', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('PASV_HIPOTECARIOS', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>

                            <div className="formgrid grid">
                                {inNumber('ACTV_EFECTIVO', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('PASV_QUIROGRAFARIO', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>

                            <div className="formgrid grid">
                                {inNumber('ACTV_INVERSIONES', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('PASV_CREDITO', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>

                            <div className="formgrid grid">
                                {inNumber('ACTV_CREDITOS', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('PASV_OTRAS_CTAS', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>

                            <div className="formgrid grid">
                                {inNumber('ACTV_OTROS', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('PASV_OTROS_PASV', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>
                            <Divider />

                            <div className="formgrid grid">
                                {inNumber('activos', 2, true, { disabled: true })}
                                {inNumber('pasivos', 2, true, { disabled: true })}
                            </div>
                            <Divider />

                            <div className="formgrid grid">
                                {inNumber('patrimonio', 2, true, { min: 'nulo', disabled: true })}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="5) PERSONA EXPUESTA POLÍTICAMENTE (PEP):" style={{ width: '100%', margin: 'auto' }}>
                            <div className="field col" style={{ textAlign: 'justify' }}>{omodel.strPep}</div>
                            <Divider />

                            <div className="formgrid grid">
                                {inDrop('es_pep', generic.estadosSiNo, { lab: omodel.optionLabel, chn: onChangePep, val: true })}
                                {inDrop('relacion_pep', generic.estadosSiNo, { lab: omodel.optionLabel, chn: onChangePep, val: false })}
                                {esVinculoPep && inText('vinculo_pep')}
                                {(esPep || esVinculoPep) && inText('nombre_pep')}
                            </div>

                            {(esPep || esVinculoPep) && <>
                                <Divider />
                                <div className="formgrid grid">
                                    {inText('cargo_pep')}
                                    {inText('institucion_pep')}
                                    {inCalendar('fecha_designacion_pep')}
                                    {inCalendar('fecha_dejo_pep')}
                                </div>
                            </>}
                        </Panel>
                        <br />

                        <Panel header="6) DECLARACIÓN" style={{ width: '100%', margin: 'auto' }}>
                            <div className="field col" style={{ textAlign: 'justify' }}>{omodel.strDeclaracion}</div>
                        </Panel>
                        <br />

                        <Panel header="7) ANEXOS" style={{ width: '100%', margin: 'auto' }}>
                            <ul>
                                <li>* Copia del Documento de identificación</li>
                                <li>* Copia del Documento de identificación del/la cónyuge/conviviente (de ser el caso).</li>
                            </ul>
                        </Panel>
                        <br />

                        <Panel header="8) OBSERVACIONES:" style={{ width: '100%', margin: 'auto' }}>
                            {inArea('observacion', 5, 6000, 12)}
                        </Panel>
                        <br />

                        {generic.buttonsFormsReports(setDialogEmpleado, generarReporte, subirReporte, enviar, loadReport, getValues('id') != null, !siCliente)}
                    </form>
                </Panel>
            }
            {upLoadFile()}
            {eliminarConfirmacionAnexo()}
            {anexoPendiente('Enviar formulario - pendientes', 'Los siguientes anexos están pendientes de subir')}
            {listaFormulario('ficha/empleado/aprobar', loadLazyData)}
            {dialogInstruccion(omodel.titulo, omodel.mensaje)}
        </>
    )
}
