import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { Factor } from "../../models/matriz/Factor";
import { useForm } from "react-hook-form";
import { FactorService } from "../../service/matriz/FactorService";
import { InputComponent } from "../../components/InputComponent";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { ItemFactor } from "./ItemFactor";

export const Factores = () => {

    const omodel = new Factor();
    const generic = new GenericComponent();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues, shouldTrim: true });
    let loadLazyTimeout = null;
    const [registers, setRegisters] = useState(null);
    const factorSrv = new FactorService();
    const toast = useRef(null);
    const [dialogFactor, setDialogFactor] = useState(false);
    const [dialogCatalogo, setDialogCatalogo] = useState(false);
    const [factor, setFactor] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inNumber } = InputComponent({ registers, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            factorSrv.lista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegisters(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        reset(defaultValues);
        setDialogFactor(true);
    }

    const edit = (data) => {
        factorSrv.consultar(data.id).then(res => {
            const lista = { estado: generic.estados };
            let _factor = res.data;

            Object.keys(lista).forEach(function (key) {
                if (_factor[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _factor[key]) {
                            _factor[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_factor, omodel.model));
            setDialogFactor(true);
        });
    }

    const deleteItem = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el factor?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                factorSrv.eliminar(data.id).then(res => {
                    toast.current.show({ severity: 'success', summary: 'Éxito', detail: 'Factor eliminado', life: 3000 });
                    loadLazyData();
                });
            }
        });
    }

    const onSubmit = (data) => {
        if (data.id == null) {
            factorSrv.nuevo(data).then(res => {
                generic.funcSuccess(res, toast);
                loadLazyData();
                setDialogFactor(false);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        } else {
            factorSrv.editar(data).then(res => {
                generic.funcSuccess(res, toast);
                loadLazyData();
                setDialogFactor(false);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const catalogos = (data) => {
        setFactor(data);
        setDialogCatalogo(true);
    }

    return (
        <>
            <Toast ref={toast} />

            {!dialogCatalogo && <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo factor', permss)}
                    end={generic.rightToolbarTemplate(registers, omodel, 'Listado_factor', 'LISTADO FACTORES')}></Toolbar>
                {table({ action3: generic.actionEditDetailTemplate, methEdit: edit, perm: permss, methDelete: deleteItem, details: catalogos, icono: 'pi pi-qrcode' })}
            </Card>}

            <Dialog visible={dialogFactor} header={getValues('id') == null ? "Nuevo factor" : "Editar factor"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogFactor(false)} style={{ maxWidth: '400px' }}>
                    <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre', {}, 12)}
                        {inText('nemonico', {}, 12)}
                        {inNumber('ponderacion', 0, false, {}, 6)}
                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 6)}
                    </div>

                    {generic.buttonsForms(setDialogFactor)}
                </form>
            </Dialog>

            {dialogCatalogo && <ItemFactor generic={generic} fact={factor} toast={toast} permss={permss} setRegresar={setDialogCatalogo} />}

        </>
    )
}
