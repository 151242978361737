export class Busqueda {
    model = {
        identificacion: {
            value: '', header: 'No. de Identificación / RUC:', required: false, maxLength: 'Máximo 20 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 20, min: 4, filterField: 'identificacion', filterPlaceholder: 'Buscar por identificación'
        },
        nombres: {
            value: '', export: true, header: 'Apellidos y Nombres / Razón Social:', required: false, maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 200, min: 4, filterField: 'nombres'
        },
        pagina: { value: null, export: true, header: 'Instituciones en línea', table: true, sort: true },
        estado: { value: null, export: true, header: 'Estado', table: true, sort: true },
    }
}