import { useEffect, useState } from "react";
import { UsuarioService } from "../../service/UsuarioService";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const RolUsuario = ({ id, toast, generic, permss }) => {

    const [sasignar, setSasignar] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState(null);
    const [lazyParams, setLazyParams] = useState(generic.paramLazy({ nombre: 'contains' }, null));
    const usuarioService = new UsuarioService();
    let loadLazyTimeout = null;

    const onPage = (event) => {
        setLazyParams(event);
    }

    const onSort = (event) => {
        setLazyParams(event);
    }

    const onFilter = (event) => {
        setLazyParams(event);
    }

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.id = id;
            usuarioService.getRolUsuario({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRoles(res.data.registros);
                setLoading(false);
                let inicial = [];
                res.data.registros.forEach(item => {
                    if (item.fecha != null) {
                        inicial.push(item.id);
                    }
                });
                setSasignar(inicial);
            });
        });
    }

    const asignar = (e) => {
        let _seleccionados = [...sasignar];
        if (e.checked)
            _seleccionados.push(e.value);
        else
            _seleccionados.splice(_seleccionados.indexOf(e.value), 1);

        usuarioService.putPermission({ id: e.value, usu: id, agregar: e.checked }).then(res => {
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: res?.message, life: 3000 });
            loadLazyData();
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 3000 });
        });

        setSasignar(_seleccionados);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {(permss.crear || permss.actualizar) &&
                    <Checkbox value={rowData.id} checked={sasignar.indexOf(rowData.id) !== -1} onChange={asignar} />
                }
            </div>
        );
    };

    return (
        <DataTable value={roles} lazy className="datatable-responsive"
            filterDisplay="row" responsiveLayout="scroll" dataKey="id"
            paginator first={lazyParams.first} rows={10} totalRecords={totalRecords} onPage={onPage}
            onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
            onFilter={onFilter} filters={lazyParams.filters} loading={loading}>

            <Column field="nombre" header='Roles disponibles' sortable showFilterMenu={false} filterField='nombre' filter filterPlaceholder='Filtrar por nombre de rol' />
            <Column field="fecha" sortable header='Fecha de asignación del rol' />
            <Column header='Asignar / Quitar' body={actionBodyTemplate}></Column>

        </DataTable>
    )
}
