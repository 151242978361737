import { useForm } from "react-hook-form";
import { GenericComponent } from "../../components/GenericComponent";
import { InfoAccionista } from "../../models/matriz/InfoAccionista";
import { InfoAccionistaService } from "../../service/matriz/InfoAccionistaService";
import { useEffect, useRef, useState } from "react";
import { InputComponent } from "../../components/InputComponent";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { DetalleAccionistaDos } from "./DetalleAccionistaDos";
import { Busqueda } from "./Busqueda";
import { Generico } from "../../components/Generico";
import { Button } from "primereact/button";

export const PerfilAccionista = () => {

    const omodel = new InfoAccionista();
    const generic = new GenericComponent();
    const accionistaSrv = new InfoAccionistaService();
    const { accionesTbl, funcMessageBlobError, downloadPdf } = Generico();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, setValue } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registers, setRegisters] = useState(null);
    const [catalogo, setCatalogo] = useState(null);
    const [permss, setPermss] = useState(null);
    const [dialogBusqueda, setDialogBusqueda] = useState(false);
    const toast = useRef(null);
    const [dialogAccionista, setDialogAccionista] = useState(false);
    const [dialogPerfil, setDialogPerfil] = useState(false);
    const [matriz, setMatriz] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inCalendar } = InputComponent({ registers, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = 'permiso';
            }
            accionistaSrv.lista({ lazyEvent: JSON.stringify(_lazyParams) }, 'n').then(res => {
                setTotalRecords(res.data.total);
                setRegisters(res.data.registros);
                if (catalogo == null) {
                    setCatalogo(res.data.catalogos);
                    const _perms = res.data.perss;
                    _perms.b1t = "Editar información inicial de matrices";
                    _perms.b2t = "Editar / registrar detalles de información de matrices";
                    _perms.b3t = "Eliminar información inicial de matrices";
                    setPermss(res.data.perss);
                }
                setLoading(false);
            });
        });
    }

    const openNew = () => {
        setMatriz(null);
        reset(defaultValues);
        setDialogAccionista(true);
    }

    const edit = () => {
        accionistaSrv.consultar(matriz?.id, 'n').then(res => {
            values(res.data);
        });
    }

    const values = (_accionista) => {
        const lista = { tipo_identificacion: catalogo?.tipo_identificacion, };

        Object.keys(lista).forEach(function (key) {
            if (_accionista[key] != null) {
                lista[key].forEach(element => {
                    if (element.cat_id === _accionista[key]) {
                        _accionista[key] = element;
                    }
                });
            }
        });
        _accionista.fecha = generic.stringToDate(_accionista.fecha);
        _accionista.fecha_nacimiento = generic.stringToDate(_accionista.fecha_nacimiento);
        setMatriz(_accionista);

        reset(generic.renewValues(_accionista, omodel.model));
        setDialogAccionista(true);
    }

    const onSubmit = (data) => {
        data.fecha = generic.dateToString(data.fecha);
        data.fecha_nacimiento = generic.dateToString(data.fecha_nacimiento);
        if (matriz?.idp) {
            data.idp = matriz.idp;
        }
        if (getValues('id') == null) {
            accionistaSrv.nuevo(data, 'n').then(res => {
                generic.funcSuccess(res, toast);
                setDialogAccionista(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        } else {
            accionistaSrv.editar(data, 'n').then(res => {
                generic.funcSuccess(res, toast);
                loadLazyData();
                setDialogAccionista(false);
            }).catch(error => {
                setDialogAccionista(false);
                generic.funcError(error, toast, setError);
            });
        }
    }

    const deleteItem = () => {
        confirmDialog({
            message: '¿Esta seguro de eliminar la matriz?',
            header: 'Confirmar',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
                accionistaSrv.eliminar(matriz.id, 'n').then(res => {
                    generic.funcSuccess(res, toast);
                    loadLazyData();
                }).catch(error => {
                    generic.funcDeleteError(error, toast);
                });
            }
        });
    }

    const calcularEdad = (data) => {
        let today = new Date();
        let age = today.getFullYear() - data.getFullYear();
        let m = today.getMonth() - data.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < data.getDate())) {
            age--;
        }
        setValue('edad', age);
    }

    const seleccionar = (data) => {
        values({
            fecha: data?.fecha,
            tipo_identificacion: data?.tipo_identificacion,
            identificacion: data?.identificacion,
            nombres: data?.razon_social,
            fecha_nacimiento: data?.fecha_nacimiento,
            edad: data?.edad,
            cargo: data?.cargo,
            idp: data?.id
        });
        setDialogBusqueda(false);
    }
    const generarReporte = (numero) => {
        setLoading(true);
        accionistaSrv.descargar(matriz?.id, numero, 'n').then(res => {
            downloadPdf(res, 'Reporte-Matriz.pdf');
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            funcMessageBlobError('Asegúrese de ingresar la información antes de generar el reporte.', error, toast);
        });
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!matriz?.nemonico || matriz?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar información de inicio', icon: 'pi pi-pencil', command: () => { edit() } });
        if (permss?.actualizar && (!matriz?.nemonico || matriz?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Contenido de la matriz', icon: 'pi pi-qrcode', command: () => { setDialogPerfil(true) } });
        if (permss?.consultar && matriz?.nemonico === 'FINALIZADO')
            items.push({ label: 'Imprimir matriz Análisis a Dos años a PDF', icon: 'pi pi-print', command: () => { generarReporte(2) } });
        if (permss?.consultar && matriz?.estado_uno === 'FINALIZADO')
            items.push({ label: 'Imprimir matriz Análisis a Un año a PDF', icon: 'pi pi-print', command: () => { generarReporte(1) } });
        if (permss?.eliminar && (!matriz?.nemonico || matriz?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { deleteItem() } });

        return [{ label: 'Opciones', items: items }];
    }

    const startContent = (
        <>
            {permss?.crear && <Button icon="pi pi-plus" className="mr-2" tooltip="Nueva matriz" onClick={openNew} />}
            {permss?.actualizar && <Button icon="pi pi-search" className="mr-2" tooltip="Búsqueda Declaración Persona Natural: Accionista" onClick={() => setDialogBusqueda(true)} />}
        </>
    );

    const regresar = () => {
        setMatriz(null);
        setDialogPerfil(true);
        setDialogPerfil(false);
        loadLazyData();
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogPerfil && <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={startContent} end={generic.rightToolbarTemplate(registers, omodel, 'Listado_matrices', 'LISTADO MATRICES')}></Toolbar>
                {table({ action01: accionesTbl, reg: setMatriz, items: itemsMenu() })}
            </Card>}

            <Dialog visible={dialogAccionista} header={getValues('id') == null ? "Nueva matriz" : "Editar matriz"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogAccionista(false)} style={{ maxWidth: '500px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inCalendar('fecha', {}, 12)}
                        {inDrop('tipo_identificacion', catalogo?.tipo_identificacion, { lab: omodel.optionLabel }, 6)}
                        {inText('identificacion', {}, 6)}
                        {inText('nombres', {}, 12)}
                        {getValues("id") === null && inCalendar('fecha_nacimiento', { onChange: calcularEdad }, 6)}
                        {getValues("id") !== null && <div className="field col-6">Fecha de nacimiento*: <br />{generic.dateToString(getValues('fecha_nacimiento'))}</div>}
                        {inText('edad', { readOnly: 'readOnly' }, 6)}
                        {inText('cargo', {}, 12)}
                    </div>

                    {generic.buttonsForms(setDialogAccionista)}
                </form>
            </Dialog>

            <Dialog visible={dialogBusqueda} header='BÚSQUEDA - INFORMACIÓN DE PERSONAS NATURAL: Accionista' modal className="p-fluid modal-ssize"
                onHide={e => setDialogBusqueda(false)} style={{ maxWidth: '900px' }}>
                <Busqueda opcion="persona-natural-accionista" permss={permss} toast={toast} seleccion={seleccionar} />
            </Dialog>

            {dialogPerfil && <DetalleAccionistaDos matrizId={matriz?.id} permss={permss} setDialogPerfil={setDialogPerfil} toast={toast} perfil="n" numero="2" uno="1" regresar={regresar} />}
        </>
    )
}
