import Axios from "axios";

export class ConsultaService {
    getLista(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/consulta/reporte/lista-pagina?${queryParams}`).then(res => res.data);
    }

    buscar(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/consulta/reporte/buscar`, data,
            { headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache' }, responseType: 'blob'}).then(async res => res.data);
    }

    buscarPrincipal(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/consulta/reporte-busqueda/buscar`, data,
            { headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache' }, responseType: 'blob'}).then(async res => res.data);
    }
}
