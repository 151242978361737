import { useEffect, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { InputComponent } from "../../components/InputComponent";
import { Historial } from "../../models/Historial";
import { HistorialService } from "../../service/HistorialService";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";

export const Historico = () => {
  const generic = new GenericComponent();
  const omodel = new Historial();
  const [permss, setPermss] = useState(null);
  let loadLazyTimeout = null;
  const [registros, setRegistros] = useState(null);
  const historicoService = new HistorialService();
  const { table, setLoading, setTotalRecords, lazyParams } = InputComponent({ registers: registros, omodel, generic, errors: null, control: null, permss, id: null });

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      let _lazyParams = lazyParams;
      delete _lazyParams.rtipo;
      if (permss == null) {
        _lazyParams.rtipo = true;
      }
      historicoService.getHistorial({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
        setTotalRecords(res.data.total);
        setRegistros(res.data.registros);
        setLoading(false);
        if (res.data.perss !== undefined) {
          setPermss(res.data.perss);
        }
      });
    });
  }

  return (
    <Card style={{ maxWidth: '100%', margin: 'auto' }}>
      <Toolbar className="mb-4" end={generic.rightToolbarTemplate(registros, omodel, 'Listado_historial', 'LISTADO HISTORIAL')}></Toolbar>
      {table()}
    </Card>
  )
}
