export class FiltroReporte {

    model = {
        identificacion: {
            value: '', export: false, header: 'Identificación', required: false, maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 50, min: 4, table: false, filter: false, sort: false
        },
        nombre: {
            value: '', export: true, header: 'Nombre', required: false, maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 200, min: 4, table: false, filter: false, sort: false
        },
        compania: {
            value: '', export: true, header: 'Compañía', required: false, maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 200, min: 4, table: false, filter: false, sort: false
        },
        fecha_inicio: {header: 'Fecha inicio', value: null, table: false, export: false},
        fecha_fin: {header: 'Fecha fin', value: null, table: false, export: false},
    }

}
