import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { Grupo } from "../../models/administracion/Grupo";
import { useForm } from "react-hook-form";
import { GrupoEmpresaService } from "../../service/GrupoEmpresaService";
import { InputComponent } from "../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { GrupoEmpresaDetalle } from "./GrupoEmpresaDetalle";

export const GrupoEmpresa = () => {

    const generic = new GenericComponent();
    const omodel = new Grupo();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const toast = useRef(null);
    const [grupos, setGrupos] = useState(null);
    const [grupo, setGrupo] = useState(null);
    const grupoService = new GrupoEmpresaService();
    const [dialogGrupo, setDialogGrupo] = useState(false);
    const [dialogDetalle, setDialogDetalle] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText } = InputComponent({ registers: grupos, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            grupoService.getGrupoEmpresa({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setGrupos(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        setDialogGrupo(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            grupoService.newGrupoEmpresa(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogGrupo(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            grupoService.editarGrupoEmpresa(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogGrupo(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editEmpresa = (grupo) => {
        grupoService.consultarGrupoEmpresa(grupo.id).then(res => {
            let _grupo = res.data;
            const lista = { estado: generic.estados, corporacion: generic.sino };

            Object.keys(lista).forEach(function (key) {
                if (_grupo[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _grupo[key]) {
                            _grupo[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_grupo, omodel.model));
            setDialogGrupo(true);
        });
    };

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el grupo?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarGrupo(data)
        });
    }

    const eliminarGrupo = (data) => {
        grupoService.eliminarGrupoEmpresa(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const anadirEmpresas = (data) => {
        setGrupo(data);
        setDialogDetalle(true);
    }

    return (
        <>
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo grupo', permss)}
                    end={generic.rightToolbarTemplate(grupos, omodel, 'Listado_grupo', 'LISTADO GRUPO')}></Toolbar>
                {table({ action3: generic.actionEditDetailTemplate, methEdit: editEmpresa, perm: permss, methDelete: confirmDelete, details: anadirEmpresas, icono: 'pi pi-qrcode' })}
            </Card>

            <Dialog visible={dialogGrupo} header={getValues('id') == null ? "Nuevo Grupo" : "Editar grupo"} modal className="p-fluid modal-ssize" onHide={e => setDialogGrupo(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre_grupo', {}, 12)}
                        {inDrop('corporacion', generic.sino, { lab: omodel.optionLabel }, 12)}
                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 12)}

                    </div>

                    {generic.buttonsForms(setDialogGrupo)}
                </form>
            </Dialog>

            <Dialog visible={dialogDetalle} header="Empresas asignadas" modal className="p-fluid modal-ssize" onHide={e => setDialogDetalle(false)}
                style={{ maxWidth: '1000px' }}>
                {grupo?.corporacion == 'NO' && <GrupoEmpresaDetalle grupoId={grupo?.id} generic={generic} toast={toast} />}
                {grupo?.corporacion == 'SI' && <b>No se puede agregar empresas a un grupo corporativo por esta funcionalidad.</b>}
            </Dialog>
        </>
    )
}
