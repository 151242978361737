import Axios from "axios";

export class DeclaranteService {

    getDeclarante(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/declarante/lista?${queryParams}`).then(res => res.data);
    }

    getRepresentanteApoderado(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/declarante/listaRA?${queryParams}`).then(res => res.data);
    }

    newDeclarante(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/declarante/nuevo`, data).then(res => res.data);
    }

    newProveedor(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/declarante/nuevo-proveedor`, data).then(res => res.data);
    }

    newRepresentanteApoderado(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/declarante/nuevoRA`, data).then(res => res.data);
    }

    editarDeclarante(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/declarante/editar`, data).then(res => res.data);
    }

    editarProveedor(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/declarante/editar-proveedor`, data).then(res => res.data);
    }

    consultarProveedor(id, tipo, idp, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/declarante/proveedor/${id}/${tipo}/${idp}/${iniciar}`).then(res => res.data);
    }

    consultarJuridica(id, tipo, idp, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/declarante/juridica/${id}/${tipo}/${idp}/${iniciar}`).then(res => res.data);
    }

    eliminarDeclarante(id, tipo, idp) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/declarante/eliminar/${id}/${tipo}/${idp}`).then(res => res.data);
    }
}