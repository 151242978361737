import Axios from "axios";

export class FormaPagoService {

    getFormaPago(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/pago/lista?${queryParams}`).then(res => res.data);
    }

    newFormaPago(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/pago/nuevo`, data).then(res => res.data);
    }

    editarFormaPago(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/pago/editar`, data).then(res => res.data);
    }

    eliminar(id, idp, tipo) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/pago/eliminar/${id}/${tipo}/${idp}`).then(res => res.data);
    }

    getPago(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/pago-ver/${id}/${iniciar}`).then(res => res.data);
    }
}