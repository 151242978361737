import { Card } from "primereact/card";
import { useToken } from "./useToken";
import { GrupoEmpresaDetalleService } from "../service/admin/GrupoEmpresaDetalleService";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";

export const FiltroMultiempresa = ({ loadLazyData, setEmpresa }) => {
    const { getRol } = useToken();
    const detalleService = new GrupoEmpresaDetalleService();
    const [items, setItems] = useState(null);
    const [esGrupo, setEsGrupo] = useState(false);
    const [item, setItem] = useState(null);

    useEffect(() => {
        const siGrupo = getRol() && getRol().tipo && getRol().tipo == 'Grupo';
        setEsGrupo(siGrupo);
        if (siGrupo) {
            cargarEmpresas();
        } else {
            setEmpresa({empresa_id: getRol()?.ideg})
        }
    }, []);

    const cargarEmpresas = () => {
        detalleService.listaEmpresas(getRol().ideg).then(res => {
            setItems(res.data);
        });
    }

    const cambiarEmpresa = () => {
        loadLazyData();
    }

    return (
        <>
            <Card title="Gestión">
                <div className="formgrid grid">
                    <div className="field col-2"><b>Grupo/Empresa:</b></div>
                    <div className="field col-2">{getRol() && getRol()?.grupo}</div>
                    <div className="field col-2"><b>Rol:</b></div>
                    <div className="field col-2">{getRol() && getRol()?.nombre}</div>
                    <div className="field col-2"><b>Observación:</b></div>
                    <div className="field col-2">{getRol() && getRol()?.observacion}</div>
                    {esGrupo && <>
                        <div className="field col-2"><b>Empresas:</b></div>
                        <div className="field col-2">
                            <Dropdown autoFocus onChange={(e) => { setItem(e.value); setEmpresa(e.value) }} options={items} value={item}
                                optionLabel="nombre" placeholder="Seleccione" filter showClear filterBy="nombre" style={{ width: '100%' }} />
                            {item?.identificacion && <><br /><b>Ruc:</b> {item?.identificacion}</>}
                        </div>
                    </>
                    }
                    <div className="field col-2">
                        <Button label="Buscar" icon="pi pi-search" onClick={cambiarEmpresa} />
                    </div>
                    <div className="field col-3">
                        *Si Ud. crea un formulario/envío de link, estará relacionado directamente a la empresa del presente usuario. No al de la selección.
                    </div>
                </div>
            </Card>
            <br />
        </>
    )
}
