import { useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { useEffect, useState } from "react";
import { FuncionarioService } from "../../../service/ficha/FuncionarioService";
import { Panel } from "primereact/panel";
import { InputComponent } from "../../../components/InputComponent";
import { GenericComponent } from "../../../components/GenericComponent";
import { Generico } from "../../../components/Generico";
import { Button } from "primereact/button";
import { Relacion } from "../../../models/fun/Relacion";

export const VinculacionRelacion = ({ id, toast, permss, opcion }) => {

    const generic = new GenericComponent();
    const omodel = new Relacion();
    const { accionesTbl } = Generico();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues, shouldTrim: true });
    let loadLazyTimeout = null;
    const funSrv = new FuncionarioService();
    const [registros, setRegistros] = useState(null);
    const [dialogBien, setDialogBien] = useState(false);
    const [bien, setBien] = useState(null);
    const [total, setTotal] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inText } =
        InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazy();
    }, [id]);

    const editBien = () => {
        funSrv.consultar(bien?.id, opcion, id).then(res => {
            reset(generic.renewValues(res.data, omodel.model));
            setDialogBien(true);
        });
    }

    const loadLazy = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        let _lazyParams = lazyParams;
        _lazyParams.idp = id;
        funSrv.listado({ lazyEvent: JSON.stringify(_lazyParams) }, opcion).then(res => {
            setTotalRecords(res.data.total);
            setRegistros(res.data.registros);
            setLoading(false);
            setTotal({monto: res.data.sum});
        });
    }

    const onSubmit = (data) => {
        data.idp = id;
        if (getValues('id') == null) {
            funSrv.nuevo(data, opcion).then(res => {
                setDialogBien(false);
                loadLazy();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            funSrv.editar(data, opcion).then(res => {
                setDialogBien(false);
                loadLazy();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const openNew = () => {
        setBien(null);
        setDialogBien(true);
        reset(defaultValues);
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteBien(bien?.id)
        });
    };

    const deleteBien = (id) => {
        funSrv.eliminar(bien?.id, opcion, id).then(res => {
            loadLazy();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!bien?.nemonico || bien?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editBien() } });
        if (permss?.eliminar && (!bien?.nemonico || bien?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    const headerTemplate = (options) => {
        return (
            <div className={options.className}>
                <div className="flex align-items-center gap-2">
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" type="button" onClick={openNew} style={{ display: permss?.crear ? 'block' : 'none' }} />
                    <span className="font-bold">Adicionalmente declaro que mantengo relación (favor detallar que tipo de relación de las siguientes: comerciales, contractuales,
                        laborales) o me encuentro asociado de cualquier otra forma con: (favor detallar el nombre de la persona natural o jurídica)</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <Panel headerTemplate={headerTemplate}>
                {table({ action01: accionesTbl, reg: setBien, items: itemsMenu(), footers: total })}
            </Panel>

            <Dialog visible={dialogBien} header={getValues('id') == null ? "Nueva Información" : "Editar Información"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogBien(false)} style={{ width: '600px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="formgrid grid">
                        {inText('tipo_relacion', {}, 12)}
                        {inText('cargo', {}, 12)}
                        {inText('razon_social', {}, 12)}
                    </div>

                    {generic.buttonsForms(setDialogBien)}
                </form>
            </Dialog>
        </>
    )
}
