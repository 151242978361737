import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import React, { useEffect } from 'react';
import { AccionistaSocios } from '../AccionistaSocios';
import { Message } from 'primereact/message';

export const SocAccPj = ({ toast, back, next, id, permss }) => {

  const { loadLazyAccionistaSocio, tableAccionistaSocio, dialogoAccionistaSocio } = AccionistaSocios({ toast, tipo: 'clientePj', id, permss });

  useEffect(() => {
    loadLazyAccionistaSocio(id);
  }, [false])

  const Siguiente = () => {
    next(id);
  };

  return (
    <>
      <Message severity="info" text="Estimado usuario, este campo utiliza información de todo el documento, así que por favor completar las secciones 1,3,5,6,7 y 8, GUARDARLAS y llenar las secciones 2 y 4." style={{ width: '100%' }} />
      <Panel header="INFORMACIÓN DE LOS SOCIOS/ACCIONISTAS:" className="panel-ficha">
        {tableAccionistaSocio()}
      </Panel>
      <br />
      <div className="formgrid grid">
        <div className="field col">
          <Button label="Anterior" icon="pi pi-chevron-left" onClick={back} />
        </div>
        <div className="field col">
          <Button label="Siguiente" icon="pi pi-chevron-right" onClick={Siguiente} />
        </div>
      </div>
      {dialogoAccionistaSocio()}
    </>
  );
}