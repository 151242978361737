const Dashboard = () => {

    return (
        <>
            <img src="assets/layout/images/logoPrelafit.png" alt="Logo Prelafit"
                style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', margin: 'auto', verticalAlign: 'middle', marginTop: '200px' }} />
        </>
    );
};

export default Dashboard;
