import { Panel } from "primereact/panel";
import { InputComponent } from "../../../components/InputComponent";
import { CatalogoService } from "../../../service/CatalogoService";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Generico } from "../../../components/Generico";
import { InfLaboral } from "../../../models/cpn/InfLaboral";
import { GenericComponent } from "../../../components/GenericComponent";
import { Divider } from "primereact/divider";
import { PersonaNaturalService } from "../../../service/personaNatural/PersonaNaturalService";

export const InformacionLaboral = ({ cliente, catalogos, toast, seccion, nextPrev, next, permss, nuevoCliente }) => {

    const omodel = new InfLaboral();
    const generic = new GenericComponent();
    const model = omodel.model;
    const { funcError, funDefault, funcSuccess, renewValues, stringToDate } = Generico();
    const defaultValues = funDefault(model);
    const catalogoSrv = new CatalogoService();
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({ defaultValues, shouldTrim: true });
    const { inDrop, inText, inAutocomplete, inCalendar } = InputComponent({ omodel, errors, control, permss, id: cliente?.id });
    const clientepnSrv = new PersonaNaturalService();
    const [selectedCiiu, setSelectedCiiu] = useState(null);
    const [ciius, setCiius] = useState(null);
    const [siOtros, setSiOtros] = useState(false);

    const onChangePep = (e) => {
        nuevoCliente({ pep: e.value?.cat_id});
    }

    const [hayPep, setHayPep] = useState({ lab: omodel.optionLabel, chn: onChangePep });

    useEffect(() => {
        editFicha();
    }, [cliente]);

    const searchCiiu = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setCiius([...ciius]);
            }
            else {
                catalogoSrv.getActividades(event.query.toLowerCase()).then(data => {
                    setCiius(data.data);
                });
            }
        });
    }

    const editFicha = () => {
        clientepnSrv.consultarClientepn(cliente?.id, seccion).then(res => {
            const cats = catalogos;
            let _clientepn = res.data.clientepn;
            const lista = {
                situacion_laboral: cats.situacionLaboral,
                entidad_laboral: cats.entidad,
                pep: generic.estadosSiNo
            };
            Object.keys(lista).forEach(function (key) {
                if (_clientepn[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _clientepn[key]) {
                            _clientepn[key] = element;
                        }
                    });
                }
            });
            if (_clientepn['actividad_economica'] != null) {
                _clientepn['actividad_economica'] = res.data.actividad_economica;
                setSelectedCiiu(res.data.actividad_economica);
            }
            if (_clientepn.fecha_inicio_laboral) {
                _clientepn.fecha_inicio_laboral = stringToDate(_clientepn.fecha_inicio_laboral);
            }
            if(_clientepn.entidad_laboral){
                setSiOtros(_clientepn.entidad_laboral.cat_id === catalogos?.trabajo_otros.cat_id);
            }
            const _pep = { lab: omodel.optionLabel, chn: onChangePep };
            if(res.data.sipep === true) {
                _pep.ne = true;
            }
            setHayPep(_pep);

            reset(renewValues(_clientepn, model));
        });
    }

    const onSubmit = (data) => {
        data.seccion = seccion;
        data.fecha_inicio_laboral = data.fecha_inicio_laboral.toISOString().split('T')[0];
        data.id = cliente?.id;
        data.otros = catalogos?.trabajo_otros.cat_id;
        clientepnSrv.editarClientepn(data).then(res => {
            funcSuccess(res, toast);
            next();
        }).catch(error => {
            funcError(error, toast, setError);
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se han detectado errores. Por favor, revise la información ingresada y proceda a corregir', life: 3000 });
    }

    const onChangeEntidad = (e) => {
        let _otros = false;
        if (!e.value) {
            _otros = true;
        } else {
            _otros = e.value?.cat_id === catalogos?.trabajo_otros.cat_id;
        }
        setSiOtros(_otros);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Panel header="2.- Información Laboral del declarante:" className="panel-ficha">
                <div className="formgrid grid">
                    {inDrop('situacion_laboral', catalogos?.situacionLaboral, { lab: omodel.optionLabel })}
                    {inDrop('entidad_laboral', catalogos?.entidad, { lab: omodel.optionLabel, chn: onChangeEntidad })}
                    {inText('lugar_trabajo')}
                    {inText('cargo')}

                    {siOtros && inText('sector_economico')}
                    {inAutocomplete('actividad_economica', selectedCiiu, setSelectedCiiu, ciius, searchCiiu, { lab: 'nombre', col: 6 })}
                    {inCalendar('fecha_inicio_laboral')}
                    {inText('direccion_trabajo')}

                    {inText('email_trabajo')}
                </div>
            </Panel>
            <br />

            <Panel header="3.- Persona Expuesta Políticamente o Públicamente (PEP´s)." className="panel-ficha">
                {omodel.labelPep}
                <div className="formgrid grid">
                    <div className="field col-12">
                        Yo, <b>{cliente?.nombre_completo}</b>, con documento de identificación No. <b>{cliente?.identificacion}</b>, he leído las definiciones de “Personas Expuestas Políticamente (PEP’s)”,
                    </div>
                    {inDrop('pep', generic.estadosSiNo, hayPep, 2)}
                    <div className="field col-10">
                        {omodel.dec2}
                    </div>
                </div>
                {omodel.dec3}
                <Divider />
                {omodel.dec4}

            </Panel>
            <br />

            {nextPrev()}
        </form>
    )
}
