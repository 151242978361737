import { FilterMatchMode } from "primereact/api"

export class PlanColor {

    model = {
        id: {
            value: null, header: 'Código', export: true, table: false, sort: true, filterField: 'id',
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        orden: {
            value: 1, export: true, header: 'Orden', required: 'Orden es requerido', table: true, max: 1000, min: 1, minLength: 'Mínimo 1', maxLength: 'Máximo 1000',
            filterField: 'orden', filter: true, sort: true, matchMode: FilterMatchMode.EQUALS, headerStyle: { width: '10%', minWidth: '10rem' }
        },
        nombre: {
            value: '', export: true, header: 'Etiqueta principal', required: 'Campo requerido', maxLength: 'Máximo 500 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, filterField: 'nombre', filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '20%', minWidth: '15rem' }
        },
        color: {
            value: '00b0f0', export: true, header: 'Color fondo', required: 'Campo requerido', maxLength: 'Máximo 100 caracteres', table: false,
            minLength: 'Mínimo 4 caracteres', max: 100, min: 4, headerStyle: { width: '1%', minWidth: '10rem' }
        },
        color_texto: {
            value: '262626', export: true, header: 'Color del texto', required: 'Campo requerido', maxLength: 'Máximo 100 caracteres', table: false,
            minLength: 'Mínimo 4 caracteres', max: 100, min: 4, headerStyle: { width: '1%', minWidth: '10rem' }
        },
        minimo: {
            value: 0, export: false, header: 'Valor mínimo', required: false, maxLength: 'Máximo 100', table: true,
            minLength: 'Mínimo 0', max: 100, min: 0, headerStyle: { width: '5%'}
        },
        maximo: {
            value: 0, export: false, header: 'Valor máximo', required: false, maxLength: 'Máximo 100', table: true,
            minLength: 'Mínimo 0', max: 100, min: 0, headerStyle: { width: '5%' }
        },
        rango: {
            value: '', export: true, header: 'Rango', required: 'Campo requerido', maxLength: 'Máximo 100 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 100, min: 4, headerStyle: { width: '10%', minWidth: '10rem' }
        },
        estado: { header: 'Estado', value: { nombre: 'Activo', cat_id: true }, required: 'Estado es requerido', optionLabel: 'nombre', export: false, table: false },
    }
    optionLabel = 'nombre';
}
