import { FilterMatchMode } from "primereact/api"

export class Permisos {
    model = {
        id: {value: null, export: false},
        padre: {value: null, export: false, header: 'Permiso padre'},
        metodo: {value: null, export: false, header: 'Método', table: true},    
        controlador: {value: '', export: false, header: 'Controlador', required: false, maxLength: 'Máximo 200 caracteres', table: true, filter: true, sort: true,
            minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'controlador', filterPlaceholder: 'Buscar por controlador', matchMode: FilterMatchMode.CONTAINS},  
        accion: {value: '', export: false, header: 'Acción', required: false, maxLength: 'Máximo 200 caracteres', table: true, filter: true, sort: true,
            minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'accion', filterPlaceholder: 'Buscar por identificación', matchMode: FilterMatchMode.CONTAINS},        
        crud: {value: null, export: false, header: 'CRUD', table: true},
        estado: {value: null, export: true, table: true, header: 'Estado', required: 'Estado es requerido'},
    }
    optionLabel = 'nombre'
}