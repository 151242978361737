import { FilterMatchMode } from "primereact/api";

export class InfoCpn {

    model = {
        id: {
            value: null,
            export: false,
            header: 'Código',
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'id',
            sort: true,
            matchMode: FilterMatchMode.EQUALS,
            showFilterMatchModes: false,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        fecha: {
            value: new Date(),
            export: false,
            header: 'Fecha (día/mes/año):',
            required: 'Fecha es requerido',
            table: true,
            sort: true,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        pais: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton: {
            value: '',
            export: false,
            header: 'Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Ciudad es requerida'
        },

        compania: {
            value: '',
            export: false,
            header: 'Nombre de la compañía donde adquirió el bien:',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Companía es requerida'
        },
        proyecto: {
            value: '',
            export: false,
            header: 'Proyecto:',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Proyecto es requerido'
        },
        tipo_formulario: {
            value: null,
            export: false,
            header: 'Tipo de formulario:',
            required: 'Tipo de formulario es requerido'
        },
        tipo_solicitante: {
            value: null,
            export: false,
            header: 'Tipo de Solicitante:',
            required: 'Tipo de formulario es requerido'
        },
        tipo_identificacion: {
            value: null,
            export: false,
            header: 'Tipo de documento:',
            required: 'Tipo de formulario es requerido'
        },
        apellidos: {
            value: '',
            export: true,
            header: 'Apellidos completos:',
            required: 'Apellido es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
        },
        nombres: {
            value: '',
            export: true,
            header: 'Nombres completos:',
            required: 'Nombre es requerido',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
        },
        nombre_completo: {
            value: '',
            export: true,
            header: 'Nombre Compleo',
            table: true,
            sort: true,
            headerStyle: { width: '20%', minWidth: '20rem' }
        },
        identificacion: {
            value: '',
            export: false,
            header: 'No. doc. Identidad:',
            required: 'No. RUC / Identificación tributaria es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'identificacion',
            filterPlaceholder: 'Buscar por número de identificación',
            table: true,
            filter: false,
            sort: true
        },
        nacionalidad: {
            value: null,
            export: false,
            header: 'Nacionalidad:',
            required: 'Nacionalidad es requerido.'
        },
        fecha_nacimiento: {
            value: new Date(),
            export: false,
            header: 'Fecha de nacimiento (día/mes/año):',
            required: 'Fecha de inicio de actividad es requerido'
        },
        genero: {
            value: null,
            export: false,
            header: 'Género:',
            required: 'Género es requerido'
        },
        estado_civil: {
            value: null,
            export: false,
            header: 'Estado civil:',
            required: 'Estado civil es requerido'
        },
        empresa: { value: null, export: true, header: 'Empresa:', required: 'Empresa es requerido' },
    }

    optionLabel = 'nombre';
}
