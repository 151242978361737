import Axios from "axios";

export class AccionistaSocioService {

    getAccionistaSocio(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/accionistasocio/lista?${queryParams}`).then(res => res.data);
    }

    newAccionistaSocio(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/accionistasocio/nuevo`, data).then(res => res.data);
    }

    editarAccionistaSocio(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/accionistasocio/editar`, data).then(res => res.data);
    }

    eliminarAccionista(id, tipo, idp) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/accionistasocio/eliminar/${id}/${tipo}/${idp}`).then(res => res.data);
    }

    getAccionista(id, tipo, idp, inicial) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/accionistasocio/consulta/${id}/${tipo}/${idp}/${inicial}`).then(res => res.data);
    }
}