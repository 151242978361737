import { FilterMatchMode } from "primereact/api";

export class RolPorEmpresaGrupo {

    model = {
        id: { value: null, export: false },
        idr: { value: null, export: false },
        ideg: { value: null, export: false },
        tipo: { value: null, export: false },
        nombre: {
            header: 'Nombre', value: '', filterPlaceholder: 'Buscar por rol.',
            export: true, table: true, filter: true, matchMode: FilterMatchMode.CONTAINS
        },
        grupo: {
            header: 'Empresa/Grupo', value: '', export: true, table: true, filter: true
        },
        observacion: {
            header: 'Detalle', value: '', table: true, required: false, export: true
        },
    }
}