import { FilterMatchMode } from "primereact/api"

export class PlanAccionista {

    model = {
        id: {
            value: null, header: 'Código', export: true, table: true, sort: true, filterField: 'id', filter: true, matchMode: FilterMatchMode.EQUALS,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        tipo_analisis: {
            value: null, export: false, required: 'Campo requerido', header: 'Tipo de análisis', table: true, filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS,
            filterField: 'tipo_analisis', headerStyle: { width: '10%', minWidth: '10rem' }
        },
        orden: { value: 1, export: true, header: 'Orden', required: 'Orden es requerido', table: true, max: 1000, min: 1, minLength: 'Mínimo 1', maxLength: 'Máximo 1000',
            filterField: 'orden', filter: true, sort: true, matchMode: FilterMatchMode.EQUALS, headerStyle: { width: '10%', minWidth: '10rem' } },
        nombre: {
            value: '', export: true, header: 'Item', required: 'Item es requerido', maxLength: 'Máximo 500 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, filterField: 'nombre', filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '30%', minWidth: '20rem' }
        },
        seccion: { value: null, export: false, required: 'Sección es requerido', header: 'Sección', table: false, filter: false, sort: true },
        color: { value: null, export: false, required: false, header: 'Color', table: true, filter: false, sort: false },
        formula1: {
            value: '', export: true, header: 'Fórmula 1(C)', required: false, maxLength: 'Máximo 500 caracteres', table: true,
            minLength: 'Mínimo 3 caracteres', max: 500, min: 3, filterField: 'formula1', filter: false,
            headerStyle: { width: '20%', minWidth: '15' }
        },
        formula2: {
            value: '', export: true, header: 'Fórmula 2(D)', required: false, maxLength: 'Máximo 500 caracteres', table: true,
            minLength: 'Mínimo 3 caracteres', max: 500, min: 3, filterField: 'formula2', filter: false,
            headerStyle: { width: '30%', minWidth: '20rem' }
        },
        formula3: {
            value: '', export: true, header: 'Fórmula 3(F)', required: false, maxLength: 'Máximo 500 caracteres', table: true,
            minLength: 'Mínimo 3 caracteres', max: 500, min: 3, filterField: 'formula3', filter: false,
            headerStyle: { width: '30%', minWidth: '20rem' }
        },
        anio1: {
            value: '', export: false, header: 'Período 1(A)', required: false, maxLength: 'Máximo 500 caracteres', table: false,
            minLength: 'Mínimo 3 caracteres', max: 500, min: 3, filterField: 'anio1', filter: false, sort: true, matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '30%', minWidth: '20rem' }
        },
        anio2: {
            value: '', export: false, header: 'Período 2(B)', required: false, maxLength: 'Máximo 500 caracteres', table: false,
            minLength: 'Mínimo 3 caracteres', max: 500, min: 3, filterField: 'anio2', filter: false, sort: true, matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '30%', minWidth: '20rem' }
        },
        estado: { header: 'Estado', value: { nombre: 'Activo', cat_id: true }, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: false },
    }
    optionLabel = 'nombre'
}
