import Axios from "axios";

export class PepFichaService {

    getPep(params, op) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/${op}/lista?${queryParams}`).then(res => res.data);
    }

    newPep(data, op) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/${op}/nuevo`, data).then(res => res.data);
    }

    editarPep(data, op) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/${op}/editar`, data).then(res => res.data);
    }

    consultarPep(id, op, tipo, idp, inicio) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/${op}/consulta/${id}/${tipo}/${idp}/${inicio}`).then(res => res.data);
    }

    eliminarPep(id, op, tipo) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/${op}/eliminar/${id}/${tipo}`).then(res => res.data);
    }

    descargar(id, op) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/${op}/reporte/${id}`, {
            headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache', 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Expires': 0 },
            params: {
                t: new Date().getTime(),
            },
            responseType: 'blob'
        }).then(async res => res.data);
    }
}
