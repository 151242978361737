import { FilterMatchMode } from "primereact/api";

export class Empleado {

        model = {
                id: {
                        value: null, export: false, header: 'Código', table: true, filter: true, filterPlaceholder: 'Buscar por código', filterField: 'id', sort: true,
                        matchMode: FilterMatchMode.EQUALS, showFilterMatchModes: false, headerStyle: { width: '10%', minWidth: '10rem' }
                },
                fecha: { value: new Date(), export: false, header: 'Fecha (día/mes/año):', required: 'Fecha es requerido', table: true, sort: true, headerStyle: { width: '10%', minWidth: '10rem' } },
                ciudad: { value: null, export: true, header: 'Ciudad:', required: 'Ciudad es requerida' },
                pais: { value: null, export: true, header: 'País:', required: 'País es requerido' },
                provincia: { value: null, export: true, header: 'Provincia:', required: 'Provincia es requerido' },
                canton: { value: '', export: true, header: 'Ciudad', maxLength: 'Máximo 100 caracteres', minLength: 'Mínimo 4 caracteres', max: 100, min: 4 },
                tipo_informacion: { value: null, export: false, header: 'Tipo de declaración:', required: 'Tipo de declaración es requerido' },
                tipo_identificacion: {
                        value: null, export: false, filterPlaceholder: 'Buscar por tipo de identificación', required: 'Tipo de identificación es requerido',
                        header: 'Tipo de identificación', table: false, filter: false, sort: true
                },
                identificacion: {
                        value: '', export: false, header: 'Identificación', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres', table: true, filter: true, sort: true,
                        minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'identificacion', filterPlaceholder: 'Buscar por identificación', fielterField: 'identificacion',
                        matchMode: FilterMatchMode.CONTAINS, headerStyle: { width: '15%', minWidth: '15rem' }
                },
                nombre_completo: { value: null, export: true, header: 'Apellidos y Nombres', table: true, sort: true, headerStyle: { width: '25%', minWidth: '25rem' } },
                fecha_ingreso: { value: new Date(), export: false, header: 'FECHA DE INGRESO A LA COMPAÑÍA:', required: 'Fecha es requerido', table: false },
                cargo: {
                        value: '', export: false, header: 'CARGO QUE OCUPA:', required: 'Cargo es requerido', maxLength: 'Máximo 200 caracteres',
                        filterPlaceholder: 'cargo', minLength: 'Mínimo 4 caracteres', max: 200, min: 4, filterField: 'cargo'
                },
                nombres: {
                        value: '', export: true, header: 'Nombres completos', required: 'Nombres es requerido', maxLength: 'Máximo 300 caracteres',
                        filterPlaceholder: 'nombres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'nombres'
                },
                apellidos: {
                        value: '', export: true, header: 'Apellidos completos', required: 'Apellidos es requerido', maxLength: 'Máximo 300 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'apellidos'
                },
                nacionalidad: { value: null, export: false, header: 'Nacionalidad', required: 'Nacionalidad es requerido', table: true, headerStyle: { width: '10%', minWidth: '10rem' } },
                genero: { value: null, export: false, header: 'Género', required: 'Género es requerido' },
                fecha_nacimiento: { value: new Date(), export: false, header: 'Fecha de nacimiento:', required: 'Fecha es requerido', table: true, headerStyle: { width: '10%', minWidth: '10rem' } },
                estado_formulario: { value: null, export: true, header: 'Estado', table: true, sort: true, headerStyle: { width: '10%', minWidth: '10rem' } },
                calles: {
                        value: '', export: false, header: 'Calle Principal No. y Calle Secundaria:', required: 'Calles son requeridos', maxLength: 'Máximo 500 caracteres', table: false,
                        minLength: 'Mínimo 4 caracteres', max: 500, min: 4
                },
                ciudad_personal: { value: null, export: true, header: 'Ciudad:', required: 'Ciudad es requerida' },
                pais_personal: { value: null, export: true, header: 'País:', required: 'País es requerido' },
                provincia_personal: { value: null, export: true, header: 'Provincia:', required: 'Provincia es requerido' },
                canton_personal: { value: '', export: true, header: 'Ciudad', maxLength: 'Máximo 100 caracteres', minLength: 'Mínimo 4 caracteres', max: 100, min: 4 },
                tiempo_residencia: {
                        value: '', export: true, header: 'Tiempo que reside en el Ecuador (Si es extranjero):', maxLength: 'Máximo 100 caracteres', minLength: 'Mínimo 4 caracteres',
                        max: 100, min: 4
                },
                correo_personal: { value: '', export: false, header: 'Correo electrónico personal:', maxLength: 'Máximo 100 caracteres', minLength: 'Mínimo 4 caracteres', max: 100, min: 4 },
                celular: {
                        value: '', export: false, header: 'No. Celular:', required: 'No. Celular es requerido', maxLength: 'Máximo 100 caracteres', table: false,
                        minLength: 'Mínimo 4 caracteres', max: 500, min: 4
                },
                telefono: {
                        value: '', export: false, header: 'No. de teléfono del domicilio:', required: 'Teléfono es requerido', maxLength: 'Máximo 100 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 500, min: 4
                },
                estado_civil: { value: null, export: false, header: 'Estado Civil', required: 'Estado Civil es requerido' },
                nombres_conyugue: {
                        value: '', export: true, header: 'Nombres completos', required: 'Nombres es requerido', maxLength: 'Máximo 300 caracteres',
                        filterPlaceholder: 'nombres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'nombres'
                },
                apellidos_conyugue: {
                        value: '', export: true, header: 'Apellidos completos', required: 'Apellidos es requerido', maxLength: 'Máximo 300 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'apellidos'
                },
                nacionalidad_conyugue: { value: null, export: false, header: 'Nacionalidad', required: 'Nacionalidad es requerido' },
                tipo_identificacion_conyugue: {
                        value: null, export: false, filterPlaceholder: 'Buscar por tipo de identificación', required: 'Tipo de identificación es requerido',
                        header: 'Tipo de identificación'
                },
                identificacion_conyugue: {
                        value: '', export: false, header: 'Identificación', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 50, min: 4
                },
                fecha_nacimiento_conyugue: { value: new Date(), export: false, header: 'Fecha de nacimiento:', required: 'Fecha es requerido', table: false },
                lugar_trabajo_conyugue: {
                        value: '', export: false, header: 'Nombre del lugar de trabajo:', required: 'Lugar de trabajo es requerido', maxLength: 'Máximo 30 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 300, min: 4
                },
                cargo_conyugue: {
                        value: '', export: false, header: 'Cargo:', required: 'Cargo es requerido', maxLength: 'Máximo 200 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 200, min: 4
                },
                actividad_economica: { value: null, export: false, header: 'Actividad Económica:', required: 'Actividad Económica es requerido.' },
                ingreso_mensual_dependencia_empleado: { value: 0, export: false, header: 'Ingreso Mensual en Relación de dependencia (Empleado) USD:', required: 'Ingreso mensual es requerido.' },
                otros_ingreso_empleado: { value: 0, export: false, header: 'Otros Ingresos (Empleado) USD:', required: 'Otros ingresos es requerido.' },
                fuentes_ingresos_empleado: {
                        value: '', export: false, header: 'Especifique la fuente de los otros ingresos del Empleado:', required: false, maxLength: 'Máximo 200 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 200, min: 4
                },
                otra_actividad_economica: { value: null, export: false, header: 'Otras actividades económicas (Empleado):', required: false },
                ingreso_mensual_conyugue: { value: 0, export: false, header: 'Ingreso Mensual en Relación de dependencia (Cónyuge/Conviviente) USD:', required: 'Ingreso mensual es requerido.' },
                otros_ingreso_conyugue: { value: 0, export: false, header: 'Otros Ingresos (Cónyuge/Conviviente) USD:', required: 'Otros ingresos es requerido.' },
                fuentes_ingresos_conyugue: {
                        value: '', export: false, header: 'Especifique la fuente de los otros ingresos del Cónyuge/Conviviente:', required: 'Fuente es requerido', maxLength: 'Máximo 200 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 200, min: 4
                },
                otra_actividad_economica_conyugue: { value: null, export: false, header: 'Otras actividades económicas (Cónyuge/Conviviente):', required: 'Actividad Económica es requerido.' },
                gasto_individual: { value: 0, export: false, header: 'Gastos Individuales (en el caso de ser soltero) / Familiares Mensuales USD:', required: 'Gasto individual es requerido.' },
                ACTV_INMUEBLES: { value: 0, export: false, header: 'Bienes inmuebles (casa, departamento, etc.) USD:', required: 'Valor es requerido.' },
                ACTV_MUEBLES: { value: 0, export: false, header: 'Bienes muebles (vehículo, enceres, etc.) USD:', required: 'Valor es requerido.' },
                ACTV_EFECTIVO: { value: 0, export: false, header: 'Dinero (en efectivo, en bancos u otros) USD:', required: 'Valor es requerido.' },
                ACTV_INVERSIONES: { value: 0, export: false, header: 'Inversiones USD:', required: 'Valor es requerido.' },
                ACTV_CREDITOS: { value: 0, export: false, header: 'Créditos por cobrar USD:', required: 'Valor es requerido.' },
                ACTV_OTROS: { value: 0, export: false, header: 'Otros activos USD:', required: 'Valor es requerido.' },
                PASV_BANCARIOS: { value: 0, export: false, header: 'Préstamos bancarios (consumo):', required: 'Valor es requerido.' },
                PASV_HIPOTECARIOS: { value: 0, export: false, header: 'Préstamos hipotecarios:', required: 'Valor es requerido.' },
                PASV_QUIROGRAFARIO: { value: 0, export: false, header: 'Préstamo quirografario:', required: 'Valor es requerido.' },
                PASV_CREDITO: { value: 0, export: false, header: 'Tarjeta de crédito:', required: 'Valor es requerido.' },
                PASV_OTRAS_CTAS: { value: 0, export: false, header: 'Otras Cuentas / Documentos por pagar USD:', required: 'Valor es requerido.' },
                PASV_OTROS_PASV: { value: 0, export: false, header: 'Otros pasivos USD:', required: 'Valor es requerido.' },
                activos: { value: 0, export: false, header: 'Total Activos USD:', required: 'Valor de activo es requerido.' },
                pasivos: { value: 0, export: false, header: 'Pasivos USD:', required: 'Valor de pasivo es requerido.' },
                patrimonio: { value: 0, export: false, header: 'Patrimonio (Activos - Pasivos) USD:', required: 'Valor de patrimonio es requerido.' },
                es_pep: { value: null, export: false, required: 'Por favor seleccione', header: 'Es usted un PEP:' },
                relacion_pep: { value: null, export: false, required: 'Por favor seleccione', header: 'Está relacionado con un PEP:' },
                vinculo_pep: {
                        value: '', export: false, header: 'Relación o vínculo:', required: 'Relacion o vínculo es requerido', maxLength: 'Máximo 300 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 300, min: 4
                },
                nombre_pep: {
                        value: '', export: false, header: 'Nombre del PEP:', required: 'Nombre es requerido', maxLength: 'Máximo 300 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 300, min: 4
                },
                cargo_pep: {
                        value: '', export: false, header: 'Cargo:', required: 'Cargo es requerido', maxLength: 'Máximo 300 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 300, min: 4
                },
                institucion_pep: {
                        value: '', export: false, header: 'Institución donde trabaja o trabajó:', required: 'Cargo es requerido', maxLength: 'Máximo 300 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 300, min: 4
                },
                fecha_designacion_pep: { value: new Date(), export: false, header: 'Fecha del nombramiento de la designación (día/mes/año):', required: 'Fecha es requerido', table: false },
                fecha_dejo_pep: { value: null, export: false, header: 'Fecha en que dejó de ser PEP, de no estar en funciones (día/mes/año):', required: false, table: false },
                observacion: {
                        value: '', export: false, header: 'Detalle:', required: 'Observación es requerido', maxLength: 'Máximo 2000 caracteres',
                        minLength: 'Mínimo 4 caracteres', max: 2000, min: 4, filterField: 'detalle'
                },
        }

        optionLabel = 'nombre';
        pattern = { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Correo no válido. E.g. example@email.com' };
        strPep = <><p>PEP: Son todas las personas naturales, nacionales o extranjeras, que desempeñan o han desempeñado funciones o cargos públicos destacados en el Ecuador o en el extranjero,
                o funciones prominentes en una organización internacional, conforme a los lineamientos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y la Superintendencia de
                Compañías, Valores y Seguros.</p>
                <p>Familiares y Personas Relacionadas de las Personas Expuestas Políticamente: Se consideran como personas expuestas políticamente (PEP) a los familiares de las Personas Expuestas
                        Políticamente (PEP), sean estos los cónyuges o personas unidas bajo el régimen de unión de hecho, familiares hasta el segundo grado de consanguinidad o primero de afinidad;
                        y el o los colaborador (es) directo (s) esto es la (s) persona (s) natural (es) con las cuales una Persona Políticamente Expuesta (PEP), se encuentre asociada o vinculada
                        societariamente, o mantenga otro tipo de relaciones empresariales, comerciales o labores estrechas.</p>
                <p>Los funcionarios públicos de menor grado que no estén comprendidos dentro del nivel jerárquico superior, se excluyen de la definición de PEP; con excepción de aquellos que ejerzan
                        la representación de un PEP o cumplan funciones en su nombre, para lo cual se realizará la correspondiente evaluación de riesgo</p></>;

        strDeclaracion = <>Declaro bajo juramento que: <br /><br />
                <p>- La información registrada en el presente documento y la documentación que se acompaña es fidedigna y asumo cualquier responsabilidad por los daños que pudiere sufrir la
                        Compañía a la que declaro esta información por la eventual falsedad o imprecisión de esta; y,<br />
                        - <b>La información económica y patrimonial registrada cuenta con todos los sustentos que corresponden.</b></p>
                <p>Finalmente, autorizo expresamente a  realizar el análisis y las verificaciones que se consideren pertinentes a la información suministrada y aquella que obtenga la compañía a
                        la que declaro; y, en el caso de detectarse alguna inusualidad, informar de manera inmediata y documentada a las autoridades competentes.</p></>;
        titulo = 'Instrucciones';
        mensaje = <><b>Instrucciones para completar el proceso de envío de información:</b><ol style={{ textAlign: 'justify' }}>
                <li>En cuanto se registre la información de las secciones de la 1 a la 5, favor guardar la información.</li>
                <li>Cuando la información se guarde, dar click en reporte y el sistema generará el formulario de su información en versión PDF, el cual se deberá imprimir y firmar físicamente o electrónicamente.</li>
                <li>En el botón anexos se deberá cargar el formulario firmado y los anexos requeridos.</li>
                <li>Cuando el formulario y sus anexos sean cargados al sistema favor dar click en enviar.</li></ol></>
}