import Axios from "axios";

export class RolService {

    getRoles(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/roles?${queryParams}`).then(res => res.data);
    }

    newRol(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/admin/nuevo-rol`, data).then(res => res.data);
    }

    editRol(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/editar-rol`, data).then(res => res.data);
    }

    eliminar(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/admin/eliminar-rol/${id}`).then(res => res.data);
    }

    consultarRol(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/rol/consulta/${id}`).then(res => res.data);
    }

    getMenusRol(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/rol/menus-rol?${queryParams}`).then(res => res.data);
    }

    putMenu(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/rol/menu-asignar`, data).then(res => res);
    }

    rolNombre(rol) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/rol/buscar/${rol}`).then(res => res.data);
    }
}
