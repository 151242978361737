import Axios from "axios";

export class UsuarioRucService {

    getCarga(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/consulta/carga/lista?${queryParams}`).then(res => res.data);
    }

    newCarga(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/consulta/carga/nuevo`, data).then(res => res.data);
    }

    editarCarga(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/consulta/carga/editar`, data).then(res => res.data);
    }

    consultarCarga(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/consulta/carga-ver/${id}/${iniciar}`).then(res => res.data);
    }

    eliminarCarga(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/consulta/carga/eliminar/${id}`).then(res => res.data);
    }
}