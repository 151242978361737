export const useToken = () => {

  const getAllToken = () => {
    const tokenString = localStorage.getItem('token');

    return JSON.parse(tokenString);
  };

  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    return userToken?.token;
  }

  const getRol = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    return userToken?.rol ?? '';
  };

  const saveIds = (ids) => {
    localStorage.setItem('ids', JSON.stringify(ids));
  };

  const saveToken = (userToken) => {
    localStorage.setItem('token', JSON.stringify(userToken));
  };

  const saveValor = (valor) => {
    localStorage.setItem('valor', JSON.stringify(valor));
  };

  const getValor = () => {
    const valor = localStorage.getItem('valor');
    return valor;
  }

  const getIds = () => {
    const idsString = localStorage.getItem('ids');
    return idsString;
  }

  const getPerss = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.perss;
  }

  const getNombre = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.name;
  }

  const getNombreEmpresa = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.nombreEmpresa ?? '';
  }

  const getNumRoles = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.numRoles ?? 0;
  }

  const getNumRolesAd = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.numRolesAd ?? 0;
  }

  const getTipo = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.rol?.tipo_rol ?? '';
  }

  const getMenus = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.menus;
  }

  const closeSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('ids');
    localStorage.removeItem('valor');
  }

  return {
    getAllToken,
    getToken,
    saveToken,
    getNombre,
    getMenus,
    closeSession,
    saveIds,
    getIds,
    saveValor,
    getValor,
    getPerss,
    getTipo,
    getNumRoles,
    getNumRolesAd,
    getNombreEmpresa ,
    getRol
  }
}
