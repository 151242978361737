import { FilterMatchMode } from "primereact/api"

export class PlanCatalogo {

    model = {
        id: {
            value: null, header: 'Código', export: true, table: true, sort: true, filterField: 'id', filter: true, matchMode: FilterMatchMode.EQUALS,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        nombre: {
            value: '', export: true, header: 'Nombre', required: 'Nombre es requerido', maxLength: 'Máximo 500 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, filterField: 'nombre', filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '30%', minWidth: '20rem' }
        },
        codigo: {
            value: '', export: true, header: 'Código', required: false, maxLength: 'Máximo 150 caracteres', table: false,
            minLength: 'Mínimo 4 caracteres', max: 150, min: 4, filterField: 'nombre', filter: false, sort: false,
        },
        catalogo: { header: 'Catálogo', value: null, required: 'Catálogo es requerido', optionLabel: 'nombre', export: false, table: false },
        valor1: {
            header: 'Valor 1', value: 0, required: false, export: true, table: true, min: 0, max: 100,
            minLength: 'Mínimo 0', maxLength: 'Máximo 100', headerStyle: { width: '10%', minWidth: '10rem' }
        },
        valor2: {
            header: 'Valor 2', value: 0, required: false, export: true, table: true, min: 0, max: 100,
            minLength: 'Mínimo 0', maxLength: 'Máximo 100', headerStyle: { width: '10%', minWidth: '10rem' }
        },
        valor3: {
            header: 'Valor 3', value: 0, required: false, export: true, table: false, min: 0, max: 100,
            minLength: 'Mínimo 0', maxLength: 'Máximo 100', headerStyle: { width: '10%', minWidth: '10rem' }
        },
        valor4: {
            header: 'Valor 4', value: 0, required: false, export: true, table: false, min: 0, max: 100,
            minLength: 'Mínimo 0', maxLength: 'Máximo 100', headerStyle: { width: '10%', minWidth: '10rem' }
        },
        valor5: {
            header: 'Valor 5', value: 0, required: false, export: true, table: false, min: 0, max: 100,
            minLength: 'Mínimo 0', maxLength: 'Máximo 100', headerStyle: { width: '10%', minWidth: '10rem' }
        },
        estado: { header: 'Estado', value: { nombre: 'Activo', cat_id: true }, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true },
    }
    optionLabel = 'nombre';
    valores = [
        {nombre: 'Valor 1', cat_id: 'valor1'},
        {nombre: 'Valor 2', cat_id: 'valor2'},
        {nombre: 'Valor 3', cat_id: 'valor3'},
        {nombre: 'Valor 4', cat_id: 'valor4'},
        {nombre: 'Valor 5', cat_id: 'valor5'},
    ];
}
