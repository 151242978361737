export class DeclaranteBvg {

    model = {
        id: { value: null, export: false },
        fecha: {
            value: new Date(),
            header: 'Fecha:',
            required: 'Fecha es requerido',
        },
        pais: {
            value: null,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia: {
            value: null,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad: {
            value: null,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton: {
            value: '',
            header: 'Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Ciudad es requerida'
        },
        tipo_declaracion: {
            value: null,
            header: 'Tipo de proceso:',
            required: 'Tipo de proceso es requerido'
        },
        nombre_completo: {
            value: '',
            header: 'Nombres completos',
            required: 'Nombres es requerido',
            maxLength: 'Máximo 500 caracteres',
            filterPlaceholder: 'nombres',
            minLength: 'Mínimo 3 caracteres',
            max: 500,
            min: 3,
        },
        profesion: {
            value: '',
            header: 'Profesión u Ocupación:',
            required: 'Apellidos es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        nacionalidad: {
            value: null,
            header: 'Nacionalidad',
            required: 'Nacionalidad es requerido'
        },
        tipo_identificacion: {
            value: null,
            required: 'Tipo de identificación es requerido',
            header: 'Tipo de identificación',
        },
        identificacion: {
            value: '',
            header: 'Identificación',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
        },
        pais_residencia: {
            value: null,
            header: 'País de Residencia',
            required: 'País de Residencia es requerido'
        },
        estado_civil: {
            value: null,
            header: 'Estado Civil',
            required: 'Estado Civil es requerido'
        },
        lugar_nacimiento: {
            value: '',
            header: 'Lugar de Nacimiento:',
            required: 'Campo es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        fecha_nacimiento: {
            value: new Date(),
            header: 'Fecha de nacimiento:',
            required: 'Fecha es requerido'
        },
        direccion: {
            value: '',
            header: 'Dirección Residencia: (Urbanización/barrio, avenida, calle, manzana, bloque, solar, villa)',
            required: 'Dirección es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        telefono_domicilio: {
            value: '',
            header: 'Teléfono/celular domicilio',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        telefono_personal: {
            value: '',
            header: 'Teléfono/celular personal',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        correo_electronico: {
            value: '',
            header: 'Correo electrónico:',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        estudia: {
            value: null,
            header: 'Actualmente Estudia?',
            required: 'Seleccione una opción',
        },
        horario: {
            value: '',
            header: 'Horario:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        carrera: {
            value: '',
            header: 'Carrera:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        nivel_cursado: {
            value: '',
            header: 'Nivel cursado:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        familiar_vinculado: {
            value: null,
            header: '¿Su cónyuge o conviviente, familiares cercanos o Ud., se encuentran vinculados con funcionarios o colaboradores del Mercado de Valores (MV) o Instituciones Públicas autorizadas en el sector MV?',
            required: 'Seleccione una opción',
        },
        relacion_asociacion: {
            value: null,
            header: '¿Mantiene relación o se encuentra asociado de cualquier otra forma con funcionarios o colaboradores del Mercado de Valores (MV) o Instituciones Públicas autorizadas en el sector MV?',
            required: 'Seleccione una opción',
        },
        si_accion: {
            value: null,
            header: '¿Usted, su cónyuge o conviviente, o familiares cercanos, tienen acciones o participaciones en entidades del sector MV, Sector Societario, Financiero u Otros?',
            required: 'Seleccione una opción',
        },
    }

    optionLabel = 'nombre';
}
