import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Generico } from '../../../components/Generico';
import { FuncionarioService } from '../../../service/ficha/FuncionarioService';
import { Laboral } from '../../../models/fun/Laboral';
import { FuncionarioPep } from './FuncionarioPep';

export const InformacionLaboral = ({ generic, toast, id, permss, catalogos, handlePrev, handleNext, urlsPep }) => {

    const omodel = new Laboral();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues, shouldTrim: true });
    const funSrv = new FuncionarioService();
    const formRef = useRef(null);
    const { inDrop, inText, inCalendar } = InputComponent({ omodel, generic, errors, control, permss, id: getValues('id') });
    const { stringToDate } = Generico();
    const [pep, setPep] = useState(false);

    useEffect(() => {
        editFicha();
    }, [id]);

    const onSubmit = (data) => {
        data.id = id;
        data.seccion = "seccion3";
        data.inicio_actividad = data.inicio_actividad.toISOString().split('T')[0];
        funSrv.editar(data, 'funcionario').then(res => {
            handleNext();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const editFicha = () => {
        funSrv.consultar(id, 'funcionario', 'seccion3').then(res => {
            const _cat = catalogos;
            const _dec = res.data.fun;
            if (!_dec) {
                reset(defaultValues);
                return;
            }
            const lista = {
                laboral: _cat.relacion_laboral,
                relacion_pep: generic.sino
            };
            setPep(_dec?.relacion_pep);
            Object.keys(lista).forEach(function (key) {
                if (_dec[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _dec[key]) {
                            _dec[key] = element;
                        }
                    });
                }
            });
            if (_dec.inicio_actividad) _dec.inicio_actividad = stringToDate(_dec.inicio_actividad);

            reset(generic.renewValues(_dec, omodel.model));
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const siguiente = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(
                new Event('submit', { bubbles: true, cancelable: true })
            );
        }
    };

    const onChangePep = (e) => {
        setPep(e.value?.cat_id);
    }

    return (
        <>
            <Panel header="3.- Información Laboral:" className="panel-ficha">

                <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="formgrid grid">
                        {inDrop('laboral', catalogos?.relacion_laboral, { lab: omodel.optionLabel })}
                        {inText('cargo')}
                        {inCalendar('inicio_actividad')}
                        {inText('area')}

                        {inText('departamento')}
                        {inText('ciudad_laboral')}
                        {inText('forma_pago')}
                        {inText('banco')}

                        {inText('banco_cuenta')}
                        {inDrop('relacion_pep', generic.sino, { lab: omodel.optionLabel, chn: onChangePep })}
                    </div>
                </form>
                {pep &&
                    <>
                        <FuncionarioPep id={id} toast={toast} permss={permss} opcion="funcionario-pep" urlsPep={urlsPep} lista={false} />
                        <br />
                    </>
                }

                {omodel.nota}
            </Panel>
            <br />

            <div className="formgrid grid">
                <div className="field col">
                    <Button label="Anterior" icon="pi pi-chevron-left" type="button" onClick={e => handlePrev()} />&nbsp;
                    <Button label="Siguiente" type="button" icon="pi pi-chevron-right" onClick={e => siguiente()} />
                </div>
            </div>
        </>
    )
}
