import { useForm } from "react-hook-form";
import { AdCatalogo } from "../models/consulta/AdCatalogo";
import { GenericComponent } from "./GenericComponent";
import { useEffect, useRef, useState } from "react";
import { InputComponent } from "./InputComponent";
import { AdCatalogoService } from "../service/admin/AdCatalogoService";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";

export const CatalogoCom = ({ opcion, permss }) => {
    const generic = new GenericComponent();
    const omodel = new AdCatalogo();
    const catalogSrv = new AdCatalogoService();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registros, setRegistros] = useState(null);
    const [dialogCatalogo, setDialogCatalogo] = useState(false);
    const toast = useRef(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText } = InputComponent({ registers: registros, omodel, generic, errors, control, id: getValues('id'), permss });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        let _lazyParams = lazyParams;
        loadLazyTimeout = setTimeout(() => {
            catalogSrv.getLista({ lazyEvent: JSON.stringify(_lazyParams) }, opcion).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
            });
        });
    }

    const openNew = () => {
        setDialogCatalogo(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            catalogSrv.newCatalogo(data, opcion).then(res => {
                setDialogCatalogo(false);
                loadLazyData();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            catalogSrv.editarCatalogo(data, opcion).then(res => {
                setDialogCatalogo(false);
                loadLazyData();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editar = (data) => {
        catalogSrv.getCatalogo(data.id, opcion).then(res => {
            let _catalogo = res.data;
            const lista = { estado: generic.estadosNum };

            Object.keys(lista).forEach(function (key) {
                if (_catalogo[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _catalogo[key]) {
                            _catalogo[key] = element;
                        }
                    });
                }
            });
            reset(_catalogo);
            setDialogCatalogo(true);
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarCatalogo(data)
        });
    }

    const eliminarCatalogo = (data) => {
        catalogSrv.eliminar(data.id, opcion).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    return (
        <>
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)} ></Toolbar>
                {table({ action: generic.actionEditTemplate, methEdit: editar, perm: permss, methDelete: confirmDelete })}
            </Card>

            <Dialog visible={dialogCatalogo} header={getValues('id') == null ? "Nuevo catálogo" : "Editar catálogo"} modal className="p-fluid modal-ssize" onHide={e => setDialogCatalogo(false)}
                style={{ maxWidth: '400px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre', {}, 12)}
                        {inDrop('estado', generic.estadosNum, { lab: omodel.optionLabel }, 12)}
                    </div>

                    {generic.buttonsForms(setDialogCatalogo)}
                </form>
            </Dialog>
        </>
    )
}
