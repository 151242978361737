export class DeclaracionCpn {
    model = {
        observacion: {
            value: '',
            export: true,
            header: 'Observacion:',
            required: 'Observacion es requerido',
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4
        },
    }
    strDeclara = <><p>Declaro bajo juramento el origen lícito de mis recursos por cuanto los valores entregados son generados de mis actividades
        económicas legales y no provienen del lavado de activos, financiamiento del terrorismo y otros delitos; y, que la información registrada en el presente
        documento y la documentación que se acompaña es fidedigna y asumo cualquier responsabilidad por los daños que pudiere sufrir la Compañía a la que declaro
        esta información por la eventual falsedad o imprecisión de la misma. Así como, autorizo expresamente a  realizar el análisis y las verificaciones que se
        consideren pertinentes a la información suministrada y aquella que obtenga la Compañía a la que declaro y en el caso de detectarse alguna inusualidad,
        informar de manera inmediata y documentada a las autoridades competentes.</p></>;
    strAnexo = <><div style={{ textAlign: 'center', width: '100%', fontWeight: 'bold', textJustify: 'inter-word' }}>CLIENTE QUE TRABAJA EN RELACIÓN DE DEPENDENCIA</div><ul>
        <li>Copia legible de cédula en caso de ecuatorianos o extranjeros residentes. Pasaporte en caso de extranjeros no residentes.</li><li>Copia legible de cédula del/la cónyuge ó
        conviviente. Pasaporte en caso de extranjeros no residentes.</li><li>Copia de los tres últimos roles del lugar donde labora  o Formulario 107 del año inmediato anterior.</li>
        <li>Certificado bancario de cualquiera de los últimos 3 meses.</li></ul></>;
    strDependencia = <><div style={{ textAlign: 'center', width: '100%', fontWeight: 'bold', textJustify: 'inter-word' }}>CLIENTE QUE TRABAJA SIN RELACIÓN DE DEPENDENCIA O INDEPENDIENTE</div>
        <ul><li>Copia legible de cédula en caso de ecuatorianos o extranjeros residentes. Pasaporte en caso de extranjeros no residentes.</li>
        <li>Copia legible de cédula del/la cónyuge ó conviviente. Pasaporte en caso de extranjeros no residentes.</li><li>Copia de R.U.C.</li>
        <li>Declaración del Impuesto a la Renta del año inmediato anterior.</li><li>Declaraciones de impuestos de IVA de los 3 últimos meses</li><li>Certificado bancario de cualquiera de los últimos 3 meses.</li></ul></>;
    strBVG = <span><p>Conocedor (a) de las disposiciones de la Ley Orgánica de Prevención, Detección y Erradicación del Delito de Lavado de Activos y del Financiamiento de Delitos y la Ley Orgánica de Protección de Datos Personales, autorizo y doy
        mi consentimiento libre, específico, informado e inequívoco, expresamente a BOLSA DE VALORES DE GUAYAQUIL S.A. BVG, a obtener, tratar y custodiar mis datos personales, sensibles durante el tiempo establecido en las
        referidas leyes, conforme los principios de lealtad, transparencia, reserva y confidencialidad que las normativas citadas establecen y las que constan en la Política de Privacidad de la BOLSA DE VALORES DE GUAYAQUIL S.A.
        BVG, información que debe utilizarse únicamente para realizar los análisis y verificaciones que se considere necesarios para mi vinculación con la BOLSA DE VALORES DE GUAYAQUIL S.A. BVG; así como, informar a las
        autoridades competentes en caso de llegar a determinar la existencia de actividades, y/o transacciones Inusuales o Injustificadas. En virtud de lo antes autorizado, renuncio a instaurar por este motivo cualquier tipo de acción
        civil, penal o administrativa en contra de BOLSA DE VALORES DE GUAYAQUIL S.A. BVG o sus funcionarios y empleados.</p>
        <p>Conocedor (a) de las penas de perjurio, declaro bajo juramento que la información consignada en el presente documento y el origen de mis recursos son completamente lícitos, no provienen ni están relacionados con ninguna
        actividad u operación ilícita, o vinculados con negocios de lavado de dinero producto de narcotráfico de sustancias estupefacientes y psicotrópicas y que el destino de los fondos son y serán completamente lícitos, eximo a
        BOLSA DE VALORES DE GUAYAQUIL S.A. BVG, de toda responsabilidad, inclusive respecto a terceros, si esta declaración fuese falsa o errónea.</p>
        <p>En caso de que la información declarada pueda variar en el futuro, me comprometo a mantener informada y actualizada a BOLSA DE VALORES DE GUAYAQUIL S.A. BVG, proporcionándole la documentación e información que
        sea necesaria durante el tiempo que dure mi relación con esta.</p></span>
    decBVG = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px', verticalAlign: 'bottom', fontWeight: 'bold' }}>EL FUNCIONARIO RESPONSABLE DEBERÁ INFORMAR AL DECLARANTE, QUE ESTE FORMULARIO ESTA BASADO EN LA POLÍTICA "CONOZCA A SU CLIENTE Y PROVEEDOR" DE LA NORMATIVA DE PREVENCIÓN, DETECCIÓN Y ERRADICACIÓN DEL DELITO DE
                    LAVADO DE ACTIVOS Y DEL FINANCIAMIENTO DEL TERRORISMO Y OTROS DELITOS VIGENTE.</span>
    bgvAnexo = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '11px', verticalAlign: 'bottom' }}><b>DOCUMENTACIÓN A ANEXAR:</b><br /><ul><li>Cédula de Identidad/Ciudadanía o Pasaporte legibles del Declarante.</li><li>Cédula de Identidad/Ciudadanía o Pasaporte legibles del Cónyuge.</li>
        <li>Impuesto a la renta del año inmediato anterior o constancia de la información publica del SRI.</li><li>Certificado Bancario con promedio de cifras, o estados de cuenta mínimo con movimientos a la fecha.</li>
        <li>Registro Único de Contribuyentes, si aplica.</li><li>Declaración de Origen y Destino Lícito de Recursos.</li><li>Recibo de cualquiera de los servicios básicos, no mayor a un mes de la dirección declarada como domicilio.</li></ul></span>
    bvgRespaldo = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '11px', verticalAlign: 'bottom' }}><b>RESPALDOS DEBIDA DILIGENCIA, SEGÚN APLIQUE A LA POLÍTICA:</b><br /><ul>        
        <li>Registro Civil o confirmación de identificación otros medios.</li><li>Servicio de Rentas Internas (Ruc e Impuesto a renta causado)</li><li>Función Judicial (procesos legales)</li>
        <li>Ministerio del Interior (Certificado de Antecedentes Penales) o verificación NUI.</li><li>Contraloría General del Estado (Declaración Juramentada sector público-PEP´s)</li>
        <li>Listas UAFE de Sentenciados y Homónimos</li><li>Lista de Personas Expuestas Políticamente PEP´S</li><li>Lista consolidada ONU</li>
        <li>Superintendencias de Bancos, SCVS, SEPS, según aplique.</li><li>Otras fuentes, Listas WC1, Interpol, Fiscalía, Registro de la Propiedad, etc.</li></ul></span>
} 
