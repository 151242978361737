import { Controller, useForm } from "react-hook-form";
import AuthService from "../../service/AuthService";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { Password } from 'primereact/password';
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { classNames } from "primereact/utils";
import { GenericComponent } from "../../components/GenericComponent";
import { Divider } from "primereact/divider";

export const Cambiar = () => {
    const defaultValues = {
        actual: '',
        nueva: '',
    }
    const { control, formState: { errors }, handleSubmit, setError, reset } = useForm({ defaultValues });
    const generic = new GenericComponent();
    const toast = useRef(null);
    const authService = new AuthService();

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error"><br />{errors[name].message}</small>
    }

    const onSubmit = (data) => {
        authService.cambiar(data).then(res => {
            generic.funcSuccess(res.data, toast);
            reset(defaultValues);
        }).catch(error => {
            if(error.response.data.data){
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.response.data.data, life: 3000 });                
            }
            else {
                generic.funcError(error, toast, setError);
            }            
        });
    }

    const passwordHeader = <h6>Seleccione una contraseña</h6>;
    const passwordFooter = (
        <>
            <Divider />
            <p className="mt-2">Sugerencias</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>Por lo menos un caracter en minúscula.</li>
                <li>Por lo menos un caracter en mayúscula.</li>
                <li>Por lo menos un número.</li>
                <li>Al menos uno de los caracteres: @$!%*#</li>
                <li>Mínimo 10 caracteres.</li>
            </ul>
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Card header="Cambio" style={{ width: '100%', margin: 'auto' }} className="card-ficha">
                <Panel header="Cambiar Contraseña" style={{ width: '25rem', margin: 'auto' }} className="panel-ficha" >
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <br />
                        <div className="field">
                            <span className="p-float-label">
                                <Controller name="actual" control={control} rules={{ required: 'Contraseña actual es requerido.' }} render={({ field, fieldState }) => (
                                    <Password id={field.name} value={field.value} autoFocus onChange={(e) => { field.onChange(e.target.value) }} 
                                        toggleMask className={classNames({ 'p-invalid': fieldState.isDirty })} style={{ width: '100%' }} />
                                )} />
                                <label htmlFor="actual" className={classNames({ 'p-error': errors.password })}>Contraseña actual*</label>
                            </span>
                            {getFormErrorMessage('actual')}
                        </div>
                        <br />

                        <div className="field">
                            <span className="p-float-label">
                                <Controller name="nueva" control={control} 
                                    rules={{ required: 'Nueva contraseña es requerido.', maxLength: { value: 100, message: 'Máximo 100 caracteres.' }, minLength: { value: 10, message: 'Mínimo 10 caracteres.' }, 
                                    pattern: { value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{10,}$/, message: 'La clave no cumple con lo mínimo requerido' }}} 
                                    render={({ field, fieldState }) => (
                                    <Password id={field.name} value={field.value} autoFocus onChange={(e) => { field.onChange(e.target.value) }} className={classNames({ 'p-invalid': fieldState.isDirty })}
                                        header={passwordHeader} footer={passwordFooter} toggleMask />
                                )} />
                                <label htmlFor="nueva" className={classNames({ 'p-error': errors.password })}>Nueva contraseña*</label>
                            </span>
                            {getFormErrorMessage('nueva')}
                        </div>

                        <Button label="Actualizar" style={{ width: '100%' }} type="submit" icon="pi pi-check-circle" className="p-button-warning" />
                    </form>
                </Panel>
            </Card>
        </>
    )
}
