import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { useState } from 'react'
import { useForm } from 'react-hook-form';
import { GenericComponent } from '../../components/GenericComponent';
import { InputComponent } from '../../components/InputComponent';
import { AccionistaSocio } from '../../models/AccionistaSocio';
import { AccionistaSocioService } from '../../service/AccionistaSocioService';
import { CatalogoService } from '../../service/CatalogoService';
import { Divider } from 'primereact/divider';
import { confirmDialog } from 'primereact/confirmdialog';

export const AccionistaSocios = ({ toast, tipo, id, permss }) => {

    const generic = new GenericComponent();
    const omodel = new AccionistaSocio();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [accionistaSocios, setAccionistaSocios] = useState([]);
    const accionistaSocioSrv = new AccionistaSocioService();
    const catalogoSrv = new CatalogoService();
    const [dialogAccionistaSocio, setDialogAccionistaSocio] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [esRuc, setEsRuc] = useState(false);
    const [esCedula, setEsCedula] = useState(false);
    const [ciius, setCiius] = useState([]);
    const [selectedCiiu, setSelectedCiiu] = useState(null);
    const [padreId, setPadreId] = useState(null);

    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inAutocomplete } =
        InputComponent({ registers: accionistaSocios, omodel, generic, errors, control, permss, id: getValues('id') });

    const loadLazyAccionistaSocio = (idj) => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.idj = idj == null ? id : idj;
            _lazyParams.tipo = tipo;
            accionistaSocioSrv.getAccionistaSocio({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setAccionistaSocios(res.data.registros);
                setLoading(false);
            });
        });
    }

    const openNew = (e, padre = null) => {
        setPadreId(padre);
        if (catalogos == null) {
            accionistaSocioSrv.newAccionistaSocio({ iniciar: 1, tipo }).then(res => {
                setCatalogos(res.data);
                setDialogAccionistaSocio(true);
                reset(defaultValues);
            });
        }
        else {
            setDialogAccionistaSocio(true);
            reset(defaultValues);
        }
    }

    const onSubmit = (data) => {
        data.idp = id;
        data.tipo = tipo;
        data.ruc = catalogos.ruc;
        if(padreId != null) {
            data.padreId = padreId;
        }
        if (getValues('id') === undefined || getValues('id') == null) {
            accionistaSocioSrv.newAccionistaSocio(data).then(res => {
                setDialogAccionistaSocio(false);
                loadLazyAccionistaSocio();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            accionistaSocioSrv.editarAccionistaSocio(data).then(res => {
                setDialogAccionistaSocio(false);
                loadLazyAccionistaSocio();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const tableAccionistaSocio = () => {
        return (
            <>
                {id && <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, '', permss)}></Toolbar>}
                {table({ action2: generic.actionEditAddTemplate, methEdit: editAccionista, perm: permss, methDelete: confirmDelete, add: openNew})}
            </>
        )
    }

    const editAccionista = (data) => {
        const iniciar = catalogos == null ? 1 : 0;
        accionistaSocioSrv.getAccionista(data.id, tipo, id, iniciar).then(res => {
            let _cats = catalogos;
            if (iniciar === 1) {
                setCatalogos(res.data.catalogo);
                _cats = res.data.catalogo;
            }
            const lista = {
                tipo_accionista_socio: _cats.tipo_accionista_socio,
                tipo_identificacion: _cats.tipo_identificacion,
                nacionalidad: _cats.nacionalidad
            };
            const _accionista = res.data.accionista;
            Object.keys(lista).forEach(function (key) {
                if (_accionista[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _accionista[key]) {
                            _accionista[key] = element;
                        }
                    });
                }
            });
            setEsCedula(JSON.stringify(_accionista.tipo_identificacion) === JSON.stringify(_cats.cedula));
            setEsRuc(JSON.stringify(_accionista.tipo_identificacion) === JSON.stringify(_cats.ruc));
            if (_accionista['actividad_economica'] != null) {
                _accionista['actividad_economica'] = res.data.actividad_economica;
                setSelectedCiiu(res.data.actividad_economica);
            }
            reset(generic.renewValues(_accionista, omodel.model));
            setDialogAccionistaSocio(true);
        });
    }

    const onChangeTipo = (e) => {
        setEsRuc(JSON.stringify(e.value) === JSON.stringify(catalogos.ruc));
        setEsCedula(JSON.stringify(e.value) === JSON.stringify(catalogos.cedula));
    }

    const searchCiiu = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setCiius([...ciius]);
            }
            else {
                catalogoSrv.getActividades(event.query.toLowerCase()).then(data => {
                    setCiius(data.data);
                });
            }
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarAccionista(data)
        });
    }

    const eliminarAccionista = (data) => {
        accionistaSocioSrv.eliminarAccionista(data.id, tipo, id).then(res => {
            loadLazyAccionistaSocio();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const dialogoAccionistaSocio = () => {
        return (
            <Dialog visible={dialogAccionistaSocio} header={getValues('id') == null ? "Nuevo Accionista/Socio" : "Editar Accionista/Socio"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogAccionistaSocio(false)} style={{ maxWidth: '1000px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inDrop('tipo_accionista_socio', catalogos?.tipo_accionista_socio, { lab: omodel.optionLabel }, 4)}
                        {inDrop('tipo_identificacion', catalogos?.tipo_identificacion, { lab: omodel.optionLabel, chn: onChangeTipo }, 4)}
                        {esCedula && inText('identificacion', generic.infoIdentificacion('cedula'), 4)}
                        {!esCedula && !esRuc && inText('identificacion', {}, 4)}
                        {esRuc && inText('identificacion', generic.infoIdentificacion('ruc'), 4)}
                        <Divider />

                        {esRuc && inText('razon_social', {}, 12)}
                        {!esRuc && inText('nombres', {}, 6)}
                        {!esRuc && inText('apellidos', {}, 6)}
                        <Divider />

                        {inAutocomplete('actividad_economica', selectedCiiu, setSelectedCiiu, ciius, searchCiiu, { lab: omodel.optionLabel, col: 12 })}
                        <Divider />

                        {inDrop('nacionalidad', catalogos?.nacionalidad, { lab: omodel.optionLabel }, 6)}
                        {inText('cargo', {}, 6)}
                    </div>

                    {generic.buttonsForms(setDialogAccionistaSocio)}
                </form>
            </Dialog>
        )
    }

    return {
        loadLazyAccionistaSocio,
        tableAccionistaSocio,
        dialogoAccionistaSocio,
        setAccionistaSocios
    }
}
