import { FilterMatchMode } from "primereact/api";

export class Juridica {

    model = {
        id: {
            value: null,
            export: false,
            header: 'Código',
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'id',
            sort: true,
            matchMode: FilterMatchMode.EQUALS,
            showFilterMatchModes: false,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        tipo_juridico: {
            value: null,
            export: false,
            header: 'Tipo de Persona Juridica:',
            required: 'Tipo de Persona Juridica es requerido'
        },
        fecha: {
            value: new Date(),
            export: false,
            header: 'Fecha (día/mes/año):',
            required: 'Fecha es requerido',
            table: true,
            sort: true,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        pais: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton: {
            value: '',
            export: false,
            header: 'Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Ciudad es requerida'
        },
        compania: {
            value: '',
            export: false,
            header: 'Compañía:',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Companía es requerida'
        },
        grupo_empresarial: {
            value: false,
            export: false,
            header: 'Agrupa para grupo empresarial:',
            required: 'Es requerida'
        },
        tipo_formulario: {
            value: null,
            export: false,
            header: 'Tipo de formulario:',
            required: 'Tipo de formulario es requerido'
        },
        razon_social: {
            value: '',
            export: true,
            header: 'Razón social',
            required: 'Razón Social es requerido',
            maxLength: 'Máximo 500 caracteres',
            table: true,
            filter: false,
            sort: true,
        },
        identificacion: {
            value: '',
            export: false,
            header: 'No. RUC / Identificación tributaria:',
            required: 'No. RUC / Identificación tributaria es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'identificacion',
            filterPlaceholder: 'Buscar por número de identificación',
            table: true,
            filter: false,
            sort: true
        },
        nacionalidad: {
            value: null,
            export: false,
            header: 'Nacionalidad',
            required: ' Buscar por nacionalidad'
        },
        fecha_inicio_actividad: {
            value: new Date(),
            export: false,
            header: 'Fecha inicio de actividades (día/mes/año):',
            required: 'Fecha inicio de actividad es requerido'
        },
        actividad_economica: {
            value: null,
            export: false,
            header: 'Actividad Económica',
            required: 'Actividad económica es requerido'
        },
        direccion_oficina: {
            value: '',
            export: true,
            header: 'Dirección',
            required: 'Dirección domicilio es requerido',
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4
        },
        pais_datos_generales: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido',
        },
        provincia_datos_generales: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad_datos_generales: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerido'
        },
        canton_datos_generales: {
            value: '',
            export: false,
            header: 'Ciudad',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Ciudad es requerido'
        },
        codigo_postal: {
            value: '',
            export: false,
            header: 'Apartado postal (en el caso de ser extranjero)',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Apartado postal es requerido'
        },
        telefono_empresa: {
            value: '',
            export: true,
            header: 'Teléfono de empresa',
            required: 'Teléfono de empresa es requerido',
            maxLength: 'Máximo 30 caracteres',
            minLength: 'Mínimo 7 caracteres',
            max: 30,
            min: 7,
            filterField: 'telefono_celular'
        },
        pagina_web_email: {
            value: '',
            export: false,
            header: 'Página web o dirección electrónica de la empresa:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Página Web o correo electrónico es requerido'
        },
        ingreso_mensual: {
            value: 0,
            export: false,
            header: 'Ingreso mensual USD:',
            required: 'Ingreso mensual es requerido'
        },
        egreso_mensual: {
            value: 0,
            export: false,
            header: 'Egreso mensual USD:',
            required: 'Egreso mensual es requerido'
        },
        total_activo_corriente: {
            value: 0,
            export: false,
            header: 'Total activo corriente USD:',
            required: 'Total activo corriente es requerido',
        },
        total_activo_no_corriente: {
            value: 0,
            export: false,
            header: 'Total activo no corriente USD:',
            required: 'Total activo no corriente es requerido'
        },
        total_activos: {
            value: 0,
            export: false,
            header: 'Total activos USD:',
            required: 'Total activos es requerido'
        },
        total_pasivo_corriente: {
            value: 0,
            export: false,
            header: 'Total pasivo corriente USD:',
            required: 'Total pasivo corriente es requerido'
        },
        total_pasivo_no_corriente: {
            value: 0,
            export: false,
            header: 'Total pasivo no corriente USD:',
            required: 'Total pasivo no corriente es requerido'
        },
        total_pasivos: {
            value: 0,
            export: false,
            header: 'Total pasivos USD:',
            required: 'Total pasivos es requerido'
        },
        patrimonio: {
            value: 0,
            export: false,
            header: 'PATRIMONIO (ACTIVOS - PASIVOS) USD:',
            required: 'Patriminio es requerido'
        },
        pep: {
            value: false,
            export: false,
            header: 'Es el Representante Legal o algún Socio/Accionista un PEP?',
            required: 'Indicar si es Pep'
        },
        nombre_pep: {
            value: '',
            export: false,
            header: 'Nombre del PEP',
            required: 'Nombre del Pep es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Apartado postal es requerido'
        },
        cargo_pep: {
            value: '',
            export: false,
            header: 'Cargo',
            required: 'Cargo es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Apartado postal es requerido'
        },
        institucion_trabaja_pep: {
            value: '',
            export: false,
            header: 'Institución donde trabaja o trabajó',
            required: 'Institución es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Apartado postal es requerido'
        },
        fecha_nombramiento_pep: {
            value: new Date(),
            export: false,
            header: 'Fecha del nombramiento de la designación (día/mes/año):',
            required: 'Fecha es requerido'
        },
        fecha_dejo_pep: {
            value: null,
            export: false,
            header: 'Fecha en que dejó de ser PEP, de no estar en funciones (día/mes/año):',
            required: false
        },
        acepta_declaracion: {
            value: false,
            export: false,
            header: 'Acepta',
            required: 'Es requerido'
        },
        observacion: {
            value: '',
            export: true,
            header: 'Observación:',
            required: 'Observacion es requerido',
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4,
            required: 'Observación es requerido'
        },
        estado: {
            value: null,
            export: false,
            header: 'Estado:',
            required: 'Estado es requerido',
            table: true,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
    }
    strPep = <><b>PEP</b>: Son todas aquellas personas naturales, nacionales o extranjeras, que desempeñan o han desempeñado funciones o cargos públicas destacados en el Ecuador o en el Extranjero; o
        funciones prominentes en una organización internacional, conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo de control
        respectivo. <br /><b>FAMILIARES O RELACIONADOS</b>: Son las relaciones comerciales o contractuales que involucren al cónyuge o a las personas unidas bajo el régimen legal de unión de hecho
        reconocida legalmente; o a familiares comprendidos hasta el segundo grado de consanguinidad o primero de afinidad; y, el o los colaborador (es) directo (s) esto es la (s) persona (s)
        natural (es) con las cuales una Persona Expuesta Políticamente (PEP). se encuentre asociada o vinculada societariamente, o mantenga otro tipo de relaciones empresariales, comerciales
        o laborales estrechas, serán consideradas como PEP.</>;
    strDeclara = <>Declaro bajo juramento que:<ul><li>La información registrada en el presente documento y la documentación que se acompaña es fidedigna y asumo cualquier responsabilidad
        por los daños que pudiere sufrir la Compañía a la que declaro esta información por la eventual falsedad o imprecisión de esta; y,</li>
        <li>La información económica y patrimonial registrada cuenta con todos los sustentos que corresponden.</li></ul>
        <p>Finalmente, autorizo expresamente a  realizar el análisis y las verificaciones que se consideren pertinentes a la información suministrada y aquella que obtenga la compañía a la que
        declaro; y, en el caso de detectarse alguna inusualidad, informar de manera inmediata y documentada a las autoridades competentes.</p></>;
    strAnexo = <ul><li>Copia del RUC u otro documento de identificación tributaria (en caso de ser extranjero).</li>
        <li>Copia del nombramiento de Representante Legal o Escritura Pública del Poder Respectivo, en el caso que suscriba el Apoderado.</li>
        <li>Copia del documento de identificación del representante legal, apoderado o propietario.</li></ul>;
    optionLabel = 'nombre';

    titulo = 'Instrucciones';
    mensaje = <><ol style={{ textAlign: 'justify' }}><b>Instrucciones para completar el proceso de envío de información:</b>
        <li>En cuanto se registre la información de las secciones de la 1 a la 6, favor guardar la información.</li>
        <li>Cuando la información se guarde, dar click en reporte y el sistema generará el formulario de su información en versión PDF, el cual se deberá imprimir y firmar físicamente o electrónicamente.</li>
        <li>En el botón anexos se deberá cargar el formulario firmado y los anexos requeridos.</li>
        <li>Cuando el formulario y sus anexos sean cargados al sistema favor dar click en enviar.</li></ol></>;
}
