import { Panel } from 'primereact/panel';
import { BienesInmueble } from './BienesInmueble';
import { BienesMueble } from './BienesMueble';
import { OtrosBienesMueble } from './OtrosBienesMueble';
import { Bienes } from '../../../models/fun/Bienes';

export const Activos = ({ id, toast, nextPrev, next, permss, catalogos }) => {
    const omodel = new Bienes();

    return (
        <>
            <Panel header="DETALLE DE ACTIVOS: (Incluir Sociedad Conyugal o Unión de Hecho y considerar las referencias citadas al final de la sección)." className="panel-ficha">
                <BienesInmueble id={id} toast={toast} permss={permss} nextPrev={nextPrev} next={next} catalogos={catalogos} opcion="funcionario-inmueble" />
                <br />

                <BienesMueble id={id} toast={toast} permss={permss} nextPrev={nextPrev} next={next} catalogos={catalogos} opcion="funcionario-mueble" />
                <br />

                <OtrosBienesMueble id={id} toast={toast} permss={permss} nextPrev={nextPrev} next={next} catalogos={catalogos} opcion="funcionario-otros-muebles" />
                {omodel.notaBien}
            </Panel>
            <br />

            {nextPrev(false)}
        </>
    )
}
