import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AuthService from '../../service/AuthService';
import { Messages } from 'primereact/messages';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

export const Resetear = ({ toast }) => {

    const defaultValues = {
        identificacion: '',
        email: ''
    }
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
    const [dialogRecordar, setDialogRecordar] = useState(false);
    const [load, setLoad] = useState(false);
    const authService = new AuthService();
    const message = useRef();

    const showDialog = () => {
        reset(defaultValues);
        setDialogRecordar(true);
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error"><br />{errors[name].message}</small>
    };

    const onSubmit = (data) => {
        setLoad(true);
        authService.reset(data).then(res => {
            setLoad(false);
            toast.current.show({ severity: 'success', summary: 'Clave enviada', detail: res.data.message });
            setDialogRecordar(false);
        }).catch(error => {
            setLoad(false);
            try {
                message.current.show({ severity: 'error', content: error.response.data.message });
            } catch (err) {
                message.current.show({ severity: 'error', content: 'Error al ingresar. Consulte al administrador' });
            }
        });
    }

    return (
        <>
            <Button label="Click aquí." className="p-button-link" onClick={showDialog} />
            <Dialog visible={dialogRecordar} header="Recuperar Contraseña" modal className="p-fluid modal-ssize" onHide={e => setDialogRecordar(false)} style={{ width: '400px' }}>
                <Messages ref={message} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <br />
                    <br />
                    <div className="field">
                        <span className="p-float-label">
                            <Controller name="identificacion" control={control} rules={{
                                required: 'Número de identificación es requerido.',
                                maxLength: { value: 100, message: 'Máximo 100 caracteres' },
                                minLength: { value: 4, message: 'Mínimo 4 caracteres' }
                            }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.identificacion} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.isDirty })} />
                                )} />
                            <label htmlFor="identificacion" className={classNames({ 'p-error': errors.identificacion })}>Número de identificación*</label>
                        </span>
                        {getFormErrorMessage('identificacion')}
                    </div>
                    <br />

                    <div className="field">
                        <span className="p-float-label p-input-icon-right">
                            <i className="pi pi-envelope" />
                            <Controller name="email" control={control}
                                rules={{ required: 'Correo electrónico es requerido.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Correo electrónico no válido. E.g. example@email.com' } }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.isDirty })} />
                                )} />
                            <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>Correo electrónico*</label>
                        </span>
                        {getFormErrorMessage('email')}
                    </div>

                    <Button type="submit" label="Resetear" className="mt-2" loading={load} />
                </form>
            </Dialog>
        </>
    )
}
