import { FilterMatchMode } from "primereact/api"

export class GrupoDetalleEmpresa {

    model = {
        id: { value: null, export: false },
        empresa: {
            header: 'Empresa', value: null, required: 'Seleccione la empresa', optionLabel: 'nombre', export: true, table: true,
            filter: true, sort: true, filterField: 'empresa', matchMode: FilterMatchMode.CONTAINS
        },
        identificacion: {
            header: 'Identificación', value: null, required: false, export: true, table: true,
            filter: true, sort: true, filterField: 'identificacion', matchMode: FilterMatchMode.CONTAINS
        },
        estado: { header: 'Estado', value: { nombre: 'Activo', cat_id: true }, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true },
        fecha: { header: 'Fecha', value: null, table: true, export: true }
    }

    optionLabel = 'nombre'
}