export class OrigenCpj {
    model = {
        transaccion_realiza: {
            value: '',
            export: true,
            header: 'Describa la transacción u operación que realiza con la compañía:',
            required: 'Describa la transacción u operación que realiza con la compañía es requerido',
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4
        },
        valor_total_bien_servicio: {
            value: 0,
            export: true,
            header: 'Valor total del bien/servicio:',
            required: 'Valor total del bien/servicio es requerido',
        },
        moneda: {
            value: null,
            export: true,
            header: 'Moneda:',
            required: 'Moneda es requerido',
        },
        fecha_transaccion: {
            value: new Date(),
            export: false,
            header: 'Fecha de la transacción u operación (día/mes/año):',
            required: 'Fecha de la transacción u operación es requerida',
        },
        pais_ol: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia_ol: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad_ol: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton_ol: {
            value: '',
            export: false,
            header: 'Ciudad',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Ciudad es requerida'
        },
        detalle_act_economica: {
            value: '',
            export: false,
            header: 'Los valores entregados provienen de (detalle actividad económica o fuente):',
            required: 'Los valores entregados provienen es requerido',
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4,
        },
        tipo_valor_entregado: {
            value: null,
            export: false,
            required: 'Seleccione una opción',
            header: 'Los valores entregados son:'
        },
    }
    optionLabel='nombre'
}