import Axios from "axios";

export class ClientePJService {

    getClientepj(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/clientepj/lista?${queryParams}`).then(res => res.data);
    }

    newClientepj(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/clientepj/nuevo`, data).then(res => res.data);
    }

    editarClientepj(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/clientepj/editar`, data).then(res => res.data);
    }

    consultarClientepj(id, paso) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/clientepj/consulta/${id}/${paso}`).then(res => res.data);
    }

    verificar() {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/clientepj/verificar`).then(res => res.data);
    }

    eliminarClientepj(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/clientepj/eliminar/${id}`).then(res => res.data);
    }
    
    descargar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/clientepj/reporte/${id}`, { headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache' }, responseType: 'blob' }).then(async res => res.data);
    }
}