import Axios from "axios";

export class PlanAccionistaService {

    lista(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/matriz/config-accionista/lista?${queryParams}`).then(res => res.data);
    }

    nuevo(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/matriz/config-accionista/nuevo`, data).then(res => res.data);
    }

    editar(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/matriz/config-accionista/editar`, data).then(res => res.data);
    }

    consultar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/matriz/config-accionista/consulta/${id}`).then(res => res.data);
    }

    eliminar(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/matriz/config-accionista/eliminar/${id}`).then(res => res.data);
    }
}
