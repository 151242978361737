import Axios from "axios";

export class CatalogoService {

    getCatalogosId(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/catalogo/ibuscar/${id}`).then(res => res.data);
    }

    getActividades(nombre) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/catalogo/nbuscar/${encodeURIComponent(nombre)}`).then(res => res);
    }
}