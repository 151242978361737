import Axios from "axios";

export class InstitucionService {

    getInstitucion(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/institucion/lista?${queryParams}`).then(res => res.data);
    }

    newInstitucion(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/administracion/institucion/nuevo`, data).then(res => res.data);
    }

    editarInstitucion(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/administracion/institucion/editar`, data).then(res => res.data);
    }

    consultarInstitucion(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/institucion/${id}`).then(res => res.data);
    }

    eliminarInstitucion(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/administracion/institucion/eliminar/${id}`).then(res => res.data);
    }
}