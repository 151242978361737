import Axios from "axios";

export class GestionAppService {

    getGestion(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/gestion-app/lista?${queryParams}`).then(res => res.data);
    }

    newGestion(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/admin/gestion-app/nuevo`, data).then(res => res.data);
    }

    editarGestion(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/gestion-app/editar`, data).then(res => res.data);
    }

    consultarGestion(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/gestion-app/${id}`).then(res => res.data);
    }

    eliminarGestion(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/admin/gestion-app/eliminar/${id}`).then(res => res.data);
    }

    procesar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/gestion-app/procesar/${id}`).then(res => res.data);
    }

    listar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/gestion-app/listar/${id}`).then(res => res.data);
    }
}