import { FilterMatchMode } from "primereact/api"

export class Menus {

    model = {
        id: {value: null, export: false},
        nombre: {value: '', export: true, header: 'Menú', required: 'Nombre de menú es requerido', maxLength: 'Máximo 200 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 200, min: 4, table: true, filterField: 'nombre', filter: true, sort: true, 
            filterPlaceholder: 'Buscar por nombre', matchMode: FilterMatchMode.CONTAINS},
        url: {value: '', export: false, header: 'Url', required: false, maxLength: 'Máximo 50 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'identificacion', filterPlaceholder: 'Buscar por identificación', table: true, filter: false, sort: true},
        descripcion: {value: '', export: true, header: 'Descripción', required: 'Descripción es requerido', maxLength: 'Máximo 300 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'descripcion', filter: false, sort: true},
        icono: {value: '', export: true, header: 'Icono', required: false, maxLength: 'Máximo 150 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 150, min: 4, filterField: 'nombre', filter: false, sort: true},
        estado: {value: null, export: true, table: true, header: 'Estado', required: 'Estado es requerido'},
        menu_padre: {value: null, export: true, table: true, header: 'Menú origen', required: false, sort: true,
            filterPlaceholder: 'Buscar por menú origen', matchMode: FilterMatchMode.CONTAINS, filter: true},
        nivel: {value: 0, export: true, header: 'Nivel', required: 'Nivel es requerido', table: true},
        orden: {value: 0, export: true, header: 'Orden', required: 'Orden es requerido', table: true},
        ultimo: {value: null, export: false, header: 'Ultimo menú', required: 'Seleccione una opción', table: true},
        nemonico: {value: '', export: true, header: 'Nemónico', required: false, maxLength: 'Máximo 100 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 100, min: 4, table: true},                    
    }
    optionLabel = 'nombre'
}