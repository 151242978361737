import { Empresas } from "../../models/Empresas";
import { GenericComponent } from "../../components/GenericComponent";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { EmpresaService } from "../../service/EmpresaService";
import { InputComponent } from "../../components/InputComponent";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { confirmDialog } from "primereact/confirmdialog";

export const Empresa = () => {

  const generic = new GenericComponent();
  const omodel = new Empresas();
  const [permss, setPermss] = useState(null);
  const defaultValues = generic.default(omodel.model, null);
  const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
  let loadLazyTimeout = null;
  const [empresas, setEmpresas] = useState([]);
  const empresaService = new EmpresaService();
  const toast = useRef(null);
  const [dialogEmpresa, setDialogEmpresa] = useState(false);
  const [catalogos, setCatalogos] = useState(null);
  const [esRuc, setEsRuc] = useState(false);
  const [esCedula, setEsCedula] = useState(false);
  const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText } = InputComponent({ registers: empresas, omodel, generic, errors, control, permss, id: getValues('id') });

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      let _lazyParams = lazyParams;
      delete _lazyParams.rtipo;
      if (permss == null) {
        _lazyParams.rtipo = true;
      }
      empresaService.getEmpresa({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
        setTotalRecords(res.data.total);
        setEmpresas(res.data.registros);
        setLoading(false);
        if (res.data.perss !== undefined) {
          setPermss(res.data.perss);
        }
      });
    });
  }

  const openNew = () => {
    if (catalogos == null) {
      empresaService.newEmpresa({ iniciar: 1 }).then(res => {
        setCatalogos(res.data);
        setDialogEmpresa(true);
        reset(defaultValues);
      });
    }
    else {
      setDialogEmpresa(true);
      reset(defaultValues);
    }
  }

  const onSubmit = (data) => {
    if (getValues('id') == null) {
      empresaService.newEmpresa(data).then(res => {
        generic.funcSuccess(res, toast);
        setDialogEmpresa(false);
        loadLazyData();
      }).catch(error => {
        generic.funcError(error, toast, setError);
      });
    }
    else {
      empresaService.editarEmpresa(data).then(res => {
        generic.funcSuccess(res, toast);
        setDialogEmpresa(false);
        loadLazyData();
      }).catch(error => {
        generic.funcError(error, toast, setError);
      });
    }
  }

  const editEmpresa = (empresa) => {
    const iniciar = catalogos == null ? 1 : 0;
    empresaService.consultarEmpresa(empresa.id, iniciar).then(res => {
      let cats = catalogos;
      if (iniciar === 1) {
        cats = res.data.catalogos;
        setCatalogos(res.data.catalogos);
      }
      let _empresa = res.data.empresa;
      const lista = { tipo_identificacion: cats.tipo, tipo_empresa: cats.tipo_empresa, estado: generic.estados, grupo: cats.grupo };

      Object.keys(lista).forEach(function (key) {
        if (_empresa[key] != null) {
          lista[key].forEach(element => {
            if (element.cat_id === _empresa[key]) {
              _empresa[key] = element;
            }
          });
        }
      });
      setEsRuc(JSON.stringify(_empresa.tipo_identificacion) === JSON.stringify(cats.ruc));
      setEsCedula(JSON.stringify(_empresa.tipo_identificacion) === JSON.stringify(cats.cedula));
      reset(generic.renewValues(_empresa, omodel.model));
      setDialogEmpresa(true);
    });
  };

  const confirmDelete = (data) => {
    confirmDialog({
      message: '¿Está seguro de eliminar la empresa?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => eliminarEmpresa(data)
    });
  }

  const eliminarEmpresa = (data) => {
    empresaService.eliminarEmpresa(data.id).then(res => {
      loadLazyData();
      generic.funcSuccess(res, toast);
    }).catch(error => {
      generic.funcDeleteError(error, toast);
    });
  }

  const onChangeTipo = (e) => {
    setEsRuc(JSON.stringify(e.value) === JSON.stringify(catalogos.ruc));
    setEsCedula(JSON.stringify(e.value) === JSON.stringify(catalogos.cedula));
  }

  return (
    <>
      <Toast ref={toast} />
      <Card style={{ maxWidth: '100%', margin: 'auto' }}>
        <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nueva empresa', permss)}
          end={generic.rightToolbarTemplate(empresas, omodel, 'Listado_empresas', 'LISTADO EMPRESA')}></Toolbar>
        {table({ action: generic.actionEditTemplate, methEdit: editEmpresa, perm: permss, methDelete: confirmDelete })}
      </Card>

      <Dialog visible={dialogEmpresa} header={getValues('id') == null ? "Nueva empresa" : "Editar empresa"} modal className="p-fluid modal-ssize" onHide={e => setDialogEmpresa(false)}
        style={{ maxWidth: '800px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formgrid grid">
            {inDrop('tipo_identificacion', catalogos?.tipo, { lab: omodel.optionLabel, chn: onChangeTipo }, 6)}
            {esCedula && inText('identificacion', generic.infoIdentificacion('cedula'), 6)}
            {!esCedula && !esRuc && inText('identificacion', {}, 6)}
            {esRuc && inText('identificacion', generic.infoIdentificacion('ruc'), 6)}
            {inDrop('tipo_empresa', catalogos?.tipo_empresa, { lab: omodel.optionLabel }, 6)}
            {inText('razon_social', {}, 6)}
            {inText('nombre_comercial', {}, 6)}
            {inDrop('grupo', catalogos?.grupo, { lab: omodel.optionLabel }, 6)}
            {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 6)}
          </div>

          {generic.buttonsForms(setDialogEmpresa)}
        </form>
      </Dialog>
    </>
  )
}
