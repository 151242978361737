import Axios from "axios";

export class PermisoServicio {

    getLista(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/permiso/lista?${queryParams}`).then(res => res.data);
    }

    newPermiso(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/permiso/nuevo`, data).then(res => res.data);
    }

    editarPermiso(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/permiso/editar`, data).then(res => res.data);
    }

    consultarPermiso(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/permiso/consulta/${id}`).then(res => res.data);
    }

    eliminar(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/permiso/eliminar/${id}`).then(res => res.data);
    }
}