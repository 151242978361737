import { useEffect, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { Info } from "../../models/matriz/Info";
import { InputComponent } from "../../components/InputComponent";
import { FuncionarioService } from "../../service/ficha/FuncionarioService";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { useToken } from "../../components/useToken";

export const Busqueda = ({ opcion, permss, toast, seleccion }) => {
    const generic = new GenericComponent();
    const omodel = new Info();
    const [registros, setRegistros] = useState(null);
    const { getRol } = useToken();
    const buscarSrv = new FuncionarioService();
    const { table, setLoading, setTotalRecords, lazyParams } = InputComponent({ registers: registros, omodel, generic, errors: null, control: null, permss, id: null });

    useEffect(() => {
        loadLazy();
    }, [lazyParams]);

    const loadLazy = (idp) => {
        setLoading(true);

        let _lazyParams = lazyParams;
        if (getRol()) {
            _lazyParams.rol = getRol();
        }
        buscarSrv.listado({ lazyEvent: JSON.stringify(_lazyParams) }, opcion).then(res => {
            setTotalRecords(res.data.total);
            setRegistros(res.data.registros);
            setLoading(false);
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Ha ocurrido algún inconveniente, por favor intente nuevamente más tarde', life: 3000 });
        });
    }

    const seleccionar = (data) => {
        confirmDialog({
            message: '¿Está seguro de seleccionar la información?',
            header: 'Confirmación de selección',
            icon: 'pi pi-exclamation-triangle',
            accept: () => seleccion(data)
        });
    };

    const acciones = (rowData) => {
        return (
            <div className="actions">
                {permss?.consultar &&
                    <Button icon="pi pi-check" title='Seleccionar' className="p-button-rounded p-button-success mr-2" size="small" onClick={() => seleccionar(rowData)} />}
            </div>
        );
    }

    return (
        <>
            {table({ action5: acciones })}
        </>
    )
}
