import { useEffect, useRef, useState } from 'react'
import { GenericComponent } from '../../components/GenericComponent';
import { InstitucionPep } from '../../models/InstitucionPep';
import { InstitucionService } from '../../service/InstitucionService';
import { InputComponent } from '../../components/InputComponent';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { useForm } from 'react-hook-form';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import AdjuntoService from '../../service/AdjuntoService';

export const Institucion = () => {

    let fileRef = useRef(null);
    const generic = new GenericComponent();
    const omodel = new InstitucionPep();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [instituciones, setInstituciones] = useState(null);
    const toast = useRef(null);
    const institucionSrv = new InstitucionService();
    const [dialogSubir, setDialogSubir] = useState(false);
    const [dialogInstitucion, setDialogInstitucion] = useState(false);
    const [archivo, setArchivo] = useState(null);
    const [info, setInfo] = useState(null);
    const adjuntoService = new AdjuntoService();
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inNumber } =
        InputComponent({ registers: instituciones, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            institucionSrv.getInstitucion({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setInstituciones(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        setDialogInstitucion(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            institucionSrv.newInstitucion(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogInstitucion(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            institucionSrv.editarInstitucion(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogInstitucion(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editarInstitucion = (inst) => {
        institucionSrv.consultarInstitucion(inst.id).then(res => {
            let _inst = res.data;
            let lista = { estado: generic.estadosNum };
            if ([0, 1].includes(_inst.si_stream)) {
                lista.si_stream = generic.estadosNum;
            }

            Object.keys(lista).forEach(function (key) {
                if (_inst[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _inst[key]) {
                            _inst[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_inst, omodel.model));
            setDialogInstitucion(true);
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar la institución?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarInstitucion(data)
        });
    }

    const eliminarInstitucion = (data) => {
        institucionSrv.eliminarInstitucion(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const selectFile = (e) => {
        const size = parseInt(process.env.REACT_APP_PEP_SIZE);
        if (e.files[0].size > size) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1000) + " Kb", life: 3000 });
        }
        else {
            setArchivo(e.files[0]);
        }
        fileRef?.clear();
    }

    const invoiceUploadHandler = ({ files }) => {
        const [file] = files;
        const size = parseInt(process.env.REACT_APP_PEP_SIZE);
        if (file.size > size) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1000) + " Kb", life: 3000 });
        }
        else {
            setArchivo(file);
        }
        fileRef?.clear();
    }

    const subirArchivo = (data) => {
        setDialogSubir(true);
        setArchivo(null);
        setInfo(data);
    }


    const subida = () => {
        if (archivo == null) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el archivo.', life: 3000 });
            return;
        }
        const formData = new FormData();
        formData.append('file', archivo);
        formData.append('tipo', 'institucion');
        formData.append('id', info?.id);
        const unuevo = 'administracion/institucion/individual';
        adjuntoService.newAdjunto(formData, unuevo).then(res => {
            setDialogSubir(false);
            setArchivo(null);
            setInfo(null);
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    return (
        <>
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nueva institución', permss)}
                    end={generic.rightToolbarTemplate(instituciones, omodel, 'Listado_instituciones', 'LISTADO INSTITUCIONES')}></Toolbar>
                {table({ action3: generic.actionEditDetailTemplate, methEdit: editarInstitucion, perm: permss, methDelete: confirmDelete, details: subirArchivo, icono: 'pi pi-file-pdf' })}
            </Card>

            <Dialog visible={dialogSubir} header="Subir archivo" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '500px' }}>
                <div className="formgrid grid">
                    <div className="field col-12">
                        <label>Seleccione un anexo*</label><br />
                        <FileUpload name="file" accept=".pdf" maxFileSize={205000} uploadHandler={invoiceUploadHandler} mode="basic"
                            customUpload chooseLabel="Seleccione" onSelect={selectFile} ref={ref => { fileRef = ref }} />

                        {archivo?.name}
                        <br /><br />
                    </div>
                </div>

                <div className="formgrid grid">
                    <div className="field col" style={{ textAlign: 'center' }}>
                        <Button label="Subir" style={{ maxWidth: '120px', margin: 'auto' }} type="button" icon="pi pi-check" className="p-button-warning" onClick={() => subida()} />
                    </div>
                    <div className="field col">
                        <Button label="Cerrar" style={{ maxWidth: '120px' }} icon="pi pi-times" type="button" className="p-button-warning" onClick={() => setDialogSubir(false)} />
                    </div>
                </div>
            </Dialog>

            <Dialog visible={dialogInstitucion} header={getValues('id') == null ? "Nueva institución" : "Editar institución"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogInstitucion(false)} style={{ maxWidth: '900px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('razon_social', {}, 6)}
                        {inText('ruc', generic.infoIdentificacion('ruc'), 6)}
                        {inText('url_transparencia', {}, 6)}
                        {inText('url_directorio', {}, 6)}

                        {inText('lbl_descarga', {}, 6)}
                        {inNumber('c_nombre', 0, false, { min: 0 }, 6)}
                        {inNumber('c_pit', 0, false, { min: 0 }, 6)}
                        {inNumber('c_rmu_anual', 0, false, { min: -1 }, 6)}

                        {inNumber('c_rmu_mensual', 0, false, { min: -1 }, 6)}
                        {inNumber('c_cedula', 0, false, { min: -1 }, 6)}
                        {inNumber('pagina', 0, false, { min: 1 }, 6)}
                        {inDrop('si_stream', generic.estadosNum, { lab: omodel.optionLabel }, 6)}
                        {inDrop('estado', generic.estadosNum, { lab: omodel.optionLabel }, 6)}

                    </div>

                    {generic.buttonsForms(setDialogInstitucion)}
                </form>
            </Dialog>
        </>
    )
}
