import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { GenericComponent } from "../../components/GenericComponent";
import { InputComponent } from "../../components/InputComponent";
import { Beneficiario } from "../../models/Beneficiario";
import { TerceroService } from "../../service/TerceroService";
import { confirmDialog } from "primereact/confirmdialog";

export const Tercero = ({ toast, tipo, id, permss }) => {
    const generic = new GenericComponent();
    const omodel = new Beneficiario();
    const model = omodel.model;
    const defaultValues = generic.default(model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [terceros, setTerceros] = useState([]);
    const terceroService = new TerceroService();
    const [esRuc, setEsRuc] = useState(false);
    const [dialogTercero, setDialogTercero] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText } = InputComponent({ registers: terceros, omodel, generic, errors, control, permss, id: getValues('id') });

    const onChangeTipo = (e) => {
        setEsRuc(JSON.stringify(e.value) === JSON.stringify(catalogos.ruc));
    }

    const hideDialog = () => { setDialogTercero(false) }

    const openNew = () => {
        if (catalogos == null) {
            terceroService.newTercero({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setDialogTercero(true);
                reset(defaultValues);
            });
        }
        else {
            setDialogTercero(true);
            reset(defaultValues);
        }
    }

    const loadLazyTercero = (idp) => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        let _lazyParams = lazyParams;
        _lazyParams.idp = idp == null ? id : idp;
        _lazyParams.tipo = tipo;
        loadLazyTimeout = setTimeout(() => {
            terceroService.getLista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setTerceros(res.data.registros);
                setLoading(false);
            });
        });
    }

    const onSubmit = (data) => {
        data.idp = id;
        data.tipo = tipo;
        data.ruc = catalogos.ruc;
        if (getValues('id') == null) {
            terceroService.newTercero(data).then(res => {
                setDialogTercero(false);
                loadLazyTercero();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            terceroService.editarTercero(data).then(res => {
                setDialogTercero(false);
                loadLazyTercero();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editTercero = (data) => {
        const iniciar = catalogos == null ? 1 : 0;
        terceroService.getTercero(data.id, iniciar).then(res => {
            let _cats = catalogos;
            if (iniciar == 1) {
                setCatalogos(res.data.catalogo);
                _cats = res.data.catalogo;
            }
            const lista = { tipo_identificacion: _cats.tipo };
            const _tercero = res.data.tercero;
            Object.keys(lista).forEach(function (key) {
                if (_tercero[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _tercero[key]) {
                            _tercero[key] = element;
                        }
                    });
                }
            });
            setEsRuc(JSON.stringify(_tercero.tipo_identificacion) === JSON.stringify(_cats.ruc));
            if(JSON.stringify(_tercero.tipo_identificacion) === JSON.stringify(_cats.ruc)){
                _tercero.nombres = '';
                _tercero.apellidos = '';
            }
            else {
                _tercero.razon_social = '';
            }
            reset(_tercero);
            setDialogTercero(true);
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarTercero(data)
        });
    }

    const eliminarTercero = (data) => {
        terceroService.eliminar(data.id, id, tipo).then(res => {
            loadLazyTercero();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const dialogoTercero = () => {
        return (
            <Dialog visible={dialogTercero} header={getValues('id') == null ? "Nuevo Beneficiario/Tercero" : "Editar Beneficiario/Tercero"} modal className="p-fluid modal-ssize" onHide={e => setDialogTercero(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inDrop('tipo_identificacion', catalogos?.tipo, { lab: omodel.optionLabel, chn: onChangeTipo, val: null }, 6)}
                        {inText('identificacion', {}, 6)}
                    </div>

                    <div className="formgrid grid">
                        {esRuc && inText('razon_social', {}, 12)}
                        {!esRuc && inText('nombres', {}, 6)}
                        {!esRuc && inText('apellidos', {}, 6)}
                    </div>

                    {generic.buttonsForms(hideDialog)}
                </form>
            </Dialog>
        )
    }

    const tableTercero = () => {
        return (
            <>
                {id && <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, '', permss)}></Toolbar>}
                {table({ action: generic.actionEditTemplate, methEdit: editTercero, perm: permss, methDelete: confirmDelete })}
            </>
        )
    }

    return {
        loadLazyTercero,
        tableTercero,
        dialogoTercero
    }
}
