import { Panel } from "primereact/panel"
import { ReferenciaBancaria } from "../ReferenciaBancaria";
import { useEffect } from "react";
import { ReferenciaComercial } from "../ReferenciaComercial";

export const AmpliadaPn = ({ id, toast, tipo, nextPrev, permss }) => {

    const { tableBancaria, dialogoBancaria, loadLazyBancaria} = ReferenciaBancaria({ tipo: tipo, id: id, toast, permss});
    const { tableComercial, dialogoComercial, loadLazyComercial} = ReferenciaComercial({ tipo: 'clientePn', id, toast, permss});

    useEffect(() => {
        loadLazyBancaria();
        loadLazyComercial();
    }, [id]);

    return (
        <Panel header="PARTE C - INFORMACIÓN AMPLIADA" className="panel-ficha">
            <Panel header="C.1 REFERENCIAS BANCARIAS:" className="panel-ficha">
                {tableBancaria()}
            </Panel>
            <br />

            <Panel header="C.1 REFERENCIAS COMERCIALES O PERSONALES (ÚNICAMENTE EN EL CASO DE SER REQUERIDO POR EL VENDEDOR):" className="panel-ficha">
                {tableComercial()}
            </Panel>
            <br />
            {nextPrev(false)}

            {dialogoBancaria()}
            {dialogoComercial()}
        </Panel>
    )
}
