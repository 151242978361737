import { FilterMatchMode } from "primereact/api"

export class Detalles {

    model = {
        id: {value: null, export: false},        
        detalle: {value: '', export: false, header: 'Detalle', required: 'Detalle es requerido', maxLength: 'Máximo 6000 caracteres', minLength: 'Mínimo 10 caracteres', 
            max: 6000, min: 4, table: true, filter: true, sort: true, filterPlaceholder:'Buscar por detalle', matchMode: FilterMatchMode.CONTAINS, filterField: 'detalle'},
        link: {value: '', export: true, header: 'Link fuente origen', required: false, maxLength: 'Máximo 2000 caracteres', 
            minLength: 'Mínimo 10 caracteres', max: 2000, min: 10, filterField: 'link', filter: false, sort: false, filterPlaceholder:'Buscar por el Link', matchMode: FilterMatchMode.CONTAINS},
        estado: {value: null, header: 'Estado', value: {nombre: 'Activo', cat_id: 1}, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true},
        identificacion_origen: {value: '', export: false, header: 'Identificación Origen', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres', minLength: 'Mínimo 5 caracteres',
            max: 50, min: 5, table: false, filter: false},
        identificacion_persona: {value: '', export: false, header: 'Identificación vinculado', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres', minLength: 'Mínimo 5 caracteres',
            max: 50, min: 5, table: false, filter: false},
    }

    optionLabel = 'nombre'
}