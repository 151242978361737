export class ConyCpn {
    model = {
        tipo_identificacion_cy: {
            value: null,
            export: false,
            header: 'Tipo de documento:',
            required: 'Tipo de identificación es requerido'
        },
        apellidos_cy: {
            value: '',
            export: true,
            header: 'Apellidos completos:',
            required: 'Apellido es requerido',
            maxLength: 'Máximo 500 caracteres'
        },
        nombres_ec: {
            value: '',
            export: true,
            header: 'Nombres completos:',
            required: 'Nombre es requerido',
            maxLength: 'Máximo 500 caracteres'
        },
        identificacion_cy: {
            value: '',
            export: false,
            header: 'No. doc. Identidad:',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'identificacion',
            filterPlaceholder: 'Buscar por número de identificación',
            table: false,
            filter: false,
            sort: true
        },
        nacionalidad_cy: {
            value: null,
            export: false,
            header: 'Nacionalidad:',
            required: 'Nacionalidad es requerido'
        },
        fecha_nacimiento_cy: {
            value: new Date(),
            export: false,
            header: 'Fecha de nacimiento (día/mes/año):',
            required: 'Fecha de nacimiento es requerido'
        },
        genero_cy: {
            value: null,
            export: false,
            header: 'Género:',
            required: 'Género es requerido'
        },
        situacion_laboral_cy: {
            value: null,
            export: false,
            header: 'Situación laboral:',
            required: 'Situación laboral es requerido',
        },
        detalle_sl_cy: {
            value: '',
            export: false,
            header: 'Detalle:',
            required: 'Fuente de los otros ingresos es requerido',
            required: 'Detalle es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
        },
        lugar_trabajo_cy: {
            value: '',
            export: false,
            header: 'Nombre del lugar de trabajo/negocio propio:',
            required: 'Lugar de trabajo es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
        },
        cargo_cy: {
            value: '',
            export: false,
            header: 'Cargo:',
            required: 'cargo es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
        },

        ruc_cy: {
            value: '',
            export: false,
            header: 'Número de RUC en el caso de tener negocio propio:',
            required: false
        },
        tiempo_trabajo_cy: {
            value: '',
            export: false,
            header: 'Tiempo que labora en el lugar de trabajo/negocio propio:',
            required: 'Bienes muebles es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
        },
        actividad_economica_cy: {
            value: null,
            export: false,
            header: 'Actividad económica de la entidad donde labora o negocio propio:',
            required: 'Actividad económica es requerido.'
        },
        direccion_trabajo_cy: {
            value: '',
            export: false,
            header: 'Dirección completa de lugar de trabajo:',
            required: 'Dinero es requerido',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 500,
            min: 4,
        },
        pais_tr: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia_tr_cy: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad_tr_cy: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton_tr_cy: {
            value: '',
            export: false,
            header: 'Ciudad',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Ciudad es requerida.'
        },
        ingreso_mensual_cy: {
            value: 0,
            export: false,
            header: 'Ingreso Mensual:',
            required: 'Ingreso Mensual es requerido'
        },
        egreso_mensual_cy: {
            value: 0,
            export: false,
            header: 'Egreso Mensual:',
            required: 'Egreso Mensual es requerido'
        },
        otro_ingreso_mes_cy: {
            value: 0,
            export: false,
            header: 'Otros Ingresos mensuales del declarante USD:',
            required: 'Otros ingresos es requerido'
        },
        detalle_otro_ingreso_cy: {
            value: '',
            export: false,
            header: 'Especifique su fuente:',
            required: false,
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 500,
            min: 4,
        },
        telefono_trabajo_cy: {
            value: '',
            export: true,
            header: 'Teléfono laboral:',
            required: 'Teléfono es requerido',
            maxLength: 'Máximo 30 caracteres',
            minLength: 'Mínimo 7 caracteres',
            max: 30,
            min: 7,
        },
        email_trabajo_cy: {
            value: '',
            export: false,
            header: 'Correo electrónico laboral:',
            required: 'Correo electrónico es requerido'
        },
    }
    optionLabel = 'nombre';
}
