export class PepFamilia {

    model = {
        id: { value: null, export: false },
        idp: { value: null, export: false },
        nombre_completo: {
            value: '',
            export: false,
            header: 'Apellidos y Nombres Completos:',
            required: 'Información requerida',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 500,
            min: 3,
            table: true,
            filter: false,
            sort: true
        },
        parentezco: {
            value: '', export: true, header: 'Parentesco:', required: 'Campo requerido', maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 100, min: 4, table: true
        },
        identificacion: {
            value: '',
            export: false,
            header: 'No. doc. Identificación:',
            required: 'No. RUC / Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            table: true,
            max: 50,
            min: 3
        },
        tipo_identificacion: {
            value: null,
            export: false,
            header: 'Tipo de documento:',
            required: 'Selecione una opción',
            table: true,
        },
    }
    optionLabel = 'nombre'
}
