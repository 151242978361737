import { Panel } from "primereact/panel"
import { ReferenciaBancaria } from "../ReferenciaBancaria";
import { useEffect, useState } from "react";
import { Divider } from "primereact/divider";
import { Generico } from "../../../components/Generico";
import { Dialog } from "primereact/dialog";
import { FileBvg } from "../../../components/FileBvg";
import { FileComponent } from "../../../components/FileComponent";
import { CapitalBvg } from "../../../models/cpj/CapitalBvg";
import { PersonaJuridicaService } from "../../../service/personaNatural/PersonaJuridicaService";
import { ReferenciaComercial } from "../ReferenciaComercial";

export const AmpliadaBvg = ({ id, toast, tipo, permss, setDialogClientepn, cancelar, generic, urls, estado, reiniciar, seccion }) => {

    const omodel = new CapitalBvg();
    const { tableBancariaBVG, dialogoBancaria, loadLazyBancaria } = ReferenciaBancaria({ tipo: tipo, id: id, toast, permss, bvg: true });
    const { tableComercialBVG, dialogoComercial, loadLazyComercial } = ReferenciaComercial({ tipo: tipo, id: id, toast, permss, bvg: true });
    const { buttonsFormsReports, funcMessageBlobError, downloadPdf } = Generico();
    const juridicaSrv = new PersonaJuridicaService();
    const [loadReport, setLoadReport] = useState(false);
    const [dialogSubir, setDialogSubir] = useState(false);
    const { enviarFormularioPep } = FileComponent({ generic, toast });
    const [estadoForm, setEstadoForm] = useState(null);

    useEffect(() => {
        loadLazyBancaria();
        loadLazyComercial();
        if (!estado) {
            editFicha();
        } else {
            setEstadoForm(estado);
        }
    }, [id]);

    const editFicha = () => {
        juridicaSrv.consultarCliente(id, seccion).then(res => {
            setEstadoForm(res.data.clientepj?.estado);
        });
    }

    const generarReporte = () => {
        setLoadReport(true);
        juridicaSrv.descargar(id).then(res => {
            downloadPdf(res, id + '-Formulario-pn.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            funcMessageBlobError('Complete la información antes de generar.', error, toast);
        });
    }

    const subirReporte = () => {
        setDialogSubir(true);
    }

    const enviar = () => {
        enviarFormularioPep(id, urls.uenviar, tipo, reiniciar);
    }

    return (
        <>
            {tableBancariaBVG()}
            <br />

            {tableComercialBVG()}
            <br />

            <Panel header="DECLARACIÓN Y AUTORIZACIÓN" className="panel-ficha">
                <div className="formgrid grid">
                    <div className="field col-12" style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px' }}>
                        {omodel.strBVG}
                    </div>
                    <div className="field col-5 h-2rem" style={{ textAlign: 'center', fontStyle: 'italic', fontSize: '10px', verticalAlign: 'bottom', fontWeight: 'bold' }}>
                        <Divider />
                        <span>Firma del Declarante</span>
                    </div>
                    <Divider />
                    {omodel.decBVG}
                </div>
            </Panel>
            <br />

            <Panel header="INFORMACIÓN Y DOCUMENTACIÓN ANEXA (CHECK LIST)." className="panel-ficha">
                <div className="formgrid grid">
                    <div className="field col-5">
                        {omodel.bgvAnexo}
                    </div>
                    <div className="field col-1">
                        <Divider layout="vertical" />
                    </div>
                    <div className="field col-6">
                        {omodel.bvgRespaldo}
                    </div>
                </div>
            </Panel>
            <br />

            {buttonsFormsReports(setDialogClientepn, generarReporte, subirReporte, enviar, loadReport, id != null, cancelar, false)}
            {dialogoBancaria()}
            {dialogoComercial()}
            <Dialog visible={dialogSubir} header="Gestión de anexos" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo={tipo} urls={urls} idp={id} toast={toast} permss={permss} estado={estadoForm} reiniciar={reiniciar} setDialogSubir={setDialogSubir} />
            </Dialog>
        </>
    )
}
