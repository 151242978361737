export class Inversion {

    model = {
        id: { value: null, export: false },
        tipo_inversion: {
            value: '',
            table: true,
            header: 'Tipo de Inversión',
            required: 'Tipo de Inversión',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3,
        },
        institucion: {
            value: '',
            table: true,
            header: 'Institución donde se realiza la Inversión',
            required: 'Campo requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3,
        },
        fecha: {
            value: null,
            table: true,
            header: 'Fecha de Inversión (año-mes-día)',
            required: 'Campo requerido',
            footer: 'SUBTOTAL:',
        },
        monto: {
            value: 0,
            table: true,
            header: 'Valor del Bien:',
            required: 'Valor requerido',
            footer: 0,
        },
    }

    optionLabel = 'nombre';
}
