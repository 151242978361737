import { FilterMatchMode } from "primereact/api"

export class FuentePersona {
    model = {
        id: {value: null, export: false},
        nombres: {value: '', export: false, header: 'Nombres', required: 'Nombre de es requerido', maxLength: 'Máximo 1000 caracteres', minLength: 'Mínimo 4 caracteres', 
            max: 1000, min: 4, table: true, filter: true, sort: true, filterPlaceholder:'Buscar por nombres', matchMode: FilterMatchMode.CONTAINS},
        identificacion: {value: '', export: false, header: 'Identificación', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres', minLength: 'Mínimo 5 caracteres',
            max: 50, min: 5, table: true, filter: true, sort: true, filterPlaceholder:'Buscar por idntificación', matchMode: FilterMatchMode.CONTAINS},
        estado: {value: null, header: 'Estado', value: {nombre: 'Activo', cat_id: 1}, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true},
    }

    optionLabel = 'nombre'
}