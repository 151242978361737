export class Conviviente {

    model = {
        id: { value: null, export: false },
        nombre_completo_cy: {
            value: '',
            header: 'Nombres completos',
            required: 'Nombres es requerido',
            maxLength: 'Máximo 500 caracteres',
            filterPlaceholder: 'nombres',
            minLength: 'Mínimo 3 caracteres',
            max: 500,
            min: 3,
        },
        tipo_identificacion_cy: {
            value: null,
            required: 'Tipo de identificación es requerido',
            header: 'Tipo de identificación',
        },
        identificacion_cy: {
            value: '',
            header: 'Identificación',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
        },
        nacionalidad_cy: {
            value: null,
            header: 'Nacionalidad',
            required: 'Nacionalidad es requerido'
        },
        profesion_cy: {
            value: '',
            header: 'Profesión u Ocupación:',
            required: 'Apellidos es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        direccion_cy: {
            value: '',
            header: 'Dirección Residencia: (Urbanización/barrio, avenida, calle, manzana, bloque, solar, villa)',
            required: 'Dirección es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        telefono_domicilio_cy: {
            value: '',
            header: 'Teléfono/celular domicilio',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        telefono_personal_cy: {
            value: '',
            header: 'Teléfono/celular personal',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        lugar_nacimiento_cy: {
            value: '',
            header: 'Lugar de Nacimiento:',
            required: 'Campo es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        fecha_nacimiento_cy: {
            value: new Date(),
            header: 'Fecha de nacimiento:',
            required: 'Fecha es requerido'
        },
        correo_electronico_cy: {
            value: '',
            header: 'Correo electrónico:',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        lugar_trabaja_cy: {
            value: '',
            header: 'Lugar donde trabaja:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        con_enfermedad: {
            value: null,
            header: 'Tiene usted, familiares dependientes con enfermedades permanentes?',
            required: 'Seleccione una opción',
        },
    }
    nota = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px' }}><b>Nota:</b> Adjunte fotocopias de los documentos de identificación
        (cédulas de identidad/pasaporte/otros) y un detalle si el desglose de su familia es muy extenso.</span>
    conv = <span style={{ textAlign: 'justify', fontSize: '10px' }}><b>DATOS DEL CÓNYUGE / CONVIVIENTE (Favor llenar esta información si su estado
        civil es casado o unión de hecho)</b></span>

    optionLabel = 'nombre';
}
