export class InfLaboral {
    model = {
        situacion_laboral: {
            value: null,
            export: false,
            header: 'Condición o relación Laboral:',
            required: 'Selección requerida'
        },
        entidad_laboral: {
            value: null,
            export: false,
            header: 'Trabaja en una entidad:',
            required: 'Selección requerida'
        },
        lugar_trabajo: {
            value: '',
            export: true,
            header: 'Nombre de la empresa donde trabaja:',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        cargo: {
            value: '',
            export: false,
            header: 'Cargo/Función actual que desempeña:',
            required: 'Cargo es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        sector_economico: {
            value: '',
            export: false,
            header: '*Sector Económico (Otros):',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        actividad_economica: {
            value: null,
            export: false,
            header: 'Actividad económica de la entidad donde labora o negocio propio:',
            required: 'Actividad económica es requerida'
        },
        fecha_inicio_laboral: {
            value: new Date(),
            export: false,
            header: 'Inicio de actividades laborales:',
            required: 'Fecha de nacimiento es requerido'
        },
        direccion_trabajo: {
            value: '',
            export: false,
            header: 'Dirección Laboral: (Urbanización/barrio, calle, manzana, bloque, solar, villa):',
            required: 'Dirección es requerido',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 500,
            min: 3,
        },
        email_trabajo: {
            value: '',
            export: false,
            header: 'Página web o correo electrónico de la empresa::',
            required: 'Campo requerido'
        },
        pep: {
            value: null,
            export: false,
            header: 'y declaro bajo juramento que:',
            required: 'Seleccione una opción'
        },
    }
    optionLabel = 'nombre';
    labelPep = <><b>Definiciones:</b><br />El Reglamento General a la Ley Orgánica de Prevención, Detección y Erradicación del Delito de Lavado de Activos y
        del Financiamiento de Delitos, establece como "<b>Personas Expuestas Políticamente (PEP)</b>.- Son todas aquellas personas naturales, nacionales o
        extranjeras, que desempeñan o han desempeñado funciones o cargos públicos destacados en el Ecuador o en el Extranjero; o funciones prominentes en
        una organización internacional, conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo de
        control respectivo. Los familiares de las Personas Expuestas Políticamente (PEP), sean estos los cónyuges o personas unidas bajo el régimen de unión
        de hecho, familiares hasta el <b>segundo grado de consanguinidad o primero de afinidad</b>; y, el o <b>los colaborador (es) directo (s)</b> esto es
        la (s) persona (s) natural (es) con las cuales una Persona Expuesta Políticamente (PEP), se encuentre asociada o vinculada societariamente, o mantenga
        otro tipo de relaciones empresariales, comerciales o laborales estrechas, serán consideradas como PEP.<br /><br /><b>Declaración:</b></>
    dec2 = <>me encuentro ejerciendo uno de los cargos, o tengo una relación de las citadas en el párrafo anterior de esta sección..</>
    dec3 = <>En el caso de que su respuesta sea <b>positiva</b>, sírvase llenar el formulario de la última sección <b>(sección PEP)</b> e indicar: cargo/función, entidad donde
        desempeña tal función, o relación con la Persona Expuesta Políticamente.</>
    dec4 = <><b>Nota: Grados de consanguinidad</b> (1C-Padres, 1C-Hijos, 2C-Abuelos, 2C-Hermanos, 2C-Nietos.) <b>Grados de Afinidad</b> (1A Cónyuge, 1A-Suegros, 1A-Yerno/Nuera, 1A-Hijastro (a)).</>
}
