import React, { useEffect, useRef, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { InformacionPJ } from './pj/InformacionPJ';
import { DatosPJ } from './pj/DatosPJ';
import { addLocale } from 'primereact/api';
import { InputComponent } from '../../components/InputComponent';
import { Clientepj } from '../../models/ClientePj';
import { GenericComponent } from '../../components/GenericComponent';
import { useForm } from 'react-hook-form';
import { ClientePJService } from '../../service/ClientePJService';
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import { InfEconomica } from './pj/InfEconomica';
import { SocAccPj } from './pj/SocAccPj';
import { PepPj } from './pj/PepPj';
import { OrigenLic } from './pj/OrigenLic';
import { InfoApliada } from './pj/InfoApliada';
import { Declaracion } from './pj/Declaracion';
import { useToken } from '../../components/useToken';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { FileComponent } from '../../components/FileComponent';
import { FiltroMultiempresa } from '../../components/FiltroMultiempresa';

export const ClientePerJu = () => {

    const generic = new GenericComponent();
    const omodel = new Clientepj();
    const model = omodel.model;
    const [permss, setPermss] = useState(null);
    const [clientespj, setClientespj] = useState(null);
    const clientepjSrv = new ClientePJService();
    const defaultValues = generic.default(model);
    const { control, formState: { errors } } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [dialogClientepj, setDialogClientepj] = useState(false);
    const toast = useRef(null);
    const [nuevo, setNuevo] = useState(true);
    const [id, setId] = useState(null);
    const { getPerss, getRol } = useToken();
    const [empresa, setEmpresa] = useState(null);
    const [siCliente, setSiCliente] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams } = InputComponent({ registers: clientespj, omodel, generic, errors, control, permss, id: id });
    const [activeIndex, setActiveIndex] = useState(0);
    const { listaFormulario, listar, dialogInstruccion, setdialogIns } = FileComponent({ generic, tipo: 'clientePj', toast, permss, id: id });
    const [catalogos, setCatalogos] = useState(null);

    addLocale(generic.idioma, generic.esFormat);

    useEffect(() => {
        setSiCliente(getPerss() !== undefined);
        if (getPerss() === undefined) {
            loadLazyData();
        }
        else {
            setPermss(getPerss());
            verificarFicha();
        }
    }, [lazyParams]);

    const verificarFicha = () => {
        clientepjSrv.verificar().then(res => {
            if (res.data != null) {
                openNew({ id: res.data });
            }
            else {
                openNew(null);
            }
        });
    }

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            delete _lazyParams.empresa;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            if (empresa) {
                _lazyParams.empresa = empresa;
            }
            if (getRol()) {
                _lazyParams.rol = getRol();
            }
            clientepjSrv.getClientepj({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setClientespj(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
                if (res.data.cats) {
                    setCatalogos(res.data.cats);
                }
            });
        });
    }

    const openNew = (cliente = null) => {
        if (catalogos == null) {
            clientepjSrv.newClientepj({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setActiveIndex(0);
                setDialogClientepj(true);
                setNuevo(cliente == null);
                setdialogIns(true);
                setId(cliente?.id);
            });
        } else {
            setActiveIndex(0);
            setDialogClientepj(true);
            setNuevo(cliente == null);
            setdialogIns(true);
            setId(cliente?.id);
        }
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el formulario?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteCliente(data)
        });
    }

    const deleteCliente = (data) => {
        clientepjSrv.eliminarClientepj(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const regresar = () => {
        setActiveIndex(0);
        setDialogClientepj(false);
        loadLazyData();
    };

    const handleNext = (valor) => {
        if (activeIndex < 7) {
            setActiveIndex(activeIndex + 1);
        }
        setId(valor);
    };

    const handlePrev = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const archivos = (data) => {
        const urls = "ficha/clientepj/listar";
        const urlsd = "ficha/clientepj/descarga";
        listar(data.id, urls, urlsd, data.nemonico);
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogClientepj && !siCliente && <>
                {getRol() && getRol()?.ideg && <FiltroMultiempresa loadLazyData={loadLazyData} setEmpresa={setEmpresa} />}
                <Toolbar className="mb-4" start={(getRol() && getRol()?.ideg) ? null : generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                    end={generic.rightToolbarTemplate(clientespj, omodel, 'Reporte_persona_juridica', 'LISTADO CLIENTES PERSONA JURIDICA')}></Toolbar>
                <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                    {table({ action3: generic.actionEditDetailTemplate, methEdit: openNew, perm: permss, methDelete: confirmDelete, details: archivos, icono: 'pi pi-clone' })}
                </Card></>
            }
            {dialogClientepj &&
                <Panel header="INFORMACIÓN DE PERSONAS JURÍDICAS" style={{ width: '100%' }}>
                    <div>
                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            <TabPanel header="Sección 1">
                                <InformacionPJ toast={toast} siCliente={siCliente} next={handleNext} catalogos={catalogos} id={id} editFicha={openNew} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 2" disabled={id == null}>
                                <DatosPJ toast={toast} id={id} back={handlePrev} next={handleNext} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 3" disabled={id == null}>
                                <InfEconomica toast={toast} id={id} back={handlePrev} next={handleNext} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 4" disabled={id == null}>
                                <SocAccPj toast={toast} id={id} back={handlePrev} next={handleNext} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 5" disabled={id == null}>
                                <PepPj toast={toast} id={id} back={handlePrev} next={handleNext} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 6" disabled={id == null}>
                                <OrigenLic toast={toast} id={id} catalogos={catalogos} back={handlePrev} next={handleNext} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 7" disabled={id == null}>
                                <InfoApliada toast={toast} id={id} nuevo={nuevo} back={handlePrev} next={handleNext} permss={permss} />
                            </TabPanel>
                            <TabPanel header="Sección 8" disabled={id == null}>
                                <Declaracion toast={toast} id={id} nuevo={nuevo} setDialogClientepj={setDialogClientepj} permss={permss} cancelar={getPerss() == undefined} setdialogIns={setdialogIns} />
                            </TabPanel>
                        </TabView>
                    </div>
                </Panel>
            }
            <br />
            {dialogClientepj && <Button label="Regresar" icon="pi pi-replay" onClick={regresar} />}
            {listaFormulario('ficha/clientepj/aprobar', loadLazyData)}
            {dialogInstruccion(omodel.mTitle, omodel.mensaje)}
        </>
    )
}
