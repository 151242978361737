import { useEffect, useState } from "react";
import { Notificacion } from "../../models/Notificacion"
import { InputComponent } from "../../components/InputComponent";

export const Envio = ({ id, service, permss }) => {
    const omodel = new Notificacion();
    let loadLazyTimeout = null;
    const [registers, setRegisters] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams } = InputComponent({ registers, omodel, permss });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.id = id;
            service.getEnvios({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegisters(res.data.registros);
                setLoading(false);
            });
        });
    }

    return (
        <>
            <span style={{ fontSize: '9px', fontWeight: 'bold' }}>Información disponible desde el 2024-06-10</span>
            {table()}
        </>
    )
}
