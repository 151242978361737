export class FormaPago {

    model = {
        id: {value: null, export: false},
        idp: {value: null, export: false},
        tipo_pago: {value: null, export: false, header: 'Medio de Pago', required: 'Medio de Pago es requerido', filterField:'nombre', table: true, filter: false, sort: true,
            filterPlaceholder:'Buscar por Medio de Pago'},
        valor: {value: 0, export: false, header: 'Detallar valor USD:', required: 'Detallar valor USD es requerido', table: true, filter: false, sort: true,},
    }

    optionLabel = 'nombre'
}