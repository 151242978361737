import { useEffect, useRef, useState } from "react";
import { InfoAccionistaService } from "../../service/matriz/InfoAccionistaService";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { DetailedCellError, HyperFormula } from 'hyperformula';
import { Generico } from "../../components/Generico";
import { MatrizComponent } from "../../components/MatrizComponent";
import { useForm } from "react-hook-form";
import { DetalleAccionistaUno } from "./DetalleAccionistaUno";

export const DetalleAccionistaDos = ({ matrizId, permss, setDialogPerfil, toast, perfil, numero, uno, regresar }) => {

    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({});
    const accionistaSrv = new InfoAccionistaService();
    const [rango, setRango] = useState(null);
    const [matriz, setMatriz] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const hfInstanceRef = useRef(null);
    const [loadSave, setLoadSave] = useState(false);
    const { funcError, funcSuccess, funcMessageBlobError, downloadPdf, stringToDate, calcularEdad, dateToString } = Generico();
    const [edad, setEdad] = useState(null);
    const [loadReport, setLoadReport] = useState(false);
    const [loadCambio, setLoadCambio] = useState(false);
    const [estadoUno, setEstadoUno] = useState(false);
    const { inDropAnio, inNumber2, numericRound, resume, generateRange, colorSenialDos, onError, buttonsMatriz, inCalendarOne } = MatrizComponent({ control, errors, permss, toast });

    useEffect(() => {
        hfInstanceRef.current = HyperFormula.buildEmpty({ licenseKey: 'gpl-v3' });
        hfInstanceRef.current.addSheet('Sheet1');
        generateRange(setRango);
        verificarMatriz();
        return () => {
            hfInstanceRef.current.destroy();
        };
    }, [matrizId]);

    const onSubmit = (data) => {
        if(matriz?.inicio) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No puede guardar, debe ingresar la información de la matriz.', life: 3000 });
            return;
        }
        setLoadSave(true);
        const _matriz = matriz;
        accionistaSrv.detalle(_matriz, numero, perfil).then(res => {
            setLoadSave(false);
            funcSuccess(res, toast);
        }).catch(error => {
            setLoadSave(false);
            funcError(error, toast, setError);
        });
    }

    const regresarUno = (estado) => {
        setEstadoUno(estado);
        setActiveIndex(0);
    }

    const verificarMatriz = () => {
        accionistaSrv.informacion(matrizId, numero, perfil).then(res => {
            const _info = res.data;
            setEstadoUno(_info?.estado_uno);
            valueCell('anio1sel', _info?.anio1, 0);
            valueCell('anio2sel', _info?.anio2, 0);
            const _values = {};
            _values.anio1 = _info?.anio1 ? { nombre: _info?.anio1 + "", cat_id: _info?.anio1 } : null;
            _values.anio2 = _info?.anio2 ? { nombre: _info?.anio2 + "", cat_id: _info?.anio2 } : null;
            _values.fecha_nacimiento = _info?.fecha_nacimiento ? stringToDate(_info.fecha_nacimiento) : new Date();
            const data = [];
            const formulas = [];
            let index = 0;
            Object.keys(_info).map((key) => {
                const siMatriz = key.includes('eco') || key.includes('pat') || key.includes('sep') || key.includes('ses');
                if (siMatriz) {
                    const orden = _info[key].orden;
                    if (_info[key]?.color.includes('MATRIZ_COLOR_CELESTE')) {
                        _values[`${key}-${orden}-valor_anio1`] = parseInt(_info[key]?.valor_anio1);
                        _values[`${key}-${orden}-valor_anio2`] = parseInt(_info[key]?.valor_anio2);
                    }
                    data.push([_info[key]?.valor_anio1, _info[key]?.valor_anio2, _info[key]?.calculado1, _info[key]?.calculado2, _info[key]?.calculado3]);
                    if (_info[key]?.anio1) formulas.push({ value: _info[key]?.anio1, row: index, col: 0 });
                    if (_info[key]?.anio2) formulas.push({ value: _info[key]?.anio2, row: index, col: 1 });
                    if (_info[key]?.formula1) formulas.push({ value: _info[key]?.formula1, row: index, col: 2 });
                    if (_info[key]?.formula2) formulas.push({ value: _info[key]?.formula2, row: index, col: 3 });
                    if (_info[key]?.formula3) formulas.push({ value: _info[key]?.formula3, row: index, col: 4 });
                    delete _info[key]?.formula3;
                    delete _info[key]?.formula2;
                    delete _info[key]?.formula1;
                    delete _info[key]?.anio1;
                    delete _info[key]?.anio2;
                    index++;
                }
                return 1;
            });
            hfInstanceRef.current.setCellContents(
                { sheet: 0, col: 0, row: 0 },
                data
            );
            formulas.forEach((formula) => {
                hfInstanceRef.current.setCellContents(
                    { sheet: 0, col: formula.col, row: formula.row },
                    [[formula.value]]
                );
            });
            setMatriz(_info);
            reset(_values);
        });
    }

    const onChangeRango = (e, name) => {
        valueCell(`${name}sel`, e.value?.cat_id, 0);
        setMatriz(prevItem => ({
            ...prevItem,
            [name]: e.value?.cat_id
        }));
    };

    const calcular = (value, name) => {
        const siNumber = name.includes('eco') || name.includes('pat');
        if(siNumber) {
            const _matriz = matriz;
            delete _matriz.inicio;
            setMatriz(_matriz);
        }
        const [key, orden, field] = name.split('-');
        const ftl = key.slice(0, 3);
        const rrow = parseInt(orden) - 1;
        const _item = matriz[key];
        _item[field] = valueCell(field, value, rrow);
        _item.calculado1 = valueCell('calculado1', null, rrow);
        _item.calculado2 = valueCell('calculado2', null, rrow);
        _item.calculado3 = valueCell('calculado3', null, rrow);
        setMatriz(prevItem => ({
            ...prevItem,
            [key]: _item
        }));
        const _eco = [3, 6, 7, 9, 10];
        const _pat = [13];
        let arrs = ftl === 'eco' ? _eco : _pat;
        const _cols = ['valor_anio1', 'valor_anio2', 'calculado1', 'calculado2', 'calculado3'];
        arrs.forEach(itm => {
            const _cal = matriz[`${ftl}${itm}`];
            _cols.forEach(col => {
                const res = valueCell(col, null, itm - 1);
                _cal[col] = res;
                if (res === null) {
                    return;
                }
            });
        });

        for (let i = 14; i <= 25; i++) {
            const _col = i < 20 ? 'sep' : 'ses';
            const _cams = ['valor_anio1', 'valor_anio2'];
            if (i < 20) _cams.push('calculado1');
            const _cal = matriz[`${_col}${i}`];
            _cams.forEach(cl => {
                const res = valueCell(cl, null, i - 1);
                _cal[cl] = res;
                if (res === null) {
                    return;
                }
            });
        }
    }

    const valueCell = (field, value, row) => {
        const cols = { valor_anio1: 0, valor_anio2: 1, calculado1: 2, calculado2: 3, calculado3: 4, anio1: 0, anio2: 1, formula1: 2, formula2: 3, formula3: 4, anio1sel: 11, anio2sel: 12 };
        if (value) {
            hfInstanceRef.current.setCellContents({ sheet: 0, col: cols[field], row: row }, [[value]]);
        }
        const calculatedValue = hfInstanceRef.current.getCellValue({ sheet: 0, col: cols[field], row: row });
        if (calculatedValue instanceof DetailedCellError) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Error: ${calculatedValue.type}, Message: ${calculatedValue.message}, field: ${field}, value: ${value}, row: ${row}`, life: 3000 });
            return null;
        }

        return numericRound(calculatedValue) + (field === 'calculado2' && typeof calculatedValue === 'number' ? ' %' : '');
    }

    const texto = (opc) => {
        return opc === 1 ? <>&lt;1: POSIBLE QUIEBRA<br />
            &gt;=1 Y &lt;1,50: POSICIÓN DELICADA<br />
            =1,5: RATIO ÓPTIMO/EQUILIBRADO<br />
            &gt;1,5: BASTANTE LIQUIDEZ</>
            : <>
                &lt;40%: AHORROS<br />
                ENTRE 40% Y 60%: RATIO ÓPTIMO<br />
                &gt;60%: DEUDA EN EXCESO
            </>
    }

    const infoSenial2 = () => {
        const info = [];
        for (let num = 20; num <= 25; num++) {
            info.push(resume((num * 100) + 1, matriz[`ses${num}`].nombre, 6, 0, false, 'xs', 'center', null, 'border-1'));
            info.push(resume((num * 100) + 2, matriz[num % 2 === 0 ? 'anio2' : 'anio1'], 2, 0, false, 'xs', 'center', null, 'border-1'));
            info.push(resume((num * 100) + 3, matriz[`ses${num}`].valor_anio2, 4, 0, false, 'xs', 'center', colorSenialDos(matriz[`ses${num}`].valor_anio2), 'border-1'));
        }

        return info;
    }

    const generarReporte = () => {
        if(matriz?.inicio) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No puede generar, debe ingresar la información de la matriz.', life: 3000 });
            return;
        }
        setLoadReport(true);
        accionistaSrv.descargar(matrizId, numero, perfil).then(res => {
            downloadPdf(res, 'Reporte-Matriz.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            funcMessageBlobError('Asegúrese de ingresar la información antes de generar el reporte.', error, toast);
        });
    }

    const verificarEdad = (value) => {
        const cl = calcularEdad(value);
        const fecha = dateToString(value);
        setMatriz(prevItem => ({
            ...prevItem,
            'fecha': fecha
        }));
        setMatriz(prevItem => ({
            ...prevItem,
            'edad': cl
        }));
        setEdad(cl);
    }

    const finalizar = () => {
        if(matriz?.inicio) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No puede finalizar, debe ingresar la información de la matriz y guardar.', life: 3000 });
            return;
        }
        setLoadCambio(true);
        accionistaSrv.finalizar({ id: matrizId, estado: 'BORRADOR', cambio: 'a' }, numero, perfil).then(res => {
            setLoadCambio(false);
            funcSuccess(res, toast);
            regresar();
        }).catch(error => {
            setLoadCambio(false);
            funcMessageBlobError("Error", error, toast);
        });
    }

    return (
        <>
            <Panel header="Matriz" style={{ maxWidth: '800px', margin: 'auto' }} className="panel-ficha">
                <div className="grid grid-nogutter">
                    {resume(1, matriz?.empresa, 12, 1, true, '2xl')}
                    {resume(2, matriz?.nombre_plantilla, 12, 1, true, '2xl')}
                    {resume(3, `FECHA DE ANÁLISIS: ${matriz?.fecha}`, 12, 1, true, null, 'center', null, 'border-bottom-1')}
                    {resume(4, 'INFORMACIÓN DEL ' + (perfil === 'n' ? 'ACCIONISTA' : 'EMPLEADO'), 12, 0, true, '2xl', 'center', null, 'border-bottom-1')}
                    {resume(5, 'NOMBRE/RAZÓN SOCIAL:', 4, 0, true, 'sm', 'center', null, 'border-1')}
                    {resume(6, matriz?.nombres, 7, 0, false, 'sm', 'center', 'blue-100', 'border-1')}
                    {resume(7, 'IDENTIFICACIÓN:', 4, 0, true, 'sm', 'center', null, 'border-1')}
                    {resume(8, matriz?.identificacion, 5, 0, false, 'sm', 'center', 'blue-100', 'border-1')}
                    {resume(9, 'FECHA DE NACIMIENTO (AAAA-MM-DD):', 4, 0, true, 'sm', 'center', null, 'border-1')}
                    {activeIndex === 1 && inCalendarOne('fecha_nacimiento', { bg: 'blue-100', onChange: verificarEdad }, 5)}
                    {activeIndex === 0 && resume(10, matriz?.fecha_nacimiento, 5, 0, false, 'sm', 'center', 'blue-100', 'border-1')}
                    {resume(11, 'EDAD:', 4, 0, true, 'sm', 'center', null, 'border-1')}
                    {resume(12, matriz?.edad, 5, 0, false, 'sm', 'center', null, 'border-1')}
                    {resume(13, 'CARGO:', 4, 0, true, 'sm', 'center', null, 'border-1')}
                    {resume(14, matriz?.cargo, 7, 0, false, 'sm', 'center', 'blue-100', 'border-1')}
                </div>
                <hr />

                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="ANÁLISIS DOS AÑOS" className="font-bold">
                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="formgrid grid">
                                <div className="field col-4"></div>
                                {inDropAnio('anio1', rango, { lab: 'nombre', chn: onChangeRango, header: 'Período 1' }, 2)}
                                {inDropAnio('anio2', rango, { lab: 'nombre', chn: onChangeRango, header: 'Período 2' }, 2)}
                                <div className="field col-4"></div>
                            </div>
                            <div className="grid grid-nogutter" style={{ maxWidth: '100%', margin: 'auto' }}>
                                {resume(15, 'INFORMACIÓN ECONÓMICA DEL ' + (perfil === 'n' ? 'ACCIONISTA' : 'EMPLEADO'), 12, 0, true, null, 'center', null, 'border-1', 1)}
                                {resume(16, '', 3, 0, true, null, 'center', null, 'border-1')}
                                <div className="field col-4 m-0 border-1">
                                    <div className="grid grid-nogutter">
                                        {resume(17, 'PERIODOS', 12, 0, true, 'xs', 'center', null, null)}
                                        {resume(18, matriz?.anio1, 6, 0, true, 'xs', 'center', 'blue-100', 'border-1')}
                                        {resume(19, matriz?.anio2, 6, 0, true, 'xs', 'center', 'blue-100', 'border-1')}
                                    </div>
                                </div>
                                {resume(20, 'DIFERENCIA', 2, 0, true, 'xs', 'center', null, 'border-1')}
                                {resume(21, 'PORCENTAJE DE:', 3, 0, true, 'xs', 'center', null, 'border-1')}

                                {resume(101, matriz?.eco1?.nombre, 3, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {inNumber2('eco1-1-valor_anio1', 2, { chn: calcular, key: 105 })}
                                {inNumber2('eco1-1-valor_anio2', 2, { chn: calcular, key: 106 })}
                                {resume(102, matriz?.eco1?.calculado1, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(103, matriz?.eco1?.calculado2, 1, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(104, matriz?.eco1?.calculado3, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}

                                {resume(201, matriz?.eco2?.nombre, 3, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {inNumber2('eco2-2-valor_anio1', 2, { chn: calcular, key: 205 })}
                                {inNumber2('eco2-2-valor_anio2', 2, { chn: calcular, key: 206 })}
                                {resume(202, matriz?.eco2?.calculado1, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(203, matriz?.eco2?.calculado2, 1, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(204, matriz?.eco2?.calculado3, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}

                                {resume(301, matriz?.eco3?.nombre, 3, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(302, matriz?.eco3?.valor_anio1, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(303, matriz?.eco3?.valor_anio2, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(304, matriz?.eco3?.calculado1, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(305, matriz?.eco3?.calculado2, 1, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(306, matriz?.eco3?.calculado3, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}

                                {resume(401, matriz?.eco4?.nombre, 3, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {inNumber2('eco4-4-valor_anio1', 2, { chn: calcular, key: 405 })}
                                {inNumber2('eco4-4-valor_anio2', 2, { chn: calcular, key: 406 })}
                                {resume(402, matriz?.eco4?.calculado1, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(403, matriz?.eco4?.calculado2, 1, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(404, matriz?.eco4?.calculado3, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}

                                {resume(501, matriz?.eco5?.nombre, 3, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {inNumber2('eco5-5-valor_anio1', 2, { chn: calcular, key: 505 })}
                                {inNumber2('eco5-5-valor_anio2', 2, { chn: calcular, key: 506 })}
                                {resume(502, matriz?.eco5?.calculado1, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(503, matriz?.eco5?.calculado2, 1, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(504, matriz?.eco5?.calculado3, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}

                                {resume(601, matriz?.eco6?.nombre, 3, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(602, matriz?.eco6?.valor_anio1, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(603, matriz?.eco6?.valor_anio2, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(604, matriz?.eco6?.calculado1, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(605, matriz?.eco6?.calculado2, 1, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(606, matriz?.eco6?.calculado3, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}

                                {resume(701, matriz?.eco7?.nombre, 3, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(702, matriz?.eco7?.valor_anio1, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(703, matriz?.eco7?.valor_anio2, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(704, matriz?.eco7?.calculado1, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(705, matriz?.eco7?.calculado2, 1, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(706, matriz?.eco7?.calculado3, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}

                                {resume(801, matriz?.eco8?.nombre, 3, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {inNumber2('eco8-8-valor_anio1', 2, { chn: calcular, key: 809 })}
                                {inNumber2('eco8-8-valor_anio2', 2, { chn: calcular, key: 810 })}
                                {resume(802, matriz?.eco8?.calculado1, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(803, matriz?.eco8?.calculado2, 1, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(804, matriz?.eco8?.calculado3, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}

                                {resume(901, matriz?.eco9?.nombre, 3, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(902, matriz?.eco9?.valor_anio1, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(903, matriz?.eco9?.valor_anio2, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(904, matriz?.eco9?.calculado1, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(905, matriz?.eco9?.calculado2, 1, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(906, matriz?.eco9?.calculado3, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}

                                {resume(1001, matriz?.eco10?.nombre, 3, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(1002, matriz?.eco10?.valor_anio1, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(1003, matriz?.eco10?.valor_anio2, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(1004, matriz?.eco10?.calculado1, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(1005, matriz?.eco10?.calculado2, 1, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                                {resume(1006, matriz?.eco10?.calculado3, 2, 0, true, 'xs', 'center', 'black-alpha-60', 'border-1 text-white')}
                            </div>
                            <br />

                            <div className="grid grid-nogutter" style={{ maxWidth: '100%', margin: 'auto' }}>
                                {resume(50, 'INFORMACIÓN PATRIMONIAL DECLARADA POR EL ' + (perfil === 'n' ? 'ACCIONISTA' : 'EMPLEADO'), 12, 0, true, null, 'center', null, 'border-1')}
                                {resume(51, '', 3, 0, true, null, 'center', null, 'border-1')}
                                <div className="field col-4 m-0 border-1">
                                    <div className="grid grid-nogutter">
                                        {resume(22, 'PERIODOS', 12, 0, true, 'xs', 'center', null, null)}
                                        {resume(23, matriz?.anio1, 6, 0, true, 'xs', 'center', null, 'border-1')}
                                        {resume(24, matriz?.anio2, 6, 0, true, 'xs', 'center', null, 'border-1')}
                                    </div>
                                </div>
                                {resume(25, 'DIFERENCIA', 2, 0, true, 'xs', 'center', null, 'border-1')}
                                {resume(26, 'PORCENTAJE DE:', 3, 0, true, 'xs', 'center', null, 'border-1')}

                                {resume(1101, matriz?.pat11?.nombre, 3, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {inNumber2('pat11-11-valor_anio1', 2, { chn: calcular, key: 1105 })}
                                {inNumber2('pat11-11-valor_anio2', 2, { chn: calcular, key: 1106 })}
                                {resume(1102, matriz?.pat11?.calculado1, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(1103, matriz?.pat11?.calculado2, 1, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(1104, matriz?.pat11?.calculado3, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}

                                {resume(1201, matriz?.pat12?.nombre, 3, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {inNumber2('pat12-12-valor_anio1', 2, { chn: calcular, key: 1205 })}
                                {inNumber2('pat12-12-valor_anio2', 2, { chn: calcular, key: 1206 })}
                                {resume(1202, matriz?.pat12?.calculado1, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(1203, matriz?.pat12?.calculado2, 1, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(1204, matriz?.pat12?.calculado3, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}

                                {resume(1301, matriz?.pat13?.nombre, 3, 0, false, 'xs', 'center', null, 'border-1')}
                                {resume(1302, matriz?.pat13?.valor_anio1, 2, 0, true, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(1303, matriz?.pat13?.valor_anio2, 2, 0, true, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(1304, matriz?.pat13?.calculado1, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(1305, matriz?.pat13?.calculado2, 1, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                                {resume(1306, matriz?.pat13?.calculado3, 2, 0, false, 'xs', 'center', 'yellow-100', 'border-1')}
                            </div>
                            <br />

                            <div className="grid grid-nogutter" style={{ maxWidth: '100%', margin: 'auto' }}>
                                {resume(33, 'SEÑALES DE ALERTA:', 9, 0, true, null, 'center', null, null)}
                                {resume(34, '', 12, 0, true, null, 'center', null, null)}

                                <div className="field col-8 m-0">
                                    <div className="grid grid-nogutter">
                                        {resume(1401, matriz?.sep14?.nombre, 4, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1402, matriz?.anio1, 2, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1403, matriz?.sep14?.valor_anio2, 2, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1404, matriz?.sep14?.calculado1, 4, 0, true, 'xs', 'center', colorSenialDos(matriz?.sep14?.calculado1), 'border-1')}

                                        {resume(1501, matriz?.sep15?.nombre, 4, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1502, matriz?.anio2, 2, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1503, matriz?.sep15?.valor_anio2, 2, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1504, matriz?.sep15?.calculado1, 4, 0, true, 'xs', 'center', colorSenialDos(matriz?.sep15?.calculado1), 'border-1')}
                                    </div>
                                </div>
                                {resume(35, texto(1), 4, 0, false, '9px', 'center', null, null)}

                                <div className="field col-8 m-0">
                                    <div className="grid grid-nogutter">
                                        {resume(1601, matriz?.sep16?.nombre, 4, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1602, matriz?.anio1, 2, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1603, matriz?.sep16?.valor_anio2, 2, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1604, '', 4, 0, true, null, 'center', null, null)}

                                        {resume(1701, matriz?.sep17?.nombre, 4, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1702, matriz?.anio2, 2, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1703, matriz?.sep17?.valor_anio2, 2, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1704, '', 4, 0, true, null, 'center', null, null)}
                                    </div>
                                </div>
                                {resume(36, '', 4, 0, false, '9px', 'center', null, null)}

                                <div className="field col-8 m-0">
                                    <div className="grid grid-nogutter">
                                        {resume(1801, matriz?.sep18?.nombre, 4, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1802, matriz?.anio1, 2, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1803, matriz?.sep18?.valor_anio2, 2, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1804, matriz?.sep18?.calculado1, 4, 0, true, 'xs', 'center', colorSenialDos(matriz?.sep18?.calculado1), 'border-1')}

                                        {resume(1901, matriz?.sep19?.nombre, 4, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1902, matriz?.anio2, 2, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1903, matriz?.sep19?.valor_anio2, 2, 0, false, 'xs', 'center', null, 'border-1')}
                                        {resume(1904, matriz?.sep19?.calculado1, 4, 0, true, 'xs', 'center', colorSenialDos(matriz?.sep19?.calculado1), 'border-1')}
                                    </div>
                                </div>
                                {resume(37, texto(2), 4, 0, false, '9px', 'center', null, 'border-1')}
                                {resume(38, '', 12, 0, false, '9px', 'center', null, 'border-left-1 h-1rem')}

                                <div className="field col-8 m-0" key="39">
                                    <div className="grid grid-nogutter" key="40">{matriz && infoSenial2()}</div>
                                </div>
                                {resume(41, '', 4, 0, false, '9px', 'center', null, null)}

                            </div>

                            <br />
                            {buttonsMatriz({
                                permss, btn1: { setDlg: setDialogPerfil }, btn2: { loadSave }, btn3: { loadReport, chn: generarReporte },
                                btn4: { msg: <>Verifique que la matriz esté guardada (Imprimir).<br />¿Desea finalizar la matriz?</>, chn: finalizar, loadCambio }
                            })}
                        </form>
                    </TabPanel>
                    <TabPanel header={estadoUno === 'BORRADOR' ? "ANÁLISIS UN AÑO" : "ANÁLISIS UN AÑO - Finalizado"} disabled={estadoUno && estadoUno !== 'BORRADOR'} >
                        <DetalleAccionistaUno matrizId={matrizId} permss={permss} setDialogPerfil={setDialogPerfil} toast={toast} edad={edad} fecha={getValues('fecha_nacimiento')}
                            perfil={perfil} uno={uno} regresarUno={regresarUno} />
                    </TabPanel>
                </TabView>

                <br />
            </Panel>
        </>
    )
}
