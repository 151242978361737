import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import React, { useEffect } from 'react';
import { RepresentanteApoderado } from '../RepresentanteApoderado';
import { Message } from 'primereact/message';

export const DatosPJ = ({ toast, back, next, id, permss }) => {

  const { loadLazyDeclarante, tableDeclarante, dialogoDeclarante } = RepresentanteApoderado({ toast: toast, tipo: 'clientepj', id: id, permss });

  useEffect(() => {
    loadLazyDeclarante(id);
  }, [id])

  const Siguiente = () => {
    next(id);
  };

  return (
    <>
      <Message severity="info" text="Estimado usuario, este campo utiliza información de todo el documento, así que por favor completar las secciones 1,3,5,6,7 y 8, GUARDARLAS y llenar las secciones 2 y 4." style={{ width: '100%' }} />
      <Panel header="DATOS DEL REPRESENTANTE LEGAL/ APODERADO/ BENEFICIARIO FINAL (DE SER EL CASO):" className="panel-ficha">
        {tableDeclarante()}
      </Panel>
      <br />
      <div className="formgrid grid">
        <div className="field col">
          <Button label="Anterior" icon="pi pi-chevron-left" onClick={back} />
        </div>
        <div className="field col">
          <Button label="Siguiente" icon="pi pi-chevron-right" onClick={Siguiente} />
        </div>
      </div>
      {dialogoDeclarante()}
    </>
  );
}