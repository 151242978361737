import React from 'react';
import { classNames } from 'primereact/utils';
import { Link, useHistory } from 'react-router-dom';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';
import { useToken } from './components/useToken';
import AuthService from './service/AuthService';

const AppTopbar = (props) => {

    const profileItemClassName = classNames('profile-item', { 'active-menuitem fadeInDown': props.topbarUserMenuActive });
    const authService = new AuthService();
    const { closeSession, getNombre, getNumRoles, getNumRolesAd, getNombreEmpresa, getRol } = useToken();
    const history = useHistory();

    const cerrarSesion = () => {
        authService.outLogin({ cerrar: true }).then(res => {
            closeSession();
            history.push("/");
        }).catch(error => { });
    }

    return (
        <div className="layout-topbar">
            <div className="topbar-left">
                <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-left"></i>
                </button>

                <Link to="/">
                    <img id="logo-horizontal" className="horizontal-logo" src="" alt="diamond-layout" />
                </Link>

                <span className="topbar-separator"></span>

                <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
                    <AppBreadcrumb routers={props.routers} />
                </div>

                <img id="logo-mobile" className="mobile-logo" src="" alt="diamond-layout" />
            </div>

            <AppMenu model={props.menu} menuMode={props.menuMode} active={props.menuActive} mobileMenuActive={props.staticMenuMobileActive}
                onMenuClick={props.onMenuClick} onMenuitemClick={props.onMenuitemClick} onRootMenuitemClick={props.onRootMenuitemClick}></AppMenu>

            <div className="layout-mask modal-in"></div>

            <div className="topbar-right">
                <ul className="topbar-menu">
                    <li className={profileItemClassName}>
                        <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                            <span className="profile-name">{getNombre()}</span>
                        </button>
                        <ul className="profile-menu fade-in-up">
                            {getNombreEmpresa() && <li><span><b>Empresa/Grupo:</b> {getNombreEmpresa()}</span></li>}
                            {getRol() && getRol().grupo && <li><span><b>Empresa/Grupo:</b> {getRol().grupo}</span></li>}
                            {getRol() && getRol().nombre && <li><span><b>Rol:</b> {getRol().nombre}</span></li>}
                            {getRol() && getRol().observacion && <li><span><b>{getRol().observacion}</b></span></li>}
                            <hr />
                            {(getNumRoles() + getNumRolesAd()) > 0 &&
                                <li>
                                    <button type="button" className="p-link" onClick={() => props.setDialogRol(true)}>
                                        <i className="pi pi-users"></i>
                                        <span>Roles Asignados</span>
                                    </button>
                                </li>
                            }
                            <li>
                                <button type="button" className="p-link" onClick={cerrarSesion}>
                                    <i className="pi pi-power-off"></i>
                                    <span>Cerrar sesión</span>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

        </div>
    );
}

export default AppTopbar;
