export class ActvCpn {
    model = {
        direccion: {
            value: '',
            export: true,
            header: 'Calle Principal No. y Calle Secundaria:',
            required: 'Dirección domicilio es requerido',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 500,
            min: 4
        },
        pais_dd: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia_dd: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad_dd: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton_dd: {
            value: '',
            export: false,
            header: 'Ciudad',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Ciudad es requerido',
        },
        codigo_postal: {
            value: '',
            export: false,
            header: 'Apartado postal: ( en caso de residir en el extranjero)',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Apartado postal es requerido',
        },
        telefono_domicilio: {
            value: '',
            export: true,
            header: 'Teléfono domicilio:',
            required: 'No. de teléfono del domicilio es requerido',
            maxLength: 'Máximo 30 caracteres',
            minLength: 'Mínimo 7 caracteres',
            max: 30,
            min: 7,
            filterField: 'telefono_celular'
        },
        telefono_celular: {
            value: '',
            export: true,
            header: 'No. Celular:',
            required: 'No. Celular es requerido',
            maxLength: 'Máximo 30 caracteres',
            minLength: 'Mínimo 7 caracteres',
            max: 30,
            min: 7,
            filterField: 'telefono_celular'
        },
        email: {
            value: '',
            export: false,
            header: 'Correo electrónico personal:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Correo electrónico es requerido',
        },
        situacion_laboral: {
            value: null,
            export: false,
            header: 'Situación laboral:',
            required: 'Tipo de formulario es requerido'
        },
        detalle_sl: {
            value: '',
            export: true,
            header: 'Detalle:',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
            required: false,
        },
        lugar_trabajo: {
            value: '',
            export: true,
            header: 'Nombre del lugar de trabajo/negocio propio:',
            required: 'Nombre es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
        },
        cargo: {
            value: '',
            export: false,
            header: 'Cargo:',
            required: 'Cargo es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
        },
        ruc: {
            value: '',
            export: false,
            header: 'Número de RUC en el caso de tener negocio propio:',
            required: false,
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
        },
        tiempo_trabaja: {
            value: '',
            export: false,
            header: 'Tiempo que labora en el lugar de trabajo/negocio propio. Ejemplo: 2 años y 10 meses:',
            required: 'Tiempo es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
        },
        actividad_economica: {
            value: null,
            export: false,
            header: 'Actividad económica de la entidad donde labora o negocio propio:',
            required: 'Actividad económica es requerida'
        },
        direccion_trabajo: {
            value: '',
            export: false,
            header: 'Dirección completa de lugar de trabajo:',
            required: 'Dirección es requerido',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
        },
        pais_ae: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia_ae: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad_ae: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerido'
        },
        canton_ae: {
            value: '',
            export: false,
            header: 'Ciudad',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 100,
            min: 4,
            required: 'Ciudad es requerido'
        },
        ingreso_mensual: {
            value: 0,
            export: false,
            header: 'Ingreso Mensual:',
            required: 'Ingreso Mensual en Relación de dependencia es requerido'
        },
        egreso_mensual: {
            value: 0,
            export: false,
            header: 'Egreso Mensual:',
            required: 'Egreso Mensual es requerido'
        },
        otro_ingreso_mes: {
            value: 0,
            export: false,
            header: 'Otros Ingresos mensuales del declarante USD:',
            required: 'Otro ingreso Mensual es requerido'
        },
        detalle_otro_ingreso: {
            value: '',
            export: false,
            header: 'Especifique su fuente:',
            required: false
        },
        telefono_trabajo: {
            value: '',
            export: true,
            header: 'Teléfono laboral:',
            required: 'Teléfono es requerido',
            maxLength: 'Máximo 30 caracteres',
            minLength: 'Mínimo 7 caracteres',
            max: 30,
            min: 7,
            filterField: 'Teléfono laboral'
        },
        email_trabajo: {
            value: '',
            export: false,
            header: 'Correo electrónico laboral:',
            required: 'Correo electrónico es requerido'
        },
    }
    optionLabel = 'nombre';
}
