import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { GenericComponent } from '../../../components/GenericComponent';
import { CatalogoService } from '../../../service/CatalogoService';
import { ClientePJService } from '../../../service/ClientePJService';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InfoCpj } from '../../../models/cpj/InfoCpj';

export const InformacionPJ = ({ toast, siCliente, catalogos, next, id, editFicha, permss }) => {

    const generic = new GenericComponent();
    const omodel = new InfoCpj();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, trigger, setValue } = useForm({ defaultValues });
    const [clientepj] = useState([]);
    const clientepjSrv = new ClientePJService();
    const catalogoSrv = new CatalogoService();
    const [ecuador, setEcuador] = useState(false);
    const [ciudades, setCiudades] = useState([]);
    const [ecuadorDD, setEcuadorDD] = useState(false);
    const [ciudadesDD, setCiudadesDD] = useState([]);
    const [selectedCiiu, setSelectedCiiu] = useState(null);
    const [ciius, setCiius] = useState([]);
    const [load, setLoad] = useState(false);
    const { inDrop, inText, inCalendar, inAutocomplete } = InputComponent({ registers: clientepj, omodel, generic, errors, control, permss, id });

    const onChangePais = (e) => {
        setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvincia = (e) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudades(res.data);
        });
    }

    const onChangePaisDD = (e) => {
        setEcuadorDD(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvinciaDD = (e) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudadesDD(res.data);
        });
    }

    const searchCiiu = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setCiius([...ciius]);
            }
            else {
                catalogoSrv.getActividades(event.query.toLowerCase()).then(data => {
                    setCiius(data.data);
                });
            }
        });
    }

    useEffect(() => {
        if (id != null) {
            editarFicha();
        }
    }, [id])

    const editarFicha = () => {
        clientepjSrv.consultarClientepj(id, 'p1').then(res => {
            let cats = catalogos;
            let _clientepj = res.clientepj;
            const lista = {
                pais: cats.pais,
                tipo_formulario: cats.tipoFormulario,
                tipo_solicitante: cats.tipoSolicitante,
                nacionalidad: cats.nacionalidad,
                pais_dd: cats.pais,
            };
            if (res.ciudades != undefined) {
                lista['ciudad'] = res.ciudades;
                lista['provincia'] = cats.provincias;
                setCiudades(res.ciudades);
            }
            if (res.ciudad_dd != undefined) {
                lista['ciudad_dd'] = res.ciudad_dd;
                lista['provincia_dd'] = cats.provincias;
                setCiudadesDD(res.ciudad_dd);
            }
            Object.keys(lista).forEach(function (key) {
                if (_clientepj[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _clientepj[key]) {
                            _clientepj[key] = element;
                        }
                    });
                }
            });
            setEcuador(JSON.stringify(_clientepj.pais) === JSON.stringify(cats.ecuador));
            setEcuadorDD(JSON.stringify(_clientepj.pais_dd) === JSON.stringify(cats.ecuador));
            if (_clientepj['actividad_economica'] != null) {
                _clientepj['actividad_economica'] = res.actividad_economica;
                setSelectedCiiu(res.actividad_economica);
            }

            //Para actualizaciones
            _clientepj.fecha = generic.stringToDate(_clientepj.fecha);
            _clientepj.fecha_ini_actividad = generic.stringToDate(_clientepj.fecha_ini_actividad);

            reset(_clientepj);
        });
    }

    const onSubmit = (data) => {
        data.fecha = data.fecha.toISOString().split('T')[0];
        data.fecha_ini_actividad = data.fecha_ini_actividad.toISOString().split('T')[0];
        data.ec = catalogos.ecuador.cat_id;
        data.ecDD = catalogos.ecuador.cat_id;
        data.paso = 'p1';
        setLoad(true);

        if (id == null) {
            clientepjSrv.newClientepj(data).then(res => {
                setLoad(false);
                setValue('id', res.data);
                editFicha({ id: res.data });
                trigger('id');
                generic.funcSuccess(res, toast);
                next(res.data);
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
        else {
            data.id = id;
            clientepjSrv.editarClientepj(data).then(res => {
                setLoad(false);
                generic.funcSuccess(res, toast);
                next(id);
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
    }

    return (
        <>
            < form onSubmit={handleSubmit(onSubmit)}>
                <div className="formgrid grid">
                    {!siCliente && catalogos?.empresas && !id && inDrop('empresa', catalogos?.empresas, { lab: omodel.optionLabel })}
                    {inCalendar('fecha')}
                    {inDrop('pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: false })}
                    {ecuador && inDrop('provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: false })}
                    {ecuador && inDrop('ciudad', ciudades, { lab: omodel.optionLabel })}
                    {!ecuador && inText('canton')}

                    {inText('compania')}
                    {inText('proyecto')}
                    {inDrop('tipo_formulario', catalogos?.tipoFormulario, { lab: omodel.optionLabel })}
                    {inDrop('tipo_solicitante', catalogos?.tipoSolicitante, { lab: omodel.optionLabel })}
                </div>
                <Panel header="PARTE A" className="panel-ficha">
                    <Panel header="A.1 DATOS GENERALES DEL CLIENTE" className="panel-ficha">
                        <div className="formgrid grid">
                            {inText('razon_social')}
                            {inText('identificacion')}
                            {inDrop('nacionalidad', catalogos?.nacionalidad, { lab: omodel.optionLabel })}
                            {inCalendar('fecha_ini_actividad')}

                            {inAutocomplete('actividad_economica', selectedCiiu, setSelectedCiiu, ciius, searchCiiu, { lab: 'nombre', col: 6 })}
                            {inText('dirreccion_oficina')}
                            {inDrop('pais_dd', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePaisDD, val: false })}
                            {ecuadorDD && inDrop('provincia_dd', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvinciaDD, val: false })}
                            {ecuadorDD && inDrop('ciudad_dd', ciudadesDD, { lab: omodel.optionLabel })}
                            {!ecuadorDD && inText('canton_dd')}
                            {inText('codigo_postal')}

                            {inText('telefono_empresa')}
                            {inText('email_pga_web')}
                        </div>
                    </Panel >
                </Panel>
                <div className="formgrid grid">
                    <div className="field col">
                        <br />
                        <Button label="Siguiente" icon="pi pi-chevron-right" type="submit" loading={load} disabled={load} />
                    </div>
                </div>
            </form >
        </>
    )
}
