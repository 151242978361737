export class PepCpj {
    model = {
        pep: {
            value: false,
            export: false,
            header: '¿Es el Representante Legal o algún Socio/Accionista un PEP?. En el caso de ser afirmativo colocar un check en el cuadro; caso contrario dejar en blanco.:',
            required: 'Campo requerido.'
        },
        nombre_pep: {
            value: '',
            export: false,
            header: 'Nombre del PEP',
            required: 'Nombre es requerido.'
        },
        cargo_pep: {
            value: '',
            export: false,
            header: 'Cargo',
            required: 'Cargo es requerido.'
        },
        institucion_trabaja_pep: {
            value: '',
            export: false,
            header: 'Institución donde trabaja o trabajó',
            required: 'Institución es requerido.'
        },
        fecha_nombramiento_pep: {
            value: null,
            export: false,
            header: 'Fecha del nombramiento de la designación (día/mes/año):',
            required: 'Fecha es requerido'
        },
        fecha_dejo_pep: {
            value: null,
            export: false,
            header: 'Fecha en que dejó de ser PEP, de no estar en funciones (día/mes/año):',
            required: false
        },
    }
    strPep = <><b>PEP</b>: Son todas aquellas personas naturales, nacionales o extranjeras, que desempeñan o han desempeñado funciones o cargos públicas destacados en el Ecuador o en el Extranjero; 
        o funciones prominentes en una organización internacional, conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo de control 
        respectivo.<br /> <b>FAMILIARES O RELACIONADOS:</b> Son las relaciones comerciales o contractuales que involucren al cónyuge o a las personas unidas bajo el régimen legal de unión de hecho reconocida 
        legalmente; o a familiares comprendidos hasta el segundo grado de consanguinidad o primero de afinidad; y, el o los colaborador (es) directo (s) esto es la (s) persona (s) natural (es) 
        con las cuales una Persona Expuesta Políticamente (PEP). se encuentre asociada o vinculada societariamente, o mantenga otro tipo de relaciones empresariales, comerciales o laborales 
        estrechas, serán consideradas como PEP.</>;
}