export class UsuarioRuc {
    model = {
        id: {value: null, export: false},
        ruc: {value: '', export: true, header: 'Identificación', required: 'Campo requerido', maxLength: 'Máximo 50 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'ruc', table: true, filter: false, sort: true},
        nombres: {value: '', export: true, header: 'Nombres', required: 'Campo requerido', maxLength: 'Máximo 700 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 700, min: 4, filterField: 'nombres', table: true, filter: false, sort: true},
        estado: {header: 'Estado', value: {nombre: 'Activo', cat_id: true}, required: 'Estado es requerido', optionLabel: 'nombre', export: true, table: true},
    }
    optionLabel = 'nombre'
}