import { useEffect, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { PepForm } from "../../models/ficha/PepForm";
import { PepFichaService } from "../../service/ficha/PepFichaService";
import { Generico } from "../../components/Generico";
import { useForm } from "react-hook-form";
import { InputComponent } from "../../components/InputComponent";
import { CatalogoService } from "../../service/CatalogoService";
import { Divider } from "primereact/divider";
import { Panel } from "primereact/panel";
import { PepFamiliar } from "./PepFamiliar";
import { PepCargo } from "./PepCargo";
import { TabPanel, TabView } from "primereact/tabview";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { FileBvg } from "../../components/FileBvg";
import { FileComponent } from "../../components/FileComponent";
import { Button } from "primereact/button";
import { Message } from "primereact/message";

export const FormPep = ({ tipo, idp, idOrigen, permss, toast, reiniciar, btnRegresar }) => {
    const generic = new GenericComponent();
    const omodel = new PepForm();
    const pepSrv = new PepFichaService();
    const { funcError, funDefault, funcSuccess, stringToDate, estadosSiNo, buttonsBGV, downloadPdf, funcMessageBlobError } = Generico();
    const catalogoSrv = new CatalogoService();
    const defaultValues = funDefault(omodel.model);
    const [ciudades, setCiudades] = useState(null);
    const [ciudadCumple, setCiudadCumple] = useState(null);
    const [catalogos, setCatalogos] = useState(null);
    const [ecuador, setEcuador] = useState(false);
    const [ecuadorCumple, setEcuadorCumple] = useState(false);
    const [load, setLoad] = useState(false);
    const [loadReport, setLoadReport] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [id, setId] = useState(0);
    const [estado, setEstado] = useState(null);
    const [dialogSubir, setDialogSubir] = useState(false);
    const [urls, setUrls] = useState(null);
    const { control, formState: { errors }, handleSubmit, reset, setError, setValue } = useForm({ defaultValues });
    const { inDrop, inText, inCalendar } = InputComponent({ omodel, errors, control, permss, id });
    const { tableFamiliar, dialogoFamiliar, loadLazyFamiliar } = PepFamiliar({ tipo, id, toast, permss, opcion: 'pep-familiar', catalogos });
    const { tableCargo, dialogoCargo, loadLazyCargo } = PepCargo({ tipo, id, toast, permss, opcion: 'pep-cargo', catalogos });
    const { enviarFormularioPep } = FileComponent({ generic, toast });
    const [siguiente, setSiguiente] = useState(null);
    const [enCargo, setEnCargo] = useState(false);

    useEffect(() => {
        setUrls({
            urlListado: 'ficha/pep-ficha',
            urlDownload: 'ficha/pep-ficha/descarga',
            urlUpload: 'ficha/pep-ficha/store',
            urlVerificacion: 'ficha/pep-ficha',
            udelete: 'ficha/pep-ficha/eliminar-anexo',
            uactual: 'ficha/pep-ficha/editar',
            uenviar: 'ficha/pep-ficha/enviar',
            uaprobar: 'ficha/pep-ficha/aprobar'
        });
        if (idOrigen && idOrigen > 0) {
            setId(idOrigen);
            loadLazyFamiliar(idOrigen);
            loadLazyCargo(idOrigen);
        }
        editFicha();
    }, [idp]);

    const onChangePais = (e, opc) => {
        if (opc) {
            setEcuadorCumple(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
        } else {
            setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
        }
    }

    const onChangeProvincia = (e, opc) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            if (opc) {
                setCiudadCumple(res.data);
            } else {
                setCiudades(res.data);
            }
        });
    }

    const editFicha = () => {
        const inicio = catalogos === null ? 1 : 0;
        pepSrv.consultarPep(idOrigen ?? 0, 'pep-ficha', tipo, idp ?? 0, inicio).then(res => {
            let _cats = catalogos;
            if (catalogos === null) {
                setCatalogos(res.data.cats);
                _cats = res.data.cats;
            }
            let _pep = res.data.pep;

            if (!_pep || Object.keys(_pep).length === 0) {
                reset(funDefault(omodel.model));
                return;
            }
            const lista = {
                pais_cumple: _cats.pais,
                tipo_formulario: _cats.tipoFormulario,
                en_cargo: estadosSiNo
            };
            lista.pais = _cats.pais;
            lista.tipo_identificacion = _cats.tipo;
            if (res.data.ciudades !== undefined) {
                lista.ciudad = res.data.ciudades;
                lista.provincia = _cats.provincias;
                setCiudades(res.data.ciudades);
            }
            if (res.data.ciudades_cumple !== undefined) {
                lista.ciudad_cumple = res.data.ciudades_cumple;
                lista.provincia_cumple = _cats.provincias;
                setCiudadCumple(res.data.ciudades_cumple);
            }
            setEnCargo(_pep.en_cargo);
            Object.keys(lista).forEach(function (key) {
                if (_pep[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _pep[key]) {
                            _pep[key] = element;
                        }
                    });
                }
            });
            setId(_pep.id);

            if(_pep?.id && parseInt(_pep?.id) > 0){
                loadLazyFamiliar(_pep.id);
                loadLazyCargo(_pep.id);
            }
            if (_pep.fecha) _pep.fecha = stringToDate(_pep.fecha);
            if (_pep.pais) setEcuador(JSON.stringify(_pep.pais) === JSON.stringify(_cats.ecuador));
            if (_pep.pais_cumple) setEcuadorCumple(JSON.stringify(_pep.pais_cumple) === JSON.stringify(_cats.ecuador));
            if (_pep.fecha_inicio) _pep.fecha_inicio = stringToDate(_pep.fecha_inicio);
            if (_pep.fecha_fin && !enCargo) _pep.fecha_fin = stringToDate(_pep.fecha_fin);
            if (_pep.fecha_aprobacion) _pep.fecha_aprobacion = stringToDate(_pep.fecha_aprobacion);

            if(!(_pep.tipo_identificacion instanceof Object)){
                delete _pep.tipo_identificacion;
            }

            setEstado(_pep.estado);
            reset(generic.renewValues(_pep, omodel.model));
        });
    }

    const onSubmit = (data) => {
        data.tipo = tipo;
        data.idp = idp;
        data.indice = activeIndex;
        if (activeIndex === 0) {
            data.fecha = data.fecha.toISOString().split('T')[0];
        }
        if (activeIndex === 1) {
            data.fecha_inicio = data.fecha_inicio.toISOString().split('T')[0];

            if(enCargo){
               delete data.fecha_fin;
            } else {
                data.fecha_fin = data.fecha_fin.toISOString().split('T')[0];
            }
        }
        data.ec = catalogos.ecuador;
        data.casado = catalogos.casado;
        setLoad(true);
        if (id == null || id <= 0) {
            pepSrv.newPep(data, 'pep-ficha').then(res => {
                setLoad(false);
                setValue('id', res.data.codigo);
                setId(res.data.codigo);
                setEstado(res.data.estado);
                funcSuccess(res, toast);
                actionBtn();
            }).catch(error => {
                setLoad(false);
                funcError(error, toast, setError);
            });
        }
        else {
            pepSrv.editarPep(data, 'pep-ficha').then(res => {
                setLoad(false);
                funcSuccess(res, toast);
                actionBtn();
            }).catch(error => {
                setLoad(false);
                funcError(error, toast, setError);
            });
        }
    }

    const actionBtn = () => {
        if (siguiente && activeIndex <= 1) {
            setActiveIndex(activeIndex + 1);
        }
        if (!siguiente && activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    }

    const generarReporte = () => {
        setLoadReport(true);
        pepSrv.descargar(id, 'pep-ficha').then(res => {
            downloadPdf(res, id + '-Formulario-pep.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            funcMessageBlobError('Complete la información antes de generar.', error, toast);
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const subirReporte = () => {
        setDialogSubir(true);
    }

    const enviar = () => {
        enviarFormularioPep(id, urls.uenviar, tipo, reiniciar);
    }

    const confirmRegresar = (ban) => {
        confirmDialog({
            message: '¿Esta seguro de cancelar?. Si confirma no se guardaran los cambios.',
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => {
                reiniciar(ban);
            },
        });
    }

    const btnNext = (sig) => {
        return (
            <>
                <Button label={sig ? "Siguiente" : "Anterior"} type="submit" icon={sig ? "pi pi-chevron-right" : "pi pi-chevron-left"} className="p-button-success"
                    onClick={() => setSiguiente(sig)} style={{ width: '130px' }} />
            </>
        )
    }

    const onChangeCargo = (e) => {
        let _cargo = false;
        if(e.value) {
             _cargo = e.value?.cat_id;
        }
        setEnCargo(_cargo);
    }

    return (
        <>
            <Panel header="Personas Expuestas Política y Públicamente" className="panel-ficha">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Sección 1">
                            {activeIndex === 0 && <div className="formgrid grid">
                                {inDrop('tipo_formulario', catalogos?.tipoFormulario, { lab: omodel.optionLabel })}
                                {inCalendar('fecha')}
                                {inDrop('pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: false })}
                                {ecuador && inDrop('provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: false })}
                                {ecuador && inDrop('ciudad', ciudades, { lab: omodel.optionLabel })}
                                {!ecuador && inText('canton')}
                                <div className="field col-12">
                                    <Divider />
                                    {omodel.cab1}
                                    <Divider />
                                </div>

                                {inText('apellidos')}
                                {inText('nombres')}
                                {inDrop('tipo_identificacion', catalogos?.tipo, { lab: omodel.optionLabel })}
                                {inText('identificacion')}
                                <div className="field col-12">
                                    {parseInt(idp) > 0 && parseInt(id) <= 0 && <><Message severity="info" style={{ width: '100%' }} text={omodel.msgPep} /><br /></>}
                                    {btnNext(true)}
                                </div>
                            </div>}
                        </TabPanel>

                        <TabPanel header="Sección 2" disabled={id === null || id === undefined || id <= 0}>
                            {activeIndex === 1 && <div className="formgrid grid">
                                {tableFamiliar()}

                                {inText('razon_social_cumple')}
                                {inText('direccion_cumple')}
                                {inDrop('pais_cumple', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: true })}
                                {ecuadorCumple && inDrop('provincia_cumple', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: true })}
                                {ecuadorCumple && inDrop('ciudad_cumple', ciudadCumple, { lab: omodel.optionLabel })}
                                {!ecuadorCumple && inText('canton_cumple')}

                                {inDrop('en_cargo', estadosSiNo, { lab: omodel.optionLabel, chn: onChangeCargo })}
                                {inText('observacion_cargo')}
                                {inCalendar('fecha_inicio')}
                                {!enCargo && inCalendar('fecha_fin')}
                                {tableCargo()}

                                <div className="field col-12"><br />{btnNext(false)}&nbsp;{btnNext(true)}</div>
                            </div>}
                        </TabPanel>

                        <TabPanel header="Sección 3" disabled={id === null || id === undefined || id <= 0}>
                            {activeIndex === 2 && <div className="formgrid grid">
                                <div className="field col-12">
                                    <Divider />
                                    {omodel.cab2}
                                    <Divider />
                                </div>

                                {inText('tipo_relacion', {}, 4)}
                                {inText('cargo_funcion', {}, 4)}
                                {inText('nombre_razon_social', {}, 4)}
                                <Panel header="DECLARACIÓN Y AUTORIZACIÓN" className="panel-ficha col-12">
                                    {omodel.cap3}
                                    <div className="field col-2" style={{ textAlign: 'center' }}>
                                        <Divider />
                                        <span style={{ fontWeight: 'bold', fontSize: '10px' }}>Firma del Declarante</span>
                                    </div>
                                </Panel>

                                <Panel header="APROBACIÓN PARA PARTÍCPES DE MERCADO DE VALORES" className="panel-ficha col-12">
                                    <div className="formgrid grid">
                                        <div className="field col-12" style={{ textAlign: 'justify' }}>
                                            {omodel.cap4}
                                        </div>

                                        <div className="field col-2" style={{ textAlign: 'center' }}>
                                            <Divider />
                                            <span style={{ fontWeight: 'bold', fontSize: '10px' }}>Firma del Representante Legal</span>
                                        </div>
                                        <div className="field col-3"></div>

                                        <Divider />
                                        <div className="field col-12" style={{ textAlign: 'justify' }}>
                                            {omodel.cap5}
                                        </div>
                                    </div>
                                </Panel>
                                <br />

                                <Panel header='LISTA DE COMPROBACIÓN DE INFORMACIÓN Y DOCUMENTOS ADJUNTOS AL FORMULARIO "Persona Expuesta Políticamente" (CHECK LIST).' className="panel-ficha col-12">
                                    <div className="formgrid grid">
                                        <div className="field col-5">
                                            {omodel.anexos}
                                        </div>
                                        <div className="field col-1">
                                            <Divider layout="vertical" />
                                        </div>
                                        <div className="field col-6">
                                            {omodel.lchk}
                                        </div>
                                    </div>
                                </Panel>

                                <div className="field col-1"><br />{btnNext(false)}</div>
                                <div className="field col-11" style={{ textAlign: 'center' }}>
                                    <br />
                                    {buttonsBGV(confirmRegresar, load, generarReporte, subirReporte, enviar, loadReport, id != null)}
                                </div>
                            </div>
                            }
                        </TabPanel>
                    </TabView>
                </form>
                {btnRegresar !== undefined && btnRegresar()}
            </Panel >

            <Dialog visible={dialogSubir} header="Gestión de anexos" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo={tipo} urls={urls} idp={id} toast={toast} permss={permss} estado={estado} reiniciar={reiniciar} setDialogSubir={setDialogSubir} />
            </Dialog>
            {dialogoFamiliar()}
            {dialogoCargo()}

        </>
    )
}
