import { Panel } from "primereact/panel";
import { useForm } from "react-hook-form";
import { InfoCpn } from "../../../models/cpn/InfoCpn";
import { InputComponent } from "../../../components/InputComponent";
import { useEffect, useState } from "react";
import { CatalogoService } from "../../../service/CatalogoService";
import { Generico } from "../../../components/Generico";
import { ClientePNService } from "../../../service/ClientePNService";
import { Button } from "primereact/button";

export const DatosPn = ({ id, siCliente, catalogos, toast, seccion, nuevoCliente, next, permss }) => {

  const omodel = new InfoCpn();
  const model = omodel.model;
  const { funcError, funDefault, funcSuccess, stringToDate } = Generico();
  const defaultValues = funDefault(model);
  const catalogoSrv = new CatalogoService();
  const { control, formState: { errors }, handleSubmit, reset, setError, setValue } = useForm({ defaultValues });
  const { inDrop, inText, inCalendar } = InputComponent({ omodel, errors, control, permss, id });
  const [ecuador, setEcuador] = useState(false);
  const [ciudades, setCiudades] = useState(null);
  const clientepnSrv = new ClientePNService();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if(id != null) {
      editFicha();
    }
  }, [id]);

  const onChangePais = (e) => {
    setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
  }

  const onChangeProvincia = (e) => {
    catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
      setCiudades(res.data);
    });
  }

  const editFicha = () => {
    clientepnSrv.consultarClientepn(id, seccion).then(res => {
      const cats = catalogos;
      let _clientepn = res.clientepn;
      const lista = {
        pais: cats.pais,
        nacionalidad: cats.nacionalidad,
        tipo_formulario: cats.tipoFormulario,
        tipo_solicitante: cats.tipoSolicitante,
        tipo_identificacion: cats.tipo,
        genero: cats.genero,
        estado_civil: cats.estado_civil,
      };
      if (res.ciudades != undefined) {
        lista['ciudad'] = res.ciudades;
        lista['provincia'] = cats.provincias;
        setCiudades(res.ciudades);
      }
      Object.keys(lista).forEach(function (key) {
        if (_clientepn[key] != null) {
          lista[key].forEach(element => {
            if (element.cat_id === _clientepn[key]) {
              _clientepn[key] = element;
            }
          });
        }
      });
      setEcuador(JSON.stringify(_clientepn.pais) === JSON.stringify(cats.ecuador));
      _clientepn.fecha = stringToDate(_clientepn.fecha);
      _clientepn.fecha_nacimiento = stringToDate(_clientepn.fecha_nacimiento);
      reset(_clientepn);
    });
  }

  const onSubmit = (data) => {
    data.seccion = seccion;
    data.fecha = data.fecha.toISOString().split('T')[0];
    data.fecha_nacimiento = data.fecha_nacimiento.toISOString().split('T')[0];
    data.ec = catalogos.ecuador.cat_id;
    setLoad(true);
    if (id == null) {
      clientepnSrv.newClientepn(data).then(res => {
        setLoad(false);
        setValue('id', res.data.codigo);
        data.id = res.data.codigo;
        data.civil = res.data.civil;
        nuevoCliente(data);
        funcSuccess(res, toast);
        next();
      }).catch(error => {
        setLoad(false);
        funcError(error, toast, setError);
      });
    }
    else {
      clientepnSrv.editarClientepn(data).then(res => {
        setLoad(false);
        nuevoCliente({civil: res.data, id: id}, true);
        next();
        funcSuccess(res, toast);
      }).catch(error => {
        setLoad(false);
        funcError(error, toast, setError);
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="formgrid grid">
        {!siCliente && catalogos?.empresas && !id && inDrop('empresa', catalogos?.empresas, { lab: omodel.optionLabel })}
        {inCalendar('fecha')}
        {inDrop('pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: false })}
        {ecuador && inDrop('provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: false })}
        {ecuador && inDrop('ciudad', ciudades, { lab: omodel.optionLabel })}
        {!ecuador && inText('canton')}

        {inText('compania')}
        {inText('proyecto')}
        {inDrop('tipo_formulario', catalogos?.tipoFormulario, { lab: omodel.optionLabel })}
        {inDrop('tipo_solicitante', catalogos?.tipoSolicitante, { lab: omodel.optionLabel })}
      </div>

      <Panel header="PARTE A" className="panel-ficha">
        <Panel header="A.1 DATOS PERSONALES" className="panel-ficha">
          <div className="formgrid grid">
            {inText('apellidos')}
            {inText('nombres')}
            {inDrop('tipo_identificacion', catalogos?.tipo, { lab: omodel.optionLabel })}
            {inText('identificacion')}
          </div>
          <div className="formgrid grid">
            {inDrop('nacionalidad', catalogos?.nacionalidad, { lab: omodel.optionLabel })}
            {inCalendar('fecha_nacimiento')}
            {inDrop('genero', catalogos?.genero, { lab: omodel.optionLabel })}
            {inDrop('estado_civil', catalogos?.estado_civil, { lab: omodel.optionLabel })}
          </div>
        </Panel>
      </Panel>
      <br />

      <Button label="Siguiente" icon="pi pi-chevron-right" type="submit" loading={load} disabled={load} />
    </form>
  )
}
