import Axios from "axios";

export class AdCatalogoService {

    getPermiso(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/consulta-pep-verificar/${id}`).then(res => res.data);
    }

    getLista(params, opcion) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/catalogo-ad-${opcion}/lista?${queryParams}`).then(res => res.data);
    }

    newCatalogo(data, opcion) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/admin/catalogo-ad-${opcion}/nuevo`, data).then(res => res.data);
    }

    editarCatalogo(data, opcion) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/catalogo-ad-${opcion}/editar`, data).then(res => res.data);
    }

    eliminar(id, opcion) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/admin/catalogo-ad-${opcion}/eliminar/${id}`).then(res => res.data);
    }

    getCatalogo(id, opcion) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/catalogo-ad-${opcion}/cat-ver/${id}`).then(res => res.data);
    }
}
