import { FilterMatchMode } from "primereact/api";

export class Consumidores {
    model = {
        id: {
            value: null, export: true, table: true, header: 'Código', filter: true, filterField: 'id', filterPlaceholder: 'Buscar por Código',
            matchMode: FilterMatchMode.EQUALS, headerStyle: { width: '5%', minWidth: '12rem' }
        },
        nombre: {
            value: '', export: true, header: 'Nombre completo', required: 'Campo es requerido', maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'nombre', table: true, filter: true, sort: true,
            filterPlaceholder: 'Buscar por nombre', matchMode: FilterMatchMode.CONTAINS, headerStyle: { width: '25%', minWidth: '12rem' }
        },
        tipo_identificacion: {
            value: null, export: true, header: 'Tipo de identificación', required: 'Tipo de identificación es requerido',
            filterField: 'nombre', table: true, filter: false, sort: true, filterPlaceholder: 'Buscar por Medio de Pago'
        },
        identificacion: {
            value: '', export: true, header: 'Identificación', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'identificacion', filterPlaceholder: 'Buscar por identificación',
            table: true, filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS, headerStyle: { width: '15%', minWidth: '12rem' }
        },
        formulario: {
            value: '', export: true, table: true, header: 'Formularios notificados', required: false, filterField: 'formulario',
            filter: true, sort: false, filterPlaceholder: 'Buscar por nombre formulario', matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '15%', minWidth: '12rem' }
        },
        email: { value: '', export: true, header: 'Correo electrónico:', table: true, required: 'Correo es requerido', maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4 },
        email2: { value: '', export: false, header: 'Correo electrónico 2 (opcional):', required: false, table: false, maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4 },
        empresa: {
            value: null, export: true, header: 'Empresa', required: 'Seleccione la empresa',
            filterField: 'nombre', table: true, filter: false, sort: false, filterPlaceholder: 'Buscar por empresa'
        },
        tipo: { value: 1, export: false, header: 'Tipo', required: 'Seleccione una opción.', filterField: 'nombre', table: false, filter: false, sort: false },
    }
    optionLabel = 'nombre';
    pattern = { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Correo no válido. E.g. example@email.com' };
    tipoConsumidor = [
        { id: 1, name: 'Cliente/Accionista/Empleado' },
        { id: 2, name: 'Proveedor' }
    ];
}
