export class CtaCobrar {

    model = {
        id: { value: null, export: false },
        tipo_identificacion: {
            value: null,
            header: 'Tipo de Identificación',
            required: 'Seleccione una opción',
        },
        identificacion: {
            value: '',
            table: true,
            header: 'No. Cédula, Pasaporte o RUC del Deudor',
            required: 'Campo requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
        },
        razon_social: {
            value: '',
            table: true,
            header: 'Nombre o Razón Social',
            required: 'Campo requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3,
        },
        garantia: {
            value: '',
            table: true,
            header: 'Garantía',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            footer: 'SUBTOTAL:',
        },
        valor: {
            value: 0,
            table: true,
            header: 'Valor USD:',
            required: 'Valor requerido',
            footer: 0,
        },
    }

    optionLabel = 'nombre';
}
