import Axios from "axios";

export class PepEtlService {

    getPepEtl(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/etl-pep/lista?${queryParams}`).then(res => res.data);
    }

    newPepEtl(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/admin/etl-pep/nuevo`, data).then(res => res.data);
    }

    editarPepEtl(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/etl-pep/editar`, data).then(res => res.data);
    }

    consultarPepEtl(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/etl-pep/mostrar/${id}`).then(res => res.data);
    }

    eliminarPepEtl(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/admin/etl-pep/eliminar/${id}`).then(res => res.data);
    }

    cambioEstado(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/etl-pep/inicio-proceso`, data).then(res => res.data);
    }

    getListaPep(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/etl-pep1/lista?${queryParams}`).then(res => res.data);
    }

    editarPep(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/etl-pep1/editar`, data).then(res => res.data);
    }

    consultarPep(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/etl-pep1/mostrar/${id}`).then(res => res.data);
    }

    eliminarPep(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/admin/etl-pep1/eliminar/${id}`).then(res => res.data);
    }

    getIntentos(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/etl-pep-intento/lista?${queryParams}`).then(res => res.data);
    }

    eliminarPepTmp(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/admin/etl-pep/eliminar-tmp/${id}`).then(res => res.data);
    }
}
