import { Panel } from "primereact/panel"
import { InfoClienteService } from "../../service/matriz/InfoClienteService";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { Generico } from "../../components/Generico";
import { MatrizComponent } from "../../components/MatrizComponent";
import { DetailedCellError, HyperFormula } from "hyperformula";

export const DetalleCliente = ({ matrizId, permss, setDialogPerfil, toast, tipo }) => {
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({});
    const clienteSrv = new InfoClienteService();
    const [matriz, setMatriz] = useState(null);
    const hfInstanceRef = useRef(null);
    const [loadSave, setLoadSave] = useState(false);
    const { funcError, funcSuccess, funcMessageBlobError, downloadPdf } = Generico();
    const [loadReport, setLoadReport] = useState(false);
    const [color, setColor] = useState(null);
    const [catalogos, setCatalogos] = useState(null);
    const [lista, setLista] = useState(null);
    const [loadCambio, setLoadCambio] = useState(false);
    const { numericRound, resume, onError, buttonsMatriz, cabecera, scolor, pie, factor, cabeceraAcelerador, acelerador, infoColor, infoSenial2,
        infoSenialCli1, infoSenialControl, infoSenialCli2 } = MatrizComponent({ control, errors, permss, toast });

    useEffect(() => {
        hfInstanceRef.current = HyperFormula.buildEmpty({ licenseKey: 'gpl-v3' });
        hfInstanceRef.current.addSheet('Sheet1');
        verificarMatriz();
        return () => {
            hfInstanceRef.current.destroy();
        };
    }, [matrizId]);

    const onSubmit = (data) => {
        setLoadSave(true);
        const _matriz = matriz;
        clienteSrv.detalle(_matriz, tipo).then(res => {
            setLoadSave(false);
            funcSuccess(res, toast);
        }).catch(error => {
            setLoadSave(false);
            funcError(error, toast, setError);
        });
    }

    const verificarMatriz = () => {
        clienteSrv.informacion(matrizId, tipo).then(res => {
            const _info = res.data;
            const _values = {};
            setColor(_info?.color);
            setCatalogos(_info?.catalogos);
            const formulas = [];
            const data = [];
            let index = 0;
            Object.keys(_info).map((key) => {
                const siMatriz = key.includes('cli') || key.includes('ope') || key.includes('ftr') || key.includes('fcr') || key.includes('sen');
                if (siMatriz) {
                    const orden = parseInt(_info[key].orden);
                    if (orden === 8) {
                        valueCell('monto', _info[key]?.catalogo ? parseFloat(_info[key]?.catalogo) : 0, 7);
                    }
                    data.push([parseFloat(_info[key]?.valor), parseFloat(_info[key]?.puntaje), parseFloat(_info[key]?.ponderacion)]);
                    const ccol = [8, 23, 24, 25, 26, 32, 33, 35, 36, 37, 38].includes(orden) ? 0 : 2;
                    if (_info[key]?.formula1) formulas.push({ value: _info[key]?.formula1, row: index, col: ccol });
                    if (_info[key]?.formula) formulas.push({ value: _info[key]?.formula, row: index, col: 1 });
                    if (_info[key]?.nemonico) _values[`${key}-${orden}-valor`] = _info[key]?.catalogo;
                    else if (_info[key]?.form1 || [34, 35].includes(orden)) _values[`${key}-${orden}-valor`] = _info[key]?.valor;

                    //Color
                    _info[key].color = scolor(_info[key]?.valor, _info?.color);

                    delete _info[key]?.formula;
                    delete _info[key]?.formula1;
                    index++;
                }
                return 1;
            });

            hfInstanceRef.current.setCellContents(
                { sheet: 0, col: 0, row: 0 },
                data
            );
            //console.log(formulas)
            formulas.forEach((formula) => {
                hfInstanceRef.current.setCellContents(
                    { sheet: 0, col: formula.col, row: formula.row },
                    [[formula.value]]
                );
            });
            delete _info.color;
            delete _info.catalogos;
            setMatriz(_info);
            reset(_values);
        }).catch(error => {
            funcError(error, toast, setError);
        });
    }

    const valueCell = (field, value, row) => {
        const cols = { valor: 0, puntaje: 1, ponderacion: 2, monto: 3 };
        if (value !== null && value !== undefined) {
            hfInstanceRef.current.setCellContents({ sheet: 0, col: cols[field], row: row }, [[value]]);
        }
        const calculatedValue = hfInstanceRef.current.getCellValue({ sheet: 0, col: cols[field], row: row });
        if (calculatedValue instanceof DetailedCellError) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Error: ${calculatedValue.type}, Message: ${calculatedValue.message}, field: ${field}, value: ${value}, row: ${row}`, life: 3000 });
            return null;
        }
        /*   if(row === 31){
               console.log("AAA:: ", hfInstanceRef.current.getCellFormula({sheet: 0, col: 0, row: row}));
               //console.log("BBB:: ", hfInstanceRef.current.getCellFormula({sheet: 0, col: 1, row: row}));
               //console.log("BBB:: ", hfInstanceRef.current.getCellValue({ sheet: 0, col: 1, row: row}));
               console.log("AAA:: ", hfInstanceRef.current.getCellValue({ sheet: 0, col: 0, row: row}));
           }*/

        return numericRound(calculatedValue)
    }

    const calcular = (value, name) => {
        const [key, orden, field] = name.split('-');
        const rrow = parseInt(orden) - 1;
        let val = value?.valor ?? 0;
        if (parseInt(orden) === 8) {
            valueCell('monto', value, rrow);
            val = null;
        }
        const _item = matriz[key];
        _item.valor = valueCell(field, val, rrow);
        _item.color = scolor(_item?.valor, color);
        _item.puntaje = valueCell('puntaje', null, rrow);
        setMatriz(prevItem => ({
            ...prevItem,
            [key]: _item
        }));

        const tips = ['cli', 'ope', 'ftr', 'fcr'];
        const arrs = [6, 11, 17, 23, 24, 25, 26, 35, 36, 37, 38];
        for (let i = 0; i < arrs.length; i++) {
            const _tip = arrs[i] > 23 ? 'sen' : tips[i];
            const _cal = matriz[`${_tip}${arrs[i]}`];
            if (arrs[i] <= 23) _cal.puntaje = valueCell('puntaje', null, arrs[i] - 1);

            if (arrs[i] >= 23) {
                _cal.valor = valueCell('valor', null, arrs[i] - 1);
                if ([25, 26].includes(arrs[i])) {
                    _cal.color = matriz?.sen24?.color;
                } else {
                    _cal.color = scolor(_cal?.valor, color);
                }
            }
            /*    if(arrs[i] === 25){
                    console.log(_cal)
                }*/
        }
        const asol = [32, 33];
        for (let i = 0; i < asol.length; i++) {
            const _cal = matriz[`sen${asol[i]}`];
            _cal.valor = valueCell('valor', null, asol[i] - 1);
            //console.log(value, name, _cal);
        }
        //Inx: 34
        const _cal = matriz['sen34'];
        _cal.ponderacion = valueCell('ponderacion', null, 33);//índice inicia en 0
        _cal.color = scolor(_cal?.ponderacion, color);

    }

    const generarReporte = () => {
        setLoadReport(true);
        clienteSrv.descargar(matrizId, tipo).then(res => {
            downloadPdf(res, 'Reporte-Matriz.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            funcMessageBlobError('Asegúrese de ingresar la información antes de generar el reporte.', error, toast);
        });
    }

    const search = (event, inf) => {
        if (!event.query.trim().length) {
            setLista([...lista]);
        }
        else {
            const vals = inf.split("-");
            clienteSrv.getLista(event.query.toLowerCase(), vals[0], vals[1]).then(data => {
                setLista(data.data);
            });
        }
    }

    const finalizar = () => {

    }

    return (
        <>
            <Panel header="Matriz" style={{ maxWidth: '800px', margin: 'auto' }} className="panel-ficha">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="grid grid-nogutter">
                        {resume(1, matriz?.empresa, 12, 1, true, '2xl')}
                        {resume(2, matriz?.nombre_plantilla, 12, 1, true, '2xl')}
                        {resume(3, `FECHA DE ANÁLISIS: ${matriz?.fecha}`, 12, 1, true, null, 'center', null, 'border-bottom-1')}
                        {resume(4, 'INFORMACIÓN DEL ACCIONISTA', 12, 0, true, '2xl', 'center', null, 'border-bottom-1')}
                        {resume(5, 'NOMBRE/RAZÓN SOCIAL:', 4, 0, true, 'sm', 'center', null, 'border-1')}
                        {resume(6, matriz?.nombres, 7, 0, false, 'sm', 'center', 'blue-100', 'border-1')}
                        {resume(7, 'IDENTIFICACIÓN:', 4, 0, true, 'sm', 'center', null, 'border-1')}
                        {resume(8, matriz?.identificacion, 5, 0, false, 'sm', 'center', 'blue-100', 'border-1')}
                    </div>
                    <br />

                    <Panel header="CLIENTE" style={{ width: '100%', margin: 'auto' }}>
                        <div className="formgrid grid align-items-center justify-content-center">
                            {cabecera()}
                            {matriz && factor('cli1-1-valor', catalogos?.TIPO_ESTRUCTURA_LEGAL, matriz, calcular, 'drop')}
                            {matriz && factor('cli2-2-valor', lista, matriz, calcular, 'complete', search)}
                            {matriz && factor('cli3-3-valor', lista, matriz, calcular, 'complete', search)}
                            {matriz && factor('cli4-4-valor', lista, matriz, calcular, 'complete', search)}
                            {matriz && factor('cli5-5-valor', lista, matriz, calcular, 'complete', search)}
                            {matriz && pie('VALOR CLIENTE:', 'cli6', matriz)}
                        </div>
                    </Panel>

                    <br />

                    <Panel header="OPERACIÓN" style={{ width: '100%', margin: 'auto' }}>
                        <div className="formgrid grid align-items-center justify-content-center">
                            {cabecera()}
                            {matriz && factor('ope7-7-valor', catalogos?.TIPO_BIEN, matriz, calcular, 'drop')}
                            {matriz && factor('ope8-8-valor', lista, matriz, calcular, 'number')}
                            {matriz && factor('ope9-9-valor', lista, matriz, calcular, 'complete', search)}
                            {matriz && factor('ope10-10-valor', lista, matriz, calcular, 'complete', search)}
                            {matriz && pie('VALOR OPERACION:', 'ope11', matriz)}
                        </div>
                    </Panel>
                    <hr />

                    <Panel header="TRANSACCIÓN" style={{ width: '100%', margin: 'auto' }}>
                        <div className="formgrid grid align-items-center justify-content-center">
                            {cabecera()}
                            {matriz && factor('ftr12-12-valor', catalogos?.TIPO_TRANSACCION, matriz, calcular, 'drop')}
                            {matriz && factor('ftr13-13-valor', catalogos?.INSTRUMENTO_MONETARIO, matriz, calcular, 'drop')}
                            {matriz && factor('ftr14-14-valor', catalogos?.CANAL_SERVICIO_BIEN, matriz, calcular, 'drop')}
                            {matriz && factor('ftr15-15-valor', lista, matriz, calcular, 'complete', search)}
                            {matriz && factor('ftr16-16-valor', lista, matriz, calcular, 'complete', search)}
                            {matriz && pie('VALOR TRANSACCION:', 'ftr17', matriz)}
                        </div>
                    </Panel>
                    <hr />

                    <Panel header="FACTOR ACELERADOR" style={{ width: '100%', margin: 'auto' }}>
                        <div className="formgrid grid justify-content-center">
                            {cabeceraAcelerador()}
                            {matriz && acelerador(matriz, 'fcr18', 'cli2')}
                            {matriz && acelerador(matriz, 'fcr19', 'cli3')}
                            {matriz && acelerador(matriz, 'fcr20', 'cli4')}
                            {matriz && acelerador(matriz, 'fcr21', 'ftr16')}
                            {cabeceraAcelerador(false)}
                            {matriz && acelerador(matriz, 'fcr22', 'fcr22', 'fcr22-22-valor', catalogos?.OTRAS_SENIALES, calcular)}
                            {cabeceraAcelerador(false)}
                            {matriz && acelerador(matriz, 'fcr23', 'fcr23', valueCell('puntaje', null, 22))}
                        </div>
                    </Panel>
                    <hr />

                    <Panel header="RESULTADOS" style={{ width: '100%', margin: 'auto' }}>
                        <div className="formgrid grid justify-content-center">
                            <div className="col-3">
                                <div className="formgrid grid justify-content-center">
                                    {color && infoColor(color)}
                                </div>
                            </div>

                            <div className="col-9">
                                <div className="formgrid grid justify-content-center">
                                    {matriz && infoSenialCli1(matriz)}
                                    <div className="field col-4 m-0 text-center text-xs font-bold border-1">
                                        <div className="formgrid grid justify-content-center">
                                            {matriz && infoSenialControl(catalogos, calcular)}
                                        </div>
                                    </div>

                                    {matriz && infoSenialCli2(matriz, calcular)}
                                    {matriz && infoSenial2(matriz)}
                                </div>
                            </div>
                        </div>
                    </Panel>

                    <br />
                    {buttonsMatriz({
                        permss, btn1: { setDlg: setDialogPerfil }, btn2: { loadSave }, btn3: { loadReport, chn: generarReporte },
                        btn4: { msg: <>Verifique que la matriz esté guardada (Imprimir).<br />¿Desea finalizar la matriz?</>, chn: finalizar, loadCambio }
                    })}
                </form>
            </Panel>
        </>
    )
}
