export class AmpliadaCpj {
    model = {
        INGR_MENSUAL_RL: {
            value: 0,
            export: false,
            header: 'Ingreso mensual USD:',
            required: 'Valor requerido'
        },
        EGRE_MENSUAL_RL: {
            value: 0,
            export: false,
            header: 'Egreso mensual USD:',
            required: 'Valor requerido'
        },
        INGR_MENSUAL_AP: {
            value: 0,
            export: false,
            header: 'Ingreso mensual USD:',
            required: 'Valor requerido'
        },
        EGRE_MENSUAL_AP: {
            value: 0,
            export: false,
            header: 'Egreso mensual USD:',
            required: 'Valor requerido'
        },
    }
}