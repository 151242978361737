import { FilterMatchMode } from "primereact/api";

export class Historial {

    model = {
        id: {value: null, export: false},
        usuario: {value: '', export: true, header: 'Nombre', filterField: 'nombre', table: true, filter: true, sort: true,
            filterPlaceholder: 'Buscar por nombre', matchMode: FilterMatchMode.CONTAINS},
        empresa: {value: '', export: true, header: 'Empresa', table: true, filter: false, sort: true},
        ruc: {value: '', export: true, header: 'Cédula / RUC', table: true, filter: false, sort: true},
        nombre: {value: '', export: true, header: 'Nombres / Razón Social', table: true, filter: false, sort: true},
        fecha: {value: '', export: true, header: 'Fecha Búsqueda', table: true, filter: false, sort: true},
    }
}