import { FilterMatchMode } from "primereact/api"

export class InfoAccionista {

    model = {
        id: {
            value: null, header: 'Código', export: true, table: true, sort: true, filterField: 'id', filter: true, matchMode: FilterMatchMode.EQUALS,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        fecha: { header: 'FECHA DE ANÁLISIS:', value: new Date(), required: 'Campo requerido', export: true, table: true, headerStyle: { width: '10%', minWidth: '10rem' } },
        nombres: {
            value: '', export: true, header: 'NOMBRE/RAZÓN SOCIAL:', required: 'Información requerida', maxLength: 'Máximo 500 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, filterField: 'nombre', filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '30%', minWidth: '20rem' }
        },
        identificacion: {
            value: '', export: true, header: 'Identificación', required: 'Campo requerido', maxLength: 'Máximo 50 caracteres', table: true,
            minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'identificacion', filter: true, sort: true, matchMode: FilterMatchMode.CONTAINS,
            headerStyle: { width: '15%', minWidth: '15rem' }
        },
        fecha_nacimiento: { header: 'Fecha de nacimiento', value: new Date(), required: 'Campo requerido', export: true, table: false },
        estado: { header: 'Estado', value: null, required: false, optionLabel: 'nombre', export: true, table: true },
        tipo_identificacion: {
            value: null, export: false, required: 'Tipo de identificación es requerido',
            header: 'Tipo de identificación', table: false, filter: false, sort: true
        },
        cargo: {
            value: '', export: true, header: 'Cargo', required: 'Cargo es requerido', maxLength: 'Máximo 500 caracteres', table: false,
            minLength: 'Mínimo 4 caracteres', max: 500, min: 4, filterField: 'nombre', filter: false, sort: false,
        },
        edad: {
            value: '', export: false, header: 'Edad', required: 'Edad es requerido', maxLength: 'Máximo 500 caracteres', table: false,
            minLength: 'Mínimo 1 caracteres', max: 500, min: 1, filterField: 'edad', filter: false, sort: false,
        },
        anio1: {
            value: null, export: false, required: 'Seleccione una opción', optionLabel: 'nombre',
            header: 'Período 1', table: false, filter: false, sort: true
        },
        anio2: {
            value: null, export: false, required: 'Seleccione una opción', optionLabel: 'nombre',
            header: 'Período 2', table: false, filter: false, sort: true
        },
    }
    optionLabel = 'nombre'
}
