export class ConsumidoresFrm {
    model = {
        id: {value: null, export: false},
        ids: {value: null, export: false},
        formulario: {value: null, export: false, header: 'Formulario', required: 'Formulario es requerido',
            filterField:'nombre', table: true, filter: false, sort: true, filterPlaceholder:'Buscar por formulario'},
        fecha: {value: new Date(), export: false, header: 'Fecha expiración (día/mes/año):', required: 'Fecha es requerido', table: true, sort: true},
        idf: {value: 0, export: false, header: 'Código formulario', table: true},
        estadof: {value: '', export: false, header: 'Estado formulario', table: true},
        estado_cf: {header: 'Estado', value: {nombre: 'Activo', cat_id: true}, required: 'Estado es requerido', optionLabel: 'nombre', export: false, table: true},
        empresa: {value: null, export: true, header: 'Empresa', required: 'Seleccione la empresa',
            filterField:'nombre', table: true, filter: false, sort: false, filterPlaceholder:'Buscar por empresa'},
        observacion: {value: '', export: true, header: 'Observación', required: 'Campo requerido', maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres', max: 300, min: 4, filterField: 'observacion', table: true, filter: false, sort: true},
    }
    optionLabel = 'nombre';
    aviso = <>Estimado(a) usuario(a), para el envío de notificación de formularios:
        <ul>
            <li>Todos los campos son obligatorios.</li>
            <li>La fecha de expiración debe ser mayor o igual a la fecha actual, caso contrario, el cliente / proveedor no podrá ingresar al sistema a llenar el formulario.</li>
            <li>Si la fecha expiró, UD. puede cambiarla (clic en el icono de lápiz de la derecha de la correspondiente notificación.).</li>
            <li>Si edita la notificación, no es necesario enviar de nuevo el correo de notificación (segundo ícono).</li>
            <li>El texto ingresado en el campo de observación, será presentado en el correo de notificación.</li>
            <li>El sistema controla que no se duplique: el nombre, el número de identificación y el primer correo ingresado.
                Si ya está registrado se sugiere que busque al cliente / proveedor previamente registrado para generar la notificación.</li>
            <li>Si el sistema no le permitirá generar una notificación si detecta que hay formularios en estado borrador o enviado.</li>
            <li>Si el <b>Estado formulario</b> está en estado <b>Finalizado</b>, pero el <b>Estado</b> esta como <b>Activo</b>, por favor coloque como <b>Inactivo</b> (editándolo),
                para que el sistema permita generar una nueva notificación.</li>
        </ul></>
}
