import { addLocale } from "primereact/api";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FileComponent } from "../../components/FileComponent";
import { GenericComponent } from "../../components/GenericComponent";
import { InputComponent } from "../../components/InputComponent";
import { Proveedor } from "../../models/Proveedor";
import { CatalogoService } from "../../service/CatalogoService";
import { ProveedorService } from "../../service/ProveedorService";
import { Declarantes } from "./Declarantes";
import { Message } from "primereact/message";
import { useToken } from "../../components/useToken";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { FiltroMultiempresa } from "../../components/FiltroMultiempresa";

export const InfoProveedor = () => {

    const generic = new GenericComponent();
    const omodel = new Proveedor();
    const model = omodel.model;
    const defaultValues = generic.default(model);
    const [permss, setPermss] = useState(null);
    const { control, formState: { errors, isValid }, handleSubmit, reset, setError, getValues, trigger, setValue } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const toast = useRef(null);
    const proveedorServicio = new ProveedorService();
    const catalogoService = new CatalogoService();
    const [proveedores, setProveedores] = useState(null);
    const [dialogProveedor, setDialogProveedor] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [ecuador, setEcuador] = useState(false);
    const [ecuadorDireccion, setEcuadorDireccion] = useState(false);
    const [ciudades, setCiudades] = useState(null);
    const [ciudadesDireccion, setCiudadesDireccion] = useState(null);
    const [esRuc, setEsRuc] = useState(false);
    const [ciius, setCiius] = useState([]);
    const [loadReport, setLoadReport] = useState(false);
    const [selectedCiiu, setSelectedCiiu] = useState(null);
    const [esSocio, setEsSocio] = useState(false);
    const [esCedula, setEsCedula] = useState(false);
    const [enombre, setEnombre] = useState(null);
    const { loadLazyDeclarante, tableDeclaranteProveedor, dialogoDeclaranteProveedor } = Declarantes({ toast: toast, tipo: 'proveedor', id: getValues('id'), items: catalogos, permss });
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inArea, inCalendar, inNumber, inAutocomplete, downloadPdf } =
        InputComponent({ registers: proveedores, omodel, generic, errors, control, permss, id: getValues('id') });
    const { upLoadFile, nuevoUpload, eliminarConfirmacionAnexo, anexoPendiente, enviarFormulario, listaFormulario, listar, dialogInstruccion, setdialogIns } =
        FileComponent({ generic, tipo: 'proveedor', toast, unuevo: 'ficha/proveedor/store', udelete: 'ficha/proveedor/eliminar-anexo', permss, id: getValues('id') });
    const { getPerss, getRol } = useToken();
    const [siCliente, setSiCliente] = useState(false);
    const [empresa, setEmpresa] = useState(null);
    const [load, setLoad] = useState(false);

    addLocale(generic.idioma, generic.esFormat);

    useEffect(() => {
        setSiCliente(getPerss() !== undefined);
        if (getPerss() === undefined) {
            loadLazyData();
        }
        else {
            setPermss(getPerss());
            editFicha({ id: 0 });
        }
    }, [lazyParams]);

    const onChangePais = (e, dirr) => {
        if (dirr) {
            setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
        }
        else {
            setEcuadorDireccion(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
        }
    }

    const onChangeSocio = (e) => {
        setEsSocio((e.value)['cat_id']);
    }

    const onChangeProvincia = (e, dirr) => {
        catalogoService.getCatalogosId(e.value.cat_id).then(res => {
            if (dirr) {
                setCiudades(res.data);
            }
            else {
                setCiudadesDireccion(res.data);
            }
        });
    }

    const loadLazyData = () => {
        setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            delete _lazyParams.empresa;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            if (empresa) {
                _lazyParams.empresa = empresa;
            }
            if (getRol()) {
                _lazyParams.rol = getRol();
            }
            proveedorServicio.getLista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setProveedores(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
                if (res.data.cats) {
                    setCatalogos(res.data.cats);
                }
            });
        });
    }

    const openNew = () => {
        if (catalogos == null) {
            let data = { iniciar: 1 };
            if (getRol()) {
                data['rol'] = getRol();
            }
            proveedorServicio.newProveedor(data).then(res => {
                setCatalogos(res.data);
                reset(defaultValues);
                setDialogProveedor(true);
                setdialogIns(true);
                setEnombre(empresa?.nombre);
                if (res.data.nombreEmpresa) {
                    setValue('compania', res.data.nombreEmpresa);
                }
            });
        }
        else {
            reset(defaultValues);
            if (catalogos.nombreEmpresa) {
                setValue('compania', catalogos.nombreEmpresa);
            }
            setDialogProveedor(true);
            setdialogIns(true);
            setEnombre(empresa?.nombre);
        }
    }

    const editFicha = (data) => {
        const iniciar = catalogos == null ? 1 : null;
        proveedorServicio.consultarProveedor(data.id, iniciar).then(res => {
            let cats = catalogos;
            if (iniciar === 1) {
                cats = res.catalogos;
                setCatalogos(res.catalogos);
            }
            if (!res.proveedor) {
                reset(defaultValues);
                setDialogProveedor(true);
                return;
            }

            let _prov = res.proveedor;
            setEnombre(_prov.empresa);
            const lista = {
                pais: cats.pais, tipo_persona: cats.tipo, tipo_informacion: cats.declaracion, tipo_identificacion: cats.tipo_identificacion, direccion_pais: cats.pais,
                politicas: generic.estadosSiNo, sancion: generic.estadosSiNo, socio: generic.estadosSiNo, proyecto: generic.sino
            }
            if (res.ciudades !== undefined) {
                lista['ciudad'] = res.ciudades;
                lista['provincia'] = cats.provincias;
                setCiudades(res.ciudades);
            }
            if (res.direccion_ciudades !== undefined) {
                lista['direccion_ciudad'] = res.direccion_ciudades;
                lista['direccion_provincia'] = cats.provincias;
                setCiudadesDireccion(res.direccion_ciudades);
            }
            if (_prov.socio) {
                _prov.fecha_pep = generic.stringToDate(_prov.fecha_pep);
                if (_prov.fecha_no_pep) {
                    _prov.fecha_no_pep = generic.stringToDate(_prov.fecha_no_pep);
                }
            }
            setEsSocio(_prov.socio);
            Object.keys(lista).forEach(function (key) {
                if (_prov[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _prov[key]) {
                            _prov[key] = element;
                        }
                    });
                }
            });
            setEcuador(JSON.stringify(_prov.pais) === JSON.stringify(cats.ecuador));
            setEcuadorDireccion(JSON.stringify(_prov.direccion_pais) === JSON.stringify(cats.ecuador));
            setSelectedCiiu(_prov.actividad);
            _prov.fecha = generic.stringToDate(_prov.fecha);
            _prov.fecha_inicio = generic.stringToDate(_prov.fecha_inicio);

            reset(generic.renewValues(_prov, model));
            setDialogProveedor(true);
            loadLazyDeclarante(_prov.id);
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el formulario?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteProveedor(data)
        });
    }

    const deleteProveedor = (data) => {
        proveedorServicio.eliminarProveedor(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const onChangeTipo = (e, opc) => {
        setEsRuc(JSON.stringify(e.value) === JSON.stringify(catalogos.ruc));
        setEsCedula(JSON.stringify(e.value) === JSON.stringify(catalogos.cedula));
    }

    const searchCiiu = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setCiius([...ciius]);
            }
            else {
                catalogoService.getActividades(event.query.toLowerCase()).then(data => {
                    setCiius(data.data);
                });
            }
        });
    }

    const onSubmit = (data) => {
        data.ec = catalogos?.ecuador;
        data.ruc = catalogos?.ruc;

        data.fecha = generic.dateToString(data.fecha);
        data.fecha_inicio = generic.dateToString(data.fecha_inicio);
        if (esSocio) {
            data.fecha_pep = generic.dateToString(data.fecha_pep);
            if (data.fecha_no_pep) {
                data.fecha_no_pep = generic.dateToString(data.fecha_no_pep);
            }
        }
        else {
            delete data.fecha_pep;
            delete data.fecha_no_pep;
        }
        setLoad(true);
        if (getValues('id') == null) {
            if (empresa?.empresa_id) data.empresa = empresa.empresa_id;
            proveedorServicio.newProveedor(data).then(res => {
                setLoad(false);
                setValue('id', res.data);
                trigger('id');
                setdialogIns(true);
                generic.funcSuccess(res, toast);
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
        else {
            proveedorServicio.editarProveedor(data).then(res => {
                setLoad(false);
                setdialogIns(true);
                generic.funcSuccess(res, toast);
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
    }

    const generarReporte = () => {
        setLoadReport(true);
        proveedorServicio.descargar(getValues('id')).then(res => {
            downloadPdf(res, getValues('id') + '-Formulario-Informacion-Proveedor.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            generic.funcMessageBlobError('Complete la información antes de generar.', error, toast);
        });
    }

    const subirReporte = () => {
        const urls = "ficha/proveedor/listar";
        const urlsd = "ficha/proveedor/descarga";
        nuevoUpload(getValues('id'), 'Subir formulario y adjuntos', urls, urlsd);
    }

    const enviar = () => {
        enviarFormulario(getValues('id'), 'ficha/proveedor/enviar', 'proveedor');
    }

    const archivos = (data) => {
        const urls = "ficha/proveedor/listar";
        const urlsd = "ficha/proveedor/descarga";
        listar(data.id, urls, urlsd, data.nemonico);
    }

    const cancelar = (estado) => {
        setDialogProveedor(estado);
        loadLazyData();
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogProveedor && !siCliente && <>
                {getRol() && getRol()?.ideg && <FiltroMultiempresa loadLazyData={loadLazyData} setEmpresa={setEmpresa} />}
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo proveedor', permss)}
                    end={generic.rightToolbarTemplate(proveedores, omodel, 'Listado_proveedor', 'LISTADO INFORMACIÓN PROVEEDOR')}></Toolbar>
                <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                    {table({ action3: generic.actionEditDetailTemplate, methEdit: editFicha, perm: permss, methDelete: confirmDelete, details: archivos, icono: 'pi pi-clone' })}
                </Card></>
            }
            {dialogProveedor &&
                <Panel header={(getValues('id') ? 'EDITAR' : 'NUEVO') + " FORMULARIO DE INFORMACIÓN DEL PROVEEDOR" + (enombre ? ', Empresa: "' + enombre + '"' : '')}
                    style={{ width: '100%', margin: 'auto' }} className="panel-ficha">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="formgrid grid">
                            {!siCliente && catalogos?.empresas && !getValues('id') && inDrop('empresa', catalogos?.empresas, { lab: omodel.optionLabel })}
                            {inCalendar('fecha')}
                            {inDrop('pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: true })}
                            {ecuador && inDrop('provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: true })}
                            {ecuador && inDrop('ciudad', ciudades, { lab: omodel.optionLabel })}
                            {!ecuador && inText('canton')}

                            {inDrop('tipo_persona', catalogos?.tipo, { lab: omodel.optionLabel })}
                            {inDrop('tipo_informacion', catalogos?.declaracion, { lab: omodel.optionLabel })}
                            {inText('compania')}
                            {inDrop('proyecto', generic.sino, { lab: omodel.optionLabel })}
                        </div>
                        <Panel header="1) INFORMACIÓN GENERAL:" className="panel-ficha">
                            <div className="formgrid grid">
                                {inDrop('tipo_identificacion', catalogos?.tipo_identificacion, { lab: omodel.optionLabel, chn: onChangeTipo, val: null })}
                                {!esRuc && !esCedula && inText('identificacion')}
                                {esRuc && inText('identificacion', generic.infoIdentificacion('ruc'))}
                                {esCedula && inText('identificacion', generic.infoIdentificacion('cedula'))}
                                {esRuc && inText('razon_social')}
                                {!esRuc && inText('nombres')}
                                {!esRuc && inText('apellidos')}
                            </div>

                            <div className="formgrid grid">
                                {inAutocomplete('actividad', selectedCiiu, setSelectedCiiu, ciius, searchCiiu, { lab: 'nombre', col: 6 })}
                                {inCalendar('fecha_inicio')}
                                {inText('web')}
                            </div>

                            <Panel header="DIRECCIÓN" style={{ width: '100%', margin: 'auto' }}>
                                <div className="formgrid grid">
                                    {inDrop('direccion_pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: false })}
                                    {ecuadorDireccion && inDrop('direccion_provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: false })}
                                    {ecuadorDireccion && inDrop('direccion_ciudad', ciudadesDireccion, { lab: omodel.optionLabel })}
                                    {!ecuadorDireccion && inText('direccion_canton')}
                                    {inArea('direccion_oficina', 5, 100)}
                                    {!ecuadorDireccion && inText('codigo_postal')}
                                    {inText('telefono')}
                                </div>
                            </Panel>
                        </Panel>
                        <br />

                        <Panel header="2) DATOS REPRESENTATE LEGAL / APODERADO" style={{ width: '100%', margin: 'auto' }}>
                            <Message severity="info" text="Estimado usuario, este campo utiliza información de todo el documento, así que por favor completar las secciones 1,3,4 y 5, GUARDARLAS y llenar las secciones 2 y 6." style={{ width: '100%' }} />
                            {tableDeclaranteProveedor()}
                        </Panel>
                        <br />

                        <Panel header="3) INFORMACION ECONÓMICA" style={{ width: '100%', margin: 'auto' }}>
                            <div className="formgrid grid">
                                {inNumber('INGR_MENSUAL', 2, true)}
                                {inNumber('EGRE_MENSUAL', 2, true)}
                                {inNumber('INGR_ANUAL', 2, true)}
                                {inNumber('EGRE_ANUAL', 2, true)}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="4) DETALLE DE PRODUCTOS O SERVICIOS QUE OFRECE EN EL MERCADO:" style={{ width: '100%', margin: 'auto' }}>
                            {inArea('detalle', 5, 1000, 12)}
                        </Panel>
                        <br />

                        <Panel header="5) INFORMACIÓN DE CONTROLES DE PREVENCIÓN DE LAVADO DE ACTIVOS, FINANCIAMIENTO DEL TERRORISMO Y OTROS DELITOS:" style={{ width: '100%', margin: 'auto' }}>
                            <div className="formgrid grid">
                                {inDrop('politicas', generic.estadosSiNo, { lab: omodel.optionLabel }, 6)}
                                {inDrop('sancion', generic.estadosSiNo, { lab: omodel.optionLabel }, 6)}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="6) PERSONA EXPUESTA POLÍTICAMENTE (PEP) DESCRIBIR EN EL CASO QUE LA PERSONA NATURAL, SOCIOS, ACCIONISTAS O DIRECTIVOS OCUPEN ESTOS CARGOS:" style={{ width: '100%', margin: 'auto' }}>
                            <div className="field col">{omodel.strPep}</div>
                            <div className="formgrid grid">
                                {inDrop('socio', generic.estadosSiNo, { lab: omodel.optionLabel, chn: onChangeSocio })}
                                {esSocio && inText('nombre_pep')}
                                {esSocio && inText('cargo_pep')}

                                {esSocio && inText('institucion_pep')}
                                {esSocio && inCalendar('fecha_pep')}
                                {esSocio && inCalendar('fecha_no_pep')}
                            </div>

                        </Panel>
                        <br />

                        <Panel header="DECLARACIÓN" style={{ width: '100%', margin: 'auto' }}>
                            <div className="field col" style={{ textAlign: 'justify' }}>{omodel.strDeclaracion}</div>
                        </Panel>
                        <br />

                        <Panel header="7) OBSERVACIONES" style={{ width: '100%', margin: 'auto' }}>
                            {inArea('observacion', 5, 1000, 12)}
                        </Panel>
                        <br />

                        <Panel header="9) ANEXOS" style={{ width: '100%', margin: 'auto' }}>
                            <div className="field col" style={{ textAlign: 'justify' }}>{omodel.strAnexos}</div>
                        </Panel>
                        <br />
                        {!isValid && <Message severity="error" text="Ingrese la información requerida o corrija los errores presentados." />}
                        {generic.buttonsFormsReports(cancelar, generarReporte, subirReporte, enviar, loadReport, getValues('id') != null, !siCliente, load)}
                    </form>
                </Panel>
            }
            {dialogoDeclaranteProveedor()}
            {upLoadFile()}
            {eliminarConfirmacionAnexo()}
            {anexoPendiente('Enviar formulario - pendientes', 'Los siguientes anexos están pendientes de subir')}
            {listaFormulario('ficha/proveedor/aprobar', loadLazyData)}
            {dialogInstruccion(omodel.titulo, omodel.mensaje)}
        </>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(InfoProveedor, comparisonFn);
