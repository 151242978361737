import Axios from "axios";

export class OrigenService {

    getOrigen(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/origen/lista?${queryParams}`).then(res => res.data);
    }

    newOrigen(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/origen/nuevo`, data).then(res => res.data);
    }

    editarOrigen(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/origen/editar`, data).then(res => res.data);
    }

    consultarOrigen(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/origen/consulta/${id}/${iniciar}`).then(res => res.data);
    }

    eliminarOrigen(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/origen/eliminar/${id}`).then(res => res.data);
    }

    descargar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/origen/reporte/${id}`, { headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache' }, responseType: 'blob'}).then(async res => res.data);
    }
}