import { useForm } from "react-hook-form";
import { GenericComponent } from "../../components/GenericComponent";
import { InfoClienteService } from "../../service/matriz/InfoClienteService";
import { useEffect, useRef, useState } from "react";
import { InputComponent } from "../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InfoCliente } from "../../models/matriz/InfoCliente";
import { DetalleCliente } from "./DetalleCliente";

export const Cliente = ({ tipo }) => {

    const omodel = new InfoCliente();
    const generic = new GenericComponent();
    const clienteSrv = new InfoClienteService();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, setValue } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registers, setRegisters] = useState(null);
    const [catalogo, setCatalogo] = useState(null);
    const [permss, setPermss] = useState(null);
    const toast = useRef(null);
    const [dialogCliente, setDialogCliente] = useState(false);
    const [dialogPerfil, setDialogPerfil] = useState(false);
    const [matriz, setMatriz] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inCalendar } = InputComponent({ registers, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = 'permiso';
            }
            clienteSrv.lista({ lazyEvent: JSON.stringify(_lazyParams) }, tipo).then(res => {
                setTotalRecords(res.data.total);
                setRegisters(res.data.registros);
                if (catalogo == null) {
                    setCatalogo(res.data.catalogos);
                    const _perms = res.data.perss;
                    _perms.b1t = "Editar información inicial de matrices";
                    _perms.b2t = "Editar / registrar detalles de información de matrices";
                    _perms.b3t = "Eliminar información inicial de matrices";
                    setPermss(res.data.perss);
                }
                setLoading(false);
            });
        });
    }

    const openNew = () => {
        reset(defaultValues);
        setDialogCliente(true);
    }

    const edit = (data) => {
        clienteSrv.consultar(data.id, tipo).then(res => {
            const lista = { tipo_identificacion: catalogo?.tipo_identificacion, };
            let _cliente = res.data;

            Object.keys(lista).forEach(function (key) {
                if (_cliente[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _cliente[key]) {
                            _cliente[key] = element;
                        }
                    });
                }
            });
            _cliente.fecha = generic.stringToDate(_cliente.fecha);

            reset(generic.renewValues(_cliente, omodel.model));
            setDialogCliente(true);
        });
    }

    const onSubmit = (data) => {
        data.fecha = generic.dateToString(data.fecha);
        if (getValues('id') == null) {
            clienteSrv.nuevo(data, tipo).then(res => {
                generic.funcSuccess(res, toast);
                setDialogCliente(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        } else {
            clienteSrv.editar(data, tipo).then(res => {
                generic.funcSuccess(res, toast);
                loadLazyData();
                setDialogCliente(false);
            }).catch(error => {
                setDialogCliente(false);
                generic.funcError(error, toast, setError);
            });
        }
    }

    const deleteItem = (data) => {
        confirmDialog({
            message: '¿Esta seguro de eliminar la matriz?',
            header: 'Confirmar',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
                clienteSrv.eliminar(data.id, tipo).then(res => {
                    generic.funcSuccess(res, toast);
                    loadLazyData();
                }).catch(error => {
                    generic.funcDeleteError(error, toast);
                });
            }
        });
    }

    const verificarMatriz = (data) => {
        setMatriz(data);
        setDialogPerfil(true);
    }

    return (
        <>
            <Toast ref={toast} />
            {!dialogPerfil && <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                    end={generic.rightToolbarTemplate(registers, omodel, 'Listado_matrices', 'LISTADO MATRICES')}></Toolbar>
                {table({ action3: generic.actionEditDetailTemplate, methEdit: edit, perm: permss, methDelete: deleteItem, details: verificarMatriz, icono: 'pi pi-qrcode' })}
            </Card>}

            <Dialog visible={dialogCliente} header={getValues('id') == null ? "Nueva matriz" : "Editar matriz"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogCliente(false)} style={{ maxWidth: '500px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inCalendar('fecha', {}, 12)}
                        {inDrop('tipo_identificacion', catalogo?.tipo_identificacion, { lab: omodel.optionLabel }, 6)}
                        {inText('identificacion', {}, 6)}
                        {inText('nombres', {}, 12)}
                    </div>
                    {generic.buttonsForms(setDialogCliente)}
                </form>
            </Dialog>

            {dialogPerfil && <DetalleCliente matrizId={matriz?.id} permss={permss} setDialogPerfil={setDialogPerfil} toast={toast} tipo={tipo} />}
        </>
    )
}
