import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { RolPorEmpresa } from "../../models/administracion/RolPorEmpresa";
import { UsuarioPermisoService } from "../../service/UsuarioPermisoService";
import { InputComponent } from "../../components/InputComponent";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { useToken } from "../../components/useToken";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const RolEmpresaLista = ({setDialogRol}) => {
    const generic = new GenericComponent();
    const omodel = new RolPorEmpresa();
    let loadLazyTimeout = null;
    const [roles, setRoles] = useState(null);
    const permisoService = new UsuarioPermisoService();
    const toast = useRef(null);
    const { getAllToken, saveToken } = useToken();
    const history = useHistory();
    const { table, setLoading, setTotalRecords, lazyParams } = InputComponent({ registers: roles, omodel, generic });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            permisoService.getRolEmpresa({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRoles(res.data.registros);
                setLoading(false);
            });
        });
    }

    const cambiarRol = (rol) => {
        confirmDialog({
            message: '¿Está seguro de cambiar al rol ' + rol.nombre + '?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => cambio(rol)
        });
    }

    const cambio = (rol) => {
        permisoService.consultarRecursoEmpresa(rol.id).then(res => {
            let token = getAllToken();
            token.menus = res.data.menus;
            token.tipo = res.data.tipo;
            token.rol = rol;
            token.rol['rol_tipo'] = 'rol-usuario';
            token.rol['tipo_rol'] = rol.tipo_rol;
            saveToken(token);
            history.push("/");
            setDialogRol(false);
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error, comuníquese con el administrador.', detail: error.response.data.message, life: 3000 });
        })
    }

    const acciones = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-sign-in" title='Cambio de rol' className="p-button-rounded p-button-success mr-2" onClick={() => cambiarRol(rowData)} />
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                {table({ action5: acciones })}
            </Card>
        </>
    )
}
