import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { GenericComponent } from '../../../components/GenericComponent';
import { ClientePJService } from '../../../service/ClientePJService';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { PepCpj } from '../../../models/cpj/PepCpj';
import { Divider } from 'primereact/divider';

export const PepPj = ({ toast, back, next, id, permss }) => {

  const generic = new GenericComponent();
  const omodel = new PepCpj();
  const defaultValues = generic.default(omodel.model);
  const { control, formState: { errors }, handleSubmit, reset, setError, setValue } = useForm({ defaultValues });
  const [clientepj] = useState([]);
  const clientepjSrv = new ClientePJService();
  const [esPep, setEsPep] = useState(false);
  const { inCheck, inText, inCalendar } =
    InputComponent({ registers: clientepj, omodel, generic, errors, control, permss, id });

  useEffect(() => {
    clientepjSrv.consultarClientepj(id, 'p5').then(res => {
      let _clientepj = res.clientepj;
      if (!(_clientepj.fecha_nombramiento_pep == null)) {
        _clientepj.fecha_nombramiento_pep = generic.stringToDate(_clientepj.fecha_nombramiento_pep);
      }
      if (!(_clientepj.fecha_dejo_pep == null)) {
        _clientepj.fecha_dejo_pep = generic.stringToDate(_clientepj.fecha_dejo_pep);
      }
      if (typeof _clientepj.pep != "boolean") {
        _clientepj.pep = false;
      }
      setEsPep(_clientepj.pep);
      reset(generic.renewValues(_clientepj, omodel.model));
    });
  }, [false])

  const onSubmit = (data) => {
    data.paso = 'p5';
    data.id = id;
    if (data.pep) {
      data.fecha_nombramiento_pep = data.fecha_nombramiento_pep.toISOString().split('T')[0];
      if (data.fecha_dejo_pep) {
        data.fecha_dejo_pep = data.fecha_dejo_pep.toISOString().split('T')[0];
      }
    }
    clientepjSrv.editarClientepj(data).then(res => {
      generic.funcSuccess(res, toast);
      next(id);
    }).catch(error => {
      generic.funcError(error, toast, setError);
    });
  }

  const onChangePep = (e) => {
    setEsPep(e.checked);
    if (!e.checked) {
      reset(defaultValues);
    }
  }

  return (
    <>
      < form onSubmit={handleSubmit(onSubmit)}>
        <Panel header="PERSONA EXPUESTA POLÍTICAMENTE (PEP)" className="panel-ficha">
          <div className="field col" style={{ textAlign: 'justify' }}>
            {omodel.strPep}
          </div>
          <Divider />

          <div className="formgrid grid">
            {inCheck('pep', null, { chn: onChangePep }, 6)}
            {esPep && inText('nombre_pep', {}, 6)}
            {esPep && <Divider />}

            {esPep && inText('cargo_pep')}
            {esPep && inText('institucion_trabaja_pep')}
            {esPep && inCalendar('fecha_nombramiento_pep')}

            {esPep && inCalendar('fecha_dejo_pep')}
          </div>
        </Panel>
        <br />
        <div className="formgrid grid">
          <div className="field col">
            <Button label="Anterior" icon="pi pi-chevron-left" onClick={back} />
          </div>
          <div className="field col">
            <Button label="Siguiente" icon="pi pi-chevron-right" type="submit" />
          </div>
        </div>
      </form >
    </>
  )
}