import { Juridica } from "../../models/Juridica";
import { GenericComponent } from "../../components/GenericComponent";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { JuridicaService } from "../../service/JuridicaService";
import { InputComponent } from "../../components/InputComponent";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { addLocale } from "primereact/api";
import { Panel } from "primereact/panel";
import { CatalogoService } from "../../service/CatalogoService";
import { RepresentanteApoderado } from "./RepresentanteApoderado";
import { FileComponent } from '../../components/FileComponent';
import { AccionistaSocios } from "./AccionistaSocios";
import { Divider } from "primereact/divider";
import { Message } from "primereact/message";
import { useToken } from "../../components/useToken";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { FiltroMultiempresa } from "../../components/FiltroMultiempresa";

export const PersonaJuridica = () => {

    const generic = new GenericComponent();
    const omodel = new Juridica();
    const model = omodel.model;
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, trigger, setValue } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [juridicas, setJuridicas] = useState([]);
    const juridicaSrv = new JuridicaService();
    const catalogoSrv = new CatalogoService();
    const toast = useRef(null);
    const [dialogJuridica, setDialogJuridica] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [ecuador, setEcuador] = useState(false);
    const [ciudades, setCiudades] = useState([]);
    const [ecuadorDG, setEcuadorDG] = useState(false);
    const [ciudadesDG, setCiudadesDG] = useState([]);
    const [selectedCiiu, setSelectedCiiu] = useState(null);
    const [ciius, setCiius] = useState([]);
    const [loadReport, setLoadReport] = useState(false);
    const [esNacional, setEsNacional] = useState(false);
    const [empresa, setEmpresa] = useState(null);
    const { getRol, getPerss } = useToken();
    const [esPep, setEsPep] = useState(false);
    const { loadLazyDeclarante, tableDeclarante, dialogoDeclarante, setDeclarantes } = RepresentanteApoderado({ toast: toast, tipo: 'juridica', id: getValues('id'), permss });
    const { loadLazyAccionistaSocio, tableAccionistaSocio, dialogoAccionistaSocio, setAccionistaSocios } = AccionistaSocios({ toast: toast, tipo: 'juridica', id: getValues('id'), permss });
    const [siCliente, setSiCliente] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inCalendar, inNumber, inCheck, inArea, inAutocomplete } =
        InputComponent({ registers: juridicas, omodel, generic, errors, control, permss, id: getValues('id') });
    const { upLoadFile, nuevoUpload, eliminarConfirmacionAnexo, anexoPendiente, enviarFormulario, listaFormulario, listar, dialogInstruccion, setdialogIns } =
        FileComponent({ generic, tipo: 'juridica', toast, unuevo: 'ficha/juridica/store', udelete: 'ficha/juridica/eliminar-anexo', permss, id: getValues('id') });

    addLocale(generic.idioma, generic.esFormat);

    useEffect(() => {
        setSiCliente(getPerss() != undefined);
        if (getPerss() == undefined) {
            loadLazyData();
        }
        else {
            setPermss(getPerss());
            editFicha({ id: 0 });
        }
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            delete _lazyParams.empresa;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            if (empresa) {
                _lazyParams.empresa = empresa;
            }
            if (getRol()) {
                _lazyParams.rol = getRol();
            }
            juridicaSrv.getJuridica({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setJuridicas(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        if (catalogos == null) {
            juridicaSrv.newJuridica({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setDeclarantes(null);
                setDialogJuridica(true);
                setAccionistaSocios(null);
                setdialogIns(true);
                reset(defaultValues);
            });
        } else {
            setDialogJuridica(true);
            setDeclarantes(null);
            setAccionistaSocios(null);
            setdialogIns(true);
            reset(defaultValues);
        }
    }

    const onChangePep = (e) => {
        setEsPep(e.value.cat_id);
    }

    const onSubmit = (data) => {
        data.fecha = generic.dateToString(data.fecha);
        data.fecha_inicio_actividad = generic.dateToString(data.fecha_inicio_actividad);
        if (esPep) {
            data.fecha_nombramiento_pep = generic.dateToString(data.fecha_nombramiento_pep);
            if (data.fecha_dejo_pep) {
                data.fecha_dejo_pep = generic.dateToString(data.fecha_dejo_pep);
            }
        }
        else {
            delete data.fecha_nombramiento_pep;
            delete data.fecha_dejo_pep;
        }

        data.ec = catalogos.ecuador.cat_id;
        data.ecDG = catalogos.ecuador.cat_id;
        data.ecn = catalogos?.ecuador_nac;

        if (getValues('id') == null) {
            juridicaSrv.newJuridica(data).then(res => {
                setValue('id', res.data);
                trigger('id');
                setdialogIns(true);
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            juridicaSrv.editarJuridica(data).then(res => {
                generic.funcSuccess(res, toast);
                setdialogIns(true);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const onChangePais = (e) => {
        setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvincia = (e) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudades(res.data);
        });
    }

    const onChangePaisDG = (e) => {
        setEcuadorDG(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvinciaDG = (e, pago) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudadesDG(res.data);
        });
    }

    const editFicha = (juridica) => {
        const iniciar = catalogos == null ? 1 : null;
        juridicaSrv.consultarJuridica(juridica.id, iniciar).then(res => {
            let cats = catalogos;
            if (iniciar == 1) {
                cats = res.data.catalogos;
                setCatalogos(res.data.catalogos);
            }
            if (!res.data.juridica) {
                reset(defaultValues);
                setDialogJuridica(true);
                return;
            }
            let _juridica = res.data.juridica;
            setEsNacional(cats.ecuador_nac.cat_id == _juridica.nacionalidad);
            setEsPep(_juridica.pep);
            //Para actualizaciones
            _juridica.fecha = generic.stringToDate(_juridica.fecha);
            _juridica.fecha_inicio_actividad = generic.stringToDate(_juridica.fecha_inicio_actividad);
            if (_juridica.pep) {
                if (_juridica.fecha_dejo_pep) {
                    _juridica.fecha_dejo_pep = generic.stringToDate(_juridica.fecha_dejo_pep);
                }
                _juridica.fecha_nombramiento_pep = generic.stringToDate(_juridica.fecha_nombramiento_pep);
            }

            const lista = {
                tipo_juridico: cats.tipoPersonaJuridica,
                pais: cats.pais,
                nacionalidad: cats.nacionalidad,
                pais_datos_generales: cats.pais,
                tipo_formulario: cats.tipoFormulario,
                pep: generic.estadosSiNo
            };
            if (res.data.ciudades != undefined) {
                lista['ciudad'] = res.data.ciudades;
                lista['provincia'] = cats.provincias;
                setCiudades(res.data.ciudades);
            }
            if (res.data.ciudad_datos_generales != undefined) {
                lista['ciudad_datos_generales'] = res.data.ciudad_datos_generales;
                lista['provincia_datos_generales'] = cats.provincias;
                setCiudadesDG(res.data.ciudad_datos_generales);
            }

            Object.keys(lista).forEach(function (key) {
                if (_juridica[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _juridica[key]) {
                            _juridica[key] = element;
                        }
                    });
                }
            });
            setEcuador(JSON.stringify(_juridica.pais) === JSON.stringify(cats.ecuador));
            setEcuadorDG(JSON.stringify(_juridica.pais_datos_generales) === JSON.stringify(cats.ecuador));

            reset(generic.renewValues(_juridica, model));
            setDialogJuridica(true);
            loadLazyDeclarante(_juridica.id);
            loadLazyAccionistaSocio(_juridica.id);
        });
    };

    const searchCiiu = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setCiius([...ciius]);
            }
            else {
                catalogoSrv.getActividades(event.query.toLowerCase()).then(data => {
                    setCiius(data.data);
                });
            }
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el formulario?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteJuridica(data)
        });
    }

    const deleteJuridica = (data) => {
        juridicaSrv.eliminarJuridica(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const generarReporte = () => {
        setLoadReport(true);
        juridicaSrv.descargar(getValues('id')).then(res => {
            generic.downloadPdf(res, getValues('id') + '-Formulario-Persona-Juridica.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            generic.funcMessageBlobError('Complete la información antes de generar.', error, toast);
        });
    }

    const onChangeNacionalidad = (e) => {
        setEsNacional(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador_nac));
    }

    const subirReporte = () => {
        const urls = "ficha/juridica/listar";
        const urlsd = "ficha/juridica/descarga";
        nuevoUpload(getValues('id'), 'Subir formulario y adjuntos', urls, urlsd);
    }

    const enviar = () => {
        enviarFormulario(getValues('id'), 'ficha/juridica/enviar', 'juridica');
    }

    const actualizarPatrimonio = (e, info) => {
        const actvs = ['total_activo_corriente', 'total_activo_no_corriente'];
        const pasvs = ['total_pasivo_corriente', 'total_pasivo_no_corriente'];
        let totalPasivos = 0;
        let totalActivos = 0;
        actvs.forEach(itm => {
            if (getValues(itm) && !isNaN(getValues(itm))) {
                totalActivos += parseFloat(getValues(itm));
            }
        });
        pasvs.forEach(itm => {
            if (getValues(itm) && !isNaN(getValues(itm))) {
                totalPasivos += parseFloat(getValues(itm));
            }
        });
        setValue('total_activos', totalActivos);
        setValue('total_pasivos', totalPasivos);
        setValue('patrimonio', totalActivos - totalPasivos);
    }

    const archivos = (data) => {
        const urls = "ficha/juridica/listar";
        const urlsd = "ficha/juridica/descarga";
        listar(data.id, urls, urlsd, data.nemonico);
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogJuridica && !siCliente && <>
                {getRol() && getRol()?.ideg && <FiltroMultiempresa loadLazyData={loadLazyData} setEmpresa={setEmpresa} />}
                <Toolbar className="mb-4" start={(getRol() && getRol()?.ideg) ? null : generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                    end={generic.rightToolbarTemplate(juridicas, omodel, 'Listado_persona_juridica', 'LISTADO PERSONA JURIDICA')}></Toolbar>
                <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                    {table({ action3: generic.actionEditDetailTemplate, methEdit: editFicha, perm: permss, methDelete: confirmDelete, details: archivos, icono: 'pi pi-clone' })}
                </Card></>
            }
            {dialogJuridica &&
                <Panel header="NUEVO FORMULARIO DE INFORMACIÓN DE PERSONA JURÍDICA" style={{ width: '100%', margin: 'auto' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="formgrid grid">
                            {inDrop('tipo_juridico', catalogos?.tipoPersonaJuridica, { lab: omodel.optionLabel })}
                            {inCalendar('fecha')}
                            {inDrop('pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: false })}
                            {ecuador && inDrop('provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: false })}
                            {ecuador && inDrop('ciudad', ciudades, { lab: omodel.optionLabel })}
                            {!ecuador && inText('canton')}

                            {inText('compania')}
                            {inCheck('grupo_empresarial')}
                            {inDrop('tipo_formulario', catalogos?.tipoFormulario, { lab: omodel.optionLabel })}
                        </div>
                        <Panel header="1) DATOS GENERALES" className="panel-ficha">
                            <div className="formgrid grid">
                                {inText('razon_social')}
                                {inText('identificacion', generic.infoIdentificacion('ruc'))}
                                {inDrop('nacionalidad', catalogos?.nacionalidad, { lab: omodel.optionLabel, chn: onChangeNacionalidad })}
                                {inCalendar('fecha_inicio_actividad')}

                                {inAutocomplete('actividad_economica', selectedCiiu, setSelectedCiiu, ciius, searchCiiu, { lab: 'nombre' })}
                                {inArea('direccion_oficina', 5, 100)}
                                {inDrop('pais_datos_generales', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePaisDG, val: false })}
                                {ecuadorDG && inDrop('provincia_datos_generales', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvinciaDG, val: false })}
                                {ecuadorDG && inDrop('ciudad_datos_generales', ciudadesDG, { lab: omodel.optionLabel })}
                                {!ecuadorDG && inText('canton_datos_generales')}

                                {!esNacional && inText('codigo_postal')}
                                {inText('telefono_empresa')}
                                {inText('pagina_web_email')}
                            </div>
                        </Panel>
                        <br />

                        <Message severity="info" text="Estimado usuario, este campo utiliza información de todo el documento, así que por favor completar las secciones 1,3,4 y 5, GUARDAR y llenar las secciones 2 y 6." style={{ width: '100%' }} />
                        <Panel header="2) DATOS  DEL REPESENTANTE LEGAL / APODERADO" className="panel-ficha">
                            {tableDeclarante()}
                        </Panel>
                        <br />

                        <Panel header="4) INFORMACIÓN ECONÓMICA" className="panel-ficha">
                            <div className="formgrid grid">
                                {inNumber('ingreso_mensual', 2, true)}
                                {inNumber('egreso_mensual', 2, true)}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="5) INFORMACIÓN ECONÓMICA" className="panel-ficha">
                            <div className="formgrid grid">
                                <div className="field col-4"><b>ACTIVOS</b></div>
                                <div className="field col-4"><b>PASIVOS</b></div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-4"><b>TIPO DE ACTIVO/VALOR USD</b></div>
                                <div className="field col-4"><b>TIPO DE ACTIVO/VALOR USD</b></div>
                            </div>
                            <div className="formgrid grid">
                                {inNumber('total_activo_corriente', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('total_pasivo_corriente', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>

                            <div className="formgrid grid">
                                {inNumber('total_activo_no_corriente', 2, true, { func: actualizarPatrimonio, vars: null })}
                                {inNumber('total_pasivo_no_corriente', 2, true, { func: actualizarPatrimonio, vars: null })}
                            </div>
                            <Divider />

                            <div className="formgrid grid">
                                {inNumber('total_activos', 2, true, { disabled: true })}
                                {inNumber('total_pasivos', 2, true, { disabled: true })}
                            </div>
                            <Divider />

                            <div className="formgrid grid">
                                {inNumber('patrimonio', 2, true, { min: 'nulo', disabled: true })}
                            </div>
                        </Panel>
                        <br />

                        <Message severity="info" text="Estimado usuario, este campo utiliza información de todo el documento, así que por favor completar las secciones 1,3,4 y 5, GUARDAR y llenar las secciones 2 y 6." style={{ width: '100%' }} />
                        <Panel header="6) INFORMACIÓN DE LOS ACCIONISTAS/SOCIOS" className="panel-ficha">
                            {tableAccionistaSocio()}
                        </Panel>
                        <br />

                        <Panel header="7) PERSONA EXPUESTA POLÍTICAMENTE (PEP) DESCRIBIR EN EL CASO QUE SOCIOS, ACCIONISTAS O DIRECTIVOS OCUPEN ESTOS CARGOS" className="panel-ficha">
                            <div className="field col-12" style={{ textAlign: 'justify' }}>
                                {omodel.strPep}
                            </div>
                            <br />
                            <div className="formgrid grid">
                                {inDrop('pep', generic.estadosSiNo, { lab: omodel.optionLabel, chn: onChangePep })}

                                {esPep && <>
                                    {inText('nombre_pep')}
                                    {inText('cargo_pep')}
                                    {inText('institucion_trabaja_pep')}
                                    {inCalendar('fecha_nombramiento_pep')}
                                    {inCalendar('fecha_dejo_pep')}
                                </>}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="8) DECLARACIÓN" className="panel-ficha">
                            <div className="formgrid grid">
                                {inCheck('acepta_declaracion', <div align="justify">{omodel.strDeclara}</div>, {}, 12)}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="9) ANEXOS" className="panel-ficha">
                            <div className="field col-12" style={{ textAlign: 'justify' }}>
                                {omodel.strAnexo}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="10) OBSERVACIONES" className="panel-ficha">
                            <div className="formgrid grid">
                                {inArea('observacion', 5, 9000, 12)}
                            </div>
                        </Panel>
                        <br />

                        {generic.buttonsFormsReports(setDialogJuridica, generarReporte, subirReporte, enviar, loadReport, getValues('id') != null, !siCliente)}
                    </form>
                </Panel>
            }
            {dialogoDeclarante()}
            {dialogoAccionistaSocio()}
            {upLoadFile()}
            {eliminarConfirmacionAnexo()}
            {anexoPendiente('Enviar formulario - pendientes', 'Los siguientes anexos están pendientes de subir')}
            {listaFormulario('ficha/juridica/aprobar', loadLazyData)}
            {dialogInstruccion(omodel.titulo, omodel.mensaje)}
        </>
    )
}
