import Axios from "axios";

export class BancariaService {

    getLista(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/bancaria/lista?${queryParams}`).then(res => res.data);
    }

    newBancaria(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/bancaria/nuevo`, data).then(res => res.data);
    }

    editarBancaria(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/bancaria/editar`, data).then(res => res.data);
    }

    consultarBancaria(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/bancaria/consulta/${id}/${iniciar}`).then(res => res.data);
    }

    deleteBancaria(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/bancaria/eliminar/${id}`).then(res => res.data);
    }
}