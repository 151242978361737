import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../../components/GenericComponent";
import { Matrices } from "../../../models/Matrices";
import { useForm } from "react-hook-form";
import { MatrizService } from "../../../service/MatrizService";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { InputComponent } from "../../../components/InputComponent";
import { Panel } from "primereact/panel";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Divider } from 'primereact/divider';
import { useToken } from "../../../components/useToken";

export const Matriz = () => {

    const generic = new GenericComponent();
    const omodel = new Matrices();
    const [permss, setPermss] = useState(null);
    const { getTipo } = useToken();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, setValue } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [matrices, setMatrices] = useState(null);
    const matrizService = new MatrizService();
    const toast = useRef(null);
    const [dialogMatriz, setDialogMatriz] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [esRuc, setEsRuc] = useState(false);
    const [esCedula, setEsCedula] = useState(false);
    const [valores, setValores] = useState(null);
    const [resultado, setResultado] = useState(null);
    const [dialogResultado, setDialogResultado] = useState(false);
    const [form, setForm] = useState(null);
    const [licitud, setLicitud] = useState(null);
    const [sustento, setSustento] = useState(null);
    const [lista, setLista] = useState(null);
    const [aplicar, setAplicar] = useState(null);
    const [loadReport, setLoadReport] = useState(false);
    const [reporte, setReporte] = useState(false);
    const [dialogEstado, setDialogEstado] = useState(false);
    const [riesgo, setRiesgo] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inCalendar, inDrop, inText } = InputComponent({ registers: matrices, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        presentar();
        loadLazyData();
    }, [lazyParams]);

    const presentar = (iniciar = true) => {
        const nems = ['TIPO_CLIENTE', 'ACTIVIDAD', 'NACIONALIDAD', 'PAIS', 'CIUDAD', 'TIPO_BIEN', 'MONTO', 'PAIS_BIEN', 'CIUDAD_BIEN', 'TIPO_TRANSACCION',
            'INSTRUMENTO', 'CANAL', 'CIUDAD_SERVICIO', 'PAIS_FONDO', 'FACTOR_ACELERADOR'];
        const fact = ['cliente', 'operacion', 'transaccion'];
        let _valor = {};
        if (iniciar) {
            nems.forEach(itm => _valor[itm] = { valor: 0, ponderacion: 0, puntaje: 0 });
            fact.forEach(itm => _valor[itm] = { ponderacion: 0, puntaje: 0 });
        }
        setValores(_valor);
    }

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            matrizService.getMatriz({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setMatrices(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        if (catalogos == null) {
            matrizService.newMatriz({ iniciar: 1 }).then(res => {
                presentar();
                setCatalogos(res.data);
                setDialogMatriz(true);
                reset(defaultValues);
                controles(null);
            });
        }
        else {
            presentar();
            setDialogMatriz(true);
            controles(null);
            reset(defaultValues);
        }
    }

    const controles = (listado) => {
        if (listado == null) {
            setForm(null);
            setLicitud(null);
            setSustento(null);
            setLista(null);
            setAplicar(null);
        }
        else {
            setForm({ nombre: listado?.lista?.RES_REGISTRO.valor + "", cat_id: parseInt(listado?.lista?.RES_REGISTRO.valor) });
            setLicitud({ nombre: listado?.lista?.RES_LICITUD.valor + "", cat_id: parseInt(listado?.lista?.RES_LICITUD.valor) });
            setSustento({ nombre: listado?.lista?.RES_SUSTENTO.valor + "", cat_id: parseInt(listado?.lista?.RES_SUSTENTO.valor) });
            setLista({ nombre: listado?.lista?.RES_LISTAS.valor + "", cat_id: parseInt(listado?.lista?.RES_LISTAS.valor) });
            setAplicar({ nombre: listado?.lista?.RES_APLICACION.valor + "", cat_id: listado?.lista?.RES_APLICACION.valor });
        }
    }

    const generarReporte = (inf = null) => {
        const idr = inf ? inf : getValues('id');
        setLoadReport(true);
        matrizService.descargar(idr).then(res => {
            generic.downloadPdf(res, idr + '-Matriz-Respuesta.pdf');
            setLoadReport(false);
        });
    }

    const onSubmit = (data) => {
        data.fecha = data.fecha.toISOString().split('T')[0];
        if (getValues('id') == null) {
            matrizService.newMatriz(data).then(res => {
                controles(res.data.lista);
                setValue('id', res.data.mat_id);
                setResultado(res.data.lista);
                setDialogResultado(true);
                setReporte(false);
                generic.funcSuccess(res, toast);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            matrizService.editarMatriz(data).then(res => {
                controles(res.data.lista);
                setReporte(res.data.lista.lista?.RES_APLICACION.valor);
                setResultado(res.data.lista);
                generic.funcSuccess(res, toast);
                setDialogResultado(true);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar la matriz?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarMatriz(data)
        });
    }

    const eliminarMatriz = (data) => {
        matrizService.eliminarMatriz(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const onChangeTipo = (e) => {
        setEsRuc(JSON.stringify(e.value) === JSON.stringify(catalogos.ruc));
        setEsCedula(JSON.stringify(e.value) === JSON.stringify(catalogos.cedula));
    }

    const editMatriz = (data) => {
        const iniciar = catalogos == null ? 1 : 0;
        matrizService.consultarMatriz(data.id, iniciar).then(res => {
            let cats = catalogos;
            if (iniciar == 1) {
                cats = res.data.catalogos;
                setCatalogos(res.data.catalogos);
            }
            let _matriz = res.data.matriz;
            const lista = {
                tipo_identificacion: cats.tipo_identificacion, TIPO_CLIENTE: cats.tipo, ACTIVIDAD: cats.actividad, NACIONALIDAD: cats.nacionalidad, PAIS: cats.nacionalidad,
                CIUDAD: cats.ciudad, TIPO_BIEN: cats.tipo_bien, MONTO: cats.monto, PAIS_BIEN: cats.nacionalidad, CIUDAD_BIEN: cats.ciudad, TIPO_TRANSACCION: cats.tipo_transaccion,
                INSTRUMENTO: cats.instrumento, CANAL: cats.canal, CIUDAD_SERVICIO: cats.ciudad, PAIS_FONDO: cats.nacionalidad, FACTOR_ACELERADOR: cats.factor
            };

            Object.keys(lista).forEach(function (key) {
                if (_matriz[key] != null) {
                    lista[key].forEach(element => {
                        let val = element.id;
                        if (key == 'tipo_identificacion') val = element.cat_id;
                        if (val === _matriz[key]) _matriz[key] = element;
                    });
                }
            });
            _matriz.fecha = generic.stringToDate(_matriz.fecha);
            setValores(res.data.valores);
            reset(generic.renewValues(_matriz, omodel.model));
            setDialogMatriz(true);
        });
    };

    const onChangeFactor = (e, nemonico) => {
        matrizService.getValor(e.value.id, nemonico).then(res => {
            let _valor = valores;
            const facts = ['cliente', 'operacion', 'transaccion'];
            _valor[nemonico] = res.data;
            document.getElementById(nemonico + "-valor").innerText = _valor[nemonico].valor;
            document.getElementById(nemonico + "-puntaje").innerText = _valor[nemonico].puntaje;
            document.getElementById(nemonico + "-ponderacion").innerText = _valor[nemonico].ponderacion + " %";
            sumar(_valor);
            facts.forEach(itm => {
                document.getElementById(itm + "-puntaje").innerText = _valor[itm].puntaje;
                document.getElementById(itm + "-ponderacion").innerText = _valor[itm].ponderacion + " %";
            });
            setValores(_valor);
        });
    }

    const sumar = (_valor) => {
        const _cliente = ['TIPO_CLIENTE', 'ACTIVIDAD', 'NACIONALIDAD', 'PAIS', 'CIUDAD'];
        const _operacion = ['TIPO_BIEN', 'MONTO', 'PAIS_BIEN', 'CIUDAD_BIEN'];
        const _transaccion = ['TIPO_TRANSACCION', 'INSTRUMENTO', 'CANAL', 'CIUDAD_SERVICIO', 'PAIS_FONDO'];
        let _sum_cli_pun = 0; let _sum_cli_pon = 0;
        let _sum_ope_pun = 0; let _sum_ope_pon = 0;
        let _sum_tra_pun = 0; let _sum_tra_pon = 0;
        Object.keys(_valor).forEach(function (key) {
            if (_cliente.includes(key)) {
                _sum_cli_pun += parseFloat(valores[key].puntaje);
                _sum_cli_pon += parseInt(valores[key].ponderacion);
            }
            if (_operacion.includes(key)) {
                _sum_ope_pun += parseFloat(valores[key].puntaje);
                _sum_ope_pon += parseInt(valores[key].ponderacion);
            }
            if (_transaccion.includes(key)) {
                _sum_tra_pun += parseFloat(valores[key].puntaje);
                _sum_tra_pon += parseInt(valores[key].ponderacion);
            }
        });
        _valor['cliente'] = { puntaje: _sum_cli_pun.toFixed(2), ponderacion: _sum_cli_pon };
        _valor['operacion'] = { puntaje: _sum_ope_pun.toFixed(2), ponderacion: _sum_ope_pon };
        _valor['transaccion'] = { puntaje: _sum_tra_pun.toFixed(2), ponderacion: _sum_tra_pon };
    }

    const archivos = (data) => {
        setRiesgo(data)
        setDialogEstado(true);
    }

    const cabecera = () => {
        return (
            <>
                <div className="field col-6 p-1 ml-1 text-white bg-blue-600" style={{ textAlign: 'center' }}><b>FACTORES DE RIESGO</b></div>
                <div className="field col-2 p-1 mr-1 ml-1 text-white bg-blue-600" style={{ textAlign: 'center' }}><b>VALOR</b></div>
                <div className="field col-2 p-1 mr-1 text-white bg-blue-600" style={{ textAlign: 'center' }}><b>PUNTAJE</b></div>
                <div className="field col-1 p-1 mr-1 text-white bg-blue-600" style={{ textAlign: 'center' }}><b>PONDERACIÓN</b></div>
            </>
        )
    }

    const factor = (nemonico, listado) => {
        return (
            <>
                {inDrop(nemonico, listado, { lab: omodel.optionLabel, chn: onChangeFactor, val: nemonico, ml: ' ml-1' }, 6)}
                <div className="field col-2 p-4 mr-1 ml-1 bg-blue-100" style={{ textAlign: 'center', verticalAlign: 'middle' }} id={nemonico + "-valor"} >{valores[nemonico].valor}</div>
                <div className="field col-2 p-4 mr-1 bg-blue-100" style={{ textAlign: 'center', verticalAlign: 'middle' }} id={nemonico + "-puntaje"}>{valores[nemonico].puntaje}</div>
                <div className="field col-1 p-4 mr-1 bg-blue-100" style={{ textAlign: 'center', verticalAlign: 'middle' }} id={nemonico + "-ponderacion"}>{valores[nemonico].ponderacion} %</div>
            </>
        )
    }

    const pie = (label, tipo) => {
        return (
            <>
                <div className="field col-8 ml-1" style={{ textAlign: 'right', verticalAlign: 'middle' }} ><b>{label}</b></div>
                <div className="field col-2 mr-1 ml-1 bg-blue-100" style={{ textAlign: 'center', verticalAlign: 'middle' }}><b><span id={tipo + "-puntaje"}>{valores[tipo].puntaje}</span></b></div>
                <div className="field col-1 mr-1 ml-1 bg-blue-100" style={{ textAlign: 'center', verticalAlign: 'middle' }}><b><span id={tipo + "-ponderacion"}>{valores[tipo].ponderacion} %</span></b></div>
            </>
        )
    }

    const row = (label, opt) => {
        let sstyle = { textAlign: 'center', verticalAlign: 'middle' };
        if (opt.bg) sstyle.backgroundColor = '#' + opt.bg;
        if (opt.txt) sstyle.color = '#' + opt.txt;
        if (opt.sm) sstyle.fontSize = opt.sm;
        if (opt.sp) label = <span id={opt.sp}>{label}</span>;
        return (
            <div className={"field col-" + opt.col + " m-0 p-0 border-1"} style={sstyle}><b>{label}</b></div>
        )
    }

    const color = (val) => {
        let bcolor = 'ff1200';
        if (val <= 1) {
            bcolor = '00afed'
        } else if (val <= 2) {
            bcolor = '00fd3e';
        } else if (val <= 3) {
            bcolor = 'fffd3e';
        } else if (val <= 4) {
            bcolor = 'ffbe2e';
        }

        return bcolor;
    }

    const opcion = (select, setSelect, opt) => {
        let opciones = Array.from(Array(6), (e, i) => { return { nombre: i + "", cat_id: i } });
        if (opt.sino) {
            opciones = [{ nombre: 'SI', cat_id: 'SI' }, { nombre: 'NO', cat_id: 'NO' }];
        }
        let sstyle = { textAlign: 'center', verticalAlign: 'middle' };
        return (
            <div className={"field col-" + opt.col + " m-0 p-0 border-1 text-sm"} style={sstyle}>
                <Dropdown value={select} options={opciones} onChange={(e) => { setSelect(e.value) }} optionLabel="nombre" placeholder="Seleccione" />
            </div>
        )
    }

    const calcularMatriz = () => {
        const vals = { RES_REGISTRO: form, RES_LICITUD: licitud, RES_SUSTENTO: sustento, RES_LISTAS: lista, RES_APLICACION: aplicar };
        const ctls = {
            RES_REGISTRO: 'FORMULARIO / REGISTRO', RES_LICITUD: 'LICITUD FONDOS', RES_SUSTENTO: 'DOCUMENTACIÓN SUSTENTO',
            RES_LISTAS: 'VERIFICACIÓN LISTAS', RES_APLICACION: 'APLICACIÓN/JUSTIFICACIÓN'
        };
        let items = {};
        let seguir = true;
        Object.keys(vals).forEach(function (key) {
            if (vals[key] == null || vals[key].cat_id == null || (key != 'RES_APLICACION' && isNaN(vals[key].cat_id))) {
                toast.current.show({ severity: 'error', summary: ctls[key], detail: ctls[key] + " es requerido.", life: 3000 });
                seguir = false;
                return;
            }
            items[key] = vals[key].cat_id;
        });
        if (!seguir) {
            return;
        }
        items.id = getValues('id');
        matrizService.calcular(items).then(res => {
            toast.current.show({ severity: 'success', summary: 'Valores calculados', detail: "Ya puede generar el reporte.", life: 3000 });
            actualizarResultado(res.data);
            setReporte(res.data.lista?.RES_APLICACION.valor);
        });
    }

    const actualizarResultado = (data) => {
        const lst = data.lista;
        const items = ['RES_CONTROL', 'RES_APLICADO', 'RES_APLICACION', 'RES_RESIDUAL', 'RES_RIESGO_RESIDUAL', 'RES_RECOMENDADO'];
        items.forEach(vl => {
            const nomb = vl == 'RES_APLICACION' ? 'nombre' : 'valor';
            const value = vl == 'RES_APLICACION' ? lst[vl].nombre : lst[vl].valor;
            document.getElementById(vl + "-" + nomb).innerText = value;
        });
    }

    const cambiarEstadoMatriz = (opc) => {
        matrizService.cambiarMatriz({opcion: opc, id: riesgo?.id}).then(res => {
            toast.current.show({ severity: 'success', summary: 'Cambio realizaado', detail: res.message, life: 3000 });
            loadLazyData();
            setDialogEstado(false);
        }).catch(error => {
            loadLazyData();
            toast.current.show({ severity: 'error', summary: 'Error al cambiar de estado', detail: error.response.data.message, life: 3000 });
        });
    }

    const confirmEstado = (opc, msg) => {
        confirmDialog({
            message: msg,
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => cambiarEstadoMatriz(opc)
        });
    }

    return (
        <>
            <Toast ref={toast} />
            <Card title={!dialogMatriz ? 'LISTADO' : ((getValues('id') == null ? 'NUEVA' : 'EDITAR') + ' MATRIZ')} style={{ maxWidth: '100%', margin: 'auto' }}>
                {!dialogMatriz && <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nueva matriz', permss)}
                    end={generic.rightToolbarTemplate(matrices, omodel, 'Listado_matrices', 'LISTADO DE MATRICES')}></Toolbar>}
                {!dialogMatriz && table({ action3: generic.actionEditDetailTemplate, methEdit: editMatriz, perm: permss, methDelete: confirmDelete, details: archivos, icono: 'pi pi-clone' })}
                {dialogMatriz &&
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <Panel header="Fecha - Datos generales" style={{ width: '100%', margin: 'auto' }}>
                            <div className="formgrid grid">
                                {inCalendar('fecha')}
                                {inText('nombres')}
                                {inDrop('tipo_identificacion', catalogos?.tipo_identificacion, { lab: omodel.optionLabel, chn: onChangeTipo })}
                                {!esRuc && !esCedula && inText('identificacion')}
                                {esRuc && inText('identificacion', generic.infoIdentificacion('ruc'))}
                                {esCedula && inText('identificacion', generic.infoIdentificacion('cedula'))}
                                {esRuc && inText('razon_social')}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="CLIENTE" style={{ width: '100%', margin: 'auto' }}>
                            <div className="formgrid grid align-items-center justify-content-center">
                                {cabecera()}
                                {factor('TIPO_CLIENTE', catalogos?.tipo)}
                                {factor('ACTIVIDAD', catalogos?.actividad)}
                                {factor('NACIONALIDAD', catalogos?.nacionalidad)}
                                {factor('PAIS', catalogos?.nacionalidad)}
                                {factor('CIUDAD', catalogos?.ciudad)}
                                {pie('VALOR CLIENTE:', 'cliente')}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="OPERACIÓN" style={{ width: '100%', margin: 'auto' }}>
                            <div className="formgrid grid align-items-center justify-content-center">
                                {cabecera()}
                                {factor('TIPO_BIEN', catalogos?.tipo_bien)}
                                {factor('MONTO', catalogos?.monto)}
                                {factor('PAIS_BIEN', catalogos?.nacionalidad)}
                                {factor('CIUDAD_BIEN', catalogos?.ciudad)}
                                {pie('VALOR OPERACIÓN:', 'operacion')}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="TRANSACCIÓN" style={{ width: '100%', margin: 'auto' }}>
                            <div className="formgrid grid align-items-center justify-content-center">
                                {cabecera()}
                                {factor('TIPO_TRANSACCION', catalogos?.tipo_transaccion)}
                                {factor('INSTRUMENTO', catalogos?.instrumento)}
                                {factor('CANAL', catalogos?.canal)}
                                {factor('CIUDAD_SERVICIO', catalogos?.ciudad)}
                                {factor('PAIS_FONDO', catalogos?.nacionalidad)}
                                {pie('VALOR TRANSACCIÓN:', 'transaccion')}
                            </div>
                        </Panel>
                        <br />

                        <Panel header="FACTOR ACELERADOR" style={{ width: '100%', margin: 'auto' }}>
                            <div className="formgrid grid align-items-center justify-content-center">
                                {cabecera()}
                                {factor('FACTOR_ACELERADOR', catalogos?.factor)}
                            </div>
                        </Panel>
                        <br />

                        {generic.buttonsForms(setDialogMatriz)}
                    </form>
                }
            </Card>

            <Dialog visible={dialogResultado} header="Resultado" modal className="p-fluid modal-ssize" onHide={e => setDialogResultado(false)}>
                <div className="formgrid grid" style={{ width: '700px' }}>
                    {row('TABLA DE ALERTAS', { col: 12 })}
                    {row('FACTORES DE RIESGO', { col: 3 })}
                    {row('PUNTAJES', { col: 3 })}
                    {row('ALERTA', { col: 3 })}
                    {row('PRIORIDAD DE ATENCIÓN', { col: 3 })}

                    {row('CLIENTE', { col: 3 })}
                    {row(resultado?.cliente.puntaje, { col: 3, bg: color(resultado?.cliente.puntaje) })}
                    {row(resultado?.cliente.alerta, { col: 3, txt: color(resultado?.cliente.puntaje) })}
                    {row(resultado?.cliente.atencion, { col: 3, txt: color(resultado?.cliente.puntaje) })}

                    {row('OPERACIÓN', { col: 3 })}
                    {row(resultado?.operacion.puntaje, { col: 3, bg: color(resultado?.operacion.puntaje) })}
                    {row(resultado?.operacion.alerta, { col: 3, txt: color(resultado?.operacion.puntaje) })}
                    {row(resultado?.operacion.atencion, { col: 3, txt: color(resultado?.operacion.puntaje) })}

                    {row('TRANSACCIÓN', { col: 3 })}
                    {row(resultado?.transaccion.puntaje, { col: 3, bg: color(resultado?.transaccion.puntaje) })}
                    {row(resultado?.transaccion.alerta, { col: 3, txt: color(resultado?.transaccion.puntaje) })}
                    {row(resultado?.transaccion.atencion, { col: 3, txt: color(resultado?.transaccion.puntaje) })}

                </div>
                <br />

                <div className="formgrid grid" style={{ width: '700px' }} >
                    <div className="field col-4">
                        <div className="formgrid grid" style={{ width: '200px' }}>
                            {row('NIVEL RIESGO', { col: 6 })}
                            {row('RANGO', { col: 6 })}

                            {row('MUY BAJO', { col: 6, bg: color(0.4) })}
                            {row('0,1 A 1', { col: 6 })}

                            {row('BAJO', { col: 6, bg: color(1.4) })}
                            {row('1,1 A 2', { col: 6 })}

                            {row('MEDIO', { col: 6, bg: color(2.4) })}
                            {row('2,1 A 3', { col: 6 })}

                            {row('ALTO', { col: 6, bg: color(3.4) })}
                            {row('3,1 A 4', { col: 6 })}

                            {row('MUY ALTO', { col: 6, bg: color(4.4) })}
                            {row('4,1 A 5', { col: 6 })}
                        </div>
                    </div>

                    <div className="field col-8" style={{ textAlign: 'right', maxWidth: '100%' }}>
                        <div className="formgrid grid" style={{ width: '100%' }}>
                            {row(resultado?.lista.RES_CALIFICACION.nombre, { col: 6 })}
                            {row(resultado?.lista.RES_CALIFICACION.valor, { col: 6, bg: color(resultado?.lista.RES_CALIFICACION.valor) })}

                            {row(resultado?.lista.RES_NIVEL.nombre, { col: 6 })}
                            {row(resultado?.lista.RES_NIVEL.valor, { col: 6, bg: color(resultado?.lista.RES_CALIFICACION.valor) })}

                            {row(resultado?.lista.RES_PROCEDIMIENTO.nombre, { col: 6 })}
                            {row(resultado?.lista.RES_PROCEDIMIENTO.valor, { col: 6, bg: '282828', txt: 'fffd3e' })}

                            {row('CONTROLES APLICADOS:', { col: 6 })}
                            <div className="field col-6 m-0 p-0 border-1">
                                <div className="formgrid grid m-0" style={{ width: '100%' }}>
                                    {row('CONTROL', { col: 6, sm: '0.8em' })}
                                    {row('SELECCIONAR CALIFICACIÓN', { col: 6, txt: 'aeb0fc', sm: '0.8em' })}

                                    {row(resultado?.lista.RES_REGISTRO.nombre, { col: 6, sm: '0.8em' })}
                                    {opcion(form, setForm, { col: 6 })}

                                    {row(resultado?.lista.RES_LICITUD.nombre, { col: 6, sm: '0.8em' })}
                                    {opcion(licitud, setLicitud, { col: 6 })}

                                    {row(resultado?.lista.RES_SUSTENTO.nombre, { col: 6, sm: '0.8em' })}
                                    {opcion(sustento, setSustento, { col: 6 })}

                                    {row(resultado?.lista.RES_LISTAS.nombre, { col: 6, sm: '0.8em' })}
                                    {opcion(lista, setLista, { col: 6 })}
                                </div>
                            </div>

                            {row(resultado?.lista.RES_CONTROL.nombre, { col: 8 })}
                            {row(resultado?.lista.RES_CONTROL.valor, { col: 4, sp: 'RES_CONTROL-valor' })}

                            {row(resultado?.lista.RES_APLICADO.nombre, { col: 8 })}
                            {row(resultado?.lista.RES_APLICADO.valor, { col: 4, sp: 'RES_APLICADO-valor' })}

                            {row(resultado?.lista.RES_APLICACION.nombre, { col: 6, bg: '55803e', txt: 'e3c866', sp: 'RES_APLICACION-nombre' })}
                            {opcion(aplicar, setAplicar, { col: 6, sino: 'sino' })}

                            {row(resultado?.lista.RES_RESIDUAL.nombre, { col: 6 })}
                            {row(resultado?.lista.RES_RESIDUAL.valor, { col: 6, bg: color(resultado?.lista.RES_RESIDUAL.valor), sp: 'RES_RESIDUAL-valor' })}

                            {row(resultado?.lista.RES_RIESGO_RESIDUAL.nombre, { col: 6 })}
                            {row(resultado?.lista.RES_RIESGO_RESIDUAL.valor, { col: 6, bg: color(resultado?.lista.RES_RESIDUAL.valor), sp: 'RES_RIESGO_RESIDUAL-valor' })}

                            {row(resultado?.lista.RES_RECOMENDADO.nombre, { col: 6 })}
                            {row(resultado?.lista.RES_RECOMENDADO.valor, { col: 6, bg: '2a2a2a', txt: 'e8e8e8', sp: 'RES_RECOMENDADO-valor' })}
                        </div>
                    </div>
                </div>
                <Divider />

                <div className="formgrid grid">
                    <div className="field col">
                        <Button label="Calcular" style={{ maxWidth: '120px' }} type="button" icon="pi pi-mobile"
                            className="p-button-warning" onClick={() => calcularMatriz()} />
                    </div>
                    <div className="field col">
                        <Button label="Generar" style={{ maxWidth: '120px' }} type="button" icon="pi pi-file-pdf" loading={loadReport} disabled={!reporte}
                            className="p-button-warning" onClick={() => generarReporte()} />
                    </div>
                    <div className="field col">
                        <Button label="Cancelar" style={{ maxWidth: '120px' }} icon="pi pi-times" type="button" className="p-button-warning" onClick={() => setDialogResultado(false)} />
                    </div>
                </div>
            </Dialog >

            <Dialog visible={dialogEstado} header="Descargar matriz / Finalizar" modal className="p-fluid modal-ssize" onHide={e => setDialogEstado(false)} style={{ width: '600px' }}>
                <br />
                {riesgo?.riesgo_residual &&
                    <div className="formgrid grid">
                        <div className="field col-12" style={{ textAlign: 'center' }}>
                            <Button title="Generar" style={{ maxWidth: '120px' }} type="button" icon="pi pi-file-pdf" loading={loadReport}
                                className="p-button-warning mr-2" onClick={() => generarReporte(riesgo?.id)} />
                        </div>

                        <div className="field col-12" style={{ textAlign: 'center' }}>
                            {getTipo() == 'todos' && riesgo?.nemonico == 'FINALIZADO' && <><Button label="Volver a borrador" type="button" style={{ maxWidth: '170px' }} icon="pi pi-history"
                                className="p-button-warning" onClick={() => confirmEstado('vb', '¿Desea regresar a estado borrador?')} />&nbsp;</>}
                            {(getTipo() == 'empresa' || getTipo() == 'todos') && riesgo?.nemonico == 'BORRADOR' && <><Button label="Finalizar" type="button" style={{ maxWidth: '150px' }}
                                icon="pi pi-check-square" className="p-button-warning" onClick={() => confirmEstado('f', '¿Desea finalizar la matriz?. Si la finaliza no va a poder editar.')} />&nbsp;</>}
                            <Button label="Cerrar" icon="pi pi-times" style={{ maxWidth: '100px' }} type="button" className="p-button-warning" onClick={() => setDialogEstado(false)} />
                        </div>
                    </div>
                }
            </Dialog>
        </>
    )
}
