import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { ColaPep } from "../../models/administracion/ColaPep";
import { useForm } from "react-hook-form";
import { PepService } from "../../service/PepService";
import { PepEtlService } from "../../service/admin/PepEtlService";
import { InputComponent } from "../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import AdjuntoService from "../../service/AdjuntoService";
import { FileUpload } from "primereact/fileupload";
import { ScrollPanel } from 'primereact/scrollpanel';
import { ListaTmpPep } from "./ListaTmpPep";
import { IntentoPepCola } from "./IntentoPepCola";

export const EtlPep = () => {
    let fileRef = useRef(null);
    const generic = new GenericComponent();
    const omodel = new ColaPep();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, setValue, trigger } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registros, setRegistros] = useState(null);
    const toast = useRef(null);
    const pepService = new PepService();
    const etlService = new PepEtlService();
    const adjuntoService = new AdjuntoService();
    const [archivo, setArchivo] = useState(null);
    const [dialogSubir, setDialogSubir] = useState(false);
    const [selectedIns, setSelectedIns] = useState(null);
    const [ins, setIns] = useState(null);
    const [dialogEtl, setDialogEtl] = useState(false);
    const [dialogIntento, setDialogIntento] = useState(false);
    const [info, setInfo] = useState(null);
    const [load, setLoad] = useState(false);
    const [totalPep, setTotalPep] = useState(0);
    const [siExcel, setSiExcel] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inNumber, inAutocomplete, inRadio, inText } =
        InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            etlService.getPepEtl({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        setDialogEtl(true);
        setSelectedIns(null);
        setSiExcel(true);
        setIns(null);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            etlService.newPepEtl(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogEtl(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            etlService.editarPepEtl(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogEtl(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editarEtl = (inst) => {
        etlService.consultarPepEtl(inst.id).then(res => {
            let _inst = res.data;
            setSelectedIns(_inst.institucion);
            reset(generic.renewValues(_inst, omodel.model));
            setSiExcel(_inst.tipo === 1);
            setDialogEtl(true);
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminar(data)
        });
    }

    const eliminar = (data) => {
        etlService.eliminarPepEtl(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const searchIns = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setIns([...ins]);
            }
            else {
                pepService.getInstituciones(event.query.toLowerCase(), 1).then(data => {
                    setIns(data.data);
                });
            }
        });
    }

    const seleccionarInstitucion = (v) => {
        setSelectedIns({ cat_id: v.cat_id, nombre: v.nombre });
        setValue('col_nombre', v.col_nombre);
        setValue('col_identificacion', v.col_identificacion ?? 0);
        setValue('col_rem_mensual', v.col_rem_mensual);
        setValue('col_rem_anual', v.col_rem_anual);
        setValue('separacion', v.separacion ?? ';');
        setValue('col_puesto1', v.col_puesto ?? 0);
        setValue('col_puesto2', v.col_puesto ?? 0);
    }

    const subirArchivos = (data) => {
        setDialogSubir(true);
        setArchivo(null);
        setInfo(data);
    }

    const subida = () => {
        if (archivo == null) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el archivo.', life: 3000 });
            return;
        }
        const formData = new FormData();
        formData.append('file', archivo, archivo.name);
        formData.append('tipo', info?.tipo === 'Excel' ? 'xlsx' : 'csv');
        formData.append('id', info?.id);
        const unuevo = 'admin/etl-pep/individual';
        setLoad(true);
        adjuntoService.newAdjunto(formData, unuevo).then(res => {
            setLoad(false);
            setDialogSubir(false);
            setArchivo(null);
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            setLoad(false);
            generic.funcDeleteError(error, toast, setError);
        });
    }

    const confirmEstado = () => {
        confirmDialog({
            message: '¿Está seguro de iniciar el proceso? No podrá modificar, ni eliminar la información',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => cambiarEstado()
        });
    }

    const cambiarEstado = () => {
        setLoad(true);
        etlService.cambioEstado({ id: info?.id }).then(res => {
            setLoad(false);
            setDialogSubir(false);
            setArchivo(null);
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            setLoad(false);
            generic.funcDeleteError(error, toast, setError);
        })
    }

    const verHistorial = (data) => {
        setDialogIntento(true);
        setInfo(data);
    }

    const acciones = (rowData) => {
        return (
            <div className="actions">
                {permss?.actualizar &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editarEtl(rowData)} title="Editar" />}
                <Button icon="pi pi-file-excel" title='Subir archivo' className="p-button-rounded p-button-success mr-2" onClick={() => subirArchivos(rowData)} />
                <Button icon="pi pi-check" title='Historial cargas' className="p-button-rounded p-button-success mr-2" onClick={() => verHistorial(rowData)} />
                {permss?.eliminar && ![2, 4].includes(rowData?.estado) && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDelete(rowData)} title="Eliminar" />}
            </div>
        );
    }

    const selectFile = (e) => {
        const size = parseInt(process.env.REACT_APP_PEP_SIZE);
        if (e.files[0].size > size) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1000) + " Kb", life: 3000 });
        }
        else {
            setArchivo(e.files[0]);
        }
        fileRef?.clear();
    }

    const invoiceUploadHandler = ({ files }) => {
        const [file] = files;
        const size = parseInt(process.env.REACT_APP_PEP_SIZE);
        if (file.size > size) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Tamaño máximo del archivo es: ' + (size / 1000) + " Kb", life: 3000 });
        }
        else {
            setArchivo(file);
        }
        fileRef?.clear();
    }

    const cambioTipo = (value) => {
        setSiExcel(value === 1);
        setValue('separacion', (value === 1 ? '\\t' : ';'));
        trigger('separacion');
    }

    const confirmLimpiar = (data) => {
        confirmDialog({
            message: '¿Está seguro de limpiar el listado temporal?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => limpiar(data)
        });
    }

    const limpiar = (data) => {
        etlService.eliminarPepTmp(info.id).then(res => {
            setDialogSubir(false);
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    return (
        <>
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                    end={generic.rightToolbarTemplate(registros, omodel, 'Listado_etl', 'LISTADO ETL PEPS')}></Toolbar>
                {table({ action5: acciones })}
                <h6>*La información cargada de pep no procesada, será eliminada luego de 24 horas.</h6>
            </Card>
            <Dialog visible={dialogEtl} header={getValues('id') == null ? "Nuevo registro" : "Editar registro"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogEtl(false)} style={{ maxWidth: '800px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <br />
                    <div className="formgrid grid">
                        {inRadio('tipo', omodel.tipoArchivos, { col: 6, onChange: cambioTipo })}
                        {inRadio('archivo', omodel.numeroArchivos, { col: 6 })}
                        {inAutocomplete('institucion', selectedIns, seleccionarInstitucion, ins, searchIns, { lab: omodel.optionLabel, noEsActividad: true, col: 12 })}
                        {inNumber('col_nombre', 0, false, { tooltip: omodel.instrucciones }, 4)}
                        {inNumber('col_puesto1', 0, false, { tooltip: omodel.instrucciones }, 4)}
                        {inNumber('col_puesto2', 0, false, { tooltip: omodel.instrucciones }, 4)}
                        {inNumber('col_identificacion', 0, false, { tooltip: omodel.instrucciones }, 4)}
                        {inNumber('col_rem_mensual', 0, false, { tooltip: omodel.instrucciones }, 4)}
                        {inNumber('col_rem_anual', 0, false, { tooltip: omodel.instrucciones }, 4)}
                        {inText('separacion', { readOnly: siExcel }, 4)}
                    </div>
                    {generic.buttonsForms(setDialogEtl)}
                </form>

            </Dialog>

            <Dialog visible={dialogSubir} header={"Subir archivo: " + info?.institucion} modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ maxWidth: '1000px' }}>
                <ListaTmpPep permss={permss} colaId={info?.id} setTotalPep={setTotalPep} />
                <br />
                {![2, 4].includes(info?.estado) &&
                    <div className="formgrid grid">
                        <div className="field col-9">
                            <div className="card scrollpanel-demo">
                                <div className="flex flex-column md:flex-row gap-5">
                                    <div className="flex-auto">
                                        <ScrollPanel style={{ width: '100%', height: '100px' }} className="custombar1">
                                            {omodel.insCarga}
                                        </ScrollPanel>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field col-3">
                            <b>Seleccione un archivo*</b><br />
                            <FileUpload name="file" accept=".zip" maxFileSize={205000} uploadHandler={invoiceUploadHandler} mode="basic"
                                customUpload chooseLabel="Seleccione" onSelect={selectFile} ref={ref => { fileRef = ref }} tooltip={omodel.insCarga} />

                            {archivo?.name}
                        </div>
                    </div>
                }

                <div className="formgrid grid">
                    {![2, 4].includes(info?.estado) &&
                        <div className="field col" style={{ textAlign: 'center' }}>
                            <Button label="Subir" style={{ maxWidth: '120px', margin: 'auto' }} type="button" icon="pi pi-check" className="p-button-warning" onClick={() => subida()} loading={load} />
                        </div>
                    }
                    <div className="field col" style={{ textAlign: 'center' }}>
                        <Button label="Cerrar" style={{ maxWidth: '120px' }} icon="pi pi-times" type="button" className="p-button-warning" onClick={() => setDialogSubir(false)} disabled={load} />
                    </div>
                    {info?.estado === 1 && totalPep > 0 && <>
                        <div className="field col" style={{ textAlign: 'center' }}>
                            <Button label="Limpiar" tooltip="Limpiar listado de PEPS temporal" style={{ maxWidth: '120px' }} icon="pi pi-angle-right" type="button"
                                className="p-button-warning" onClick={() => confirmLimpiar()} disabled={load} />
                        </div>
                        <div className="field col" style={{ textAlign: 'center' }}>
                            <Button label="Aceptar" tooltip="Aceptar e iniciar proceso" style={{ maxWidth: '120px' }} icon="pi pi-angle-right" type="button"
                                className="p-button-warning" onClick={() => confirmEstado()} disabled={load} />
                        </div>
                    </>
                    }
                </div>
            </Dialog>

            <Dialog visible={dialogIntento} header={"Historial de cargas: " + info?.institucion} modal className="p-fluid modal-ssize"
                onHide={e => setDialogIntento(false)} style={{ maxWidth: '500px', minWidth: '300px' }}>
                <IntentoPepCola permss={permss} etlService={etlService} ids={info?.id} />
            </Dialog>
        </>
    )
}
