import Axios from "axios";

export class EmpleadoService {

    getLista(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/empleado/lista?${queryParams}`).then(res => res.data);
    }

    newEmpleado(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/empleado/nuevo`, data).then(res => res.data);
    }

    editarEmpleado(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/empleado/editar`, data).then(res => res.data);
    }

    descargar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/empleado/reporte/${id}`, { headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache' }, responseType: 'blob'}).then(async res => res.data);
    }

    consultarEmpleado(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/empleado/consulta/${id}/${iniciar}`).then(res => res.data);
    }

    eliminarEmpleado(id, tipo, idp) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/empleado/eliminar/${id}`).then(res => res.data);
    }
}