import { useEffect, useState } from "react";
import { PlanAccionista } from "../../models/matriz/PlanAccionista";
import { useForm } from "react-hook-form";
import { PlanAccionistaService } from "../../service/matriz/PlanAccionistaService";
import { InputComponent } from "../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Generico } from "../../components/Generico";

export const PlantillaAccionista = ({ generic, permss, toast, plan, setRegresar }) => {

    const omodel = new PlanAccionista();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const { accionesTbl } = Generico();
    const [registers, setRegisters] = useState(null);
    const accionistaSrv = new PlanAccionistaService();
    const [dialogAccionista, setDialogAccionista] = useState(false);
    const [catalogo, setCatalogo] = useState(null);
    const [accionista, setAccionista] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inNumber } = InputComponent({ registers, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.idp = plan?.id;
            delete _lazyParams.catalogo;
            if (catalogo == null) {
                _lazyParams.catalogo = 'consultar';
            }
            accionistaSrv.lista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegisters(res.data.registros);
                if (catalogo == null) {
                    setCatalogo(res.data.catalogo);
                }
                setLoading(false);
            });
        });
    }

    const openNew = () => {
        setAccionista(null);
        reset(defaultValues);
        setDialogAccionista(true);
    }

    const edit = () => {
        accionistaSrv.consultar(accionista?.id).then(res => {
            const lista = { estado: generic.estados, seccion: catalogo?.seccion, color: catalogo?.color, tipo_analisis: catalogo?.analisis, };
            let _plan = res.data;

            Object.keys(lista).forEach(function (key) {
                if (_plan[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _plan[key]) {
                            _plan[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_plan, omodel.model));
            setDialogAccionista(true);
        })
    }

    const onSubmit = (data) => {
        data.idp = plan?.id;
        if (getValues('id') == null) {
            accionistaSrv.nuevo(data).then(res => {
                generic.funcSuccess(res, toast);
                loadLazyData();
                setDialogAccionista(false);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        } else {
            accionistaSrv.editar(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogAccionista(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const deleteItem = () => {
        confirmDialog({
            message: '¿Esta seguro de eliminar la información?',
            header: 'Confirmar',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
                accionistaSrv.eliminar(accionista?.id).then(res => {
                    generic.funcSuccess(res, toast);
                    loadLazyData();
                }).catch(error => {
                    generic.funcDeleteError(error, toast);
                });
            }
        });
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar)
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { edit() } });
        if (permss?.eliminar)
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { deleteItem() } });

        return [{ label: 'Opciones', items: items }];
    }

    return (
        <>
            <Card style={{ maxWidth: '100%', margin: 'auto' }} title={"Factores Matriz: " + plan?.nombre}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}></Toolbar>
                {table({ action01: accionesTbl, reg: setAccionista, items: itemsMenu() })}

                <br />
                <Button label="Regresar" icon="pi pi-arrow-left" className="p-button-success mr-2" type="button" onClick={() => setRegresar(false)} />
            </Card>

            <Dialog visible={dialogAccionista} header={getValues('id') == null ? "Nuevo Item" : "Editar Item"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogAccionista(false)} style={{ maxWidth: '600px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inDrop('tipo_analisis', catalogo?.analisis, { lab: omodel.optionLabel }, 6)}
                        {inNumber('orden', 0, false, { min: 0 }, 6)}
                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 6)}
                        {inDrop('seccion', catalogo?.seccion, { lab: omodel.optionLabel }, 6)}
                        {inText('nombre', {}, 12)}
                        {inDrop('color', catalogo?.color, { lab: omodel.optionLabel }, 6)}
                        {inText('anio1', {}, 6)}
                        {inText('anio2', {}, 6)}
                        {inText('formula1', {}, 6)}
                        {inText('formula2', {}, 6)}
                        {inText('formula3', {}, 6)}

                    </div>

                    {generic.buttonsForms(setDialogAccionista)}
                </form>
            </Dialog>
        </>
    )
}
