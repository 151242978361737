import { FilterMatchMode } from "primereact/api";

export class Matrices {
    model = {
        id: {value: null, export: true, header: 'Código', table: true, filter: true, filterPlaceholder: 'Buscar por código', filterField: 'id', sort: true, 
                matchMode: FilterMatchMode.EQUALS, showFilterMatchModes: false, headerStyle: { width: '10%', minWidth: '10rem' }},
        nombres: {value: '', export: true, header: 'NOMBRE/RAZÓN SOCIAL:', required: 'Nombres es requerido', maxLength: 'Máximo 300 caracteres', 
                minLength: 'Mínimo 4 caracteres', max: 200, min: 4, table: true, filterField: 'nombre', filter: false, sort: true},
        tipo_identificacion: {value: null, export: false, header: 'Tipo de Identificación', required: 'Tipo de identificación es requerido'},
        identificacion: {value: '', export: true, header: 'Identificación', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres', table: true, filter: true, sort: true,
                minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'identificacion', filterPlaceholder: 'Buscar por identificación', fielterField: 'identificacion',
                matchMode: FilterMatchMode.CONTAINS, headerStyle: { width: '15%', minWidth: '15rem' }},
        fecha: {value: new Date(), export: true, header: 'Fecha (día/mes/año):', required: 'Fecha es requerido', table: true, sort: true, headerStyle: { width: '10%', minWidth: '10rem' }},
        valor_cliente: {value: null, export: true, header: 'Valor cliente', required: 'Valor es requerido', table: true, headerStyle: { width: '10%', minWidth: '10rem' }},
        valor_operacion: {value: null, export: true, header: 'Valor operación', required: 'Valor es requerido', table: true, headerStyle: { width: '10%', minWidth: '10rem' }},
        valor_transaccion: {value: null, export: true, header: 'Valor transacción', required: 'Valor es requerido', table: true, headerStyle: { width: '10%', minWidth: '10rem' }},
        estado: {value: null, export: false, header: 'Estado', required: 'Estado es requerido', table: false, headerStyle: { width: '10%', minWidth: '10rem' }},
        TIPO_CLIENTE: {value: null, export: false, header: 'TIPO / ESTRUCTURA LEGAL:', required: 'Seleccione una opción'},
        ACTIVIDAD: {value: null, export: false, header: 'ACTIVIDAD ECONÓMICA:', required: 'Seleccione una opción'},
        NACIONALIDAD: {value: null, export: false, header: 'NACIONALIDAD:', required: 'Seleccione una opción'},
        PAIS: {value: null, export: false, header: 'PAÍS RESIDENCIA:', required: 'Seleccione una opción'},
        CIUDAD: {value: null, export: false, header: 'CIUDAD/CANTÓN:', required: 'Seleccione una opción'},
        TIPO_BIEN: {value: null, export: false, header: 'TIPO DE BIEN:', required: 'Seleccione una opción'},
        MONTO: {value: null, export: false, header: 'MONTO DE LA OPERACIÓN (CONTRATO):', required: 'Seleccione una opción'},
        PAIS_BIEN: {value: null, export: false, header: 'PAÍS DE UBICACIÓN DEL BIEN:', required: 'Seleccione una opción'},
        CIUDAD_BIEN: {value: null, export: false, header: 'CIUDAD/CANTÓN DEL BIEN:', required: 'Seleccione una opción'},
        TIPO_TRANSACCION: {value: null, export: false, header: 'TIPO DE TRANSACCIÓN:', required: 'Seleccione una opción'},
        INSTRUMENTO: {value: null, export: false, header: 'INSTRUMENTO MONETARIO CON QUE PAGA EL CLIENTE:', required: 'Seleccione una opción'},
        CANAL: {value: null, export: false, header: 'CANAL DE SERVICIO DEL BIEN:', required: 'Seleccione una opción'},
        CIUDAD_SERVICIO: {value: null, export: false, header: 'CIUDAD/CANTÓN DEL CANAL DE SERVICIO DEL BIEN:', required: 'Seleccione una opción'},
        PAIS_FONDO: {value: null, export: false, header: 'PAÍS RESIDENCIA:', required: 'Seleccione una opción'},
        FACTOR_ACELERADOR: {value: null, export: false, header: 'FACTOR ACELERADOR(SEÑAL/ES DE ALERTA DETECTADA/S):', required: 'Seleccione una opción'},
        riesgo_inherente: {value: null, export: true, header: 'Riesgo inherente', required: false, table: true, headerStyle: { width: '10%', minWidth: '10rem' }},
        riesgo_residual: {value: null, export: true, header: 'Riesgo residual', required: false, table: true, headerStyle: { width: '10%', minWidth: '10rem' }},
        estado_matriz: {value: null, export: false, header: 'Estado', required: false, table: true, headerStyle: { width: '10%', minWidth: '10rem' }},
    }
    optionLabel = 'nombre';
}