import { FilterMatchMode } from "primereact/api";

export class ColaPep {

    model = {
        id: { value: null, export: false, header: 'Código' },
        institucion: {
            value: null, export: true, header: 'Institución', required: 'Institución es requerido', table: true, sort: false, filter: true,
            filterPlaceholder: 'Buscar por institución', matchMode: FilterMatchMode.CONTAINS, filterField: 'institucion'
        },
        col_nombre: { value: 1, export: false, header: 'Índice para el nombre', required: 'Campo requerido', minLength: 'Valor mínimo 1', min: 1 },
        col_puesto1: { value: 1, export: false, header: 'Índice para el puesto institucional (directorio)', required: 'Campo requerido', minLength: 'Valor mínimo 1', min: 1 },
        col_puesto2: { value: 1, export: false, header: 'Índice para el puesto institucional (salario)', required: 'Campo requerido', minLength: 'Valor mínimo 1', min: 1 },
        col_identificacion: { value: 1, export: false, header: 'Índice para la identificación', required: false, minLength: 'Valor mínimo 0', min: 0 },
        col_rem_anual: { value: 0, export: false, header: 'Remuneración anual', required: false, minLength: 'Valor mínimo 0', min: 0 },
        col_rem_mensual: { value: 0, export: false, header: 'Remuneración mensual', required: false, minLength: 'Valor mínimo 0', min: 0 },
        tipo: { value: 2, export: true, header: 'Tipo de archivo', required: 'Seleccione el tipo de archivo', table: true, sort: false },
        archivo: { value: 1, export: true, header: 'Número de archivos a subir', required: '¿Son 1 o 2 archivos?', table: true, sort: false },
        estado: { value: null, export: false, header: 'Estado', required: false, table: false, sort: false },
        estado_nombre: { value: null, export: true, header: 'Estado', required: false, table: true, sort: false },
        inicio: { value: null, export: true, header: 'Fecha', required: false, table: true, sort: false },
        separacion: { value: ';', export: true, header: 'Separación columna', required: false, maxLength: 'Máximo 5 caracteres', max: 5 }
    }
    tipoArchivos = [
        { id: 1, name: 'Microsoft Excel (xlsx)' },
        { id: 2, name: 'CSV' }
    ];
    numeroArchivos = [
        { id: 1, name: 'Uno' },
        { id: 2, name: 'Dos' }
    ]
    optionLabel = 'nombre';
    instrucciones = `Tomar en cuenta:\n
            * Las columnas de índices deben tener 3 dígitos.
            * Si existe un solo archivo (renombrar a directorio.csv o \ndirectorio.xlsx) la columna número comenzará con 1.
            * Para dos archivos: el primer dígito corresponde al\n archivo directorio.csv o directorio.xlsx,\n el 2 para salario.csv o salario.xlsx.
            * Si la columna no existe colocar 0.
            * El índice de las columnas inician desde el 1.`;
    insCarga = <>
        <b>Tomar en cuenta:</b>
        <ul>
            <li>El o los archivos deben ser comprimidos (.zip). <b>No la carpeta que los contiene.</b></li>
            <li>El formato de archivo es CSV o XLSX (según lo ingresado)</li>
            <li>El archivo debe ser de 10 MB o menor.</li>
            <li>La primera fila se tomado en cuenta como encabezado.</li>
            <li>Para dos archivos, colocar los nombres: <b>directorio.csv o directorio.xlsx</b> y <b>salario.csv o salario.xlsx</b></li>
            <li>Para un archivo, colocar el nombre: <b>directorio.csv o directorio.xlsx</b></li>
            <li>Para un archivo Excel, toda la información debe estar en la primera hoja.</li>
        </ul>
    </>
}
