import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useToken } from '../components/useToken';
import { Controller, useForm } from 'react-hook-form';
import { useRef } from 'react';
import { useHistory } from "react-router-dom";
import AuthService from '../service/AuthService';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { Resetear } from './administracion/Resetear';
import { Toast } from 'primereact/toast';

export const Login = () => {
    const { getNombre, saveToken } = useToken();
    const history = useHistory();
    const authService = new AuthService();
    const toast = useRef(null);
    const defaultValues = {
        usuario: '',
        clave: ''
    }
    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });
    const message = useRef();

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error"><br />{errors[name].message}</small>
    };

    const onSubmit = (data) => {
        authService.getLogin(data).then(res => {
            saveToken(res.data.data);
            if (getNombre()) {
                history.push("/");
            }
        }).catch(error => {
            try {
                message.current.show({ severity: 'error', content: error.response.data.message });
            } catch (err) {
                message.current.show({ severity: 'error', content: 'Error al ingresar. Consulte al administrador' });
            }
        });
    }

    return (
        <div className="login-body">
            <Toast ref={toast} />
            <div className="login-wrapper">
                <div className="login-panel">
                    <img src="assets/layout/images/logoPrelafit.png" className="logo" alt="Prelafit" />

                    <div className="login-form">
                        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                            <h2>Ingresar</h2>
                            <>
                                <Controller name="usuario" control={control} rules={{
                                    required: 'Nombre de usuario es requerido.', pattern: { value: /^[^\s*$]/, message: 'Nombre de usuario incorrecto' },
                                    maxLength: { value: 200, message: 'Máximo 100 caracteres para el usuario' }, minLength: { value: 4, message: 'Mínimo 4 caracteres para el usuario' }
                                }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} style={{ width: '100%' }} className={classNames({ 'p-invalid': fieldState.isDirty })} placeholder="Usuario" />
                                )} />
                                {getFormErrorMessage('usuario')}
                            </>
                            <br />
                            <br />

                            <>
                                <Controller name="clave" control={control} rules={{
                                    required: 'Clave es requerido.',
                                    maxLength: { value: 200, message: 'Máximo 200 caracteres para la clave' }, minLength: { value: 4, message: 'Mínimo 4 caracteres para la clave' }
                                }} render={({ field: { ref, ...field }, fieldState }) => (
                                    <Password id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.isDirty })} placeholder="Clave" />
                                )} />
                                {getFormErrorMessage('clave')}
                            </>
                            <br />
                            <>
                                <Messages ref={message} />
                                <Button label="CONTINUAR" type="submit"></Button>
                            </>

                        </form>
                    </div>

                    <p>Recordar contraseña <br /><Resetear toast={toast} /></p>
                </div>
                <div className="login-image">
                    <div className="login-image-content">

                    </div>
                    <div className="image-footer">
                        <p>© {(new Date()).getFullYear()} Prelafit - Todos los derechos reservados.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
