import { FilterMatchMode } from "primereact/api";

export class RolPorEmpresa {

    model = {
        id: { value: null, export: false },
        nombre: {
            header: 'Nombre', value: '', required: false, filterPlaceholder: 'Buscar por nombre de rol.', maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 4 caracteres', min: 4, max: 200, export: true, table: true, filter: true, matchMode: FilterMatchMode.CONTAINS
        },
        descripcion: {
            header: 'Descripción', value: '', table: true, required: false, maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 2 caracteres',
            min: 2, max: 300, export: false
        },
    }
}