export class DeclaracionCpj {
    model = {
        observacion: {
            value: '',
            export: true,
            header: 'Observacion:',
            //            required: 'Observacion es requerido',
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4
        }
    }
    strDeclara = `Declaro bajo juramento que los valores entregados son lícitos y  no provienen de actividades de lavado de activos, financiamiento del terrorismo y otros delitos, son generados de actividades económicas legales y que la información registrada en el presente documento y la documentación que se acompaña es fidedigna y asumo cualquier responsabilidad por los daños que pudiere sufrir la Compañía a la que declaro esta información por la eventual falsedad o imprecisión de esta. Así como, autorizo expresamente a  realizar el análisis y las verificaciones que se consideren pertinentes a la información suministrada y aquella que obtenga la Compañía a la que declaro y en el caso de detectarse alguna inusualidad, informar de manera inmediata y documentada a las autoridades competentes.`;
    strAnexo1 = <><ul><li>Copia del RUC de la compañía</li><li>Copia del nombramiento del Representante Legal debidamente inscrito</li>
        <li>Poder original de la persona que comparecerá en representación de la compañía, en lugar del Representante Legal</li>
        <li>Copia de cédula de los representantes legales o apoderados (copia de pasaporte en caso de ser extranjeros no residentes)</li>
        <li>Declaración de Impuesto a la Renta último año (Formulario 101)</li><li>Declaraciones de I.V.A. de los tres últimos meses</li>
        <li>Certificado bancario de cualquiera de los últimos tres meses.</li></ul></>;
    strAnexo2 = <><ul><li>Copia del documento de idenTificación tributaria de la compañía</li>
        <li>Copia del nombramiento del Representante Legal debidamente inscrito</li>
        <li>Poder original de la persona que comparecerá en representación de la compañía, en lugar del Representante Legal, debidamente apostillado, en idioma español y protocolizado</li>
        <li>Copia de cédula del representante legal o apoderado (copia del pasaporte en caso de ser extranjero no residente)</li>
        <li>Declaración de Impuesto a la Renta del último año y declaraciones de I.V.A de los tres últimos meses.</li>
        <li>Certificado bancario de cualquiera de los últimos tres meses.</li></ul></>;
}
