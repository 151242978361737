import Axios from "axios";

export class ReporteService {

    consultar(url) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/${url}`).then(res => res.data);
    }

    buscar(params, url) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/${url}?${queryParams}`,
            { headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache' }, responseType: "blob"}).then(res => res.data);
    }
}
