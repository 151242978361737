import { Panel } from 'primereact/panel';
import { EfectivoSaldo } from './EfectivoSaldo';
import { Bienes } from '../../../models/fun/Bienes';
import { Inversiones } from './Inversiones';
import { CuentasCobrar } from './CuentasCobrar';

export const ActivosCuentas = ({ id, toast, nextPrev, next, permss, catalogos }) => {
    const omodel = new Bienes();

    return (
        <>
            <Panel header="DETALLE DE ACTIVOS: (Incluir Sociedad Conyugal o Unión de Hecho y considerar las referencias citadas al final de la sección)." className="panel-ficha">
                <EfectivoSaldo id={id} toast={toast} permss={permss} nextPrev={nextPrev} next={next} catalogos={catalogos} opcion="funcionario-efectivo" />
                {omodel.nota}
                <br />

                <Inversiones id={id} toast={toast} permss={permss} nextPrev={nextPrev} next={next} catalogos={catalogos} opcion="funcionario-inversion" />
                <br />

                <CuentasCobrar id={id} toast={toast} permss={permss} nextPrev={nextPrev} next={next} catalogos={catalogos} opcion="funcionario-cuenta-cobrar" />
            </Panel>
            <br />

            {nextPrev(false)}
        </>
    )
}
