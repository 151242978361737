export class Adjunto {
    model = {
        id: { value: null, export: false },
        adjunto: { value: null, export: false },
        tipo: { value: null, export: false },
        lista: { value: null, export: false, header: 'Lista de anexos', required: 'Seleccione un anexo' },
        descripcion: {
            value: '', export: true, header: 'Observaciones', required: false, maxLength: 'Máximo 500 caracteres',
            filterPlaceholder: 'descripcion', minLength: 'Mínimo 3 caracteres', max: 500, min: 3, filterField: 'descripcion'
        },
        lugar_verificacion: {
            value: '', export: true, header: 'Lugar', required: 'Campo requerido', maxLength: 'Máximo 100 caracteres',
            filterPlaceholder: 'descripcion', minLength: 'Mínimo 3 caracteres', max: 100, min: 3, filterField: 'descripcion'
        },
        fecha_verificacion: {
            value: new Date(),
            export: false,
            header: 'Fecha de Verificación:',
            required: 'Fecha es requerido'
        },
        observacion: {
            value: '', export: true, header: 'Observaciones', required: 'Observación es requerido', maxLength: 'Máximo 500 caracteres',
            filterPlaceholder: 'descripcion', minLength: 'Mínimo 3 caracteres', max: 500, min: 3, filterField: 'descripcion'
        },
    }

    optionLabel = 'tipo_adjunto'
}
