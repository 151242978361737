import { FilterMatchMode } from "primereact/api";

export class Origen {

    model = {
        id: {value: null, export: true, header: 'Código', table: true, filter: true, filterPlaceholder: 'Buscar por código', filterField: 'id', sort: true, 
                matchMode: FilterMatchMode.EQUALS, showFilterMatchModes: false, headerStyle: { width: '10%', minWidth: '10rem' }},
        fecha: {value: new Date(), export: true, header: 'Fecha (día/mes/año):', required: 'Fecha es requerido', table: true, sort: true, headerStyle: { width: '10%', minWidth: '10rem' }},
        ciudad: {value: null, export: false, header: 'Ciudad:', required: 'Ciudad es requerida'},
        pais: {value: null, export: false, header: 'País:', required: 'País es requerido'},
        provincia: {value: null, export: false, header: 'Provincia:', required: 'Provincia es requerido'},
        canton: {value: '', export: false, header: 'Ciudad', maxLength: 'Máximo 100 caracteres', minLength: 'Mínimo 4 caracteres', max: 100, min: 4},
        tipo_persona: {value: null, export: false, header: 'Tipo de persona:', required: 'Tipo de persona es requerido'},
        identificacion: {value: '', export: true, header: 'Identificación', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres', table: true, filter: true, sort: true,
                minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'identificacion', filterPlaceholder: 'Buscar por identificación', fielterField: 'identificacion',
                matchMode: FilterMatchMode.CONTAINS, headerStyle: { width: '15%', minWidth: '15rem' }},
        nombre_completo: {value: null, export: true, header: 'Nombres / Razón social', table: true, sort: true, headerStyle: { width: '20%', minWidth: '20rem' }},
        compania: {value: '', export: true, header: 'Nombre de la compañía donde adquirió el bien', required: 'Compañía es requerido', 
                    maxLength: 'Máximo 500 caracteres', minLength: 'Mínimo 4 caracteres', max: 500, min: 4, table: true},
        proyecto: {value: '', export: true, header: 'Proyecto', required: 'Proyecto es requerido', table: true,
                    maxLength: 'Máximo 500 caracteres', minLength: 'Mínimo 3 caracteres', max: 500, min: 3},
        descripcion: {value: '', export: false, header: 'Describa la transacción u operación que realiza con la compañía', required: 'Descripción es requerido', 
                    maxLength: 'Máximo 1000 caracteres', minLength: 'Mínimo 4 caracteres', max: 1000, min: 4},
        valor_total: {value: 0, export: false, header: 'Valor total del bien/servicio:', required: 'Valor total del bien/servicio es requerido'},
        moneda: {value: null, export: false, header: 'Moneda:', required: 'Moneda es requerido'},
        pais_pago: {value: null, export: false, header: 'País de donde proviene el pago', required: 'País de donde proviene el pago es requerido'},
        provincia_pago: {value: null, export: false, header: 'Provincia de donde proviene el pago', required: 'Provincia de donde proviene el pago es requerido'},
        ciudad_pago: {value: null, export: false, header: 'Ciudad de donde proviene el pago', required: 'Ciudad de donde proviene el pago es requerido'},
        canton_pago: {value: '', export: false, header: 'Ciudad donde proviene el pago', maxLength: 'Máximo 100 caracteres', minLength: 'Mínimo 4 caracteres', max: 100, min: 4},
        fecha_transaccion: {value: new Date(), export: false, header: 'Fecha de la transacción u operación (día/mes/año):', required: 'Fecha de la transacción u operación es requerido'},
        detalle_actividad: {value: '', export: false, header: 'Los valores entregados provienen de (detalle actividad económica o fuente):', maxLength: 'Máximo 1000 caracteres', 
            minLength: 'Mínimo 4 caracteres', max: 100, min: 4, required: 'Detalle de valores entregados es requerido.'},
        entrega: {value: false, export: false, header: 'Entrega de fondos'},
        recepcion: {value: false, export: false, header: 'Recepción de fondos'},
        estado: {value: null, export: true, header: 'Estado:', required: 'Estado es requerido', table: true, headerStyle: { width: '10%', minWidth: '10rem' }},
        tipo_declarante: {value: null, export: false, header: 'Tipo de declarante:', required: 'Tipo de declarante es requerido'},
        tipo_entrega: {value: null, export: false, header: 'Los valores entregados son:', required: 'Valor entregado es requerido'},
        nombres: {value: '', export: false, header: 'Nombres', required: 'Nombres es requerido', 
            maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, table: false},
        nombres_rep: {value: '', export: false, header: 'Nombres del Representante Legal / Apoderado: ', required: 'Nombres es requerido', 
            maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, table: false},
        apellidos: {value: '', export: false, header: 'Apellidos', required: 'Apellidos es requerido', 
            maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, table: false},
        apellidos_rep: {value: '', export: false, header: 'Apellidos del Representante Legal / Apoderado: ', required: 'Apellidos es requerido', 
            maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, table: false},
        razon_social: {value: '', export: false, header: 'Razón social', required: 'Razón social es requerido', 
            maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, table: false},
        razon_social_rep: {value: '', export: false, header: 'Razón Social del Representante Legal / Apoderado: ', required: 'Razón social es requerido', 
            maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres', max: 300, min: 4, table: false},
        tipo_identificacion: {value: null, export: false, header: 'Tipo de Identificación', required: 'Tipo de identificación es requerido'},
        tipo_identificacion_rep: {value: null, export: false, header: 'Tipo de identificacion del Representante Legal / Apoderado:', required: 'Tipo de identificación es requerido'},
        identificacion_rep: {value: '', export: false, header: 'Identificación del Representante Legal / Apoderado:', required: 'Identificación es requerido', maxLength: 'Máximo 50 caracteres', table: false, filter: true, sort: true,
                minLength: 'Mínimo 4 caracteres', max: 50, min: 4, filterField: 'identificacion', filterPlaceholder: 'Buscar por identificación', fielterField: 'identificacion'},
    }
    optionLabel = 'nombre';
    strEntrega = `Declaro el origen lícito de mis recursos por cuanto los valores entregados son generados de mis actividades económicas legales y no provienen del lavado de activos, financiamiento del terrorismo y otros delitos.`;
    strRecepcion = `Declaro el origen lícito de los recursos por cuanto los valores recibidos y que constan registrados en este documento, serán utilizados en actividades económicas legales y permitidas por las leyes de la República del Ecuador.`;
}