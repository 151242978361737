export class HijoBvg {

    model = {
        id: { value: null, export: false },
        nombre_completo: {
            value: '',
            table: true,
            header: 'Apellidos y Nombres Completos:',
            required: 'Nombres es requerido',
            maxLength: 'Máximo 500 caracteres',
            filterPlaceholder: 'nombres',
            minLength: 'Mínimo 3 caracteres',
            max: 500,
            min: 3,
        },
        edad: {
            value: 0,
            table: true,
            required: 'Edad es requerido',
            header: 'Edad',
        },
        ocupacion: {
            value: '',
            table: true,
            header: 'Ocupación:',
            required: 'Ocupación es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        lugar_trabajo: {
            value: '',
            table: true,
            header: 'Lugar de Trabajo/Estudios:',
            required: 'Campo requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3,
        },
        identificacion: {
            value: '',
            table: true,
            header: 'No. De identificación:',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
        },
        telefono: {
            value: '',
            table: true,
            header: 'Teléfono/Celular:',
            required: 'Apellidos es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
    }

    optionLabel = 'nombre';
}
