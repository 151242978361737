import Axios from "axios";

export class TerceroService {

    getLista(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/tercero/lista?${queryParams}`).then(res => res.data);
    }

    newTercero(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/tercero/nuevo`, data).then(res => res.data);
    }

    editarTercero(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/tercero/editar`, data).then(res => res.data);
    }

    eliminar(id, idp, tipo) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/tercero/eliminar/${id}/${tipo}/${idp}`).then(res => res.data);
    }

    getTercero(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/tercero-ver/${id}/${iniciar}`).then(res => res.data);
    }
}