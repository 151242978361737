import Axios from "axios";

export class EmpresaService {

    getEmpresa(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/empresa/lista?${queryParams}`).then(res => res.data);
    }

    newEmpresa(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/administracion/empresa/nuevo`, data).then(res => res.data);
    }

    editarEmpresa(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/administracion/empresa/editar`, data).then(res => res.data);
    }

    consultarEmpresa(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/empresa/${id}/${iniciar}`).then(res => res.data);
    }

    eliminarEmpresa(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/administracion/empresa/eliminar/${id}`).then(res => res.data);
    }

    consultarEmpresaNombre(nombre) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/administracion/empresa-buscar/razon/${nombre}`).then(res => res.data);
    }
}
