import { FilterMatchMode } from "primereact/api";

export class InfoCliPro {

    model = {
        id: {
            value: null,
            export: false,
            header: 'Código',
            table: true,
            filter: true,
            filterPlaceholder: 'Buscar por código',
            filterField: 'id',
            sort: true,
            matchMode: FilterMatchMode.EQUALS,
            showFilterMatchModes: false,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        fecha: {
            value: new Date(),
            export: false,
            header: 'Fecha (día/mes/año):',
            required: 'Fecha es requerido',
            table: true,
            sort: true,
            headerStyle: { width: '10%', minWidth: '10rem' }
        },
        pais: {
            value: null,
            export: false,
            header: 'País:',
            required: 'País es requerido'
        },
        provincia: {
            value: null,
            export: false,
            header: 'Provincia:',
            required: 'Provincia es requerido'
        },
        ciudad: {
            value: null,
            export: false,
            header: 'Ciudad:',
            required: 'Ciudad es requerida'
        },
        canton: {
            value: '',
            export: false,
            header: 'Ciudad:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Ciudad es requerida'
        },
        tipo_formulario: {
            value: null,
            export: false,
            header: 'Tipo de proceso:',
            required: 'Tipo de proceso es requerido'
        },
        tipo_solicitante: {
            value: null,
            export: false,
            header: 'Relación/vinculación con BVG:',
            required: 'Relación/vinculación es requerido'
        },
        tipo_identificacion: {
            value: null,
            export: false,
            header: 'Tipo de documento:',
            required: 'Seleccione una opción'
        },
        apellidos: {
            value: '',
            export: true,
            header: 'Apellidos completos:',
            required: 'Apellido es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        nombres: {
            value: '',
            export: true,
            header: 'Nombres completos:',
            required: 'Nombre es requerido',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        nombre_completo: {
            value: '',
            export: true,
            header: 'Nombre Compleo',
            table: true,
            sort: true,
            headerStyle: { width: '20%', minWidth: '20rem' }
        },
        identificacion: {
            value: '',
            export: false,
            header: 'No. doc. Identificación:',
            required: 'No. RUC / Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3
        },
        nacionalidad: {
            value: null,
            export: false,
            header: 'Nacionalidad:',
            required: 'Nacionalidad es requerido.'
        },
        pais_dd: {
            value: null,
            export: false,
            header: 'País de Residencia:',
            required: 'País es requerido'
        },
        fecha_nacimiento: {
            value: new Date(),
            export: false,
            header: 'Fecha de nacimiento (día/mes/año):',
            required: 'Fecha de inicio de actividad es requerido'
        },
        lugar_nacimiento: {
            value: '',
            export: true,
            header: 'Lugar de nacimiento',
            required: 'Dirección es requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3
        },
        genero: {
            value: null,
            export: false,
            header: 'Sexo:',
            required: 'Requerido seleccionar'
        },
        estado_civil: {
            value: null,
            export: false,
            header: 'Estado civil:',
            required: 'Estado civil es requerido'
        },
        direccion: {
            value: '',
            export: true,
            header: 'Dirección Domiciliaria o de Residencia: (Urbanización/barrio, calle, manzana, bloque, solar, villa)',
            required: 'Dirección es requerido',
            maxLength: 'Máximo 500 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 500,
            min: 3
        },
        telefono_celular: {
            value: '',
            export: true,
            header: 'Teléfono/celular',
            required: 'No. es requerido',
            maxLength: 'Máximo 30 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 30,
            min: 3,
            filterField: 'telefono_celular'
        },
        detalle_sl: {
            value: '',
            export: true,
            header: 'Profesión u Ocupación:',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
            required: 'Profesión u Ocupación es requerido',
        },
        email: {
            value: '',
            export: false,
            header: 'Correo electrónico:',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
            required: 'Correo electrónico es requerido',
        },
        //Cónyugue
        apellidos_cy: {
            value: '',
            export: true,
            header: 'Apellidos completos:',
            required: 'Apellido es requerido',
            maxLength: 'Máximo 500 caracteres'
        },
        nombres_ec: {
            value: '',
            export: true,
            header: 'Nombres completos:',
            required: 'Nombre es requerido',
            maxLength: 'Máximo 500 caracteres'
        },
        tipo_identificacion_cy: {
            value: null,
            export: false,
            header: 'Tipo de documento:',
            required: 'Tipo de identificación es requerido'
        },

        identificacion_cy: {
            value: '',
            export: false,
            header: 'No. doc. Identidad:',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
            filterField: 'identificacion',
            filterPlaceholder: 'Buscar por número de identificación',
            table: false,
            filter: false,
            sort: true
        },
        nacionalidad_cy: {
            value: null,
            export: false,
            header: 'Nacionalidad:',
            required: 'Nacionalidad es requerido'
        },
        pais_tr: {
            value: null,
            export: false,
            header: 'País de Residencia:',
            required: 'País es requerido'
        },
        genero_cy: {
            value: null,
            export: false,
            header: 'Sexo:',
            required: 'Requerido seleccionar'
        },
        lugar_nacimiento_cy: {
            value: '',
            export: true,
            header: 'Lugar de nacimiento',
            required: 'Dirección es requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 200,
            min: 3
        },
        fecha_nacimiento_cy: {
            value: new Date(),
            export: false,
            header: 'Fecha de nacimiento (día/mes/año):',
            required: 'Fecha de nacimiento es requerido'
        },
        lugar_trabajo_cy: {
            value: '',
            export: false,
            header: 'Lugar donde trabaja:',
            required: 'Lugar de trabajo es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        cargo_cy: {
            value: '',
            export: false,
            header: 'Cargo que desempeña:',
            required: 'cargo es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        email_trabajo_cy: {
            value: '',
            export: false,
            header: 'Correo electrónico',
            required: 'Correo electrónico es requerido'
        },
        telefono_trabajo_cy: {
            value: '',
            export: true,
            header: 'Teléfono/Celular:',
            required: 'Teléfono es requerido',
            maxLength: 'Máximo 30 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 30,
            min: 3,
        },
        tipos_solicitante: {
            value: [],
            export: false,
            header: 'Relación/vinculación con BVG:',
            required: 'Relación/vinculación es requerido'
        },
    }

    optionLabel = 'nombre';
}
