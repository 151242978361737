import Axios from "axios";

export class DetallesService {

    getDetalle(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/fuente/detalle/lista?${queryParams}`).then(res => res.data);
    }

    newDetalle(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/fuente/detalle/nuevo`, data).then(res => res.data);
    }

    editarDetalle(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/fuente/detalle/editar`, data).then(res => res.data);
    }

    consultarDetalle(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/fuente/detalle-ver/${id}`).then(res => res.data);
    }

    eliminarDetalle(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/fuente/detalle/eliminar/${id}`).then(res => res.data);
    }
}