import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { FuentePersona } from "../../models/fuente/FuentePersona";
import { FuentePersonaService } from "../../service/fuente/FuentePersonaService";
import { InputComponent } from "../../components/InputComponent";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { useForm } from "react-hook-form";
import { confirmDialog } from "primereact/confirmdialog";
import XLSX from "xlsx-color";
import { Toolbar } from "primereact/toolbar";
import { FileUpload } from "primereact/fileupload";
import { Origenes } from "./Origenes";

export const PersonaFuente = ({ origenId, detalleId }) => {
    const generic = new GenericComponent();
    const omodel = new FuentePersona();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registros, setRegistros] = useState(null);
    const toast = useRef(null);
    const fuentePerService = new FuentePersonaService();
    const [dialogFuentePersona, setDialogFuentePersona] = useState(false);
    const [dialogSubir, setDialogSubir] = useState(false);
    const [dialogOrigen, setDialogOrigen] = useState(false);
    const [persona, setPersona] = useState(null);
    const [archivo, setArchivo] = useState(null);
    const [siPopup, setSiPopup] = useState(false);
    const [load, setLoad] = useState(false);
    const [estado, setEstado] = useState(null);
    let fileRef = useRef(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText } = InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        setSiPopup(origenId !== undefined || detalleId !== undefined);
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            if (origenId != undefined) {
                _lazyParams.origenId = origenId;
            }
            if (detalleId != undefined) {
                _lazyParams.detalleId = detalleId;
            }
            fuentePerService.getPersona({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        setDialogFuentePersona(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            fuentePerService.newPersona(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogFuentePersona(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            fuentePerService.editarPersona(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogFuentePersona(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editPersona = (persona) => {
        fuentePerService.consultarPersona(persona.id).then(res => {
            let _persona = res.data;
            let lista = { estado: generic.estadosNum };

            Object.keys(lista).forEach(function (key) {
                if (_persona[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _persona[key]) {
                            _persona[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_persona, omodel.model));
            setDialogFuentePersona(true);
        });
    };

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarPersona(data)
        });
    }

    const eliminarPersona = (data) => {
        fuentePerService.eliminarPersona(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" type="button" onClick={openNew} />
                {permss?.crear && <Button label="Subir archivo" icon="pi pi-upload" className="p-button-success mr-2" type="button" onClick={subir} />}
                {permss?.crear && <a href={`${process.env.PUBLIC_URL}/Plantilla-detalle-fuente.xlsx`} download="Plantilla-detalle-fuente" target="_blank" rel="noreferrer">
                    <Button label="Descargar plantilla" icon="pi pi-download" className="p-button-success mr-2" type="button" /></a>}
            </div>
        );
    }

    const submitFile = (event) => {
        event.preventDefault();
        if (archivo == null) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el archivo.', life: 3000 });
            return;
        }
        if (archivo.size > 1000000 && !archivo.type.includes('csv')) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Archivos de más de 1MB debe ser formato CSV.', life: 3000 });
            return;
        }
        if (archivo.size < 1000000 && archivo.type.includes('spreadsheetml')) {
            procesar(event);
        } else {
            const formData = new FormData();
            formData.append('file', archivo);
            setLoad(true);
            fuentePerService.subir(formData).then(res => {
                setLoad(false);
                setArchivo(null);
                setEstado(res.data);
                generic.funcSuccess(res, toast);
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
    }

    const procesar = (event) => {
        const reader = new FileReader();
        setLoad(true);
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

            fuentePerService.subirArchivo({ info: data }).then(res => {
                generic.funcSuccess(res, toast);
                setDialogSubir(false);
                setArchivo(null);
                loadLazyData();
                setLoad(false);
            }).catch(error => {
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        };
        reader.readAsBinaryString(archivo);
    }

    const invoiceUploadHandler = ({ files }) => {
        const [file] = files;
        setArchivo(file);
    }

    const selectFile = (e) => {
        setArchivo(e.files[0]);
        fileRef?.clear();
    }

    const subir = () => {
        fuentePerService.consultarEstado().then(res => {
            setDialogSubir(true);
            setArchivo(null);
            setEstado(res.data);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const mostrarOrigen = (data) => {
        setDialogOrigen(true);
        setPersona(data);
    }

    const acciones = (rowData) => {
        return (
            <div className="actions">
                {permss?.actualizar && (!rowData?.nemonico || rowData.nemonico != 'FINALIZADO') &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editPersona(rowData)} title="Editar" />}
                <Button icon="pi pi-images" title='Fuentes relacionadas' className="p-button-rounded p-button-success mr-2" onClick={() => mostrarOrigen(rowData)} />
                {permss?.eliminar && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDelete(rowData)} title="Eliminar" />}
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                {!siPopup &&
                    <Toolbar className="mb-4" start={leftToolbarTemplate}
                        end={generic.rightToolbarTemplate(registros, omodel, 'Listado_fuentes_persona', 'LISTADO FUENTE DE PERSONAS')}></Toolbar>
                }
                {table({ action5: acciones })}
            </Card>

            <Dialog visible={dialogFuentePersona} header={getValues('id') == null ? "Registrar persona" : "Editar persona"} modal className="p-fluid modal-ssize" onHide={e => setDialogFuentePersona(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombres', {}, 6)}
                        {inText('identificacion', {}, 6)}
                        {inDrop('estado', generic.estadosNum, { lab: omodel.optionLabel }, 6)}

                    </div>

                    {generic.buttonsForms(setDialogFuentePersona)}
                </form>
            </Dialog>

            <Dialog visible={dialogSubir} header="Subir detalles" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)}>
                <span style={{ fontSize: '10px' }} >
                    Cumplir en forma general y por columnas:
                    <ul>
                        <li>El archivo plantilla descargar del botón <i>Descargar plantilla.</i></li>
                        <li>No hay campos opcionales.</li>
                        <li><b>Nombres</b>: Entre 4 y 500 caracteres.</li>
                        <li><b>Identificación</b>: Entre 5 y 50 caracteres. Debe ser único y que no haya sido previamente registrado. <br />
                            Si ya existe, se actualizará el resto de la información de ese registro.</li>
                        <li><b>Identificación de la fuente origen</b>: Debe haberse registrado previamente en el menú "Fuentes de Origen", columna identificación</li>
                        <li><b>Resultado</b>: Entre 10 y 2000 caracteres. Si el mismo resultado se debe registrar a varias personas, colocar .. en la celda correspondiente.</li>
                        <li><b>Link</b>: Opcional, entre 10 y 2000 caracteres.</li>
                    </ul>
                    <b>Archivos permitidos Excel y CSV:</b>
                    <ul>
                        <li>Si la información pesa menos de <b>1MB</b>, el formato admitido es Microsoft Excel (<b>XLSX</b>)</li>
                        <li>Si pesa más de <b>1MB</b>, subir en formato <b>CSV:</b>
                            <ul>
                                <li>Separado por tabuladores.</li>
                                <li>Sin comillas para la delimitación de campos</li>
                                <li>Peso máximo 100MB</li>
                            </ul>
                        </li>
                    </ul>
                    <Card title="Estado última carga (CSV):" style={{ fontSize: '10px' }}>
                        <div className="formgrid grid">
                            <div className="field col-3">
                                <b>Estado:</b><br />
                                {estado?.estado}
                            </div>

                            <div className="field col-3">
                                <b>Fecha inicio proceso:</b><br />
                                {estado?.fecha_inicio}<br />
                                <b>Fecha fin proceso:</b><br />
                                {estado?.fecha_fin}
                            </div>

                            <div className="field col-6">
                                <b>Mensaje de error:</b><br />
                                {estado?.msg_error}
                            </div>
                        </div>
                    </Card>
                </span>
                <form onSubmit={submitFile}>
                    <div className="field col">
                        <label>Seleccione un archivo*</label><br />
                        <FileUpload name="file" accept=".xlsx,.csv" maxFileSize={100000000} uploadHandler={invoiceUploadHandler} mode="basic"
                            customUpload chooseLabel="Seleccione" onSelect={selectFile} ref={ref => { fileRef = ref }} disabled={!['Error','Disponible'].includes(estado?.estado) } />
                    </div>
                    {archivo?.name}
                    {generic.buttonsForms(setDialogSubir, load)}
                </form>
            </Dialog>

            <Dialog visible={dialogOrigen} header={"Fuentes vinculadas: " + persona?.nombres} modal className="p-fluid modal-ssize"
                onHide={e => { setDialogOrigen(false); setPersona(null); }} style={{ maxWidth: '900px' }}>
                <Origenes personaId={persona?.id} />
            </Dialog>
        </>
    )
}
