export class Declarante {

    model = {
        id: { value: null, export: false },
        oid: { value: null, export: false },
        idp: { value: null, export: false },
        tipo_declarante: { value: null, export: false, header: 'Tipo de Declarante', required: 'Tipo de declarante es obligatorio', table: true, filter: false, sort: true },
        tipo_identificacion: {
            value: null,
            export: false,
            filterPlaceholder: 'Buscar por tipo de identificación',
            required: 'Tipo de identificación es requerido',
            header: 'Tipo de identificación',
            table: true,
            filter: false,
            sort: true
        },
        identificacion: {
            value: '',
            export: false,
            header: 'Identificación',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'identificacion',
            filterPlaceholder: 'Buscar por identificación'
        },
        nombres: {
            value: '',
            export: true,
            header: 'Nombres',
            required: 'Nombres es requerido',
            maxLength: 'Máximo 300 caracteres',
            filterPlaceholder: 'nombres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
            filterField: 'nombres'
        },
        descripcion: {
            value: '',
            export: true,
            header: 'Describa la transacción u operación que realiza con la compañía:',
            required: 'Descripción es requerido',
            maxLength: 'Máximo 1000 caracteres',
            filterPlaceholder: 'descripcion',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4,
            filterField: 'descripcion'
        },
        apellidos: {
            value: '',
            export: true,
            header: 'Apellidos',
            required: 'Apellidos es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
            filterField: 'apellidos'
        },
        razon_social: {
            value: '',
            export: true,
            header: 'Razón Social',
            required: 'Razón Social es requerido',
            maxLength: 'Máximo 400 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 400,
            min: 4,
            filterField: 'razon_social'
        },
        nacionalidad: { value: null, export: false, header: 'Nacionalidad', required: 'Nacionalidad es requerido' },
        genero: { value: null, export: false, header: 'Género', required: 'Género es requerido' },
        fecha_nacimiento: {value: new Date(), export: false, header: 'Fecha de nacimiento/fecha de constitución:', required: 'Fecha es requerido', table: false},
        direccion_domicilio: {
            value: '',
            export: true,
            header: 'Dirección',
            required: 'Dirección domicilio es requerido',
            maxLength: 'Máximo 1000 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 1000,
            min: 4,
            filterField: 'direccion_domicilio'
        },
        pais_id: {
            value: null,
            export: false,
            header: 'País',
            required: 'País es requerido'
        },
        provincia_id: {
            value: null,
            export: false,
            header: 'Provincia',
            required: 'Provincia es requerido'
        },
        ciudad_id: {
            value: null,
            export: false,
            header: 'Ciudad',
            required: 'Ciudad es requerido'
        },
        ciudad: {
            value: '',
            export: true,
            header: 'Ciudad',
            required: 'Ciudad es requerido',
            maxLength: 'Máximo 400 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 400,
            min: 4,
            filterField: 'ciudad'
        },
        telefono_celular: {
            value: '',
            export: true,
            header: 'Teléfono Celular',
            required: 'Teléfono celular es es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'telefono_celular'
        },
        telefono_fijo: {
            value: '',
            export: true,
            header: 'Teléfono Fijo',
            required: 'Teléfono fijo es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'telefono_celular'
        },
        telefono_oficina: {
            value: '',
            export: true,
            header: 'Teléfono oficina',
            required: 'Teléfono oficina es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4
        },
        telefono_domicilio: {
            value: '',
            export: true,
            header: 'Teléfono domicilio',
            required: 'Teléfono domicilio es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4
        },
        estado_civil: {
            value: null,
            export: false,
            header: 'Estado Civil',
            required: 'Estado Civil es requerido'
        },
        apellidos_conyugue: {
            value: '',
            export: true,
            header: 'Apellido Cónyugue',
            required: 'Apellidos del cónyugue es requerido',
            maxLength: 'Máximo 400 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 400,
            min: 4,
            filterField: 'apellidos_conyugue'
        },
        nombres_conyugue: {
            value: '',
            export: true,
            header: 'Nombres Cónyugue',
            required: 'Nombres del cónyugue es requerido',
            maxLength: 'Máximo 400 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 400,
            min: 4,
            filterField: 'nombres_conyugue'
        },
        identificacion_conyugue: {
            value: '',
            export: false,
            header: 'Identificación Cónyugue',
            required: 'Identificación del cónyugue es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'identificacion_conyugue',
            filterPlaceholder: 'Buscar por identificación'
        },
        nombre_completo: { value: '', export: false, header: 'Razón social/Nombres y Apellidos', table: true, filter: false, sort: true },
    }

    optionLabel = 'nombre';
}