import { Checkbox } from 'primereact/checkbox';
import { useEffect, useState } from 'react';
import { MenuServicio } from '../../service/MenuServicio';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

export const MenuPermiso = ({ id, toast, generic, permss }) => {

    const [sasignar, setSasignar] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [permisos, setPermisos] = useState(null);
    const [lazyParams, setLazyParams] = useState(generic.paramLazy({ controlador: 'contains', accion: 'contains' }, null));
    const menuService = new MenuServicio();
    let loadLazyTimeout = null;

    const onPage = (event) => {
        setLazyParams(event);
    }

    const onSort = (event) => {
        setLazyParams(event);
    }

    const onFilter = (event) => {
        setLazyParams(event);
    }

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.id = id;
            menuService.getPermisos({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setPermisos(res.data.registros);
                setLoading(false);
                valores(res.data.registros);
            });
        });
    }

    const valores = (items) => {
        let _asignados = [...sasignar];
        items.forEach(itm => {
            if (!itm.sinasignar) _asignados.push(itm.id);
        });
        setSasignar(_asignados);
    }

    const actionBodyTemplateAsignar = (rowData) => {
        return (
            <div className="actions">
                {(permss?.actualizar || permss?.crear) &&
                    <Checkbox value={rowData.id} checked={sasignar.indexOf(rowData.id) !== -1} onChange={e => asignar(e)} />
                }
            </div>
        );
    }

    const asignar = (e) => {
        let _seleccionados = [...sasignar];
        if (e.checked) _seleccionados.push(e.value);
        else _seleccionados.splice(_seleccionados.indexOf(e.value), 1);

        setSasignar(_seleccionados);
        setLoading(true);
        menuService.putPermiso({ id: e.value, menu: id, agregar: e.checked }).then(res => {
            setLoading(false);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: res.data.data, life: 3000 });
        }).catch(error => {
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 3000 });
        });
    }

    return (
        <>
            <DataTable value={permisos} lazy className="datatable-responsive"
                filterDisplay="row" responsiveLayout="scroll" dataKey="id"
                paginator first={lazyParams.first} rows={parseInt(process.env.REACT_APP_ROWS_NUM)} totalRecords={totalRecords} onPage={onPage}
                onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                onFilter={onFilter} filters={lazyParams.filters} loading={loading}>
                <Column field="controlador" header="Controlador" style={{ width: '20%' }} showFilterMenu={false} filter filterPlaceholder="Buscar"></Column>
                <Column field="accion" header="Acción" style={{ width: '20%' }} showFilterMenu={false} filter filterPlaceholder="Buscar"></Column>
                <Column field="crud" header="CRUD" style={{ width: '20%' }}></Column>
                <Column field="fecha" header="Fecha Asignación" style={{ width: '20%' }}></Column>
                <Column field="sinasignar" body={actionBodyTemplateAsignar} header="Asignar" style={{ width: '10%' }}></Column>
            </DataTable>
        </>
    )
}
