import Axios from "axios";

export class ConsumidorFrmService {

    getForm(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/form/frm/lista?${queryParams}`).then(res => res.data);
    }

    newForm(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/form/frm/nuevo`, data).then(res => res.data);
    }

    editarForm(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/form/frm/editar`, data).then(res => res.data);
    }

    consultarForm(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/form/frm/${id}/${iniciar}`).then(res => res.data);
    }

    eliminarForm(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/form/frm/eliminar/${id}`).then(res => res.data);
    }

    notificar(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/form/frm/notificar`, data).then(res => res.data);
    }

    getEnvios(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/form/frm/lista-envios?${queryParams}`).then(res => res.data);
    }
}
