import Axios from "axios";

export default class AdjuntoService {

    newAdjunto(data, urls) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/${urls}`, data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res.data);
    }

    listar(id, tipo, urls, inicio) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/${urls}/${id}/${tipo}/${inicio}`).then(res => res.data);
    }

    descargar(id, urls, idp) {
        const headers = { 'Content-Type': 'application/json', 'Pragma': 'no-cache' };
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/${urls}/${id}/${idp}`, { headers: headers, responseType: 'blob' }).then(async res => res.data);
    }

    deleteAdjunto(id, idp, url) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/${url}/${id}/${idp}`).then(res => res.data);
    }

    enviar(data, url) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/${url}`, data).then(res => res.data);
    }

    aprobar(data, url) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/${url}`, data).then(res => res.data);
    }

    listado(params, urls) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/${urls}/listado?${queryParams}`).then(res => res.data);
    }

    verificar(data, urls) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/${urls}/verificar-anexo`, data).then(res => res);
    }

    actualizar(data, urls) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/${urls}`, data).then(res => res);
    }
}
