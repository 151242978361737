import { ClientePn } from "../../models/ClientePn";
import { GenericComponent } from "../../components/GenericComponent";
import { useEffect, useRef, useState } from "react";
import { ClientePNService } from "../../service/ClientePNService";
import { InputComponent } from "../../components/InputComponent";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { addLocale } from "primereact/api";
import { Panel } from "primereact/panel";
import { DatosPn } from "./cpn/DatosPn";
import { TabPanel, TabView } from "primereact/tabview";
import { ActividadPn } from "./cpn/ActividadPn";
import { ConyuguePn } from "./cpn/ConyuguePn";
import { PepPn } from "./cpn/PepPn";
import { PartebPn } from "./cpn/PartebPn";
import { AmpliadaPn } from "./cpn/AmpliadaPn";
import { DeclaracionPn } from "./cpn/DeclaracionPn";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useToken } from "../../components/useToken";
import { FileComponent } from "../../components/FileComponent";
import { FiltroMultiempresa } from "../../components/FiltroMultiempresa";

export const ClientePerNa = () => {

    const generic = new GenericComponent();
    const omodel = new ClientePn();
    let loadLazyTimeout = null;
    const [clientespn, setClientespn] = useState([]);
    const clientepnSrv = new ClientePNService();
    const toast = useRef(null);
    const [dialogClientepn, setDialogClientepn] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [cliente, setCliente] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [permss, setPermss] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams } =
        InputComponent({ registers: clientespn, omodel, generic, permss, id: cliente?.id });
    const { getPerss, getRol } = useToken();
    const [siCliente, setSiCliente] = useState(false);
    const [empresa, setEmpresa] = useState(null);
    const { listaFormulario, listar, dialogInstruccion, setdialogIns } = FileComponent({ generic, tipo: 'clientePn', toast, permss, id: cliente?.id });

    addLocale(generic.idioma, generic.esFormat);

    useEffect(() => {
        setSiCliente(getPerss() !== undefined);
        if (getPerss() === undefined) {
            loadLazyData();
        }
        else {
            setPermss(getPerss());
            editFicha({ id: 0 });
        }
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            delete _lazyParams.empresa;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            if (empresa) {
                _lazyParams.empresa = empresa;
            }
            if (getRol()) {
                _lazyParams.rol = getRol();
            }
            clientepnSrv.getClientepn({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setClientespn(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
                if (res.data.cats) {
                    setCatalogos(res.data.cats);
                }
            });
        });
    }

    const openNew = () => {
        if (catalogos == null) {
            clientepnSrv.newClientepn({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setDialogClientepn(true);
                setdialogIns(true);
                setCliente(null);
            });
        }
        else {
            setDialogClientepn(true);
            setCliente(null);
            setdialogIns(true);
        }
    }

    const editFicha = (clientepn) => {
        if (catalogos == null) {
            clientepnSrv.newClientepn({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setDialogClientepn(true);
                if (clientepn.id === 0) {
                    clientepn = res.data.cliente ? { id: res.data.cliente, civil: res.data.civil } : null;
                }
                setCliente(clientepn);
                setActiveIndex(0);
                setdialogIns(true);
            });
        }
        else {
            setDialogClientepn(true);
            setCliente(clientepn);
            setActiveIndex(0);
            setdialogIns(true);
        }
    };

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el formulario?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteCliente(data)
        });
    }

    const deleteCliente = (data) => {
        clientepnSrv.eliminarClientepn(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const nuevoCliente = (nuevo, editar = false) => {
        let _cliente = nuevo;
        if (editar) {
            _cliente = cliente;
            _cliente.civil = nuevo.civil;
        }
        setCliente(nuevo);
    }

    const regresar = () => {
        setActiveIndex(0);
        setDialogClientepn(false);
        loadLazyData();
    }

    const handleNext = () => {
        if (activeIndex < 6) {
            const num = activeIndex === 1 && !cliente.civil ? 2 : 1;
            setActiveIndex(activeIndex + num);
        }
    };

    const handlePrev = () => {
        if (activeIndex > 0) {
            const num = activeIndex === 3 && !cliente.civil ? 2 : 1;
            setActiveIndex(activeIndex - num);
        }
    };

    const nextPrev = (btn = true) => {
        const btnNext = btn ? <Button label="Siguiente" type="submit" icon="pi pi-chevron-right" /> :
            <Button label="Siguiente" type="button" icon="pi pi-chevron-right" onClick={e => { handleNext(); }} />;
        return (
            <div className="formgrid grid">
                <div className="field col">
                    <Button label="Anterior" icon="pi pi-chevron-left" type="button" onClick={e => handlePrev()} />
                </div>
                <div className="field col">
                    {btnNext}
                </div>
            </div>
        )
    }

    const archivos = (data) => {
        const urls = "ficha/clientepn/listar";
        const urlsd = "ficha/clientepn/descarga";
        listar(data.id, urls, urlsd, data.nemonico);
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogClientepn && !siCliente && <>
                {getRol() && getRol()?.ideg && <FiltroMultiempresa loadLazyData={loadLazyData} setEmpresa={setEmpresa} />}
                <Toolbar className="mb-4" start={(getRol() && getRol()?.ideg) ? null : generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                    end={generic.rightToolbarTemplate(clientespn, omodel, 'Reporte_clientes', 'LISTADO CLIENTES PERSONA NATURAL')}></Toolbar>
                <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                    {table({ action3: generic.actionEditDetailTemplate, methEdit: editFicha, perm: permss, methDelete: confirmDelete, details: archivos, icono: 'pi pi-clone' })}
                </Card></>
            }
            {dialogClientepn &&
                <Panel header="INFORMACIÓN DE PERSONAS NATURALES" style={{ maxWidth: '100%', margin: 'auto' }}>
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Sección 1">
                            <DatosPn id={cliente?.id} siCliente={siCliente} next={handleNext} permss={permss} catalogos={catalogos} nuevoCliente={nuevoCliente} toast={toast} seccion="seccion1" />
                        </TabPanel>

                        <TabPanel header="Sección 2" disabled={cliente == null}>
                            <ActividadPn generic={generic} nextPrev={nextPrev} next={handleNext} permss={permss} id={cliente?.id} catalogos={catalogos} toast={toast} seccion="seccion2" />
                        </TabPanel>

                        <TabPanel header="Sección 3" disabled={cliente == null || !cliente?.civil}>
                            <ConyuguePn generic={generic} id={cliente?.id} permss={permss} nextPrev={nextPrev} next={handleNext} catalogos={catalogos} toast={toast} seccion="seccion3" />
                        </TabPanel>

                        <TabPanel header="Sección 4" disabled={cliente == null}>
                            <PepPn generic={generic} id={cliente?.id} permss={permss} nextPrev={nextPrev} next={handleNext} catalogos={catalogos} toast={toast} seccion="seccion4" />
                        </TabPanel>

                        <TabPanel header="Sección 5" disabled={cliente == null}>
                            <PartebPn generic={generic} id={cliente?.id} permss={permss} nextPrev={nextPrev} next={handleNext} catalogos={catalogos} toast={toast} seccion="seccion5" />
                        </TabPanel>

                        <TabPanel header="Sección 6" disabled={cliente == null}>
                            <AmpliadaPn id={cliente?.id} nextPrev={nextPrev} permss={permss} next={handleNext} toast={toast} tipo='clientePn' />
                        </TabPanel>

                        <TabPanel header="Sección 7" disabled={cliente == null}>
                            <DeclaracionPn id={cliente?.id} permss={permss} setDialogClientepn={setDialogClientepn} toast={toast} seccion='seccion7' cancelar={getPerss() == undefined} setdialogIns={setdialogIns} />
                        </TabPanel>
                    </TabView>
                </Panel>
            }
            <br />
            {dialogClientepn && <Button label="Regresar" icon="pi pi-replay" onClick={regresar} />}
            {listaFormulario('ficha/clientepn/aprobar', loadLazyData)}
            {dialogInstruccion(omodel.titulo, omodel.mensaje)}
        </>
    )

}
