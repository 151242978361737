import { Panel } from "primereact/panel"
import { DeclaracionCpn } from "../../../models/cpn/DeclaracionCpn"
import { useForm } from "react-hook-form";
import { Generico } from "../../../components/Generico";
import { InputComponent } from "../../../components/InputComponent";
import { ClientePNService } from "../../../service/ClientePNService";
import { useEffect, useState } from "react";
import { Divider } from 'primereact/divider';
import { FileComponent } from "../../../components/FileComponent";
import { GenericComponent } from "../../../components/GenericComponent";

export const DeclaracionPn = ({ id, setDialogClientepn, toast, seccion, permss, cancelar, setdialogIns }) => {

    const generic = new GenericComponent();
    const omodel = new DeclaracionCpn();
    const model = omodel.model;
    const { buttonsFormsReports, funcError, funcMessageBlobError, funDefault, funcSuccess, downloadPdf } = Generico();
    const defaultValues = funDefault(model);
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({ defaultValues });
    const { inArea } = InputComponent({ omodel, errors, control, permss, id });
    const clientepnSrv = new ClientePNService();
    const [loadReport, setLoadReport] = useState(false);
    const { upLoadFile, nuevoUpload, eliminarConfirmacionAnexo, anexoPendiente, enviarFormulario } =
        FileComponent({ generic, tipo: 'clientePn', toast, unuevo: 'ficha/clientepn/store', udelete: 'ficha/clientepn/eliminar-anexo', permss, id });

    useEffect(() => {
        editFicha();
    }, [id]);

    const onSubmit = (data) => {
        data.seccion = seccion;
        data.id = id;
        clientepnSrv.editarClientepn(data).then(res => {
            setdialogIns(true);
            funcSuccess(res, toast);
        }).catch(error => {
            funcError(error, toast, setError);
        });
    }

    const editFicha = () => {
        clientepnSrv.consultarClientepn(id, seccion).then(res => {
            let _clientepn = res.clientepn;
            if (!_clientepn.observacion) {
                _clientepn.observacion = '';
            }
            reset(_clientepn);
        });
    }

    const generarReporte = () => {
        setLoadReport(true);
        clientepnSrv.descargar(id).then(res => {
            downloadPdf(res, id + '-Formulario-cliente-PN.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);            
            funcMessageBlobError('Complete la información antes de generar.', error, toast);
        });
    }

    const subirReporte = () => {
        const urls = "ficha/clientepn/listar";
        const urlsd = "ficha/clientepn/descarga";
        nuevoUpload(id, 'Subir formulario y adjuntos', urls, urlsd, toast);
    }

    const enviar = () => {
        enviarFormulario(id, 'ficha/clientepn/enviar', 'clientePn');
    }

    return (
        <Panel header="PARTE D" className="panel-ficha">
            <Panel header="D.1 DECLARACIÓN" className="panel-ficha">
                <div className="field col" style={{ textAlign: 'justify', fontStyle: 'italic' }}>
                    {omodel.strDeclara}
                </div>
            </Panel>
            <br />

            <Panel header="OBSERVACIONES:" className="panel-ficha">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inArea('observacion', 5, 9000, 12)}
                    </div>

                    {buttonsFormsReports(setDialogClientepn, generarReporte, subirReporte, enviar, loadReport, id != null, cancelar)}
                </form>
            </Panel>
            <br />

            <Panel header="ANEXOS:" className="panel-ficha">
                <div className="formgrid grid">
                    <div className="field col-5">
                        {omodel.strAnexo}
                    </div>
                    <div className="field col-1">
                        <Divider layout="vertical" />
                    </div>
                    <div className="field col-6">
                        {omodel.strDependencia}
                    </div>
                </div>
            </Panel>
            {upLoadFile()}
            {eliminarConfirmacionAnexo()}
            {anexoPendiente('Enviar formulario - pendientes', 'Los siguientes anexos están pendientes de subir')}

        </Panel>
    )
}
